import { usePreviewContext } from "@/components/contexts/operation-preview-context";
import { DropdownOperationMore } from "@/components/dropdown-operation-more";
import { H5 } from "@/components/headings";
import LayoutRightDrawer, {
  ContentHeightContainer,
  LayoutSidebar,
  LayoutSidebarContent,
} from "@/components/layout-right-drawer";
import {
  EditorItemDescription,
  PreviewHeading,
  PreviewToolbarContainer,
} from "@/components/module-api-editor";
import {
  ActiveOperationItem,
  EditorPreviewOperationActiveItem,
} from "@/components/module-api-editor/editor-preview-operation-active-item";
import {
  ActiveElement,
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { useHandleCopy } from "@/components/module-api-editor/use-handle-copy";
import { OperationPreview } from "@/components/module-preview-pane/operation-preview";
import { AuthParameterCard } from "@/components/module-visual-editor/auth-parameters.card";
import { PathParametersCard } from "@/components/module-visual-editor/path-parameters-card";
import { RequestBodyCard } from "@/components/module-visual-editor/request-body-card";
import { RequestParameterCard } from "@/components/module-visual-editor/request-parameters-card";
import { ResponsesCard } from "@/components/module-visual-editor/responses-card";
import {
  BtnGroup,
  CopyButton,
  DeleteButton,
  EditButton,
  MoreButton,
} from "@/components/module-visual-editor/shared-components";
import { MultiSelectPopover } from "@/components/multi-select-popover";
import {
  SecondaryCard,
  SecondaryCardContent,
  SecondaryCardHeader,
} from "@/components/secondary-card";
import { HttpMethodBadge, TagBadge } from "@/components/special-badges";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { ColorBadge } from "@/components/ui/color-badge";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useOperation } from "@/hooks/use-operation";
import { useSecuritySchemes } from "@/hooks/use-security-schemes";
import { useServers } from "@/hooks/use-servers";
import { useTags } from "@/hooks/use-tags";
import { httpColorMap } from "@/lib/oas-tools/style-helpers";
import { OASComponentsObject, SupportedHTTPVerbs } from "@/lib/types";
import { canEdit, NormIcons, setClipboard, toastSuccess } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { PropsWithChildren, ReactNode, useMemo, useState } from "react";
import { toast } from "sonner";
import { useActionBarContext } from "../contexts/action-bar-context";

type Option = {
  id: string;
  label: string;
};

const templateOptions = [
  { id: "BearerJwt", label: "BearerJwt" },
  { id: "ApiKey", label: "ApiKey" },
  { id: "BearerBasic", label: "BearerBasic" },
  { id: "BearerCustom", label: "BearerCustom" },
];

export function AddAuthSelect({
  children,
  operationId,
  value,
  onChange,
}: PropsWithChildren<
  {
    operationId: string;
  } & EditorInputProps
>) {
  const {
    findAllSecuritySchemes,
    addSecuritySchemesToOperation,
    findOperationSecuritySchemeNames,
  } = useSecuritySchemes({ value, onChange });

  const defaultValues = useMemo(() => {
    return findOperationSecuritySchemeNames({ operationId });
  }, [findOperationSecuritySchemeNames, operationId]);

  const items = useMemo(() => {
    const securitySchemes = findAllSecuritySchemes();

    const optionArr: Option[] = [];

    Object.keys(securitySchemes).forEach((e) =>
      optionArr.push({ id: e, label: e })
    );

    templateOptions.forEach((templateOption) => {
      if (!optionArr.some((e) => e.id === templateOption.id)) {
        optionArr.push(templateOption);
      }
    });

    return optionArr;
  }, [findAllSecuritySchemes]);

  const handleValueChange = (schemeNames: string[]) => {
    addSecuritySchemesToOperation({ operationId, schemeNames });
  };

  return (
    <MultiSelectPopover
      items={items}
      value={defaultValues}
      onChange={handleValueChange}
    >
      {children}
    </MultiSelectPopover>
  );
}

export function LinkCard({
  title,
  icon,
  subtitle,
  onClick,
}: {
  title: ReactNode;
  icon: ReactNode;
  subtitle: string;
  onClick: () => unknown;
}) {
  return (
    <Card
      className="group border-0 py-0 text-sm"
      onClick={onClick}
      role="button"
    >
      <CardContent className="grid grid-cols-[max-content,1fr] justify-start items-start gap-x-2 pb-1 px-2">
        <span className="row-span-2">{icon}</span>
        <p className="group-hover:underline">{title}</p>
        <p className="group-hover:underline text-muted-foreground">
          {subtitle}
        </p>
      </CardContent>
    </Card>
  );
}

function LayoutPlaceholderSidebar({
  value,
  onChange,
  activeElement,
  componentsObject,
  workspaceRole,
}: EditorInputProps & {
  activeElement: ActiveElement;
  componentsObject: OASComponentsObject;
  workspaceRole: WorkspaceRole;
}) {
  const [, actionBarDispatch] = useActionBarContext();
  const [previewState, previewDispatch] = usePreviewContext();
  const { handleCopyAllOperations, handleCopyOperation, handleExportAsPng } =
    useHandleCopy({ onChange, value });

  const { findOperationsWithInfo: getOperationsWithInfo } = useOperation({
    value,
    onChange,
  });

  const handleExportOperationAsPng = async () => {
    if (!activeElement) return;
    await handleExportAsPng({ activeElement, componentsObject });
  };

  const handleCopyActive = async () => {
    if (!activeElement) return;
    await handleCopyOperation({ activeElement, componentsObject });
  };

  const handleCopyAll = async () => {
    const operationsWithInfo = getOperationsWithInfo();
    await handleCopyAllOperations({
      operationsWithInfo,
      componentsObject,
    });
  };

  const handleOpenCrudWizard = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "crud-wizard",
        context: {
          schemaName: "",
        },
      },
    });
  };

  const handleShowNestedComponents = (v: boolean) => {
    previewDispatch({
      type: "SET_SHOW_NESTED_COMPONENTS",
      payload: v,
    });
    toastSuccess(
      `${v ? "Enabled" : "Disabled"}: This affects copy operations and previews only.`
    );
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <LayoutSidebar className="bg-background py-0">
      <LayoutSidebarContent>
        <div className="px-4 grid grid-cols-1 gap-2 gap-y-2 text-left">
          <H5 className="text-muted-foreground pb-2">Quick actions</H5>
          <div className="flex items-center space-x-2 pb-2">
            <Label
              htmlFor="nested-components"
              className="font-normal text-muted-foreground grow"
            >
              Show nested components
            </Label>
            <Switch
              id="nested-components"
              checked={previewState.showNestedComponents}
              onCheckedChange={handleShowNestedComponents}
            />
          </div>
          {isEditor && (
            <LinkCard
              title="New operation"
              icon={<span>➕</span>}
              subtitle="Create operation"
              onClick={() =>
                actionBarDispatch({
                  type: "SET_PAGE",
                  payload: {
                    name: "add-operation",
                  },
                })
              }
            />
          )}
          {isEditor && (
            <LinkCard
              title="New tag"
              icon={<span>🏷️</span>}
              subtitle="Create operation tag"
              onClick={() =>
                actionBarDispatch({
                  type: "SET_PAGE",
                  payload: {
                    name: "add-tag",
                  },
                })
              }
            />
          )}
          <LinkCard
            title="Get PNG"
            icon={<span>📸</span>}
            subtitle="Render operation"
            onClick={handleExportOperationAsPng}
          />
          <LinkCard
            title="Copy operation"
            subtitle="Operation to clipboard"
            icon={<span>📗</span>}
            onClick={handleCopyActive}
          />
          <LinkCard
            title="Copy operations"
            subtitle="All operations to clipboard"
            icon={<span>📚</span>}
            onClick={handleCopyAll}
          />
          {isEditor && (
            <LinkCard
              title="CRUD Wizard"
              subtitle="Quickly create CRUD operations"
              icon={<span>🧙🏻</span>}
              onClick={handleOpenCrudWizard}
            />
          )}
        </div>
      </LayoutSidebarContent>
    </LayoutSidebar>
  );
}

export function EditorPreviewOperation({
  value,
  onChange,
  isAuthed,
  workspaceSlug,
  organizationSlug,
  activeElement,
  componentsObject,
  workspaceRole,
  extraToolbarItems,
}: {
  activeElement: NonNullable<ActiveElement>;
  isAuthed: boolean;
  workspaceSlug: string | undefined;
  organizationSlug: string | undefined;
  componentsObject: OASComponentsObject;
  workspaceRole: WorkspaceRole;
  extraToolbarItems: ReactNode;
} & EditorInputProps) {
  const { findDefaultServer, findServers } = useServers({ value, onChange });
  const { findAllTags: getAll } = useTags({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();
  const {
    findOperationWithInfo: getOperationWithInfo,
    removeOperation,
    editOperationAndParams,
  } = useOperation({
    value,
    onChange,
  });
  const { findOperationSecuritySchemeNames } = useSecuritySchemes({
    value,
    onChange,
  });
  const servers = useMemo(() => findServers(), [findServers]);
  const tags = useMemo(() => getAll(), [getAll]);

  const defaultServer = useMemo(() => findDefaultServer(), [findDefaultServer]);

  const [activeItem, setActiveItem] = useState<ActiveOperationItem | undefined>(
    undefined
  );
  const [serverSelectValue, setServerSelectValue] = useState(
    defaultServer ? defaultServer.server.url : ""
  );

  if (activeElement.type !== "operation")
    throw new Error("Active element needs to be operation");

  const operationWithInfo = useMemo(
    () => getOperationWithInfo(activeElement.operationId),
    [activeElement.operationId, getOperationWithInfo]
  );

  const operation = operationWithInfo.operation;
  if (!operation.operationId) throw new Error();
  const method = operationWithInfo.method;
  const urlPath = operationWithInfo.urlPath;
  const path = `paths.${urlPath}.${method}`;

  const securitySchems = useMemo(
    () =>
      findOperationSecuritySchemeNames({
        operationId: operation.operationId!,
      }),
    [findOperationSecuritySchemeNames, operation.operationId]
  );

  const handleEditClick = (urlPath: string, method: SupportedHTTPVerbs) => {
    if (!operation.operationId) throw new Error("Operation does not have id");
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-operation",
        context: {
          urlPath,
          method,
          operationId: operation.operationId,
        },
      },
    });
  };

  const handleDeleteClick = async (
    urlPath: string,
    method: SupportedHTTPVerbs
  ) => removeOperation(urlPath, method, activeElement, operation.operationId);

  const handleCopyUrlToClipboard = async () => {
    await setClipboard(
      serverSelectValue
        ? `${serverSelectValue}/${operationWithInfo.urlPath}`
        : operationWithInfo.urlPath
    );
    toast.success("Copied to clipboard");
  };

  const handleTagsChange = async (value: string[]) => {
    await editOperationAndParams({
      oldMethod: operationWithInfo.method,
      newMethod: operationWithInfo.method,
      oldUrlPath: operationWithInfo.urlPath,
      newUrlPath: operationWithInfo.urlPath,
      newOperation: { ...operationWithInfo.operation, tags: value },
    });
  };

  const color = httpColorMap[method];
  if (!color) throw new Error("Unable to find color for method");

  const isEditor = canEdit(workspaceRole);
  return (
    <Tabs defaultValue="editor" onValueChange={() => setActiveItem(undefined)}>
      <PreviewToolbarContainer
        value={value}
        onChange={onChange}
        workspaceRole={workspaceRole}
        isAuthed={isAuthed}
        workspaceSlug={workspaceSlug}
        organizationSlug={organizationSlug}
        extraToolbarItems={extraToolbarItems}
        tabs={
          <TabsList>
            <TabsTrigger value="editor">Editor</TabsTrigger>
            <TabsTrigger value="plain">Document</TabsTrigger>
          </TabsList>
        }
      >
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem className="hidden md:block">
              <Link search={(prev) => ({ ...prev, activeElement: undefined })}>
                <BreadcrumbLink asChild>
                  <span>Workspace</span>
                </BreadcrumbLink>
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="hidden md:block" />
            <BreadcrumbItem>
              <BreadcrumbPage>Operation</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </PreviewToolbarContainer>
      <LayoutRightDrawer
        placeholder={
          <LayoutPlaceholderSidebar
            value={value}
            onChange={onChange}
            activeElement={activeElement}
            componentsObject={componentsObject}
            workspaceRole={workspaceRole}
          />
        }
        sidebar={
          activeItem ? (
            <EditorPreviewOperationActiveItem
              value={value}
              onChange={onChange}
              activeItem={activeItem}
              onClose={() => setActiveItem(undefined)}
              operationId={operation.operationId}
              workspaceRole={workspaceRole}
            />
          ) : undefined
        }
      >
        <TabsContent
          value="editor"
          className="grow mt-0 overflow-auto rounded-md shadow-lg relative pb-10"
        >
          <div className="flex flex-col items-stretch gap-10 pt-2 grow">
            <div className="px-4 gap-y-2">
              <div className="flex justify-between">
                <PreviewHeading className="mb-1">
                  <div className="grid grid-cols-[max-content,max-content] gap-1 items-start">
                    {operationWithInfo.operation.summary}
                  </div>
                </PreviewHeading>
                {isEditor && (
                  <BtnGroup>
                    <DropdownOperationMore
                      operationWithInfo={operationWithInfo}
                    >
                      <EditButton />
                    </DropdownOperationMore>

                    <DeleteButton
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        await handleDeleteClick(urlPath, method);
                      }}
                    />
                  </BtnGroup>
                )}
              </div>
              <div className="pb-10 grid grid-cols-[repeat(3,max-content)] items-center gap-2">
                <HttpMethodBadge
                  method={operationWithInfo.method}
                  className="uppercase"
                  variant="outline"
                />
                <div className="flex items-center gap-1">
                  {servers.length > 0 ? (
                    <Select
                      value={serverSelectValue}
                      onValueChange={(v) => setServerSelectValue(v)}
                    >
                      <SelectTrigger className="text-md font-semibold h-5 px-0 pb-0 pt-0 basis-[min-content] focus:border-0 focus:ring-0 border-0 text-muted-foreground">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {servers.map((e) => (
                          <SelectItem key={e.server.url} value={e.server.url}>
                            {e.server.url}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  ) : isEditor ? (
                    <Link
                      search={(prev) => ({
                        ...prev,
                        activeElement: { type: "general_information" },
                      })}
                    >
                      <Button
                        size="link"
                        variant="ghost"
                        className="text-xs pt-0 h-min justify-start"
                      >
                        Create server
                      </Button>
                    </Link>
                  ) : (
                    <span />
                  )}
                  <p className="font-semibold">{urlPath}</p>
                </div>
                <CopyButton onClick={handleCopyUrlToClipboard}>
                  Copy url path
                </CopyButton>
              </div>
              <div className="col-span-2 grid grid-cols-2 gap-2 auto-rows-max">
                <SecondaryCard>
                  <SecondaryCardHeader>
                    <H5 className="text-muted-foreground">Access</H5>
                    {isEditor && (
                      <AddAuthSelect
                        operationId={operation.operationId}
                        value={value}
                        onChange={onChange}
                      >
                        <MoreButton Icon={NormIcons.Diff} />
                      </AddAuthSelect>
                    )}
                  </SecondaryCardHeader>
                  <SecondaryCardContent>
                    <BtnGroup>
                      {securitySchems.length > 0 ? (
                        securitySchems.map((e) => (
                          <ColorBadge color="muted" key={e}>
                            {e}
                          </ColorBadge>
                        ))
                      ) : (
                        <ColorBadge color="muted">Unspecified</ColorBadge>
                      )}
                    </BtnGroup>
                  </SecondaryCardContent>
                </SecondaryCard>

                <SecondaryCard>
                  <SecondaryCardHeader>
                    <H5 className="text-muted-foreground">Tags</H5>
                    {isEditor && (
                      <MultiSelectPopover
                        items={tags.map((e) => ({ id: e.name, label: e.name }))}
                        value={operation.tags || []}
                        onChange={handleTagsChange}
                      >
                        <MoreButton Icon={NormIcons.Diff} />
                      </MultiSelectPopover>
                    )}
                  </SecondaryCardHeader>
                  <BtnGroup>
                    {(operation.tags || []).length > 0 ? (
                      operation.tags?.map((e) => (
                        <TagBadge key={e}>{e}</TagBadge>
                      ))
                    ) : (
                      <TagBadge>Default</TagBadge>
                    )}
                  </BtnGroup>
                </SecondaryCard>
                <SecondaryCard className="col-span-2">
                  <SecondaryCardHeader className="">
                    <H5 className="text-muted-foreground">Description</H5>
                    {isEditor && (
                      <EditButton
                        onClick={() => handleEditClick(urlPath, method)}
                      />
                    )}
                  </SecondaryCardHeader>
                  <SecondaryCardContent className="pb-2">
                    <EditorItemDescription
                      className="text-sm max-w-none text-primary"
                      item={operation}
                      onAddDescriptionClick={undefined}
                      workspaceRole={workspaceRole}
                    />
                  </SecondaryCardContent>
                </SecondaryCard>
              </div>
            </div>
            <PathParametersCard
              operationId={operation.operationId}
              value={value}
              onChange={onChange}
              workspaceRole={workspaceRole}
              setActiveItem={setActiveItem}
              activeItem={activeItem}
            />
            <AuthParameterCard
              onChange={onChange}
              value={value}
              operationWithInfo={operationWithInfo}
              workspaceRole={workspaceRole}
              setActiveItem={setActiveItem}
              activeItem={activeItem}
            />
            <RequestParameterCard
              operationWithInfo={operationWithInfo}
              value={value}
              onChange={onChange}
              workspaceRole={workspaceRole}
              setActiveItem={setActiveItem}
              activeItem={activeItem}
            />
            <ResponsesCard
              operationId={operation.operationId}
              activeItem={activeItem}
              method={method}
              urlPath={urlPath}
              path={`${path}.responses`}
              value={value}
              onChange={onChange}
              workspaceRole={workspaceRole}
              setActiveItem={setActiveItem}
            />
            <RequestBodyCard
              operationId={operation.operationId}
              method={method}
              value={value}
              onChange={onChange}
              activeItem={activeItem}
              workspaceRole={workspaceRole}
              setActiveItem={setActiveItem}
            />
          </div>
        </TabsContent>
        <TabsContent
          value="plain"
          className="growoverflow-auto rounded-md shadow-lg relative"
        >
          <ContentHeightContainer className="px-4 pb-4">
            <div className="w-full min-h-full bg-preview-background">
              {activeElement?.type === "operation" && (
                <OperationPreview
                  activeElement={activeElement}
                  componentsObject={componentsObject}
                  value={value}
                  onChange={onChange}
                />
              )}
            </div>
          </ContentHeightContainer>
        </TabsContent>
      </LayoutRightDrawer>
    </Tabs>
  );
}
