import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { DashboardHeader } from "@/components/dashboard/dashboard-header";
import { WorkspaceInvitationPrompt } from "@/components/dashboard/workspace-invitation-promt";
import { WorkspaceList } from "@/components/dashboard/workspace-list";
import { PatchOrganizationForm } from "@/forms/form-organization";
import { FormPatchUser } from "@/forms/form-user";
import { companyInfo } from "@/lib/const";
import {
  organizationBySlugQuery,
  useOrganizationByIdOrSlug,
} from "@/queries/organizations";
import { useMe, userMeQuery } from "@/queries/users";
import {
  userWorkspacesQueryOptions,
  useUserWorkspaces,
} from "@/queries/workspaces";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/dash/$organizationSlug/home")({
  loader: async ({
    context: { queryClient },
    params: { organizationSlug },
  }) => {
    await queryClient.ensureQueryData(userWorkspacesQueryOptions());
    await queryClient.ensureQueryData(
      organizationBySlugQuery({ organizationSlug })
    );
    await queryClient.ensureQueryData(userMeQuery);
  },
  component: UserDashboard,
});

export function UserDashboard() {
  const organizationSlug = Route.useParams().organizationSlug;
  const organizationWorkspacesQuery = useUserWorkspaces();
  const organizationQuery = useOrganizationByIdOrSlug({ organizationSlug });

  const meQuery = useMe();

  const workspaceList = organizationWorkspacesQuery.data;
  const organizationDto = organizationQuery.data;
  const meDto = meQuery.data;

  if (!workspaceList || !organizationDto || !meDto) return null;

  return (
    <Tabs defaultValue="workspaces">
      <div className="max-w-screen-xl mx-auto px-norm">
        <DashboardHeader
          organizationSlug={organizationDto.slug}
          showUpgradeButton
          leftItems={
            <TabsList>
              <TabsTrigger value="workspaces">Workspaces</TabsTrigger>
              <TabsTrigger value="settings">Settings</TabsTrigger>
            </TabsList>
          }
        />
        <TabsContent value="workspaces">
          <WorkspaceInvitationPrompt />
          <WorkspaceList
            workspaceList={workspaceList}
            organizationDto={organizationDto}
          />
        </TabsContent>
        <TabsContent value="settings" className="grid grid-cols-1 gap-4">
          <Card>
            <CardHeader>
              <CardTitle>User settings</CardTitle>
            </CardHeader>
            <CardContent>
              <FormPatchUser
                userId={meDto.id}
                defaultValues={{ username: meDto.username }}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Organization settings</CardTitle>
            </CardHeader>
            <CardContent>
              <PatchOrganizationForm
                organizationId={organizationDto.id}
                defaultValues={{
                  name: organizationDto.name,
                  slug: organizationDto.slug,
                }}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Delete organization</CardTitle>
            </CardHeader>
            <CardContent>
              <p>
                To delete your organization and all its users, send an email to{" "}
                <a
                  href={`mailto:${companyInfo.supportEmail}`}
                  className="underline"
                >
                  {companyInfo.supportEmail}
                </a>
                .
              </p>
            </CardContent>
          </Card>
        </TabsContent>
      </div>
    </Tabs>
  );
}
