export const xFields = {
  dtoInfo: "x-fiddle-dto-info",
} as const;

export function getDtoXFields(baseSchemaName: string) {
  // eslint-disable-next-line
  return {
    [xFields.dtoInfo]: {
      baseSchemaName: baseSchemaName,
    },
  } as any;
}
