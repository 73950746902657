import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  createDtoInDefinition,
  removeDtoFromDefinition,
  patchDtoInDefinition,
} from "@/lib/editor-mutations/oas-dto";
import { DefinitionNotDefinedError } from "@/lib/errors";
import { xFields } from "@/lib/oas-tools/x-fields";
import { OASDefinition, OASSchema } from "@/lib/types";
import { useCallback } from "react";

export type DtoSchemaWithInfo = {
  dtoSchema: OASSchema;
  name: string;
};

function getSchemaObjectFromDefinition(definition: OASDefinition) {
  return definition.components?.schemas || {};
}

export function useSchemaDtos(props: EditorInputProps) {
  const { oas, componentsObject, setNewEditorState } = useAPIEditorTools(props);
  if (!oas) throw new DefinitionNotDefinedError();

  const getBaseSchemaNameOrError = useCallback((schema: OASSchema) => {
    const baseSchemaName = schema["x-fiddle-dto-info"]?.baseSchemaName;
    if (!baseSchemaName) throw new Error("xField is not set for dto");
    return baseSchemaName;
  }, []);

  const getSchemaDtoOrError = useCallback(
    (dtoName: string): OASSchema => {
      const schemaEntries = Object.entries(componentsObject.schemas || {});
      const found = schemaEntries.find(
        ([name, schema]) => name === dtoName && schema[xFields.dtoInfo]
      );
      if (!found) throw new Error("Dto schema not found");
      const requestBodyObject = found[1];
      return requestBodyObject;
    },
    [componentsObject.schemas]
  );

  const getDtoSchemasWithInfo = useCallback((): DtoSchemaWithInfo[] => {
    const schemaObject = getSchemaObjectFromDefinition(oas);
    return Object.entries(schemaObject).map(([name, schema]) => {
      return {
        dtoSchema: schema,
        name,
      };
    });
  }, [oas]);

  const getDtoSchemasForSchema = useCallback(
    (schemaName: string): DtoSchemaWithInfo[] => {
      const dtoSchemas = getDtoSchemasWithInfo();
      return dtoSchemas.filter(
        (e) => e.dtoSchema["x-fiddle-dto-info"]?.baseSchemaName === schemaName
      );
    },
    [getDtoSchemasWithInfo]
  );

  const add = useCallback(
    (dtoName: string, schema: OASSchema, baseSchemaName: string) => {
      const newDefinition = createDtoInDefinition({
        definition: oas,
        dtoName,
        dtoSchema: schema,
        baseSchemaName,
      });
      setNewEditorState({
        document_type: props.value.document_type,
        data: newDefinition,
      });
    },
    [oas, setNewEditorState, props.value.document_type]
  );

  const update = useCallback(
    (
      oldDtoName: string,
      newDtoName: string,
      schema: OASSchema,
      baseSchemaName: string
    ) => {
      const newDefinition = patchDtoInDefinition({
        oldDtoName,
        newDtoName,
        definition: oas,
        dtoSchema: schema,
        baseSchemaName,
      });
      setNewEditorState({
        data: newDefinition,
        document_type: props.value.document_type,
      });
    },
    [oas, setNewEditorState, props.value.document_type]
  );

  const remove = useCallback(
    (dtoName: string) => {
      const newDefinition = removeDtoFromDefinition(oas, dtoName);

      setNewEditorState({
        data: newDefinition,
        document_type: props.value.document_type,
      });
    },
    [oas, setNewEditorState, props.value.document_type]
  );

  return {
    getSchemaDtoOrError,
    getDtoSchemasForSchema,
    getDtoSchemasWithInfo,
    getBaseSchemaNameOrError,
    add,
    update,
    remove,
  };
}
