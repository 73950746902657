import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  addTagToDefinition,
  editTagsInDefinition,
  findTagInDefinition,
  findTagsInDefinition,
  removeTagFromDefinition,
} from "@/lib/editor-mutations/oas-tags";
import { OASTag } from "@/lib/types";
import { useCallback } from "react";

export function useTags({ value, onChange }: EditorInputProps) {
  const { setNewEditorState, getOASOrError } = useAPIEditorTools({
    value,
    onChange,
  });

  const update = useCallback(
    ({ tagName, tagObject }: { tagName: string; tagObject: OASTag }) => {
      const definition = getOASOrError();
      const newDefinition = editTagsInDefinition({
        definition,
        tagName,
        tagObject: { ...tagObject, name: tagObject.name },
      });

      setNewEditorState({ document_type: "oas_api_3_1", data: newDefinition });
    },
    [setNewEditorState, getOASOrError]
  );

  const create = useCallback(
    (tagObject: OASTag) => {
      const definition = getOASOrError();
      const newDefinition = addTagToDefinition({
        definition,
        tagObject: { ...tagObject, name: tagObject.name.toLocaleLowerCase() },
      });

      setNewEditorState({ document_type: "oas_api_3_1", data: newDefinition });
    },
    [setNewEditorState, getOASOrError]
  );

  const findTag = useCallback(
    (tagName: string) => {
      const definition = getOASOrError();
      return findTagInDefinition({
        definition,
        tagName: tagName.toLowerCase(),
      });
    },
    [getOASOrError]
  );

  const remove = useCallback(
    (tagName: string) => {
      const definition = getOASOrError();
      const newDefinition = removeTagFromDefinition({ definition, tagName });
      if (!newDefinition) return;
      setNewEditorState({ document_type: "oas_api_3_1", data: newDefinition });
    },
    [getOASOrError, setNewEditorState]
  );

  const findAllTags = useCallback(() => {
    return findTagsInDefinition({ definition: value.data });
  }, [value.data]);

  return {
    update,
    create,
    findAllTags,
    remove,
    findTag,
  };
}
