import { H3 } from "@/components/headings";
import { ActionBarFormTitle } from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { SuggestCard } from "@/components/suggest-card";
import { Button } from "@/components/ui/button";
import { useComponentResponses } from "@/hooks/use-component-responses";
import { useResponses } from "@/hooks/use-responses";
import {
  HttpStatus,
  httpStatusMap,
  supportedHttpStatusCodes,
} from "@/lib/helpers";
import { httpStatusDescriptions } from "@/lib/oas-tools/http-status-default-description";
import { getDefaultSchemaForResponseCode } from "@/lib/oas-tools/https-status-default-schemas";
import {
  httpMethodResponseCodes,
  TemplateResponseCodes,
} from "@/lib/oas-tools/https-verb-status-codes";
import { SupportedContentFormats, SupportedHTTPVerbs } from "@/lib/types";
import {
  DEFAULT_ICON_SIZE,
  safeGet,
  toastError,
  toastSuccess,
} from "@/lib/utils";
import { ArrowRight } from "lucide-react";
import { useActionBarContext } from "../contexts/action-bar-context";
import { ActionBarContent } from "./action-bar-content";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

export function SuggestedResponses({
  method,
  value,
  onChange,
  operationId,
}: {
  method: SupportedHTTPVerbs;
  operationId: string;
} & EditorInputProps) {
  const { addResponse } = useResponses({ value, onChange });
  const {
    addTemplateComponentResponseToOperation,
    addComponentResponseToOperation,
  } = useComponentResponses({
    value,
    onChange,
  });
  const [, dispatch] = useActionBarContext();

  const suggestedResponseCodes = httpMethodResponseCodes[method];
  const allResponseCodes = supportedHttpStatusCodes;

  const handleTemplateAdd = (
    responseCode: HttpStatus,
    description: string,
    { onSuccess }: { onSuccess?: () => unknown }
  ) => {
    const response = getDefaultSchemaForResponseCode(responseCode);
    addResponse({
      responseCode,
      operationId,
      onSuccess,
      response: {
        description: description || `Response for code: ${responseCode}`,
        content: { "application/json": { schema: response } },
      },
    });
  };

  const handleComponentResponseAdd = (
    responseCode: TemplateResponseCodes,
    { onSuccess }: { onSuccess: () => unknown }
  ) => {
    addTemplateComponentResponseToOperation({
      operationId,
      responseCode,
      onSuccess,
      format: "application/json",
    });
  };

  const handleAddGenericResponse = (
    responseCode: HttpStatus,
    format: SupportedContentFormats,
    { onSuccess }: { onSuccess: () => unknown }
  ) => {
    const description = httpStatusDescriptions[responseCode].description;
    const codeDescription = httpStatusMap[responseCode];
    const defaultSchema = getDefaultSchemaForResponseCode(responseCode);
    try {
      addComponentResponseToOperation({
        componentResponseName: codeDescription.message,
        operationId,
        responseCode,
        newResponse: {
          description,
          content: {
            [format]: {
              schema: defaultSchema,
            },
          },
        },
      });
      toastSuccess("Response added.");
      onSuccess();
    } catch (err) {
      toastError(err);
    }
  };

  const handleDone = () => {
    dispatch({
      type: "CLOSE",
    });
  };

  return (
    <ActionBarContent>
      <ActionBarFormTitle>Suggested responses</ActionBarFormTitle>
      <Tabs defaultValue="recommended">
        <TabsList>
          <TabsTrigger value="recommended">Recommended</TabsTrigger>
          <TabsTrigger value="all">All</TabsTrigger>
        </TabsList>
        <TabsContent
          value="recommended"
          className="max-h-[500px] overflow-auto"
        >
          {suggestedResponseCodes.custom.length > 0 && (
            <>
              <H3 className="pb-3 pt-2 pl-0">Add custom responses</H3>
              <div className="grid grid-cols-2 gap-2 pr-2">
                {suggestedResponseCodes.custom.map((responseCode) => {
                  const description =
                    safeGet(httpStatusDescriptions, responseCode)
                      ?.description || "";
                  return (
                    <SuggestCard
                      key={responseCode}
                      title={`${responseCode} ${httpStatusMap[responseCode].message}`}
                      description={description}
                      onAdd={({ onSuccess }) =>
                        handleTemplateAdd(responseCode, description, {
                          onSuccess,
                        })
                      }
                    />
                  );
                })}
              </div>
            </>
          )}
          {suggestedResponseCodes.authComponents.length > 0 && (
            <>
              <H3 className="pb-3 pt-2 pl-0">Resusable auth responses</H3>
              <div className="grid grid-cols-2 gap-2 pr-2">
                {suggestedResponseCodes.authComponents.map((responseCode) => {
                  const description =
                    safeGet(httpStatusDescriptions, responseCode)
                      ?.description || "";
                  return (
                    <SuggestCard
                      key={responseCode}
                      title={`${responseCode} ${httpStatusMap[responseCode].message}`}
                      description={description}
                      onAdd={({ onSuccess }) =>
                        handleTemplateAdd(responseCode, description, {
                          onSuccess,
                        })
                      }
                    />
                  );
                })}
              </div>
            </>
          )}
          <H3 className="pb-3 pt-norm pl-0">More reusable responses</H3>
          <div className="grid grid-cols-2 gap-2 pr-2 pb-norm">
            {suggestedResponseCodes.components.map((responseCode) => {
              const description =
                safeGet(httpStatusDescriptions, responseCode)?.description ||
                "";
              return (
                <SuggestCard
                  key={responseCode}
                  title={`${responseCode} ${httpStatusMap[responseCode].message}`}
                  description={description}
                  onAdd={({ onSuccess }) =>
                    handleComponentResponseAdd(responseCode, { onSuccess })
                  }
                />
              );
            })}
          </div>
        </TabsContent>
        <TabsContent value="all" className="max-h-[500px] overflow-auto">
          <H3 className="pb-3 pt-norm pl-0">Add reusable responses</H3>
          <div className="grid grid-cols-2 gap-2 pr-2 pb-norm">
            {allResponseCodes.map((responseCode) => {
              const description =
                safeGet(httpStatusDescriptions, responseCode)?.description ||
                "";
              return (
                <SuggestCard
                  key={responseCode}
                  title={`${responseCode} ${httpStatusMap[responseCode].message}`}
                  description={description}
                  onAdd={({ onSuccess }) =>
                    handleAddGenericResponse(responseCode, "application/json", {
                      onSuccess,
                    })
                  }
                />
              );
            })}
          </div>
        </TabsContent>
      </Tabs>
      <div className="max-h-[500px] overflow-auto"></div>
      <BtnGroup className="justify-end pt-4">
        <Button onClick={handleDone} size="sm">
          Done
          <ArrowRight size={DEFAULT_ICON_SIZE} />
        </Button>
      </BtnGroup>
    </ActionBarContent>
  );
}
