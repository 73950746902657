import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { OnSchemaChange } from "@/components/schema-editor";
import {
  EditGenericMetadataForm,
  EditIntegerForm,
  EditNumberForm,
  EditReferenceForm,
  EditStringForm,
  GenericMetadataFormValues,
  IntegerFormOutputValues,
  NumberFormOutputValues,
  StringFormValues,
} from "@/forms/form-advanced-type-options";
import { OASSchemaRow } from "@/lib/oas-tools/generate-schema-rows";
import {
  changeGenericMetaData,
  changeIntegerPropertyMetaData,
  changeNumberPropertyMetaData,
  changeReferenceMetaData,
  changeStringPropertyMetaData,
  getPathProperty,
  resolveReferenceSchema,
  unwrapPathSchemaFromArray,
  wrapPathSchemaInArray,
} from "@/lib/oas-tools/oas-schema-utils";
import {
  isArraySchema,
  isMixedSchema,
  isObjectSchema,
  isReferenceSchema,
} from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASComponentsObject,
  OASNonArraySchemaObject,
  OASSchema,
  StandardDialogProps,
} from "@/lib/types";
import { toastError, toastSuccess } from "@/lib/utils";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { FormToolbar, FormToolbarButton } from "@/forms";
import { NormIcon } from "@/components/norm-icon";
import { typeIconMap } from "@/lib/oas-tools/style-helpers";

function WrapInArrayButton({
  onChange,
  onClose,
  rootSchema,
  path,
}: {
  onChange: OnSchemaChange;
  onClose: () => unknown;
  rootSchema: OASSchema;
  path: string;
}) {
  const disabled = useMemo(() => {
    const property = getPathProperty(rootSchema, path);
    return isArraySchema(property);
  }, [path, rootSchema]);

  const wrapPropertyInArray = useCallback(
    (schema: OASSchema, path: string) => {
      try {
        const newSchema = wrapPathSchemaInArray(schema, path);
        onChange(newSchema);
        toastSuccess("Schema modified.");
        onClose();
      } catch (err) {
        toastError(err);
      }
    },
    [onChange, onClose]
  );
  const Icon = typeIconMap.array;

  if (disabled) return null;
  return (
    <FormToolbarButton onClick={() => wrapPropertyInArray(rootSchema, path)}>
      <Icon className="mr-2 size-3" />
      Wrap in array
    </FormToolbarButton>
  );
}

function UnwrapInArrayButton({
  onChange,
  onClose,
  rootSchema,
  path,
}: {
  onChange: OnSchemaChange;
  onClose: () => unknown;
  rootSchema: OASSchema;
  path: string;
}) {
  const disabled = useMemo(() => {
    const property = getPathProperty(rootSchema, path);
    return !isArraySchema(property);
  }, [path, rootSchema]);

  const unwrapPropertyFromArray = useCallback(
    (schema: OASSchema, path: string) => {
      try {
        const newSchema = unwrapPathSchemaFromArray(schema, path);
        onChange(newSchema);
        toastSuccess("Schema modified.");
        onClose();
      } catch (err) {
        toastError(err);
      }
    },
    [onChange, onClose]
  );
  const Icon = typeIconMap.array;

  if (disabled) return null;
  return (
    <FormToolbarButton
      onClick={() => unwrapPropertyFromArray(rootSchema, path)}
    >
      <Icon className="mr-2 size-3" />
      Unwrap array
    </FormToolbarButton>
  );
}

type ContentProps = {
  schema: OASSchema;
  rootSchema: OASSchema;
  path: string;
  onChange: OnSchemaChange;
  componentsObject: OASComponentsObject;
  onClose: () => unknown;
};

function ReferenceContent({
  schema,
  rootSchema,
  path,
  onChange,
  onClose,
  componentsObject,
}: ContentProps) {
  const handleSubmit: SubmitHandler<GenericMetadataFormValues> = (values) => {
    try {
      const newRootSchema = changeReferenceMetaData({
        rootSchema: rootSchema,
        schema: schema,
        path: path,
        propertyMetaData: {
          description: values.description,
        },
      });

      onChange(newRootSchema);
      onClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleResolveReference = () => {
    try {
      const newSchema = resolveReferenceSchema(
        rootSchema,
        path,
        componentsObject
      );
      onChange(newSchema);
      onClose();
      toastSuccess("Reference resolved");
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <EditReferenceForm
      title="Update reference"
      toolbar={
        <FormToolbar>
          <FormToolbarButton onClick={handleResolveReference}>
            <NormIcon name="Component" className="mr-2" size="xs" />
            Resolve reference
          </FormToolbarButton>
          <WrapInArrayButton
            onChange={onChange}
            onClose={onClose}
            rootSchema={rootSchema}
            path={path}
          />
          <UnwrapInArrayButton
            onChange={onChange}
            onClose={onClose}
            rootSchema={rootSchema}
            path={path}
          />
        </FormToolbar>
      }
      btnTitle="Save"
      isDisabled={false}
      onSubmit={handleSubmit}
      defaultValues={{
        description: schema.description || "",
      }}
    />
  );
}

function GenericContent({
  schema,
  rootSchema,
  path,
  onClose,
  onChange,
}: ContentProps) {
  const handleSubmit: SubmitHandler<GenericMetadataFormValues> = (values) => {
    try {
      const newRootSchema = changeGenericMetaData({
        rootSchema: rootSchema,
        schema: schema,
        path: path,
        propertyMetaData: {
          description: values.description,
        },
      });

      onChange(newRootSchema);
      onClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <EditGenericMetadataForm
      title="Edit property"
      btnTitle="Edit"
      toolbar={
        <FormToolbar>
          <WrapInArrayButton
            onChange={onChange}
            onClose={onClose}
            rootSchema={rootSchema}
            path={path}
          />
          <UnwrapInArrayButton
            onChange={onChange}
            onClose={onClose}
            rootSchema={rootSchema}
            path={path}
          />
        </FormToolbar>
      }
      isDisabled={false}
      onSubmit={handleSubmit}
      defaultValues={{
        description: schema.description || "",
      }}
    />
  );
}

function StringContent({
  schema,
  rootSchema,
  path,
  onClose,
  onChange,
}: ContentProps) {
  const handleSubmit: SubmitHandler<StringFormValues> = (values) => {
    try {
      const newRootSchema = changeStringPropertyMetaData({
        rootSchema: rootSchema,
        schema: schema,
        path: path,
        propertyMetaData: {
          description: values.description,
          enum: values.enumValues,
          default: values.default,
        },
      });

      onChange(newRootSchema);
      onClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <EditStringForm
      title="Edit string format"
      btnTitle="Edit"
      toolbar={
        <FormToolbar>
          <WrapInArrayButton
            onChange={onChange}
            onClose={onClose}
            rootSchema={rootSchema}
            path={path}
          />
        </FormToolbar>
      }
      isDisabled={false}
      onSubmit={handleSubmit}
      defaultValues={{
        description: schema.description || "",
        default: (schema.default as string) || "",
        enumValues: schema.enum || [],
      }}
    />
  );
}

function NumberContent({
  schema,
  rootSchema,
  path,
  onClose,
  onChange,
}: ContentProps) {
  const handleSubmit: SubmitHandler<NumberFormOutputValues> = (values) => {
    try {
      const newRootSchema = changeNumberPropertyMetaData({
        rootSchema: rootSchema,
        schema: schema,
        path: path,
        propertyMetaData: {
          description: values.description,
          maximum: values.maximum,
          minimum: values.minimum,
          format: values.format,
        },
      });

      onChange(newRootSchema);
      onClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <EditNumberForm
      title="Edit number format"
      btnTitle="Edit"
      toolbar={
        <FormToolbar>
          <WrapInArrayButton
            onChange={onChange}
            onClose={onClose}
            rootSchema={rootSchema}
            path={path}
          />
        </FormToolbar>
      }
      isDisabled={false}
      onSubmit={handleSubmit}
      defaultValues={{
        description: schema.description || "",
        maximum: schema.maximum ? String(schema.maximum) : "",
        minimum: schema.minimum ? String(schema.minimum) : "",
        format: schema.format as "float" | "double" | undefined,
      }}
    />
  );
}

function IntegerContent({
  schema,
  rootSchema,
  path,
  onChange,
  onClose,
}: ContentProps) {
  const handleSubmit: SubmitHandler<IntegerFormOutputValues> = (values) => {
    try {
      const newRootSchema = changeIntegerPropertyMetaData({
        rootSchema: rootSchema,
        schema: schema,
        path: path,
        propertyMetaData: {
          description: values.description,
          maximum: values.maximum,
          minimum: values.minimum,
          format: values.format,
        },
      });

      onChange(newRootSchema);
      onClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <EditIntegerForm
      title="Edit integer format"
      btnTitle="Edit"
      toolbar={
        <FormToolbar>
          <WrapInArrayButton
            onChange={onChange}
            onClose={onClose}
            rootSchema={rootSchema}
            path={path}
          />
        </FormToolbar>
      }
      isDisabled={false}
      onSubmit={handleSubmit}
      defaultValues={{
        description: schema.description || "",
        maximum: schema.maximum ? String(schema.maximum) : "",
        minimum: schema.minimum ? String(schema.minimum) : "",
        format: (schema.format as "int32" | "int64") || "",
      }}
    />
  );
}

export function SchemaEditorAdvancedTypeDialog({
  children,
  schemaRow,
  onChange,
  isOpen,
  onClose,
  componentsObject,
}: PropsWithChildren<
  {
    schemaRow: OASSchemaRow;
    onChange: OnSchemaChange;
    componentsObject: OASComponentsObject;
  } & StandardDialogProps
>) {
  const content = useMemo(() => {
    if (isReferenceSchema(schemaRow.schema))
      return (
        <ReferenceContent
          schema={schemaRow.schema}
          onChange={onChange}
          path={schemaRow.path}
          rootSchema={schemaRow.rootSchema}
          componentsObject={componentsObject}
          onClose={onClose}
        />
      );

    if (
      isObjectSchema(schemaRow.schema) ||
      isArraySchema(schemaRow.schema) ||
      isMixedSchema(schemaRow.schema)
    ) {
      return (
        <GenericContent
          schema={schemaRow.schema}
          onChange={onChange}
          path={schemaRow.path}
          rootSchema={schemaRow.rootSchema}
          componentsObject={componentsObject}
          onClose={onClose}
        />
      );
    }
    // TODO: Issue around narrowing. IsObjectSchema narrows down to NonArraySchema and returns.
    // This leads to schema being never here
    const schema = schemaRow.schema as OASNonArraySchemaObject;

    switch (schema.type) {
      case "string":
        return (
          <StringContent
            schema={schemaRow.schema}
            onChange={onChange}
            path={schemaRow.path}
            rootSchema={schemaRow.rootSchema}
            componentsObject={componentsObject}
            onClose={onClose}
          />
        );
      case "number":
        return (
          <NumberContent
            schema={schemaRow.schema}
            onChange={onChange}
            path={schemaRow.path}
            rootSchema={schemaRow.rootSchema}
            componentsObject={componentsObject}
            onClose={onClose}
          />
        );
      case "integer":
        return (
          <IntegerContent
            schema={schemaRow.schema}
            onChange={onChange}
            path={schemaRow.path}
            rootSchema={schemaRow.rootSchema}
            componentsObject={componentsObject}
            onClose={onClose}
          />
        );
      case "boolean":
      case "null":
        return (
          <GenericContent
            schema={schemaRow.schema}
            onChange={onChange}
            path={schemaRow.path}
            rootSchema={schemaRow.rootSchema}
            componentsObject={componentsObject}
            onClose={onClose}
          />
        );
      default:
        return null;
    }
  }, [
    onChange,
    onClose,
    schemaRow.path,
    schemaRow.schema,
    schemaRow.rootSchema,
    componentsObject,
  ]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader></DialogHeader>
        <div>{content}</div>
      </DialogContent>
    </Dialog>
  );
}
