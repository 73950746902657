import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { MutationCallbacks } from "@/hooks";
import {
  createSecuritySchemeComponentInDefinition,
  addSecuritySchemesOrTemplatesToOperationInDefinition,
  findOperationSecuritySchemeNamesInDefinition,
  findSecuritySchemeInDefinition,
  findSecuritySchmesInDefinition,
  removeSecuritySchemeComponentFromDefinition,
  updateSecuritySchemeInDefinition,
} from "@/lib/editor-mutations/oas-security-schemes";
import { OASSecuritySchemeObject } from "@/lib/types";
import { toastError } from "@/lib/utils";
import { useCallback } from "react";

export function useSecuritySchemes({ value, onChange }: EditorInputProps) {
  const { setNewEditorState } = useAPIEditorTools({ value, onChange });

  const findAllSecuritySchemes = useCallback(() => {
    return findSecuritySchmesInDefinition({ definition: value.data });
  }, [value.data]);

  const findSecurityScheme = useCallback(
    (schemeName: string) => {
      return findSecuritySchemeInDefinition({
        schemeName,
        definition: value.data,
      });
    },
    [value.data]
  );

  const getSecuritySchemeOrError = useCallback(
    (schemeName: string) => {
      const found = findSecurityScheme(schemeName);
      if (!found) throw new Error("Not found: Security scheme not found.");
      return found;
    },
    [findSecurityScheme]
  );

  const addSecurityScheme = useCallback(
    ({
      securityScheme,
      schemeName,
      onSettled,
      onSuccess,
      onError,
    }: {
      securityScheme: OASSecuritySchemeObject;
      schemeName: string;
    } & MutationCallbacks) => {
      try {
        const newEditorData = createSecuritySchemeComponentInDefinition({
          definition: value.data,
          securityScheme,
          schemeName,
        });
        setNewEditorState({
          data: newEditorData,
          document_type: value.document_type,
        });
        onSuccess?.();
      } catch (err) {
        toastError(err);
        onError?.();
      }
      onSettled?.();
    },
    [value.data, setNewEditorState, value.document_type]
  );

  const editSecuritySchema = useCallback(
    ({
      oldSchemeName,
      newScheme,
      newSchemeName,
      onSuccess,
      onError,
      onSettled,
    }: {
      oldSchemeName: string;
      newSchemeName: string;
      newScheme: OASSecuritySchemeObject;
    } & MutationCallbacks) => {
      try {
        const newEditorData = updateSecuritySchemeInDefinition({
          definition: value.data,
          oldSchemeName,
          newSchemeName,
          newScheme,
        });
        setNewEditorState({
          data: newEditorData,
          document_type: value.document_type,
        });
        onSuccess?.();
      } catch (err) {
        toastError(err);
        onError?.();
      }
      onSettled?.();
    },
    [value.data, value.document_type, setNewEditorState]
  );

  const removeSecuritySchema = useCallback(
    ({
      schemeName,
      onSettled,
      onSuccess,
      onError,
    }: { schemeName: string } & MutationCallbacks) => {
      try {
        const newEditorData = removeSecuritySchemeComponentFromDefinition({
          definition: value.data,
          schemeName,
        });
        setNewEditorState({
          data: newEditorData,
          document_type: value.document_type,
        });
        onSuccess?.();
      } catch (err) {
        toastError(err);
        onError?.();
      }
      onSettled?.();
    },
    [value.data, value.document_type, setNewEditorState]
  );

  const addSecuritySchemesToOperation = useCallback(
    ({
      operationId,
      schemeNames,
    }: {
      operationId: string;
      schemeNames: string[];
    }) => {
      const requirementsObjectArr = schemeNames.map((e) => ({ [e]: [] }));
      try {
        const newEditorData =
          addSecuritySchemesOrTemplatesToOperationInDefinition({
            definition: value.data,
            operationId,
            requirementsObjectArr,
          });
        setNewEditorState({
          data: newEditorData,
          document_type: value.document_type,
        });
      } catch (err) {
        toastError(err);
      }
    },
    [value.data, value.document_type, setNewEditorState]
  );

  const findOperationSecuritySchemeNames = useCallback(
    ({ operationId }: { operationId: string }) => {
      return findOperationSecuritySchemeNamesInDefinition({
        definition: value.data,
        operationId,
      });
    },
    [value.data]
  );

  return {
    findOperationSecuritySchemeNames,
    addSecuritySchemesToOperation,
    removeSecuritySchema,
    addSecurityScheme,
    getSecuritySchemeOrError,
    editSecuritySchema,
    findSecurityScheme,
    findAllSecuritySchemes,
  };
}
