import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  addServerToDefinition,
  findServerInDefinitionOrError,
  findServersInDefinition,
  removeServerFromDefinition,
  updateServerInDefinition,
} from "@/lib/editor-mutations/oas-servers";
import { OASServer } from "@/lib/types";
import { useCallback } from "react";
import { toast } from "sonner";

export function useServers({ value, onChange }: EditorInputProps) {
  const { setNewEditorState } = useAPIEditorTools({ value, onChange });

  const addServer = useCallback(
    (serverObject: OASServer) => {
      const newDefinition = addServerToDefinition({
        definition: value.data,
        serverObject,
      });
      setNewEditorState({
        data: newDefinition,
        document_type: value.document_type,
      });
      toast.success("Server added");
    },
    [setNewEditorState, value.data, value.document_type]
  );

  const updateServer = useCallback(
    ({ idx, newServerObject }: { idx: number; newServerObject: OASServer }) => {
      const newDefinition = updateServerInDefinition({
        definition: value.data,
        idx,
        newServerObject,
      });
      setNewEditorState({
        data: newDefinition,
        document_type: value.document_type,
      });
      toast.success("Server updated");
    },
    [setNewEditorState, value.data, value.document_type]
  );

  const removeServer = useCallback(
    (idx: number) => {
      const newDefinition = removeServerFromDefinition({
        definition: value.data,
        idx,
      });
      setNewEditorState({
        data: newDefinition,
        document_type: value.document_type,
      });
    },
    [setNewEditorState, value.data, value.document_type]
  );

  const findServers = useCallback(() => {
    return findServersInDefinition({ definition: value.data });
  }, [value.data]);

  const findDefaultServer = useCallback(() => {
    const servers = findServers();
    return servers[0];
  }, [findServers]);

  const findServer = useCallback(
    (idx: number) => {
      const found = findServerInDefinitionOrError({
        definition: value.data,
        idx,
      });
      return found;
    },
    [value.data]
  );

  return {
    addServer,
    removeServer,
    findServer,
    findServers,
    updateServer,
    findDefaultServer,
  };
}
