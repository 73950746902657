import { QueryClient } from "@tanstack/react-query";
import {
  createRootRouteWithContext,
  Link,
  Outlet,
} from "@tanstack/react-router";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { TooltipProvider } from "@/components/ui/tooltip";
import { Toaster } from "@/components/ui/sonner";

export const Route = createRootRouteWithContext<{ queryClient: QueryClient }>()(
  {
    component: () => (
      <main className="dark h-screen overflow-auto">
        <TooltipProvider>
          <Outlet />
          <Toaster />
          {/* <ReactQueryDevtools buttonPosition="bottom-right" /> */}
          {/* 
          <TanStackRouterDevtools position="bottom-right" /> */}
        </TooltipProvider>
      </main>
    ),
    notFoundComponent: () => {
      return (
        <div>
          <p>Path not found</p>
          <Link to="/">Start Over</Link>
        </div>
      );
    },
  }
);
