import { findOperationsWithInfoInDefinition } from "@/lib/editor-mutations/oas-operations";
import { OASDefinition, OASTag } from "@/lib/types";

export const findTagsInDefinition = ({
  definition,
}: {
  definition: OASDefinition;
}): OASTag[] => {
  return definition.tags || [];
};

export const findTagInDefinition = ({
  definition,
  tagName,
}: {
  definition: OASDefinition;
  tagName: string;
}): OASTag | undefined => {
  const tags = findTagsInDefinition({ definition });

  return tags.find((tag) => tag.name.toLowerCase() === tagName.toLowerCase());
};

export const editTagsInDefinition = ({
  definition,
  tagName,
  tagObject,
}: {
  definition: OASDefinition;
  tagName: string;
  tagObject: OASTag;
}) => {
  if (
    tagName !== tagObject.name &&
    doesTagExistInDefinition(tagObject.name, definition)
  ) {
    throw new Error("A tag with this name already exists");
  }

  tagName = tagName.toLowerCase();
  const cp = structuredClone(definition);

  const tag = findTagInDefinition({ definition, tagName });
  if (!tag) throw new Error("Tag does not exist in definition");

  if (!cp.tags) throw Error("No tags found in definition");

  cp.tags = cp.tags.filter((tag) => tag.name !== tagName);

  cp.tags?.push({ ...tagObject, name: tagObject.name.toLowerCase() });

  const operationsWithInfo = findOperationsWithInfoInDefinition(cp);
  operationsWithInfo.forEach(({ operation }) => {
    if (!operation.tags) return;
    if (operation.tags.includes(tagName)) {
      operation.tags = operation.tags.filter((t) => t !== tagName);
      operation.tags.push(tagObject.name);
    }
  });

  return cp;
};

function doesTagExistInDefinition(tagName: string, definition: OASDefinition) {
  return definition.tags?.some((tag) => tag.name === tagName);
}

export const addTagToDefinition = ({
  definition,
  tagObject,
}: {
  definition: OASDefinition;
  tagObject: OASTag;
}) => {
  if (doesTagExistInDefinition(tagObject.name, definition))
    throw new Error("Tag already exists");

  const cp = structuredClone(definition);
  if (!cp.tags) cp.tags = [];
  cp.tags.push({ ...tagObject, name: tagObject.name.toLowerCase() });

  return cp;
};

export const removeTagFromDefinition = ({
  definition,
  tagName,
}: {
  definition: OASDefinition;
  tagName: string;
}) => {
  const cp = structuredClone(definition);
  const operationsWithInfo = findOperationsWithInfoInDefinition(cp);

  operationsWithInfo.forEach(({ operation }) => {
    if (!operation.tags) return;
    operation.tags = operation.tags.filter((tag) => tag !== tagName);
  });

  if (!cp.tags) return;
  cp.tags = cp.tags.filter((tagObject) => tagObject.name !== tagName);
  return cp;
};
