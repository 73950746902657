import { EditorInputProps } from "@/components/module-api-editor/types";
import { sortOASDefinition } from "@/lib/helpers";
import { useCallback } from "react";
import { stringify } from "yaml";

export function useExportOpenAPI({ value }: Pick<EditorInputProps, "value">) {
  const handleExportOpenAPI = useCallback(() => {
    if (!value.data) return;
    const str = stringify(sortOASDefinition(value.data));
    const blob = new Blob([str], { type: "text/yaml" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.yaml";

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [value.data]);

  return handleExportOpenAPI;
}
