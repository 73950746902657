import { Logo } from "@/components";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { H1 } from "@/components/headings";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { OperationPreviewContainer } from "@/components/module-preview-pane/operation-preview";
import {
  SecondaryCard,
  SecondaryCardContent,
} from "@/components/secondary-card";
import { Separator } from "@/components/ui/separator";
import { companyInfo, testIds } from "@/lib/const";
import { canEdit, cn, NormIcons } from "@/lib/utils";
import { Book, Import, LucideIcon, MoveRight, Youtube } from "lucide-react";
import { ComponentProps } from "react";

function ArrowButton({
  children,
  className,
  Icon,
  ...rest
}: ComponentProps<"div"> & { Icon: LucideIcon }) {
  return (
    <div className={cn("flex items-center group", className)} {...rest}>
      <Icon className="mr-2 text-brandSoft" />
      <span className="group-hover:underline">{children}</span>{" "}
      <MoveRight className="ml-2" />
    </div>
  );
}

export function NoOperationInWorkspace({
  value,
  onChange,
  workspaceRole,
}: EditorInputProps & { workspaceRole: WorkspaceRole }) {
  const { resetToExample } = useAPIEditorTools({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();

  const handleAddOperation = () => {
    actionBarDispatch({ type: "SET_PAGE", payload: { name: "add-operation" } });
  };

  const handleImportClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: { name: "import-open-api-file" },
    });
  };

  const handleStartWithExample = async () => {
    await resetToExample("bookStore");
  };

  return (
    <OperationPreviewContainer className="bg-background">
      <div
        className="h-full w-full grid place-items-center"
        data-testid={testIds.emptyLoadExample}
      >
        {canEdit(workspaceRole) ? (
          <SecondaryCard className="col-span-4 bg-preview-background rounded-xl px-6 py-6 max-w-screen-sm shadow-xl">
            <SecondaryCardContent>
              <H1 className="font-bold text-brandSoft pb-2">
                Design professional{" "}
                <span className="text-blue-300">
                  {"{"}APIs{"}"}
                </span>{" "}
                based on OpenAPI.
              </H1>
              <p className="text-sm text-muted-foreground">
                API Fiddle is a modern graphical editor for API descriptions.
                Use it to design APIs, generate clients & docs, or visualize
                changes to existing APIs.
              </p>
              <div className="pt-6 grid gap-3 grid-cols-1 cursor-pointer">
                <a href={companyInfo.videos.intro} target="_blank">
                  <ArrowButton Icon={Youtube}>Watch intro</ArrowButton>
                </a>
                <ArrowButton onClick={handleStartWithExample} Icon={Book}>
                  Start from example (recommended)
                </ArrowButton>
                <ArrowButton
                  onClick={handleAddOperation}
                  Icon={NormIcons.Operation}
                >
                  Start by creating an operation
                </ArrowButton>
                <ArrowButton onClick={handleImportClick} Icon={Import}>
                  Start importing OpenAPI 3.1 file
                </ArrowButton>
              </div>
              <Separator className="mt-16 bg-brandSoft/40" />
              <div className="flex justify-between items-center pt-4">
                <span className="text-sm inline-block text-brandSoft">
                  Made with ❤️ in Berlin
                </span>
                <Logo width={80} />
              </div>
            </SecondaryCardContent>
          </SecondaryCard>
        ) : (
          <div className="flex gap-2 justify-center pt-3">
            <span className="text-muted-foreground">
              You have read permissions to this workspace
            </span>
          </div>
        )}
      </div>
    </OperationPreviewContainer>
  );
}
