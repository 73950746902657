import { ContentHeightContainer } from "@/components/layout-right-drawer";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { useEffect, useRef } from "react";

export function VisualPreviewOpenAPI(props: EditorInputProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    // Create the HTML content
    const html = `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="UTF-8">
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/swagger-ui/5.17.14/swagger-ui.css">
          <base href="${window.location.origin}" />
        </head>
        <body>
          <div id="swagger-ui"></div>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/swagger-ui/5.11.0/swagger-ui-bundle.js"></script>
          <script>
            window.onload = function() {
              const ui = SwaggerUIBundle({
                spec: ${JSON.stringify(props.value.data)},
                dom_id: '#swagger-ui',
                presets: [
                  SwaggerUIBundle.presets.apis,
                  SwaggerUIBundle.SwaggerUIStandalonePreset
                ],
                layout: "BaseLayout",
                deepLinking: false,
              });
            }
          </script>
        </body>
      </html>
    `;

    // Create a blob URL from the HTML content
    const blob = new Blob([html], { type: "text/html" });
    const blobUrl = URL.createObjectURL(blob);

    // Set the iframe src to the blob URL
    iframe.src = blobUrl;

    // Clean up the blob URL when the component unmounts
    return () => {
      URL.revokeObjectURL(blobUrl);
    };
  }, [props.value.data]);

  return (
    <ContentHeightContainer>
      <iframe
        ref={iframeRef}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          minHeight: "500px",
        }}
        sandbox="allow-scripts"
      />
    </ContentHeightContainer>
  );
}
