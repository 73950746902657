import { localStorageKeys } from "@/lib/const";
import { ApiClient } from "@/lib/main-rest-client/api";
import { TokenResponseDto } from "@/lib/main-rest-client/definitions";
import axios, { AxiosError } from "axios";
import createFetchClient, { Middleware } from "openapi-fetch";
import createClient from "openapi-react-query";
import { paths } from "@/.api-fiddle/main/schema";
import { jwtDecode } from "jwt-decode";

export function getApiBaseUrl() {
  if (process.env.NODE_ENV === "production") {
    return "https://api.api-fiddle.com";
  }
  return "http://localhost:8000";
}

export function getAppBaseUrl() {
  if (process.env.NODE_ENV === "production") return "https://api-fiddle.com";
  return "http://localhost:5173";
}

export interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}

export const setTokens = (tokens: AuthTokens) => {
  localStorage.setItem(localStorageKeys.authTokens, JSON.stringify(tokens));
};

export const clearTokens = () => {
  localStorage.removeItem(localStorageKeys.authTokens);
};

export const getTokens = (): Record<string, string> => {
  const tokens = localStorage.getItem("authTokens");
  // eslint-disable-next-line
  return tokens ? JSON.parse(tokens) : {};
};

export const refreshAccessToken = async (refreshToken: string) => {
  const { data } = await axios.post<TokenResponseDto>(
    `${getApiBaseUrl()}/v1/public/me/token-refresh`,
    {
      refresh_token: refreshToken,
    }
  );
  setTokens({
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
  });
  return data.access_token;
};

export const appClient = axios.create({
  baseURL: getApiBaseUrl(),
});

appClient.interceptors.response.use((response) => {
  return response;
});

appClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config;
    const { refreshToken } = getTokens();
    if (error.response?.status === 401 && originalRequest) {
      // eslint-disable-next-line
      if (refreshToken && !(originalRequest as any)._retry) {
        // eslint-disable-next-line
        (originalRequest as any)._retry = true;
        const accessToken = await refreshAccessToken(refreshToken).catch(
          () => undefined
        );
        appClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        return appClient({
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
      window.location.href = `/force-logout`;
      return null;
    }
    return Promise.reject(error);
  }
);

export const unauthedClient = new ApiClient(
  axios.create({
    baseURL: getApiBaseUrl(),
  })
);

export const apiClient = new ApiClient(appClient);

const tokens = getTokens();
if ("accessToken" in tokens) {
  apiClient.setTokenHeader(tokens.accessToken);
}

type DecodedToken = {
  token_type: "access" | "refresh";
  global_token_version: number;
  user_token_version: number;
  organization_id: string;
  organization_role: string;
  user_id: string;
  exp: number;
};

function isTokenExpired(token: string): boolean {
  try {
    const decoded = jwtDecode<DecodedToken>(token);
    const currentTime = Math.floor(Date.now() / 1000); // Current UTC time in seconds
    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Failed to decode token:", error);
    return true; // Treat as expired if decoding fails
  }
}

const authMiddleware: Middleware = {
  async onRequest({ request }) {
    const tokens = getTokens();
    if (!tokens.accessToken || !tokens.refreshToken) {
      throw new Error("Failed to retrieve access token");
    }

    let accessToken = tokens.accessToken;

    // check if the token is expired
    if (isTokenExpired(accessToken)) {
      accessToken = await refreshAccessToken(tokens.refreshToken);
    }

    request.headers.set("Authorization", `Bearer ${accessToken}`);
    return request;
  },
  onResponse({ response }) {
    if (response.status === 401) {
      window.location.href = `/force-logout`;
      return;
    }
  },
};
const fetchClient = createFetchClient<paths>({ baseUrl: getApiBaseUrl() });
export const $api = createClient(fetchClient);
fetchClient.use(authMiddleware);

export const _DEV_ = process.env.NODE_ENV == "development";
