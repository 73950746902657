import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { DocumentationCardDescription } from "@/components/documentation-card";
import { H5 } from "@/components/headings";
import { ScrollableLayoutContainer } from "@/components/layout-right-drawer";
import {
  EditorItemDescription,
  PreviewHeading,
  PreviewToolbarContainer,
} from "@/components/module-api-editor";
import {
  ActiveElement,
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaContentQuickEditor } from "@/components/schema-content-quick-editor";
import {
  SecondaryCard,
  SecondaryCardContent,
  SecondaryCardHeader,
} from "@/components/secondary-card";
import { TypeBadge } from "@/components/special-badges";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { useComponentResponses } from "@/hooks/use-component-responses";
import {
  AllPropertyTypes,
  getFirstSchemaFromContentObject,
  getPristineType,
  getResponseTypeName,
} from "@/lib/oas-tools/oas-schema-utils";
import { typeColorMap } from "@/lib/oas-tools/style-helpers";
import { canEdit } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { ReactNode, useMemo } from "react";

export function EditorPreviewResponse({
  value,
  onChange,
  activeElement,
  workspaceSlug,
  organizationSlug,
  isAuthed,
  workspaceRole,
  extraToolbarItems,
}: {
  isAuthed: boolean;
  workspaceSlug: string | undefined;
  organizationSlug: string | undefined;
  activeElement: Extract<ActiveElement, { type: "response" }>;
  workspaceRole: WorkspaceRole;
  extraToolbarItems: ReactNode;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();

  const { getComponentResponseOrError, removeComponentResponse } =
    useComponentResponses({
      value,
      onChange,
    });

  const { responseName, responseObject } = useMemo(
    () => getComponentResponseOrError(activeElement.responseName),
    [activeElement.responseName, getComponentResponseOrError]
  );

  const typeName = getResponseTypeName(responseObject);

  const color = typeColorMap[typeName as AllPropertyTypes] || "blue";

  const schema = responseObject.content
    ? getFirstSchemaFromContentObject(responseObject.content)
    : getPristineType("null");

  const handleEditClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-response",
        context: {
          responseName,
          responseObject,
        },
      },
    });
  };

  const handleRemoveClick = async () => {
    await removeComponentResponse(responseName);
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <div>
      <PreviewToolbarContainer
        value={value}
        onChange={onChange}
        workspaceRole={workspaceRole}
        isAuthed={isAuthed}
        workspaceSlug={workspaceSlug}
        organizationSlug={organizationSlug}
        extraToolbarItems={extraToolbarItems}
      >
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem className="hidden md:block">
              <Link search={(prev) => ({ ...prev, activeElement: undefined })}>
                <BreadcrumbLink asChild>
                  <span>Workspace</span>
                </BreadcrumbLink>
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="hidden md:block" />
            <BreadcrumbItem>
              <BreadcrumbPage>Response</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </PreviewToolbarContainer>

      <ScrollableLayoutContainer>
        <div className="flex flex-col items-stretch gap-2 pb-10 pt-4">
          <div className="flex justify-between px-4">
            <PreviewHeading preHeading="Response">
              {responseName}
            </PreviewHeading>
            {isEditor ? (
              <BtnGroup>
                <EditButton onClick={handleEditClick} />
                <DeleteButton onClick={handleRemoveClick} />
              </BtnGroup>
            ) : (
              <span />
            )}
          </div>
          <div className="grid grid-cols-1 auto-rows-max px-4 gap-2">
            <TypeBadge
              schema={schema}
              showIcon
              color={color}
              className="justify-self-start px-5 py-5"
            />

            <SecondaryCard>
              <SecondaryCardHeader>
                <H5 className="text-muted-foreground">Description</H5>
                {isEditor && <EditButton onClick={handleEditClick} />}
              </SecondaryCardHeader>
              <SecondaryCardContent>
                <EditorItemDescription
                  className="max-w-none"
                  item={responseObject}
                  onAddDescriptionClick={handleEditClick}
                  workspaceRole={workspaceRole}
                />
              </SecondaryCardContent>
            </SecondaryCard>
            <SecondaryCard>
              <SecondaryCardHeader>
                <H5 className="text-muted-foreground">Schema</H5>
              </SecondaryCardHeader>
              <SecondaryCardContent>
                <SchemaContentQuickEditor
                  onChange={onChange}
                  value={value}
                  title={`${responseName} schema`}
                  className="min-h-[220px]"
                  path={`components.responses.${responseName}.content`}
                  allowTopLevelReferences={true}
                  workspaceRole={workspaceRole}
                />
              </SecondaryCardContent>
            </SecondaryCard>
          </div>
          <DocumentationCardDescription className="text-primary"></DocumentationCardDescription>
        </div>
      </ScrollableLayoutContainer>
    </div>
  );
}
