import { useActionBarContext } from "../contexts/action-bar-context";

import { Dialog, DialogContent } from "@/components/ui/dialog";
import { ActionBarContent } from "@/components/module-action-bar/action-bar-content";
import { AddComponentParameterToOperation } from "@/components/module-action-bar/add-component-parameter-to-operation";
import { ComponentAddSuggestDtos } from "@/components/module-action-bar/component-add-suggest-dtos";
import { Debug } from "@/components/module-action-bar/debug";
import { ImportOpenAPIContent } from "@/components/module-action-bar/import-open-api-content";
import { MetadataEdit } from "@/components/module-action-bar/metadata-edit";
import { OperationAdd, OperationEdit } from "@/forms/form-operation";
import { PathParameterEdit } from "@/components/module-action-bar/path-parameter-edit";
import { SuggestedResponses } from "@/components/module-action-bar/suggested-responses";
import {
  ActiveElement,
  EditorInputProps,
} from "@/components/module-api-editor/types";
import {
  FormAddComponentResponse,
  FormEditComponentResponse,
} from "@/forms/form-component-response";
import {
  FormComponentSchemaAdd,
  FormComponentSchemaEdit,
} from "@/forms/form-component-schema";
import { FormDtoAdd, FormDtoEdit } from "@/forms/form-dto";
import { RequestBodyAdd, RequestBodyEdit } from "@/forms/form-request-body";
import {
  FormComponentRequestParameterAdd,
  FormEditComponentRequestParameter,
  RequestParameterAdd,
  RequestParameterEdit,
} from "@/forms/form-request-parameter";
import { FormResponseAdd, FormResponseEdit } from "@/forms/form-response";
import {
  AddSecurityScheme,
  EditSecurityScheme,
} from "@/forms/form-security-scheme";
import { CreateTagForm, EditTagForm } from "@/forms/form-tag";
import { SelectExample } from "./select-example";
import { WorkspaceActions } from "./workspace-actions";
import { CreateServerForm, UpdateServerForm } from "@/forms/form-server";
import { CRUDWizard } from "@/components/module-action-bar/crud-wizard";

function ActionBarRouter({
  value,
  onChange,
  activeElement,
}: EditorInputProps & {
  organizationSlug?: string;
  activeElement: ActiveElement;
}) {
  const [state, actionBarDispatch] = useActionBarContext();
  const { page } = state;
  if (!page) return null;

  return (
    <>
      {page.name === "add-operation" && (
        <OperationAdd
          value={value}
          defaultValues={page.context?.defaultValues}
          onChange={onChange}
        />
      )}
      {page.name === "suggested-responses" && (
        <SuggestedResponses
          method={page.context.method}
          operationId={page.context.operationId}
          value={value}
          onChange={onChange}
        />
      )}
      {page.name === "edit-operation" && (
        <OperationEdit
          urlPath={page.context.urlPath}
          method={page.context.method}
          value={value}
          onChange={onChange}
        />
      )}
      {page.name === "edit-tag" && (
        <ActionBarContent>
          <EditTagForm
            tagObject={page.context.tagObject}
            value={value}
            onChange={onChange}
          />
        </ActionBarContent>
      )}
      {page.name === "add-tag" && (
        <ActionBarContent>
          <CreateTagForm value={value} onChange={onChange} />
        </ActionBarContent>
      )}
      {page.name === "crud-wizard" && (
        <CRUDWizard
          schemaName={page.context.schemaName}
          value={value}
          onChange={onChange}
          activeElement={activeElement}
        />
      )}
      {page.name === "add-component-response" && (
        <ActionBarContent>
          <FormAddComponentResponse value={value} onChange={onChange} />
        </ActionBarContent>
      )}
      {page.name === "edit-component-response" && (
        <ActionBarContent>
          <FormEditComponentResponse
            responseObject={page.context.responseObject}
            responseObjectName={page.context.responseName}
            value={value}
            onChange={onChange}
          />
        </ActionBarContent>
      )}
      {page.name === "add-component-parameter" && (
        <ActionBarContent>
          <FormComponentRequestParameterAdd
            value={value}
            onChange={onChange}
            parameterPosition={page.context.parameterPosition}
          />
        </ActionBarContent>
      )}
      {page.name === "edit-component-parameter" && (
        <ActionBarContent>
          <FormEditComponentRequestParameter
            parameter={page.context.parameter}
            label={page.context.label}
            value={value}
            onChange={onChange}
          />
        </ActionBarContent>
      )}
      {page.name === "response-add" && (
        <ActionBarContent>
          <FormResponseAdd
            operationId={page.context.operationId}
            method={page.context.method}
            value={value}
            onChange={onChange}
          />
        </ActionBarContent>
      )}
      {page.name === "add-component-parameter-to-operation" && (
        <AddComponentParameterToOperation
          operationId={page.context.operationId}
          urlPath={page.context.urlPath}
          value={value}
          onChange={onChange}
          parameterPosition={page.context.parameterPosition}
        />
      )}
      {page.name === "response-edit" && (
        <ActionBarContent>
          <FormResponseEdit
            operationId={page.context.operationId}
            method={page.context.method}
            response={page.context.response}
            responseCode={page.context.responseCode}
            value={value}
            onChange={onChange}
          />
        </ActionBarContent>
      )}
      {page.name === "add-request-parameter" && (
        <RequestParameterAdd
          operationId={page.context.operationId}
          urlPath={page.context.urlPath}
          parameterPosition={page.context.parameterPosition}
          value={value}
          onChange={onChange}
        />
      )}
      {page.name === "edit-request-parameter" && (
        <RequestParameterEdit
          operationId={page.context.operationId}
          parameterWithInfo={page.context.parameterWithInfo}
          value={value}
          onChange={onChange}
        />
      )}
      {page.name === "edit-path-parameter" && (
        <PathParameterEdit
          operationId={page.context.operationId}
          parameterWithInfo={page.context.parameterWithInfo}
          value={value}
          onChange={onChange}
        />
      )}
      {page.name === "add-server" && (
        <ActionBarContent>
          <CreateServerForm
            value={value}
            onChange={onChange}
            onSuccess={() => actionBarDispatch({ type: "CLOSE" })}
          />
        </ActionBarContent>
      )}
      {page.name === "edit-server" && (
        <ActionBarContent>
          <UpdateServerForm
            value={value}
            onChange={onChange}
            serverWithInfo={page.context.serverWithInfo}
            onSuccess={() => actionBarDispatch({ type: "CLOSE" })}
          />
        </ActionBarContent>
      )}
      {page.name === "request-body-add" && (
        <ActionBarContent>
          <RequestBodyAdd
            operationId={page.context.operationId}
            value={value}
            onChange={onChange}
          />
        </ActionBarContent>
      )}
      {page.name === "request-body-edit" && (
        <ActionBarContent>
          <RequestBodyEdit
            operationId={page.context.operationId}
            requestBody={page.context.requestBody}
            value={value}
            onChange={onChange}
          />
        </ActionBarContent>
      )}
      {page.name === "edit-oas-metadata" && (
        <MetadataEdit value={value} onChange={onChange} />
      )}
      {page.name === "select-example" && (
        <SelectExample value={value} onChange={onChange} />
      )}
      {page.name === "workspace-actions" && (
        <WorkspaceActions value={value} onChange={onChange} />
      )}
      {page.name === "add-component-schema" && (
        <ActionBarContent>
          <FormComponentSchemaAdd value={value} onChange={onChange} />
        </ActionBarContent>
      )}
      {page.name === "edit-component-schema" && (
        <ActionBarContent>
          <FormComponentSchemaEdit
            schemaName={page.context.schemaName}
            componentSchemaObject={page.context.componentSchemaObject}
            value={value}
            onChange={onChange}
          />
        </ActionBarContent>
      )}
      {page.name === "add-component-suggest-dtos" && (
        <ComponentAddSuggestDtos
          schemaName={page.context.schemaName}
          value={value}
          onChange={onChange}
        />
      )}
      {page.name === "dto-add" && (
        <ActionBarContent>
          <FormDtoAdd
            defaultValues={{
              baseSchemaName: page.context.baseSchemaName,
              name: "",
              description: "",
            }}
            value={value}
            onChange={onChange}
          />
        </ActionBarContent>
      )}
      {page.name === "dto-edit" && (
        <ActionBarContent>
          <FormDtoEdit
            dtoName={page.context.dtoName}
            value={value}
            onChange={onChange}
          />
        </ActionBarContent>
      )}
      {page.name === "add-security-scheme" && (
        <ActionBarContent>
          <AddSecurityScheme
            onSuccess={() => actionBarDispatch({ type: "CLOSE" })}
            value={value}
            onChange={onChange}
          />
        </ActionBarContent>
      )}
      {page.name === "edit-security-scheme" && (
        <ActionBarContent>
          <EditSecurityScheme
            value={value}
            onChange={onChange}
            onSuccess={() => actionBarDispatch({ type: "CLOSE" })}
            securityScheme={page.context.securityScheme}
            schemeName={page.context.schemeName}
          />
        </ActionBarContent>
      )}
      {page.name === "import-open-api-file" && (
        <ImportOpenAPIContent value={value} onChange={onChange} />
      )}
      {page.name === "debug" && <Debug value={value} onChange={onChange} />}
    </>
  );
}

export function ActionBar({
  value,
  onChange,
  organizationSlug,
  activeElement,
}: EditorInputProps & {
  workspaceSlug?: string;
  organizationSlug?: string;
  activeElement: ActiveElement;
}) {
  const [state, dispatch] = useActionBarContext();
  const handleClose = () => dispatch({ type: "CLOSE" });
  return (
    <Dialog onOpenChange={handleClose} open={!!state.page}>
      <DialogContent
        onInteractOutside={state.page?.onInteractOutside}
        className="max-h-screen xl:max-w-xl min-h-0 overflow-y-auto pt-4 pb-3"
      >
        <ActionBarRouter
          value={value}
          onChange={onChange}
          organizationSlug={organizationSlug}
          activeElement={activeElement}
        />
      </DialogContent>
    </Dialog>
  );
}
