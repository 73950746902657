import { OASContentObject, SupportedContentFormats } from "@/lib/types";

// Take existing values of a format and change them to another type
export function changeFormatInContentObject({
  contentObject,
  oldFormat,
  newFormat,
}: {
  contentObject: OASContentObject;
  oldFormat: SupportedContentFormats;
  newFormat: SupportedContentFormats;
}) {
  const cp = structuredClone(contentObject);

  const mediaObject = cp[oldFormat];

  if (!mediaObject)
    throw new Error("Not found: No media object found at old path");

  delete cp[oldFormat];

  cp[newFormat] = mediaObject;

  return cp;
}
