import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { FormLabelRequired } from "@/components/form-label-required";
import { MdTextarea } from "@/components/md-textarea";
import {
  ActionBarFieldGroup,
  ActionBarForm,
  ActionBarFormTitle,
} from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { FormComp } from "@/forms";
import { useTags } from "@/hooks/use-tags";
import { OASTag } from "@/lib/types";
import { toastError, toastSuccess } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { defaults } from "lodash";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  name: z.string().min(1, "Required").toLowerCase(),
  description: z.string(),
});

export type TagFormValues = z.infer<typeof formSchema>;

export function TagForm({
  defaultValues,
  onSubmit,
  title,
  btnTitle,
}: {
  defaultValues: Partial<TagFormValues>;
  onSubmit: SubmitHandler<TagFormValues>;
  title?: string;
  btnTitle: string;
}) {
  const form = useForm<TagFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaults(defaultValues, { name: "", description: "" }),
  });

  return (
    <Form {...form}>
      <ActionBarForm
        onSubmit={(evt) => {
          evt.stopPropagation();
          return form.handleSubmit(onSubmit)(evt);
        }}
      >
        {title && <ActionBarFormTitle>{title}</ActionBarFormTitle>}
        <ActionBarFieldGroup>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired /> Name
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <MdTextarea height={130} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </ActionBarFieldGroup>
        <BtnGroup className="justify-end">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </ActionBarForm>
    </Form>
  );
}

export function EditTagForm({
  value,
  onChange,
  tagObject,
}: { tagObject: OASTag } & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { update } = useTags({ value, onChange });

  const handleSubmit: SubmitHandler<TagFormValues> = (values) => {
    try {
      update({
        tagName: tagObject.name,
        tagObject: { description: values.description, name: values.name },
      });
      toastSuccess("Updated tag");
    } catch (err) {
      toastError(err);
    }
    actionBarDispatch({ type: "CLOSE" });
  };

  return (
    <TagForm
      onSubmit={handleSubmit}
      btnTitle="Edit"
      title="Edit tag"
      defaultValues={{
        name: tagObject.name,
        description: tagObject.description || "",
      }}
    />
  );
}

export function CreateTagForm({ value, onChange }: EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { create } = useTags({ value, onChange });

  const handleSubmit: SubmitHandler<TagFormValues> = (values) => {
    try {
      create({
        name: values.name,
        description: values.description,
      });
      toastSuccess("Tag created");
    } catch (err) {
      toastError(err);
    }
    actionBarDispatch({ type: "CLOSE" });
  };

  return (
    <TagForm
      onSubmit={handleSubmit}
      btnTitle="Create"
      title="Create tag"
      defaultValues={{
        name: "",
        description: "",
      }}
    />
  );
}

export function TagInlineForm({
  defaultValues,
  onSubmit,
  btnTitle,
}: {
  defaultValues: TagFormValues;
  onSubmit: SubmitHandler<TagFormValues>;
  btnTitle: string;
}) {
  const form = useForm<TagFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaults(defaultValues, { name: "", description: "" }),
  });

  return (
    <Form {...form}>
      <FormComp
        className="px-0"
        onSubmit={(evt) => {
          evt.stopPropagation();
          return form.handleSubmit(onSubmit)(evt);
        }}
      >
        <div className="grid grid-cols-[1fr,max-content] items-end gap-2">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired /> Tag Name
                </FormLabel>
                <FormControl>
                  <Input className="h-9" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button type="submit" size="sm" className="mb-0.5">
            {btnTitle}
          </Button>
        </div>
      </FormComp>
    </Form>
  );
}
