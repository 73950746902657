import {
  ActiveElement,
  EditorInputProps,
} from "@/components/module-api-editor/types";
import { useComponentParameters } from "@/hooks/use-component-parameters";
import { useComponentResponses } from "@/hooks/use-component-responses";
import { useComponents } from "@/hooks/use-components";
import { useOperation } from "@/hooks/use-operation";
import { useTags } from "@/hooks/use-tags";
import { OperationWithInfo } from "@/lib/editor-mutations/oas-operations";
import { supportedHttpVerbs } from "@/lib/helpers";
import { OASTag, SupportedHTTPVerbs } from "@/lib/types";
import { useMemo } from "react";

export function useEditorNavigation({
  value,
  onChange,
  activeElement,
}: EditorInputProps & { activeElement: ActiveElement }) {
  const { findAllTags: getAll } = useTags({ value, onChange });
  const { findOperationsWithInfo: getOperationsWithInfo } = useOperation({
    value,
    onChange,
  });
  const { getComponentSchemas } = useComponents({
    value,
    onChange,
  });
  const { getComponentResponses } = useComponentResponses({
    value,
    onChange,
  });
  const { getComponentParametersWithInfo } = useComponentParameters({
    value,
    onChange,
  });

  const componentSchemasWithInfo = useMemo(
    () => getComponentSchemas(),
    [getComponentSchemas]
  );

  const componentResponsesWithInfo = useMemo(
    () => getComponentResponses(),
    [getComponentResponses]
  );

  const componentParametersWithInfo = useMemo(
    () => getComponentParametersWithInfo(),
    [getComponentParametersWithInfo]
  );

  const navigationTree = useMemo(() => {
    const allOperations = getOperationsWithInfo();
    const operations = [...allOperations];

    operations.sort((a, b) => {
      const indexA = supportedHttpVerbs.indexOf(
        a.method.toLowerCase() as SupportedHTTPVerbs
      );
      const indexB = supportedHttpVerbs.indexOf(
        b.method.toLowerCase() as SupportedHTTPVerbs
      );

      // If method not found in the order list, assign a large index value
      const orderA = indexA === -1 ? supportedHttpVerbs.length : indexA;
      const orderB = indexB === -1 ? -supportedHttpVerbs.length : indexB;

      return orderA - orderB;
    });

    const res: Record<string, OperationWithInfo[]> = {};

    operations.forEach((operationWithInfo) => {
      if (!operationWithInfo.operation.tags?.length) {
        if (!res.default) res.default = [];
        res.default.push(operationWithInfo);
      } else {
        const firstTag = operationWithInfo.operation.tags[0];
        if (!res[firstTag]) res[firstTag] = [];
        res[firstTag].push(operationWithInfo);
      }
    });

    const sortedList = Object.entries(res).sort(([tagA], [tagB]) => {
      if (tagA === "default") {
        return -1;
      } else {
        return tagB.localeCompare(tagA);
      }
    });
    return { sortedList, map: res };
  }, [getOperationsWithInfo]);

  const unusedTags = useMemo(() => {
    const allTags = getAll();
    const unusedTags: OASTag[] = [];
    allTags.forEach((tag) => {
      if (!navigationTree.map[tag.name]) unusedTags.push(tag);
    });
    return unusedTags;
  }, [getAll, navigationTree.map]);

  const isGeneralInformationActive = !!(
    activeElement && activeElement.type === "general_information"
  );
  const isCreateClientActive = !!(
    activeElement && activeElement.type === "create_client"
  );

  return {
    isGeneralInformationActive,
    isCreateClientActive,
    unusedTags,
    componentParametersWithInfo,
    componentResponsesWithInfo,
    componentSchemasWithInfo,
    navigationTree,
  };
}
