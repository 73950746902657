import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { FormLabelRequired } from "@/components/form-label-required";
import { MdTextarea } from "@/components/md-textarea";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaEditor } from "@/components/schema-editor";
import { SchemaEditorPreviewContainer } from "@/components/schema-editor-containers";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FieldHStack,
  FieldVStack,
  FormComp,
  FormContent,
  FormHeader,
  FormTitle,
} from "@/forms";
import { useComponents } from "@/hooks/use-components";
import { useSchemaDtos } from "@/hooks/use-dtos";
import { templateCrudOperations } from "@/lib/helpers";
import { createDtoFromOASSchema } from "@/lib/oas-tools/create-dto-from-schema";
import { getDtoTemplateValues } from "@/lib/oas-tools/dto-templates";
import {
  getPristineType,
  zodSchemaSchema,
} from "@/lib/oas-tools/oas-schema-utils";
import { OASComponentsObject } from "@/lib/types";
import { toastError } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  schema: zodSchemaSchema,
  baseSchemaName: z.string().min(1, "Required"),
  template: z.string(),
  name: z.string().min(1, "Required"),
  description: z.string().optional(),
});
export type FormValues = z.infer<typeof formSchema>;

export function DtoForm({
  defaultValues,
  onSubmit,
  componentsObject,
  isEditing,
  title,
  btnTitle,
  value,
  onChange,
}: {
  defaultValues: FormValues;
  onSubmit: SubmitHandler<FormValues>;
  componentsObject: OASComponentsObject;
  isEditing: boolean;
  title: string;
  btnTitle: string;
} & EditorInputProps) {
  const [textRemount, setTextAreaRemount] = useState(new Date());
  const { getComponentSchemas } = useComponents({
    value,
    onChange,
  });

  const componentObjectSchemasWithInfo = useMemo(() => {
    return getComponentSchemas();
  }, [getComponentSchemas]);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const baseSchemaName = form.watch("baseSchemaName");

  return (
    <Form {...form}>
      <FormComp onSubmit={form.handleSubmit(onSubmit)}>
        <FormHeader>
          <FormTitle>{title}</FormTitle>
        </FormHeader>
        <FormContent>
          <FieldHStack>
            <FormField
              control={form.control}
              name="baseSchemaName"
              render={({ field }) => {
                return (
                  <FormItem className="">
                    <FormLabel>
                      <FormLabelRequired />
                      Base schema
                    </FormLabel>
                    <Select
                      disabled={true}
                      onValueChange={(v) => {
                        field.onChange(v);
                        try {
                          const schema = createDtoFromOASSchema({
                            schema: defaultValues.schema,
                            componentsObject,
                            crudOperation: { level: "list", method: "get" },
                          });

                          form.setValue("schema", schema);
                        } catch (err) {
                          toastError(err);
                        }
                      }}
                      defaultValue={String(field.value)}
                    >
                      <FormControl>
                        <SelectTrigger className="">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectGroup>
                          {componentObjectSchemasWithInfo.map(
                            (componentSchemaWithInfo) => {
                              return (
                                <SelectItem
                                  key={componentSchemaWithInfo.name}
                                  value={componentSchemaWithInfo.name}
                                >
                                  {componentSchemaWithInfo.name}
                                </SelectItem>
                              );
                            }
                          )}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <FormDescription>Target this schema</FormDescription>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              name="template"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Template</FormLabel>
                  <Select
                    value={field.value}
                    disabled={isEditing}
                    onValueChange={(v) => {
                      field.onChange(v);
                      if (v === "") return;
                      if (v === "None") {
                        form.setValue("schema", defaultValues.schema);
                        form.setValue("description", "");
                        setTextAreaRemount(new Date());
                        form.setValue("name", "");
                        return;
                      }

                      const [level, method] = v.split("-");
                      const templateCrudOperation = templateCrudOperations.find(
                        (e) => e.level === level && e.method === method
                      );

                      if (!templateCrudOperation)
                        throw new Error("Unable to locate template operation");

                      const templateDtoValues = getDtoTemplateValues(
                        templateCrudOperation,
                        baseSchemaName
                      );
                      const schema = createDtoFromOASSchema({
                        schema: defaultValues.schema,
                        componentsObject,
                        crudOperation: templateCrudOperation,
                        casingPreference: "snake_case",
                      });
                      form.setValue("schema", schema);
                      form.setValue(
                        "description",
                        templateDtoValues.dtoDescription
                      );
                      setTextAreaRemount(new Date());
                      form.setValue("name", templateDtoValues.dtoName);
                    }}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="None">None</SelectItem>
                        {templateCrudOperations.map((crudOperation) => {
                          const value = `${crudOperation.level}-${crudOperation.method}`;
                          const dtoInfo = getDtoTemplateValues(
                            crudOperation,
                            baseSchemaName || "<Name>"
                          );
                          return (
                            <SelectItem key={value} value={value}>
                              {dtoInfo.dtoName}
                            </SelectItem>
                          );
                        })}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    Setting this will overwrite form values
                  </FormDescription>
                </FormItem>
              )}
            />
          </FieldHStack>
          <FieldVStack>
            {(baseSchemaName || isEditing) && (
              <>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        <FormLabelRequired />
                        DTO name
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <MdTextarea
                          height={130}
                          key={textRemount.toISOString()}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <SchemaEditorPreviewContainer
                  title="Schema"
                  className="min-h-[220px]"
                >
                  <Controller
                    name="schema"
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <SchemaEditor
                        componentsObject={componentsObject}
                        allowTopLevelReferences={false}
                        value={value}
                        onChange={(schema) => {
                          onChange(schema);
                        }}
                        onRemoveRootSchema={() => {
                          form.setValue("schema", getPristineType("object"));
                        }}
                      />
                    )}
                  />
                </SchemaEditorPreviewContainer>
              </>
            )}
          </FieldVStack>
        </FormContent>
        <BtnGroup className="justify-end">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </FormComp>
    </Form>
  );
}

export function FormDtoEdit({
  dtoName,
  value,
  onChange,
}: { dtoName: string } & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { componentsObject } = useAPIEditorTools({
    value,
    onChange,
  });
  const { update, getSchemaDtoOrError, getBaseSchemaNameOrError } =
    useSchemaDtos({ value, onChange });

  const schema = useMemo(
    () => getSchemaDtoOrError(dtoName),
    [dtoName, getSchemaDtoOrError]
  );

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    update(
      dtoName,
      values.name,
      {
        ...values.schema,
        description: values.description,
      },
      values.baseSchemaName
    );
    actionBarDispatch({ type: "CLOSE" });
  };

  return (
    <DtoForm
      title="Edit data transfer object"
      btnTitle="Edit"
      onSubmit={onSubmit}
      value={value}
      onChange={onChange}
      componentsObject={componentsObject}
      isEditing={true}
      defaultValues={{
        schema,
        baseSchemaName: getBaseSchemaNameOrError(schema),
        name: dtoName || "",
        description: schema.description || "",
        template: "None",
      }}
    />
  );
}

export function FormDtoAdd({
  defaultValues,
  value,
  onChange,
}: {
  defaultValues: Omit<FormValues, "schema" | "template">;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { getComponentSchemaOrError } = useComponents({ value, onChange });
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { add } = useSchemaDtos({ value, onChange });
  const onSubmit: SubmitHandler<FormValues> = (values) => {
    add(
      values.name,
      {
        ...values.schema,
        description: values.description,
      },
      values.baseSchemaName
    );
    actionBarDispatch({ type: "CLOSE" });
  };
  const defaultSchema = useMemo(() => {
    const componentSchemaWithInfo = getComponentSchemaOrError(
      defaultValues.baseSchemaName
    );
    return createDtoFromOASSchema({
      schema: componentSchemaWithInfo.schema,
      componentsObject,
      crudOperation: {
        method: "get",
        level: "list",
      },
    });
  }, [
    defaultValues.baseSchemaName,
    getComponentSchemaOrError,
    componentsObject,
  ]);

  return (
    <DtoForm
      title="Create data transfer object"
      btnTitle="Create"
      onSubmit={onSubmit}
      componentsObject={componentsObject}
      isEditing={false}
      value={value}
      onChange={onChange}
      defaultValues={{
        ...defaultValues,
        template: "None",
        schema: defaultSchema,
      }}
    />
  );
}
