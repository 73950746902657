import { Button } from "@/components/ui/button";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { UserDto, UserWorkspaceDto } from "@/lib/main-rest-client/definitions";
import { DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";
import { useRemoveWorkspaceMember } from "@/queries/workspaces";
import { useNavigate } from "@tanstack/react-router";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

export function WorkspaceSettingsLeaveCard({
  userWorkspace,
  me,
}: {
  userWorkspace: UserWorkspaceDto;
  me: UserDto;
}) {
  const navigate = useNavigate();
  const removeUserQuery = useRemoveWorkspaceMember();

  const handleClick = () => {
    removeUserQuery.mutate(
      { userId: me.id, workspaceId: userWorkspace.id },
      {
        onSuccess: () => void navigate({ to: "/dash/onboarding" }),
      }
    );
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between gap-norm">
          <div>
            <CardTitle>Leave workspace</CardTitle>
            <CardDescription>
              To regain access to this workspace you have to request an
              invitation from a workspace administrator.
            </CardDescription>
          </div>
          <BtnGroup className="justify-end">
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  variant="destructive"
                  disabled={removeUserQuery.isPending}
                >
                  <NormIcons.Logout size={DEFAULT_ICON_SIZE} className="mr-2" />
                  Leave
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    You will lose access to this workspace and all its
                    resources.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={handleClick}>
                    Leave workspace
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </BtnGroup>
        </div>
      </CardHeader>
    </Card>
  );
}
