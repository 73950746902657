import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { NormIcon } from "@/components/norm-icon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { OperationWithInfo } from "@/lib/editor-mutations/oas-operations";
import { PropsWithChildren } from "react";

export function DropdownOperationMore({
  operationWithInfo,
  children,
}: PropsWithChildren<{ operationWithInfo: OperationWithInfo }>) {
  const [, actionBarDispatch] = useActionBarContext();
  const operation = operationWithInfo.operation;

  const handleEditClick = () => {
    if (!operation.operationId) throw new Error("Operation does not have id");
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-operation",
        context: {
          urlPath: operationWithInfo.urlPath,
          method: operationWithInfo.method,
          operationId: operation.operationId,
        },
      },
    });
  };

  const handleAddDuplicate = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "add-operation",
        context: {
          defaultValues: {
            description: operation.description,
            method: operationWithInfo.method,
            urlPath: operationWithInfo.urlPath,
            summary: `${operation.summary}-Copy`,
            tag: operation.tags?.[0] || "",
          },
        },
      },
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={handleAddDuplicate}>
            <NormIcon name="Duplicate" size="sm" className="mr-2" />
            Duplicate
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleEditClick}>
            <NormIcon name="Edit" className="mr-2" size="sm" />
            <span>Edit</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
