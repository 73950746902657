import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { FormLabelRequired } from "@/components/form-label-required";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { FieldVStack } from "@/forms";
import { WorkspaceDocumentDraftDto } from "@/lib/main-rest-client/definitions";
import { useUpdateWorkspaceDocumentDraft } from "@/queries/workspaces";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogDescription, DialogTitle } from "@radix-ui/react-dialog";
import { useNavigate } from "@tanstack/react-router";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

const formValues = z.object({
  name: z.string().min(1, "Required"),
});
type FormValues = z.infer<typeof formValues>;

export function SetDraftNameDialog({
  workspaceSlug,
  organizationSlug,
  workspaceDocumentDraft,
  isOpen,
  onOpenChange,
}: {
  workspaceDocumentDraft: WorkspaceDocumentDraftDto;
  isOpen: boolean;
  onOpenChange: (v: boolean) => unknown;
  workspaceSlug: string;
  organizationSlug: string;
}) {
  const navigate = useNavigate();

  const patchWorkspacedocumentDraftQuery = useUpdateWorkspaceDocumentDraft();

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    patchWorkspacedocumentDraftQuery.mutate(
      {
        name: values.name,
        workspaceDocumentDraftId: workspaceDocumentDraft.id,
      },
      {
        onSuccess: () => {
          onOpenChange(false);
          return void navigate({
            to: "/editor/$organizationSlug/$workspaceSlug/drafts/$draftId",
            params: {
              workspaceSlug,
              organizationSlug,
              draftId: workspaceDocumentDraft.id,
            },
          });
        },
      }
    );
  };

  const form = useForm<FormValues>({
    resolver: zodResolver(formValues),
    defaultValues: {
      name: workspaceDocumentDraft.name,
    },
  });

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="pt-10">
        <DialogHeader>
          <DialogTitle>Draft</DialogTitle>
          <DialogDescription className="text-sm text-muted-foreground">
            Give your draft a unique name.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FieldVStack>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormLabelRequired />
                      Draft name
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </FieldVStack>
            <BtnGroup className="justify-end">
              <SubmitButton
                isLoading={patchWorkspacedocumentDraftQuery.isPending}
              >
                Change name
              </SubmitButton>
            </BtnGroup>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
