import { useActionBarContext } from "@/components/contexts/action-bar-context";
import {
  useAPIEditor,
  useAPIEditorTools,
} from "@/components/contexts/api-editor-context-hooks";
import { H1, H5 } from "@/components/headings";
import {
  AboutDialog,
  FeedbackDialog,
  NewsletterDialog,
  ShareDialog,
} from "@/components/module-api-editor/header-dialogs";
import { SignupDialog } from "@/components/module-api-editor/signup-dialog";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  SecondaryCard,
  SecondaryCardContent,
} from "@/components/secondary-card";
import { Button } from "@/components/ui/button";
import { ColorBadeColors, ColorBadge } from "@/components/ui/color-badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";
import { SidebarTrigger } from "@/components/ui/sidebar";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useDisclosure } from "@/hooks/use-disclosure";
import { useExportOpenAPI } from "@/hooks/use-export-openapi";
import { _DEV_ } from "@/lib/http-utils";
import { OASSecuritySchemeObject } from "@/lib/types";
import { canEdit, cn, DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { Menu } from "lucide-react";
import {
  ComponentProps,
  LegacyRef,
  PropsWithChildren,
  ReactNode,
  useMemo,
} from "react";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import dark from "react-syntax-highlighter/dist/esm/styles/prism/coldark-dark";
import remarkGfm from "remark-gfm";

// export function BadgeCard({ children }: PropsWithChildren) {
//   return (
//     <SecondaryCard className="py-3">
//       <SecondaryCardContent>{children}</SecondaryCardContent>
//     </SecondaryCard>
//   );
// }

// export function TitleCard({ children }: PropsWithChildren) {
//   return (
//     <SecondaryCard className="place-items-center text-sm content-center text-muted-foreground">
//       {children}
//     </SecondaryCard>
//   );
// }

export function DescriptionCard({
  children,
  title,
}: PropsWithChildren<{ title?: string }>) {
  return (
    <SecondaryCard className="px-0 py-4">
      <SecondaryCardContent className="px-6 max-h-[250px] overflow-auto">
        {title && (
          <H5 className="pb-1 text-sm text-muted-foreground">{title}</H5>
        )}
        {children}
      </SecondaryCardContent>
    </SecondaryCard>
  );
}

export function EditorCard({ children }: PropsWithChildren) {
  return <div className="editor-card overflow-hidden">{children}</div>;
}

export function MarkdownDescription({
  markdown,
  className,
  ...rest
}: ComponentProps<"article"> & { markdown: string }) {
  return (
    <article
      className={cn(
        "prose text-primary dark:prose-invert prose-a:text-brand prose-code:text-muted-foreground text-sm max-w-none",
        className
      )}
      {...rest}
    >
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={{
          code(props) {
            const { children, className, node: _node, ref, ...rest } = props;
            const match = /language-(\w+)/.exec(className || "");
            return match ? (
              <SyntaxHighlighter
                {...rest}
                ref={ref as LegacyRef<SyntaxHighlighter> | undefined}
                PreTag="div"
                children={String(children).replace(/\n$/, "")}
                language={match[1]}
                style={dark}
              />
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            );
          },
        }}
      >
        {markdown}
      </Markdown>
    </article>
  );
}

export function EditorItemDescription({
  item,
  workspaceRole,
  onAddDescriptionClick,
  className,
  ...rest
}: {
  item: {
    description?: string;
  };
  workspaceRole: WorkspaceRole;
  // If not provided the description cannot be edited.
  // This can be if a.) we're looking at a reference
  // b.) we're in teh sidebar view
  onAddDescriptionClick: (() => unknown) | undefined;
} & ComponentProps<"div">) {
  const isEditor = canEdit(workspaceRole);
  return (
    <>
      {item.description ? (
        <>
          <MarkdownDescription
            className={className}
            {...rest}
            markdown={item.description}
          />
        </>
      ) : isEditor && onAddDescriptionClick ? (
        <span className="text-sm text-muted-foreground">
          Add a description{" "}
          <Button
            variant="link"
            size="link"
            className="underline"
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              onAddDescriptionClick();
            }}
          >
            here
          </Button>
        </span>
      ) : (
        <span className="text-sm">No description yet.</span>
      )}
    </>
  );
}

export function HideEmptyListWhenNonEditor({
  children,
  workspaceRole,
  list,
}: PropsWithChildren<{ workspaceRole: WorkspaceRole; list: unknown[] }>) {
  const isEditor = canEdit(workspaceRole);
  if (isEditor || list.length > 0) return children;
  return null;
}

export const SecuritySchemeBadge = ({
  schemeName,
  securityScheme,
  ...props
}: React.ComponentProps<typeof ColorBadge> & {
  securityScheme: OASSecuritySchemeObject;
  schemeName: string;
}) => {
  const badgeValues = useMemo((): { name: string; color: ColorBadeColors } => {
    if (schemeName === "BearerJwt") {
      return { name: "JWT Auth", color: "violet" };
    }
    if (schemeName === "BearerCustom") {
      return { name: "Token auth", color: "emerald" };
    }
    if (schemeName === "BearerBasic") {
      return { name: "Basic auth", color: "green" };
    }
    if (securityScheme.type === "apiKey") {
      return { name: "API key auth", color: "amber" };
    }

    return { name: "Auth required", color: "lime" };
  }, [securityScheme, schemeName]);
  return (
    <ColorBadge color={badgeValues.color} {...props}>
      {badgeValues.name}
    </ColorBadge>
  );
};

export function PreviewToolbarContainer({
  value,
  onChange,
  children,
  tabs,
  workspaceRole,
  isAuthed,
  workspaceSlug,
  organizationSlug,
  hideSidebarToggle = false,
  extraToolbarItems,
}: PropsWithChildren<
  {
    workspaceRole: WorkspaceRole;
    isAuthed: boolean;
    workspaceSlug: string | undefined;
    organizationSlug: string | undefined;
    hideSidebarToggle?: boolean;
    tabs?: ReactNode;
    extraToolbarItems: ReactNode;
  } & EditorInputProps
>) {
  const [state] = useAPIEditor();
  const [, actionBarDispatch] = useActionBarContext();
  const { goBackInHistory, canGoBackInHistory } = useAPIEditorTools({
    value,
    onChange,
  });

  const handleExportOpenAPI = useExportOpenAPI({ value });

  const feedbackDisclosure = useDisclosure();
  const aboutDisclosure = useDisclosure();
  const shareDialogDisclosure = useDisclosure();
  const newsletterDisclosure = useDisclosure();
  const signUpDialogDisclosure = useDisclosure();

  const handleResetWorkspaceClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "workspace-actions",
      },
    });
  };

  const handleDebugClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "debug",
      },
    });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <header className="h-16 shrink-0 gap-2 px-4 flex justify-between items-stretch">
      <div className="flex items-center gap-2 basis-[300px] grow justify-start">
        {!hideSidebarToggle ? (
          <>
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
          </>
        ) : (
          <span className="col-span-2" />
        )}
        <div className="flex">{children}</div>
      </div>
      <div className="flex items-center grow">{tabs}</div>
      <div className="flex items-center gap-3 basis-[300px] grow justify-end">
        <div className="flex items-center">
          {isEditor && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={goBackInHistory}
                  disabled={!canGoBackInHistory}
                >
                  <NormIcons.Undo size={DEFAULT_ICON_SIZE} className="mr-2" />
                  Undo
                </Button>
              </TooltipTrigger>
              <TooltipContent>Undo last action</TooltipContent>
            </Tooltip>
          )}
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                className="text-muted-foreground"
                size="sm"
                onClick={handleExportOpenAPI}
              >
                <NormIcons.Export size={DEFAULT_ICON_SIZE} className="mr-2" />
                Export
              </Button>
            </TooltipTrigger>
            <TooltipContent>Export OpenAPI file</TooltipContent>
          </Tooltip>
          {_DEV_ && (
            <Button variant="ghost" size="sm" onClick={handleDebugClick}>
              Debug
            </Button>
          )}
          {extraToolbarItems}
        </div>
        <Separator orientation="vertical" className="mr-2 h-4" />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className={cn("h-7 w-7")}>
              <Menu />
              <span className="sr-only">Menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              {!isAuthed && (
                <Link to="/login">
                  <DropdownMenuItem>
                    <NormIcons.Login className="mr-2 h-4 w-4" />
                    Login
                  </DropdownMenuItem>
                </Link>
              )}
              {isAuthed && (
                <Link to="/force-logout">
                  <DropdownMenuItem>
                    <NormIcons.Logout className="mr-2 h-4 w-4" />
                    Logout
                  </DropdownMenuItem>
                </Link>
              )}
              {!isAuthed && (
                <Link to="/login">
                  <DropdownMenuItem>
                    <NormIcons.Signup className="mr-2 h-4 w-4" />
                    Sign up
                  </DropdownMenuItem>
                </Link>
              )}
              {isEditor && state.editorMode === "annonymous" && (
                <DropdownMenuItem onClick={handleResetWorkspaceClick}>
                  <NormIcons.Reset className="mr-2 h-4 w-4" />
                  Reset workspace
                </DropdownMenuItem>
              )}
              {isEditor && state.editorMode === "annonymous" && (
                <DropdownMenuItem
                  onClick={
                    isAuthed
                      ? shareDialogDisclosure.onOpen
                      : signUpDialogDisclosure.onOpen
                  }
                >
                  <NormIcons.Share className="mr-2 h-4 w-4" />
                  Share workspace
                </DropdownMenuItem>
              )}
              <DropdownMenuItem onClick={aboutDisclosure.onOpen}>
                <NormIcons.About className="mr-2 h-4 w-4" />
                About API-Fiddle
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <FeedbackDialog
        open={feedbackDisclosure.isOpen}
        onOpenChange={feedbackDisclosure.onOpenChange}
      />
      <AboutDialog
        open={aboutDisclosure.isOpen}
        onOpenChange={aboutDisclosure.onOpenChange}
      />
      {isAuthed && workspaceSlug && organizationSlug && (
        <ShareDialog
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          open={shareDialogDisclosure.isOpen}
          onOpenChange={shareDialogDisclosure.onOpenChange}
        />
      )}
      <NewsletterDialog
        open={newsletterDisclosure.isOpen}
        onOpenChange={newsletterDisclosure.onOpenChange}
      />
      <SignupDialog
        open={signUpDialogDisclosure.isOpen}
        onOpenChange={signUpDialogDisclosure.onOpenChange}
      />
    </header>
  );
}

export function PreviewHeading({
  children,
  preHeading,
  className,
  ...rest
}: ComponentProps<"div"> & { preHeading?: React.ReactNode }) {
  return (
    <div className={cn(className)} {...rest}>
      {preHeading && (
        <small className="text-muted-foreground text-cm">{preHeading}</small>
      )}
      <H1>{children}</H1>
    </div>
  );
}
