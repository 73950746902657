import { Logo } from "@/components";
import { Button } from "@/components/ui/button";
import { DashboardAvatarDrawer } from "@/components/avatar-drawer";
import { cn, DEFAULT_ICON_SIZE, DEFAULT_ICON_SIZE_LG } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { ComponentProps, ReactNode } from "react";

export function HeaderBackButton({
  children,
  className,
  ...rest
}: ComponentProps<typeof Button>) {
  return (
    <div className="flex items-center gap-2">
      <Button
        variant="secondary"
        size={"icon"}
        className={cn(" text-white", className)}
        {...rest}
      >
        <ArrowLeft size={DEFAULT_ICON_SIZE_LG} className="" />
      </Button>
      <span className="font-semibold">{children}</span>
    </div>
  );
}

export function DashboardHeader({
  organizationSlug,
  showUpgradeButton,
  leftItems,
}: {
  organizationSlug: string;
  showUpgradeButton: boolean;
  leftItems?: ReactNode;
}) {
  return (
    <div className="grid items-center grid-cols-[1fr,max-content,max-content] gap-4 pt-3">
      <div>
        {leftItems || (
          <Link to="/dash/$organizationSlug/home" params={{ organizationSlug }}>
            <Logo width={80} />
          </Link>
        )}
      </div>
      {showUpgradeButton && (
        <Link
          to="/dash/$organizationSlug/pricing"
          params={{ organizationSlug }}
        >
          <Button
            size="sm"
            variant="secondary"
            className="gap-2 rounded-full pr-1 pl-4"
          >
            <span>Upgrade to pro</span>
            <div className="arrow grid items-center justify-center h-6 w-6 rounded-full bg-brand text-primary-foreground">
              <ArrowRight size={DEFAULT_ICON_SIZE} />
            </div>
          </Button>
        </Link>
      )}
      <DashboardAvatarDrawer organizationSlug={organizationSlug} />
    </div>
  );
}
