import { HttpStatus } from "@/lib/helpers";

export const httpStatusDescriptions: Record<
  HttpStatus,
  { description: string }
> = {
  "100": {
    description: `The server has received the initial part of the request and the client should continue with the request.`,
  },
  "200": {
    description: `The request was successful, and the server has returned the requested resource in the response body.`,
  },
  "201": {
    description: `The request was successful, and a new resource was created.`,
  },
  "202": {
    description: `The request has been accepted for processing, but the processing has not been completed. The request might be processed later.`,
  },
  "203": {
    description: `The request was successful, but the returned metadata might not be the definitive set from the origin server.`,
  },
  "204": {
    description: `The request was successful, but there is no content to return in the response.`,
  },
  "206": {
    description: `The server is delivering only a part of the resource due to a range header sent by the client.`,
  },
  "207": {
    description: `The message body that follows is by default an XML message and can contain a number of separate response codes.`,
  },
  "208": {
    description: `The members of a DAV binding have already been enumerated in a previous reply to this request, and are not being included again.`,
  },
  "226": {
    description: `The server has fulfilled a GET request for the resource, and the response is a representation of the result of one or more instance-manipulations applied to the current instance.`,
  },
  "300": {
    description: `The requested resource corresponds to any one of a set of representations, each with its own specific location, and agent-driven negotiation information is being provided so that the user can select a preferred representation and redirect its request to that location.`,
  },
  "301": {
    description: `The requested resource has been permanently moved to a new URI, and any future references should use this new URI.`,
  },
  "302": {
    description: `The requested resource resides temporarily at a different URI, as indicated by the Location header in the response.`,
  },
  "303": {
    description: `The server sent this response to direct the client to get the requested resource at another URI with a GET request.`,
  },
  "304": {
    description: `The resource has not been modified since the version specified by the request headers. The client can use its cached version of the resource.`,
  },
  "305": {
    description: `The requested resource is only available through a proxy, whose address is provided in the response.`,
  },
  "306": {
    description: `No longer used. Originally meant to indicate that a subsequent request should be made using a specified proxy.`,
  },
  "307": {
    description: `The request should be repeated with another URI, but future requests can still use the original URI.`,
  },
  "308": {
    description: `The requested resource has been permanently moved to a new URI, and all future requests should use this new URI.`,
  },
  "400": {
    description: `The server could not understand the request due to invalid syntax. The client should modify the request and try again.`,
  },
  "401": {
    description: `Authentication is required to access the requested resource. The client must include the appropriate credentials.`,
  },
  "402": {
    description: `Reserved for future use. Some APIs use this status code for billing-related errors.`,
  },
  "403": {
    description: `The server understood the request, but refuses to authorize it. Ensure the client has appropriate permissions.`,
  },
  "404": {
    description: `The server cannot find the requested resource. The endpoint may be invalid or the resource may no longer exist.`,
  },
  "405": {
    description: `The request method is not allowed for the requested resource. Check the Allow header for supported methods.`,
  },
  "406": {
    description: `The requested resource is capable of generating only content not acceptable according to the Accept headers sent in the request.`,
  },
  "407": {
    description: `The client must first authenticate itself with the proxy before the request can be fulfilled.`,
  },
  "408": {
    description: `The server timed out waiting for the request. Consider retrying the request with a longer timeout.`,
  },
  "409": {
    description: `The request could not be completed due to a conflict with the current state of the resource. Resolve the conflict and try again.`,
  },
  "410": {
    description: `The requested resource is no longer available and will not be available again. Clients should remove all references.`,
  },
  "411": {
    description: `The server refuses to accept the request without a defined Content-Length header.`,
  },
  "412": {
    description: `One or more conditions given in the request header fields evaluated to false when tested on the server.`,
  },
  "413": {
    description: `The request is larger than the server is willing or able to process. Reduce the size of the request and try again.`,
  },
  "414": {
    description: `The URI requested by the client is longer than the server is willing to interpret. Simplify the URI and try again.`,
  },
  "416": {
    description: `The client has asked for a portion of the file, but the server cannot supply that portion. Ensure the request range is valid.`,
  },
  "417": {
    description: `The server cannot meet the requirements of the Expect request-header field. Adjust the request and try again.`,
  },
  "418": {
    description: `A humorous response code indicating that the server is a teapot, not capable of brewing coffee.`,
  },
  "420": {
    description: `Used by some servers to indicate that a user has sent too many requests in a given amount of time. Retry after a delay.`,
  },
  "422": {
    description: `The request was well-formed but could not be processed due to semantic errors. Correct the data and try again.`,
  },
  "423": {
    description: `The resource that is being accessed is locked. Check the lock status and try again.`,
  },
  "424": {
    description: `The request failed because it depended on another request and that request failed. Resolve the dependency issue and try again.`,
  },
  "425": {
    description: `The server is unwilling to risk processing a request that might be replayed.`,
  },
  "426": {
    description: `The server refuses to perform the request using the current protocol but might be willing to do so after the client upgrades to a different protocol.`,
  },
  "429": {
    description: `The user has sent too many requests in a given amount of time. Reduce the request rate and try again later.`,
  },
  "431": {
    description: `The server is unwilling to process the request because its header fields are too large. Reduce the size of the request headers and try again.`,
  },
  "444": {
    description: `The server closed the connection without sending any response to the client.`,
  },
  "450": {
    description: `The request was blocked by Windows Parental Controls. Adjust parental control settings to allow access.`,
  },
  "451": {
    description: `The server is denying access to the resource as a consequence of a legal demand.`,
  },
  "494": {
    description: `The request header is too large for the server to process. Reduce the size of the request header and try again.`,
  },
  "500": {
    description: `The server encountered an unexpected condition that prevented it from fulfilling the request. Report the issue to the support team if it persists.`,
  },
  "501": {
    description: `The server does not support the functionality required to fulfill the request. Verify the request method and URI.`,
  },
  "502": {
    description: `The server, while acting as a gateway or proxy, received an invalid response from the upstream server. Check the upstream server status.`,
  },
  "503": {
    description: `The server is currently unable to handle the request due to a temporary overload or scheduled maintenance. Try again later.`,
  },
  "504": {
    description: `The server, while acting as a gateway or proxy, did not receive a timely response from the upstream server. Check the network connection and server status.`,
  },
  "506": {
    description: `The server has an internal configuration error: the chosen variant resource is configured to engage in transparent content negotiation itself, and is therefore not a proper end point in the negotiation process.`,
  },
  "507": {
    description: `The server is unable to store the representation needed to complete the request. Free up space on the server and try again.`,
  },
  "508": {
    description: `The server detected an infinite loop while processing the request. Check for cyclic references in the request.`,
  },
  "509": {
    description: `The server has exceeded the bandwidth limit imposed by the server owner. Try again after the limit resets.`,
  },
  "510": {
    description: `Further extensions to the request are required for the server to fulfill it. Review the request and include the necessary extensions.`,
  },
  default: {
    description:
      "Operations can return multiple errors with different HTTP status codes, but all of them have the same response structure",
  },
};
