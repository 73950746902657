import { cn, NormIcons } from "@/lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps } from "react";

const normIconStyles = cva("", {
  variants: {
    variant: {
      frame: "flex size-6 items-center justify-center rounded-sm border",
      default: "",
    },
    size: {
      xs: "size-3",
      sm: "size-4",
      md: "size-6",
      lg: "size-8",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "sm",
  },
});

const frameStyles = cva("shrink-0 flex items-center justify-center ", {
  variants: {
    variant: {
      frame: "rounded-sm border",
      default: "",
    },
    size: {
      xs: "",
      sm: "",
      md: "",
      lg: "",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "sm",
  },
});

export function NormIcon({
  name,
  size,
  variant,
  className,
}: ComponentProps<"span"> & { name: keyof typeof NormIcons } & VariantProps<
    typeof normIconStyles
  >) {
  const Icon = NormIcons[name];
  return (
    <span className={cn(frameStyles({ size, variant }), className)}>
      <Icon className={normIconStyles({ size, variant })} />
    </span>
  );
}
