import { Logo } from "@/components";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ButtonGroup } from "@/forms";
import { Link } from "@tanstack/react-router";
import { ComponentProps } from "react";

export function SignupDialog({ ...rest }: ComponentProps<typeof Dialog>) {
  return (
    <Dialog {...rest}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Create an account</DialogTitle>
          <div className="py-4">
            <Logo width={120} />
          </div>
          <DialogDescription>
            Easily share workspaces and collaborate on API Design.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="sm:justify-start">
          <ButtonGroup>
            <Link to="/login">
              <Button type="button">Sign up</Button>
            </Link>

            <Link to="/login">
              <Button type="button" variant="secondary">
                Login
              </Button>
            </Link>
          </ButtonGroup>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
