import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps } from "react";
import { cn } from "@/lib/utils";

export type ColorBadeColors = NonNullable<
  VariantProps<typeof colorBadge>["color"]
>;

const colorBadge = cva(
  "inline-flex items-center border px-2.5 py-1.5 rounded-md text-xs font-semibold transition-colors border-transparent focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 shadow hover:[&[role='button']]:opacity-80",
  {
    variants: {
      variant: {
        default: "",
        link: "",
        outline: "border",
      },
      size: {
        default: "",
        icon: "",
      },
      color: {
        blue: "data-[variant=default]:bg-blue-300 data-[variant=default]:text-blue-950 data-[variant=link]:text-blue-300 data-[variant=outline]:text-blue-300 data-[variant=outline]:border-blue-300",
        cyan: "data-[variant=default]:bg-cyan-300/90 data-[variant=default]:text-cyan-950 data-[variant=link]:text-cyan-300 data-[variant=outline]:text-cyan-300 data-[variant=outline]:border-cyan-300",
        yellow:
          "data-[variant=default]:bg-yellow-300/90 data-[variant=default]:text-yellow-950 data-[variant=link]:text-yellow-300 data-[variant=outline]:text-yellow-300 data-[variant=outline]:border-yellow-300",
        orange:
          "data-[variant=default]:bg-orange-300/90 data-[variant=default]:text-orange-950 data-[variant=link]:text-orange-300 data-[variant=outline]:text-orange-300 data-[variant=outline]:border-orange-300",
        red: "data-[variant=default]:bg-red-300/90 data-[variant=default]:text-red-950 data-[variant=link]:text-red-300 data-[variant=outline]:text-red-300 data-[variant=outline]:border-red-300",
        green:
          "data-[variant=default]:bg-green-300/90 data-[variant=default]:text-green-950 data-[variant=link]:text-green-300 data-[variant=outline]:text-green-300 data-[variant=outline]:border-green-300",
        purple:
          "data-[variant=default]:bg-purple-300/90 data-[variant=default]:text-purple-950 data-[variant=link]:text-purple-300 data-[variant=outline]:text-purple-300 data-[variant=outline]:border-purple-300",
        gray: "data-[variant=default]:bg-gray-300/90 data-[variant=default]:text-purple-950 data-[variant=link]:text-gray-300 data-[variant=outline]:text-gray-300 data-[variant=outline]:border-gray-300",
        amber:
          "data-[variant=default]:bg-amber-300/90 data-[variant=default]:text-amber-950 data-[variant=link]:text-amber-300 data-[variant=outline]:text-amber-300 data-[variant=outline]:border-amber-300",
        lime: "data-[variant=default]:bg-lime-300/90 data-[variant=default]:text-lime-950 data-[variant=link]:text-lime-300 data-[variant=outline]:text-lime-300 data-[variant=outline]:border-lime-300",
        emerald:
          "data-[variant=default]:bg-emerald-300/90 data-[variant=default]:text-emerald-950 data-[variant=link]:text-emerald-300 data-[variant=outline]:text-emerald-300 data-[variant=outline]:border-emerald-300",
        violet:
          "data-[variant=default]:bg-violet-300/90 data-[variant=default]:text-violet-950 data-[variant=link]:text-violet-300 data-[variant=outline]:text-violet-300 data-[variant=outline]:border-violet-300",
        rose: "data-[variant=default]:bg-rose-300/90 data-[variant=default]:text-rose-950 data-[variant=link]:text-rose-300 data-[variant=outline]:text-rose-300 data-[variant=outline]:border-rose-300",
        pink: "data-[variant=default]:bg-pink-300/90 data-[variant=default]:text-pink-950 data-[variant=link]:text-pink-300 data-[variant=outline]:text-pink-300 data-[variant=outline]:border-pink-300",
        teal: "data-[variant=default]:bg-teal-300/90 data-[variant=default]:text-teal-950 data-[variant=link]:text-teal-300 data-[variant=outline]:text-teal-300 data-[variant=outline]:border-teal-300",
        sky: "data-[variant=default]:bg-sky-300/90 data-[variant=default]:text-sky-950 data-[variant=link]:text-sky-300 data-[variant=outline]:text-sky-300 data-[variant=outline]:border-sky-300",
        indigo:
          "data-[variant=default]:bg-indigo-300/90 data-[variant=default]:text-indigo-950 data-[variant=link]:text-indigo-300 data-[variant=outline]:text-indigo-300 data-[variant=outline]:border-indigo-300",
        fuchsia:
          "data-[variant=default]:bg-fuchsia-300/90 data-[variant=default]:text-fuchsia-950 data-[variant=link]:text-fuchsia-300 data-[variant=outline]:text-fuchsia-300 data-[variant=outline]:border-fuchsia-300",
        muted:
          "data-[variant=default]:bg-orange-200/10 data-[variant=default]:text-primary data-[variant=link]:text-gray-300 data-[variant=outline]:text-gray-300 data-[variant=outline]:border-gray-300",
        ghost:
          "data-[variant=default]:bg-transparent data-[variant=default]:text-muted-foreground data-[variant=link]:text-primary data-[variant=outline]:text-gray-300 data-[variant=outline]:border-gray-300",
      },
      state: {
        active: "ring-2",
        inactive: "ring-0",
      },
    },
    defaultVariants: {
      state: "inactive",
      color: "blue",
      variant: "link",
    },
  }
);

export function ColorBadge({
  className,
  color,
  onClick,
  variant = "default",
  size = "default",
  ...rest
}: ComponentProps<"div"> & VariantProps<typeof colorBadge>) {
  return (
    <div
      role={onClick && "button"}
      data-variant={variant}
      onClick={onClick}
      className={cn(
        "whitespace-nowrap",
        colorBadge({ color: color, size }),
        className
      )}
      {...rest}
    />
  );
}
