import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  addResponseToOperationInDefinition,
  dereferenceResponseInDefinition,
  editResponseInDefinition,
  getResponseOrErrorFromDefinition,
  removeResponseFromDefinition,
} from "@/lib/editor-mutations/oas-responses";
import { HttpStatus } from "@/lib/helpers";
import {
  OASResponse,
  OASResponseObject,
  OASResponsesObject,
} from "@/lib/types";
import { toastError } from "@/lib/utils";
import get from "lodash/get";
import { useCallback } from "react";
import { toast } from "sonner";

export function useResponses(props: EditorInputProps) {
  const { oas, setNewEditorState } = useAPIEditorTools(props);
  const definition = oas;
  if (!definition) throw new Error("Definition is not defined");

  const getResponses = useCallback(
    (path: string) => {
      return get(definition, path) as OASResponsesObject | undefined;
    },
    [definition]
  );

  const getResponse = useCallback(
    (path: string, code: string | number) => {
      const response = get(definition, `${path}.${code}`) as
        | OASResponseObject
        | undefined;
      return response;
    },
    [definition]
  );

  const getResponseOrError = useCallback(
    ({
      operationId,
      responseCode,
    }: {
      operationId: string;
      responseCode: string;
    }) => {
      return getResponseOrErrorFromDefinition({
        operationId,
        definition: props.value.data,
        responseCode,
      });
    },
    [props.value]
  );

  const addResponse = useCallback(
    ({
      operationId,
      responseCode,
      response,
      onSuccess,
    }: {
      operationId: string;
      responseCode: HttpStatus;
      response: OASResponse;
      onSuccess?: () => unknown;
    }) => {
      try {
        const newDefinition = addResponseToOperationInDefinition({
          operationId,
          responseCode,
          response,
          definition,
        });
        setNewEditorState({
          data: newDefinition,
          document_type: props.value.document_type,
        });
        toast.success("Response added");
        onSuccess?.();
      } catch (err) {
        toastError(err);
      }
    },
    [definition, setNewEditorState, props.value.document_type]
  );

  const removeResponse = useCallback(
    ({
      operationId,
      responseCode,
    }: {
      operationId: string;
      responseCode: string;
    }) => {
      const newDefinition = removeResponseFromDefinition({
        operationId,
        responseCode,
        definition,
      });
      setNewEditorState({
        data: newDefinition,
        document_type: props.value.document_type,
      });
    },
    [definition, setNewEditorState, props.value.document_type]
  );

  const update = useCallback(
    ({
      response,
      newResponseCode,
      oldResponseCode,
      operationId,
    }: {
      response: OASResponse;
      newResponseCode: string;
      oldResponseCode: string;
      operationId: string;
    }) => {
      const newDefinition = editResponseInDefinition({
        operationId,
        response,
        newResponseCode,
        oldResponseCode,
        definition: props.value.data,
      });
      setNewEditorState({
        data: newDefinition,
        document_type: props.value.document_type,
      });
    },
    [setNewEditorState, props.value]
  );

  const dereferenceResponse = useCallback(
    ({
      operationId,
      responseCode,
    }: {
      operationId: string;
      responseCode: string;
    }) => {
      try {
        const newDefinition = dereferenceResponseInDefinition({
          operationId,
          responseCode,
          definition: props.value.data,
        });
        setNewEditorState({
          data: newDefinition,
          document_type: props.value.document_type,
        });
        toast.success("Reference removed");
      } catch (err) {
        toastError(err);
      }
    },
    [props.value, setNewEditorState]
  );

  return {
    removeResponse,
    addResponse,
    definition,
    getResponse,
    getResponseOrError,
    update,
    getResponses,
    dereferenceResponse,
  };
}
