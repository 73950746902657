import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { MdTextarea } from "@/components/md-textarea";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaEditor } from "@/components/schema-editor";
import { SchemaEditorPreviewContainer } from "@/components/schema-editor-containers";
import { FieldVStack, FormComp, FormContent, FormHeader } from "@/forms";
import { getPristineType } from "@/lib/oas-tools/oas-schema-utils";
import {
  OASComponentsObject,
  OASReferenceObject,
  OASSchema,
} from "@/lib/types";
import { zodResolver } from "@hookform/resolvers/zod";
import merge from "lodash/merge";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { ActionBarContent } from "./action-bar-content";
import { ActionBarFormTitle } from "./elements";

const formValues = z.object({
  description: z.string().trim(),
  schema: z.record(z.string(), z.any()) as z.ZodType<
    OASSchema | OASReferenceObject
  >,
});
export type PathParameterFormValues = z.infer<typeof formValues>;

export function PathParameterForm({
  defaultValues,
  onSubmit,
  title,
  componentsObject,
  btnTitle,
}: {
  defaultValues: Partial<PathParameterFormValues>;
  onSubmit: (values: PathParameterFormValues) => unknown;
  title: string;
  componentsObject: OASComponentsObject;
  btnTitle: "Create" | "Edit";
}) {
  const form = useForm<PathParameterFormValues>({
    resolver: zodResolver(formValues),
    defaultValues: merge(
      {
        description: "",
        schema: getPristineType("string"),
      },
      defaultValues
    ),
  });

  return (
    <ActionBarContent>
      <Form {...form}>
        <FormComp
          onSubmit={(e) => {
            form.handleSubmit(onSubmit)(e);
          }}
        >
          <FormHeader>
            <ActionBarFormTitle>{title}</ActionBarFormTitle>
          </FormHeader>
          <FormContent>
            <FieldVStack>
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="flex gap-2 items-center">
                      Description
                    </FormLabel>
                    <FormControl>
                      <MdTextarea height={130} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Controller
                name="schema"
                control={form.control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <SchemaEditorPreviewContainer title="Schema">
                      <SchemaEditor
                        value={value}
                        onChange={onChange}
                        onRemoveRootSchema={() =>
                          form.setValue("schema", getPristineType("string"))
                        }
                        componentsObject={componentsObject}
                        allowTopLevelReferences={true}
                      />
                    </SchemaEditorPreviewContainer>
                  );
                }}
              />
            </FieldVStack>
          </FormContent>
          <BtnGroup className="justify-end">
            <SubmitButton>{btnTitle}</SubmitButton>
          </BtnGroup>
        </FormComp>
      </Form>
    </ActionBarContent>
  );
}
