import { Copy } from "lucide-react";
import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useState } from "react";

export default function WorkspaceInvitationLinkModal({
  isOpen,
  onClose,
  inviteLink,
  inviteeEmail,
}: {
  isOpen: boolean;
  onClose: () => unknown;
  inviteLink: string;
  inviteeEmail: string;
}) {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(inviteLink);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader className="pt-4">
          <Alert variant="default">
            <AlertDescription>
              An email was sent! In case the email got lost, send the invitation
              link manually.
            </AlertDescription>
          </Alert>
        </DialogHeader>
        <div className="space-y-4">
          <p className="text-sm text-muted-foreground">
            To invite a user to your workspace, please follow these steps:
          </p>
          <ol className="list-decimal list-inside space-y-2 text-sm">
            <li>Copy the invite link below</li>
            <li>Send this link to the user at {inviteeEmail}</li>
            <li>
              Ask them to sign up using {inviteeEmail} as their email address
            </li>
          </ol>
          <div className="flex space-x-2">
            <Input readOnly value={inviteLink} className="flex-grow" />
            <Button onClick={copyToClipboard} className="flex-shrink-0">
              {isCopied ? (
                "Copied!"
              ) : (
                <>
                  <Copy className="mr-2 h-4 w-4" />
                  Copy
                </>
              )}
            </Button>
          </div>
          <p className="text-xs text-muted-foreground">
            Note: This invite link is unique and can only be used once.
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
}
