import { apiClient } from "@/lib/http-utils";
import { useQuery } from "@tanstack/react-query";

export const workspaceItemKeys = {
  all: ["workspace-items"] as const,
  lists: () => [...workspaceItemKeys.all, "list"] as const,
  list: (filters: { workspaceId: string }) =>
    [...workspaceItemKeys.lists(), filters] as const,
  details: () => [...workspaceItemKeys.all, "detail"] as const,
  detail: (id: string) => [...workspaceItemKeys.details(), id] as const,
};

export function useWorkspaceItems({ workspaceId }: { workspaceId: string }) {
  return useQuery({
    queryKey: workspaceItemKeys.list({ workspaceId }),
    queryFn: async () => {
      return (await apiClient.findWorkspaceItems({ workspaceId })).data;
    },
  });
}
