const WaveLoading = () => {
  return (
    <div className="flex items-center justify-center w-full h-12">
      <svg viewBox="0 0 200 100" className="w-full max-w-md">
        <path
          d="M 0,50 
             C 20,50 20,20 40,20 
             C 60,20 60,80 80,80 
             C 100,80 100,20 120,20 
             C 140,20 140,80 160,80 
             C 180,80 180,20 200,20"
          fill="none"
          stroke="currentColor"
          strokeWidth="12"
          strokeLinecap="round"
          className="animate-[wave_6s_ease-in-out_infinite]"
        />
      </svg>
    </div>
  );
};

export default WaveLoading;
