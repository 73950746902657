import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { FormLabelRequired } from "@/components/form-label-required";
import { MdTextarea } from "@/components/md-textarea";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import {
  FieldVStack,
  FormComp,
  FormContent,
  FormHeader,
  FormTitle,
} from "@/forms";
import { MutationCallbacks } from "@/hooks";
import { useServers } from "@/hooks/use-servers";
import { OASServerWithInfo } from "@/lib/editor-mutations/oas-servers";
import { appRegex } from "@/lib/regex";
import { StandardFormProps } from "@/lib/types";
import { toastError } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  url: z
    .string()
    .regex(appRegex.genericUrl.expression, appRegex.genericUrl.message),
  description: z.string().optional(),
});

type FormValues = z.infer<typeof formSchema>;

function RawForm({
  defaultValues,
  onSubmit,
  isDisabled,
  btnTitle,
  title,
}: StandardFormProps<FormValues>) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    disabled: isDisabled,
  });

  return (
    <Form {...form}>
      <FormComp onSubmit={form.handleSubmit(onSubmit)}>
        <FormHeader>
          <FormTitle>{title}</FormTitle>
        </FormHeader>
        <FormContent>
          <FieldVStack>
            <FormField
              control={form.control}
              name="url"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Url
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <MdTextarea height={130} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </FieldVStack>
          <BtnGroup className="justify-end">
            <SubmitButton>{btnTitle}</SubmitButton>
          </BtnGroup>
        </FormContent>
      </FormComp>
    </Form>
  );
}

export function CreateServerForm({
  onSettled,
  onSuccess,
  onError,
  value,
  onChange,
}: MutationCallbacks & EditorInputProps) {
  const { addServer } = useServers({ value, onChange });

  const handleSubmit: SubmitHandler<FormValues> = (values) => {
    try {
      addServer({ url: values.url, description: values.description });
      toast.success("Server created");
      onSuccess?.();
    } catch (err) {
      toastError(err);
      onError?.();
    } finally {
      onSettled?.();
    }
  };

  return (
    <RawForm
      defaultValues={{ url: "", description: "" }}
      onSubmit={handleSubmit}
      btnTitle="Create"
      title="Create server"
      isDisabled={false}
    />
  );
}

export function UpdateServerForm({
  onChange,
  value,
  onSuccess,
  onSettled,
  onError,
  serverWithInfo,
}: { serverWithInfo: OASServerWithInfo } & EditorInputProps &
  MutationCallbacks) {
  const { updateServer } = useServers({ value, onChange });

  const handleSubmit: SubmitHandler<FormValues> = (values) => {
    try {
      updateServer({
        idx: serverWithInfo.idx,
        newServerObject: {
          url: values.url,
          description: values.description,
        },
      });
      toast.success("Server udpated");
      onSuccess?.();
    } catch (err) {
      toastError(err);
      onError?.();
    } finally {
      onSettled?.();
    }
  };

  return (
    <RawForm
      btnTitle="Update"
      title="Update server"
      onSubmit={handleSubmit}
      defaultValues={{ ...serverWithInfo.server }}
      isDisabled={false}
    />
  );
}
