import { LegacyEditorState } from "@/components/contexts/api-editor-context";
import {
  editorSearchSchema,
  EditorState,
} from "@/components/module-api-editor/types";
import { WorkspaceDocumentEditor } from "@/components/module-api-editor/workspace-document-editor";
import { localStorageKeys, queryParams } from "@/lib/const";
import { emptyWorkspaceExample } from "@/lib/oas-examples/empty-workspace";
import { validateEditorStateBeforeUse } from "@/lib/oas-tools/oas-schema-utils";
import { OASDefinition } from "@/lib/types";
import { getIsAuthed } from "@/lib/utils";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { zodSearchValidator } from "@tanstack/router-zod-adapter";
import { useCallback, useMemo, useState } from "react";
import { toast } from "sonner";
import { parse } from "yaml";

function cleanURL() {
  const newURL = new URL(window.location.href);
  newURL.search = "";
  return newURL.toString();
}

export const Route = createFileRoute("/")({
  component: Index,
  validateSearch: zodSearchValidator(editorSearchSchema),
});

function saveEditorToLocalStorage(editorState: EditorState) {
  try {
    localStorage.setItem(
      localStorageKeys.editorStateV1,
      JSON.stringify(editorState)
    );
  } catch {
    toast.error("Unable to save editor state");
  }
}

function getAndMigratePreservedLegacyEditorState(): EditorState {
  const preservedLegacyEditorState = localStorage.getItem(
    localStorageKeys.legacyPreservedEditorState
  );

  const preservedEditorState: string | null = localStorage.getItem(
    localStorageKeys.editorStateV1
  );

  if (preservedLegacyEditorState) {
    try {
      const legacyEditorState: LegacyEditorState = JSON.parse(
        preservedLegacyEditorState
      ) as LegacyEditorState;
      const newEditorState: EditorState = {
        data: legacyEditorState.oas,
        document_type: "oas_api_3_1",
      };
      localStorage.setItem(
        localStorageKeys.editorStateV1,
        JSON.stringify(newEditorState)
      );
      localStorage.removeItem(localStorageKeys.legacyPreservedEditorState);
      return newEditorState;
    } catch {
      toast.error("Unable to validate preserved editor state");
    }
  } else if (preservedEditorState) {
    try {
      const editorState: EditorState = JSON.parse(
        preservedEditorState
      ) as EditorState;
      return validateEditorStateBeforeUse(editorState);
    } catch {
      toast.error("Unable to validate preserved editor state");
    }
  }
  return {
    data: parse(emptyWorkspaceExample) as OASDefinition,
    document_type: "oas_api_3_1",
  };
}

function Index() {
  const initialState: EditorState = useMemo(() => {
    const searchParam = new URLSearchParams(window.location.search);
    const editorParam = searchParam.get(queryParams.editorState);
    if (editorParam) {
      try {
        const legacyEditorState: LegacyEditorState = JSON.parse(
          editorParam
        ) as LegacyEditorState;
        const parsed = validateEditorStateBeforeUse({
          data: legacyEditorState.oas,
          document_type: "oas_api_3_1",
        });
        const newOas = parsed.data;

        if (!newOas) throw new Error("Unable to find initial oas editor state");

        window.history.pushState({}, document.title, cleanURL());
        toast.success("Successfully opened workspace");
        saveEditorToLocalStorage(parsed);
        return parsed;
      } catch (err) {
        if (err instanceof Error)
          toast.error(`Unable to parse initial editor state: ${err.message}`);
      }
    }
    return getAndMigratePreservedLegacyEditorState();
  }, []);

  const { activeElement } = Route.useSearch();

  const navigate = useNavigate();

  const [value, setValue] = useState<EditorState>(initialState);

  const isAuthed = useMemo(() => getIsAuthed(), []);

  if (isAuthed) void navigate({ to: "/dash/onboarding" });

  const handleChange = useCallback((newEditorState: EditorState) => {
    saveEditorToLocalStorage(newEditorState);
    setValue(newEditorState);
  }, []);

  return (
    <WorkspaceDocumentEditor
      value={value}
      onChange={handleChange}
      activeElement={activeElement}
      workspaceRole="admin"
      isAuthed={isAuthed}
      initialState={{
        editorMode: "annonymous",
      }}
    />
  );
}
