import { H2, H3 } from "@/components/headings";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { ComponentProps, forwardRef } from "react";

export function FormBox({
  children,
  title,
  className,
  ...rest
}: ComponentProps<"div"> & { title: string }) {
  return (
    <div className={cn("rounded-lg px-6 py-6 border", className)} {...rest}>
      <H3 className="font-mono pb-4">{title}</H3>
      <div>{children}</div>
    </div>
  );
}

export function FieldVStack({ className, ...rest }: ComponentProps<"div">) {
  return (
    <div
      className={cn("flex flex-col items-stretch gap-4 py-2", className)}
      {...rest}
    />
  );
}

export function FieldHStack({
  className,
  itemsPerRow = 2,
  ...rest
}: ComponentProps<"div"> & { itemsPerRow?: number }) {
  return (
    <div
      className={cn(`grid gap-2 py-2 grid-cols-${itemsPerRow}`, className)}
      {...rest}
    />
  );
}

export function FormComp({
  children,
  className,
  ...props
}: ComponentProps<"form">) {
  return (
    <form className={className} {...props}>
      {children}
    </form>
  );
}

export function FormHeader({
  children,
  className,
  ...rest
}: ComponentProps<"div">) {
  return (
    <div className={cn(className)} {...rest}>
      {children}
    </div>
  );
}

export function FormContent({
  children,
  className,
  ...rest
}: ComponentProps<"div">) {
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
}

export function FormFooter({
  children,
  className,
  ...rest
}: ComponentProps<"div">) {
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
}

export const FormToolbar = forwardRef<HTMLDivElement, ComponentProps<"div">>(
  ({ className, children, ...rest }, ref) => {
    return (
      <div {...rest} className={cn("flex gap-2", className)} ref={ref}>
        {children}
      </div>
    );
  }
);

export const FormToolbarButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>(({ className, children, ...rest }, ref) => {
  return (
    <Button
      {...rest}
      size="xs"
      variant="outline"
      className={cn("", className)}
      ref={ref}
    >
      {children}
    </Button>
  );
});

export function FormTitle({
  children,
  className,
  ...rest
}: ComponentProps<typeof H3>) {
  return (
    <H2 className={cn("text-muted-foreground", className)} {...rest}>
      <span className="inline-block pb-0.5 pr-1 mb-2">{children}</span>
    </H2>
  );
}

export function ButtonGroup({
  className,
  children,
  ...rest
}: ComponentProps<"div">) {
  return (
    <div className={cn("pt-4 flex gap-2", className)} {...rest}>
      {children}
    </div>
  );
}
