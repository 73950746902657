import { cn } from "@/lib/utils";
import { ComponentProps } from "react";

interface HeadingsProps extends ComponentProps<"h1"> {}

export function H1({ className, ...rest }: HeadingsProps) {
  return <h1 className={cn("text-3xl font-normal", className)} {...rest} />;
}

export function H2({ className, ...rest }: HeadingsProps) {
  return <h1 className={cn("text-md font-bold", className)} {...rest} />;
}

export function H3({ className, ...rest }: HeadingsProps) {
  return <h1 className={cn("text-md", className)} {...rest} />;
}

export function H4({ className, ...rest }: HeadingsProps) {
  return <h1 className={cn("text-sm", className)} {...rest} />;
}

export function H5({ className, ...rest }: HeadingsProps) {
  return <h1 className={cn("text-xs", className)} {...rest} />;
}
