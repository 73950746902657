import { EditorState } from "@/components/module-api-editor/types";
import { OASDefinition } from "@/lib/types";
import defaults from "lodash/defaults";
import { createContext, PropsWithChildren, useMemo, useReducer } from "react";

export const WorkspaceEditorContext = createContext<
  readonly [WorkspaceEditorContextState, React.Dispatch<Action>] | undefined
>(undefined);

export type LegacyEditorState = {
  oas: OASDefinition;
};

export type EditorMode = "annonymous" | "authed" | "draft";

type Action = {
  type: "SET_HISTORY";
  payload: EditorState[];
};

export type WorkspaceEditorContextState = {
  history: EditorState[];
  editorMode: EditorMode;
};

const initialState: Omit<WorkspaceEditorContextState, "workspace"> = {
  editorMode: "annonymous",
  history: [],
};

function reducer(
  state: WorkspaceEditorContextState,
  action: Action
): WorkspaceEditorContextState {
  switch (action.type) {
    case "SET_HISTORY":
      return {
        ...state,
        history: action.payload,
      };
    default:
      // const _: never = action;
      throw new Error(
        `Unknown action in APIEditorContext: ${JSON.stringify(action, null, 2).toString()}`
      );
  }
}

export const WorkspaceEditorProvider = ({
  children,
  initialState: initialProvidedState,
}: PropsWithChildren<{
  initialState?: Partial<WorkspaceEditorContextState> & {
    editorMode: EditorMode;
  };
}>) => {
  const [state, dispatch] = useReducer(
    reducer,
    defaults(initialProvidedState, initialState)
  );
  const value = useMemo(() => [state, dispatch] as const, [state]);
  return (
    <WorkspaceEditorContext.Provider value={value}>
      {children}
    </WorkspaceEditorContext.Provider>
  );
};
