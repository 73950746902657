const NotFoundAnimation = () => {
  return (
    <div className="flex flex-col items-center justify-center h-[160px]">
      <svg viewBox="0 0 400 200" className="w-full max-w-md">
        <g className="animate-[cross_6s_ease-in-out_infinite]">
          <path
            d="M0,100 C40,130 80,70 120,100 C160,130 200,70 240,100 C280,130 320,70 360,100 C400,130 400,70 400,70"
            fill="none"
            stroke="currentColor"
            strokeWidth="4"
            strokeLinecap="round"
            className="animate-[dash_6s_linear_infinite]"
          />
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            className="text-6xl font-bold"
            fill="white" // Set text color to white
          >
            404
          </text>
        </g>
      </svg>
    </div>
  );
};

export default NotFoundAnimation;
