import { UserAvatar } from "@/components";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";
import { Link } from "@tanstack/react-router";

export function DashboardAvatarDrawer({
  organizationSlug,
}: {
  organizationSlug: string;
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <UserAvatar />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Navigate</DropdownMenuLabel>
        <DropdownMenuGroup>
          <Link to="/dash/$organizationSlug/home" params={{ organizationSlug }}>
            <DropdownMenuItem>
              <NormIcons.Dashboard
                size={DEFAULT_ICON_SIZE}
                className="text-muted-foreground mr-2"
              />
              <span>Go to Dashboard</span>
            </DropdownMenuItem>
          </Link>
          <Link to="/force-logout">
            <DropdownMenuItem>
              <NormIcons.Logout
                size={DEFAULT_ICON_SIZE}
                className="text-muted-foreground mr-2"
              />
              Logout
            </DropdownMenuItem>
          </Link>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
