import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { useComponentParameters } from "@/hooks/use-component-parameters";
import { findOperationByIdOrErrorFromDefinition } from "@/lib/editor-mutations/oas-operations";
import {
  addParameterToDefinition,
  dereferenceParameterInDefinition,
  findAuthenticationRequestParametersForOperationFromDefinition,
  getParameterByIndexFromDefinition,
  removeParameterFromDefinition,
  updateParameterInDefinition,
} from "@/lib/editor-mutations/oas-parameters";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASParameter,
  OASParameterObject,
  ParameterPosition,
} from "@/lib/types";
import { toastError } from "@/lib/utils";
import { useCallback } from "react";
import { toast } from "sonner";

export type ParameterWithInfo = {
  parameter: OASParameter;
  idx: number;
};

export function useParameters(props: EditorInputProps) {
  const { setNewEditorState, getOASOrError } = useAPIEditorTools(props);
  const definition = getOASOrError();
  const { getComponentParameterByLabelOrError } = useComponentParameters(props);

  const resolveParameterObject = useCallback(
    (parameter: OASParameter) => {
      if (!isReference(parameter)) return parameter;
      return getComponentParameterByLabelOrError(deref(parameter.$ref));
    },
    [getComponentParameterByLabelOrError]
  );

  const getParametersByPosition = useCallback(
    (
      operationId: string,
      parameterPosition?: ParameterPosition
    ): ParameterWithInfo[] => {
      const parameters =
        findOperationByIdOrErrorFromDefinition(props.value.data, operationId)
          .parameters || [];
      return parameters
        .map((p, i) => ({ parameter: p, idx: i }))
        .filter((p) => {
          const parameterObject = resolveParameterObject(p.parameter);
          return parameterPosition
            ? parameterObject.in === parameterPosition
            : true;
        });
    },
    [resolveParameterObject, props.value.data]
  );

  const getParameterByIndex = useCallback(
    (operationId: string, idx: number) => {
      try {
        return getParameterByIndexFromDefinition({
          definition: props.value.data,
          operationId,
          idx,
        });
      } catch (err) {
        toastError(err);
      }
    },
    [props.value.data]
  );

  const update = useCallback(
    ({
      parameterIdx,
      newParameter,
      operationId,
    }: {
      parameterIdx: number;
      oldParameter: OASParameterObject;
      newParameter: OASParameterObject;
      operationId: string;
    }) => {
      const newSpec = updateParameterInDefinition({
        definition,
        parameterIdx,
        newParameter,
        operationId,
      });
      setNewEditorState({
        data: newSpec,
        document_type: props.value.document_type,
      });
    },
    [definition, setNewEditorState, props.value.document_type]
  );

  const addParameter = useCallback(
    ({
      parameter,
      operationId,
      urlPath,
    }: {
      parameter: OASParameter;
      operationId: string;
      urlPath: string;
    }) => {
      try {
        const newSpec = addParameterToDefinition({
          definition,
          parameter,
          operationId,
          urlPath,
        });
        setNewEditorState({
          data: newSpec,
          document_type: props.value.document_type,
        });
      } catch (err) {
        toastError(err);
      }
    },
    [setNewEditorState, definition, props.value.document_type]
  );

  const findAuthenticationRequestParametersForOperation = useCallback(
    ({ operationId }: { operationId: string }) => {
      let res: ReturnType<
        typeof findAuthenticationRequestParametersForOperationFromDefinition
      > = [];
      try {
        res = findAuthenticationRequestParametersForOperationFromDefinition({
          definition,
          operationId,
        });
      } catch (err) {
        toastError(err);
      }
      return res;
    },
    [definition]
  );

  const remove = useCallback(
    ({
      parameterIdx,
      operationId,
    }: {
      parameterIdx: number;
      operationId: string;
    }) => {
      const newSpec = removeParameterFromDefinition({
        definition,
        operationId,
        parameterIdx,
      });
      setNewEditorState({
        document_type: props.value.document_type,
        data: newSpec,
      });
    },
    [definition, setNewEditorState, props.value.document_type]
  );

  const dereferenceParameter = useCallback(
    ({
      operationId,
      parameterIdx,
    }: {
      operationId: string;
      parameterIdx: number;
    }) => {
      try {
        const newDefinition = dereferenceParameterInDefinition({
          operationId,
          idx: parameterIdx,
          definition: props.value.data,
        });
        setNewEditorState({
          data: newDefinition,
          document_type: props.value.document_type,
        });
        toast.success("Reference removed");
      } catch (err) {
        toastError(err);
      }
    },
    [props.value, setNewEditorState]
  );

  return {
    dereferenceParameter,
    getParameterByIndex,
    getParametersByPosition,
    resolveParameterObject,
    findAuthenticationRequestParametersForOperation,
    update,
    addParameter,
    remove,
  };
}
