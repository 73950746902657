import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { DtoSchemaWithInfo } from "@/hooks/use-dtos";
import {
  addComponentResponseByCodeToOperationInDefinition,
  addComponentResponseToDefinition,
  addComponentResponseToOperationByResponseName,
  editComponentResponseInDefinition,
  getComponentResponseFromDefinition,
  getComponentResponsesFromDefinition,
  removeComponentResponseFromDefinition,
} from "@/lib/editor-mutations/oas-component-responses";
import { DefinitionNotDefinedError, NotFoundError } from "@/lib/errors";
import { HttpStatus } from "@/lib/helpers";
import { TemplateResponseCodes } from "@/lib/oas-tools/https-verb-status-codes";
import {
  OASComponentsObject,
  OASResponseObject,
  OASSchema,
  SupportedContentFormats,
} from "@/lib/types";
import { toastError } from "@/lib/utils";
import { useCallback } from "react";
import { toast } from "sonner";

export type ComponentSchemaWithInfo = {
  schema: OASSchema;
  name: string;
  dtoSchemas: DtoSchemaWithInfo[];
};

export type ComponentResponseWithInfo = {
  idx?: number;
  responseName: string;
  responseObject: OASResponseObject;
};

export function useComponentResponses(props: EditorInputProps) {
  const { oas, setNewEditorState, setActiveElement } = useAPIEditorTools(props);
  if (!oas) throw new DefinitionNotDefinedError();

  const getComponentResponses = useCallback((): ComponentResponseWithInfo[] => {
    const entries = Object.entries(
      getComponentResponsesFromDefinition({ definition: oas })
    );
    return entries.map(([responseName, responseObject], i) => ({
      idx: i,
      responseName,
      responseObject: responseObject as OASResponseObject,
    }));
  }, [oas]);

  const getComponentResponseOrError = useCallback(
    (responseName: string): ComponentResponseWithInfo => {
      const responseObject = getComponentResponseFromDefinition({
        definition: oas,
        responseName,
      });
      if (!responseObject) throw new NotFoundError("response");
      return { responseObject, responseName };
    },
    [oas]
  );

  const getComponentResponse = useCallback(
    (responseName: string): ComponentResponseWithInfo | undefined => {
      const responseObject = getComponentResponseFromDefinition({
        definition: oas,
        responseName,
      });
      if (!responseObject) return;
      return {
        responseName,
        responseObject: responseObject,
      };
    },
    [oas]
  );

  const addComponentResponse = useCallback(
    ({
      responseObject,
      name,
    }: {
      name: string;
      responseObject: OASResponseObject;
    }) => {
      setNewEditorState({
        document_type: props.value.document_type,
        data: addComponentResponseToDefinition({
          definition: oas,
          name,
          responseObject,
        }),
      });
    },
    [setNewEditorState, oas, props.value.document_type]
  );

  const removeComponentResponse = useCallback(
    async (name: string) => {
      await setActiveElement(undefined);
      setNewEditorState({
        document_type: props.value.document_type,
        data: removeComponentResponseFromDefinition({
          definition: oas,
          responseName: name,
        }),
      });
    },
    [setNewEditorState, oas, props.value.document_type, setActiveElement]
  );

  const editComponentResponse = useCallback(
    async ({
      oldName,
      newName,
      responseObject,
    }: {
      oldName: string;
      newName: string;
      responseObject: OASResponseObject;
    }) => {
      try {
        setNewEditorState({
          document_type: props.value.document_type,
          data: editComponentResponseInDefinition({
            oldName,
            newName,
            definition: oas,
            newResponseObject: responseObject,
          }),
        });
        await setActiveElement({ type: "response", responseName: newName });
        toast.success("Response edited");
      } catch (err) {
        toastError(err);
      }
    },
    [oas, props.value.document_type, setNewEditorState, setActiveElement]
  );

  const addComponentResponseToOperation = useCallback(
    ({
      componentResponseName,
      newResponse,
      operationId,
      responseCode,
    }: {
      componentResponseName: string;
      newResponse: OASResponseObject;
      operationId: string;
      responseCode: HttpStatus;
    }) => {
      const newDefinition = addComponentResponseToOperationByResponseName({
        definition: props.value.data,
        componentResponseName,
        newResponse,
        operationId,
        responseCode,
      });

      setNewEditorState({
        data: newDefinition,
        document_type: props.value.document_type,
      });
    },
    [props.value.data, props.value.document_type, setNewEditorState]
  );

  const addTemplateComponentResponseToOperation = useCallback(
    ({
      operationId,
      responseCode,
      onSuccess,
      skipCheckForExisting = false,
      format,
    }: {
      operationId: string;
      responseCode: TemplateResponseCodes;
      onSuccess?: () => unknown;
      skipCheckForExisting?: boolean;
      format: SupportedContentFormats;
    }) => {
      try {
        const newDefinition = addComponentResponseByCodeToOperationInDefinition(
          {
            operationId,
            responseCode,
            definition: oas,
            skipCheckForExisting,
            format,
          }
        );
        setNewEditorState({
          document_type: props.value.document_type,
          data: newDefinition,
        });
        onSuccess?.();
        toast.success("Added as component");
      } catch (err) {
        toastError(err);
      }
    },
    [setNewEditorState, oas, props.value.document_type]
  );

  const componentsObject: OASComponentsObject = oas.components || {};

  return {
    getComponentResponses,
    addComponentResponse,
    editComponentResponse,
    removeComponentResponse,
    getComponentResponseOrError,
    getComponentResponse,
    addTemplateComponentResponseToOperation,
    addComponentResponseToOperation,
    componentsObject,
  };
}
