import { Button } from "@/components/ui/button";
import {
  cn,
  DEFAULT_ICON_SIZE,
  DEFAULT_ICON_SIZE_SM,
  NormIcons,
} from "@/lib/utils";
import {
  ArrowRight,
  ChevronRightIcon,
  LucideIcon,
  MoveLeft,
  MoveRight,
} from "lucide-react";
import React, { ComponentProps, forwardRef } from "react";

export const AddButton = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(({ children, ...props }, ref) => {
  return (
    <Button size="icon-sm" type="button" variant="ghost" {...props} ref={ref}>
      <NormIcons.Add size={DEFAULT_ICON_SIZE} strokeWidth={3} className="" />
      <span className="sr-only">{children || "Add"}</span>
    </Button>
  );
});

export const CollapseButton = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button> & {
    showOnHover?: boolean;
  }
>(({ children, className, showOnHover = false, ...props }, ref) => {
  return (
    <Button
      size="icon-sm"
      variant="ghost"
      className={cn(
        "transition-transform group-data-[state=open]/collapsible:rotate-90",
        showOnHover &&
          "group-hover/menu-item:opacity-100 peer-data-[active=true]/menu-button:text-sidebar-accent-foreground opacity-0",
        className
      )}
      {...props}
      ref={ref}
    >
      <ChevronRightIcon size={DEFAULT_ICON_SIZE} strokeWidth={4} />
      <span className="sr-only">{children || "Collapse"}</span>
    </Button>
  );
});

export const EditButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button> & { Icon?: LucideIcon }
>(({ children, className, Icon = NormIcons.Edit, ...props }, ref) => {
  return (
    <Button
      size="icon-sm"
      variant="ghost"
      className={cn("text-muted-foreground shrink-0", className)}
      {...props}
      ref={ref}
    >
      <span className="sr-only">{children}</span>
      <Icon strokeWidth={2} size={DEFAULT_ICON_SIZE} />
    </Button>
  );
});

export const MoreButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button> & { Icon?: LucideIcon }
>(({ children, className, Icon = NormIcons.More, ...props }, ref) => {
  return (
    <Button
      size="icon-sm"
      variant="ghost"
      type="button"
      className={cn("shrink-0", className)}
      {...props}
      ref={ref}
    >
      <span className="sr-only">{children}</span>
      <Icon strokeWidth={3} size={DEFAULT_ICON_SIZE} />
    </Button>
  );
});

export const CopyButton = forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ children, ...props }, ref) => {
  return (
    <Button size="icon-sm" variant="ghost" {...props} ref={ref}>
      <span className="sr-only">{children}</span>
      <NormIcons.Copy size={DEFAULT_ICON_SIZE} />
    </Button>
  );
});

export const LinkedComponentButton = forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ children, ...props }, ref) => {
  return (
    <Button size="icon-sm" variant="secondary" ref={ref} {...props}>
      <ArrowRight size={DEFAULT_ICON_SIZE_SM} />
      {children}
    </Button>
  );
});

export const ComponentButton = forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ children, ...props }, ref) => {
  return (
    <Button size="icon-sm" variant="ghost" ref={ref} {...props}>
      <NormIcons.Component size={DEFAULT_ICON_SIZE} />
      {children}
    </Button>
  );
});

export const DeleteButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>(({ children, className, ...props }, ref) => {
  return (
    <Button
      size="icon-sm"
      variant="ghost"
      type="button"
      className={cn("shrink-0", className)}
      {...props}
      ref={ref}
    >
      <span className="sr-only">{children}</span>
      <NormIcons.Delete size={DEFAULT_ICON_SIZE} strokeWidth={4} />
    </Button>
  );
});

export const AddLabelButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>(({ children, ...props }, ref) => {
  return (
    <Button
      size="sm"
      className="font-mono"
      variant="secondary"
      {...props}
      ref={ref}
    >
      {!children && <NormIcons.Add size={DEFAULT_ICON_SIZE} className="mr-2" />}
      {children || "Add"}
    </Button>
  );
});

export const MinimizeButton = forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ children, ...props }, ref) => {
  return (
    <Button size="icon-sm" variant="outline" {...props} ref={ref}>
      <span className="sr-only">{children}</span>
      <NormIcons.Minimize size={DEFAULT_ICON_SIZE} />
    </Button>
  );
});

export const SubmitButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button> & { isLoading?: boolean }
>(({ children, isLoading, disabled, ...props }, ref) => {
  return (
    <Button
      size="sm"
      type="submit"
      disabled={disabled || isLoading}
      {...props}
      ref={ref}
    >
      {isLoading ? (
        "Loading"
      ) : (
        <>
          <span>{children}</span>
          <MoveRight className="ml-2" />
        </>
      )}
    </Button>
  );
});

export const BackButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<"button"> & { isLoading?: boolean }
>(({ children, isLoading, disabled, ...props }, ref) => {
  return (
    <Button
      size="sm"
      type="button"
      variant="secondary"
      disabled={disabled || isLoading}
      {...props}
      ref={ref}
    >
      {isLoading ? (
        "Loading"
      ) : (
        <>
          <MoveLeft className="mr-2" />
          <span>{children}</span>
        </>
      )}
    </Button>
  );
});

export function BtnGroup({
  children,
  className,
  ...rest
}: ComponentProps<"div">) {
  return (
    <div className={cn("flex gap-2 items-center", className)} {...rest}>
      {children}
    </div>
  );
}
