import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { Label } from "@/components/ui/label";
import { cva } from "class-variance-authority";

export interface ListItem {
  id: string;
  [key: string]: any;
}

interface ListProps<T extends ListItem> {
  items: T[];
  onItemSelect?: (item: T) => void | Promise<void>;
  className?: string;
  displayStyle?: "row" | "card";
  renderItem?: (item: T, isSelected: boolean) => React.ReactNode;
  searchable?: boolean;
  itemClassName?: string;
}

const itemVariants = cva(
  "cursor-pointer hover:bg-accent aria-selected:border-border aria-selected:bg-accent",
  {
    variants: {
      variant: {
        row: "",
        card: "border rounded-md",
      },
    },
  }
);

export default function List<T extends ListItem>({
  items,
  onItemSelect,
  className,
  displayStyle = "row",
  renderItem,
  searchable = true,
  itemClassName,
}: ListProps<T>) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const filteredItems = searchable
    ? items.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    : items;

  const handleItemClick = async (item: T) => {
    setSelectedItemId(item.id);
    await onItemSelect?.(item);
  };

  const defaultRenderItem = (item: T, isSelected: boolean) => (
    <div
      aria-selected={isSelected}
      className={cn(itemVariants({ variant: displayStyle }), itemClassName)}
    >
      {Object.entries(item).map(([key, value]) => (
        <div key={key}>
          <strong>{key}:</strong> {value}
        </div>
      ))}
    </div>
  );

  return (
    <div
      className={cn(
        "w-full h-full flex flex-col gap-2 items-stretch",
        className
      )}
    >
      {searchable && (
        <div className="px-2">
          <Label className="inline-block pb-2">Search</Label>
          <Input
            type="search"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="shrink-0 grow-0"
            aria-label="Search list items"
          />
        </div>
      )}
      <ScrollArea className="h-[300px] rounded-sm overflow-x-visible">
        <ul
          className={cn(
            "mt-2 mx-2",
            displayStyle === "card" &&
              "grid grid-cols-2 md:grid-cols-2 gap-2 items-stretch"
          )}
          role="listbox"
        >
          {filteredItems.map((item) => (
            <li
              key={item.id}
              role="option"
              aria-selected={selectedItemId === item.id}
              onClick={() => handleItemClick(item)}
              onKeyDown={async (e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  await handleItemClick(item);
                }
              }}
              tabIndex={0}
              className={cn(
                "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
                displayStyle === "row" ? "mb-1" : "mb-0"
              )}
            >
              {renderItem
                ? renderItem(item, selectedItemId === item.id)
                : defaultRenderItem(item, selectedItemId === item.id)}
            </li>
          ))}
        </ul>
      </ScrollArea>
    </div>
  );
}
