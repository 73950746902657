import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { MdTextarea } from "@/components/md-textarea";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { EnumInput } from "@/components/schema-editor-enum-input";
import {
  FieldHStack,
  FieldVStack,
  FormComp,
  FormContent,
  FormFooter,
  FormHeader,
  FormTitle,
} from "@/forms";
import { StandardFormProps } from "@/lib/types";
import { DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ReactNode } from "@tanstack/react-router";

const referenceFormSchema = z.object({
  description: z
    .string()
    .transform((v) => (v === "" ? undefined : v))
    .optional(),
});
export type RferenceFormValues = z.infer<typeof referenceFormSchema>;

export function EditReferenceForm({
  title,
  btnTitle,
  isDisabled,
  onSubmit,
  defaultValues,
  toolbar,
}: StandardFormProps<GenericMetadataFormValues> & { toolbar?: ReactNode }) {
  const form = useForm<GenericMetadataFormValues>({
    resolver: zodResolver(referenceFormSchema),
    defaultValues,
    disabled: isDisabled,
  });

  return (
    <Form {...form}>
      <FormComp
        onSubmit={async (evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          await form.handleSubmit(onSubmit)(evt);
        }}
      >
        <FormHeader>
          <FormTitle>{title}</FormTitle>
        </FormHeader>
        {toolbar}
        <FormContent>
          <FieldVStack>
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <MdTextarea height={130} {...field} />
                  </FormControl>
                  <FormDescription className="flex items-center gap-1">
                    <NormIcons.Info
                      size={DEFAULT_ICON_SIZE}
                      className="inline-block text-brand"
                    />
                    Overwrite the global model description for this use of the
                    model.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </FieldVStack>
        </FormContent>
        <BtnGroup className="justify-end pt-2">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </FormComp>
    </Form>
  );
}

const genericMetadataFormSchema = z.object({
  description: z.string(),
});
export type GenericMetadataFormValues = z.infer<
  typeof genericMetadataFormSchema
>;

export function EditGenericMetadataForm({
  title,
  btnTitle,
  isDisabled,
  onSubmit,
  defaultValues,
  toolbar,
}: StandardFormProps<GenericMetadataFormValues> & { toolbar?: ReactNode }) {
  const form = useForm<GenericMetadataFormValues>({
    resolver: zodResolver(genericMetadataFormSchema),
    defaultValues,
    disabled: isDisabled,
  });

  return (
    <Form {...form}>
      <FormComp
        onSubmit={async (evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          await form.handleSubmit(onSubmit)(evt);
        }}
      >
        <FormHeader>
          <FormTitle>{title}</FormTitle>
        </FormHeader>
        {toolbar}
        <FormContent>
          <FieldVStack>
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <MdTextarea height={130} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </FieldVStack>
        </FormContent>
        <BtnGroup className="justify-end pt-2">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </FormComp>
    </Form>
  );
}

const stringFormSchema = z
  .object({
    description: z.string().optional(),
    enumValues: z
      .array(z.string())
      .transform((val) => (val.length === 0 ? undefined : val))
      .optional(),
    default: z
      .string()
      .optional()
      .transform((val) => (val === "" ? undefined : val)),
  })
  .refine((data) => !data.default || data.enumValues?.includes(data.default), {
    message: "'default' must be one of the 'enumValues'",
    path: ["default"], // This is optional, you can specify which field the error pertains to
  });
export type StringFormValues = z.infer<typeof stringFormSchema>;

export function EditStringForm({
  title,
  btnTitle,
  isDisabled,
  onSubmit,
  defaultValues,
  toolbar,
}: StandardFormProps<StringFormValues> & { toolbar?: ReactNode }) {
  const form = useForm<StringFormValues>({
    resolver: zodResolver(stringFormSchema),
    defaultValues,
    disabled: isDisabled,
  });

  return (
    <Form {...form}>
      <FormComp
        onSubmit={async (evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          await form.handleSubmit(onSubmit)(evt);
        }}
      >
        <FormHeader>
          <FormTitle>{title}</FormTitle>
        </FormHeader>
        {toolbar}
        <FormContent>
          <FieldVStack>
            <FormField
              control={form.control}
              name="enumValues"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Enum values</FormLabel>
                  <FormControl>
                    <EnumInput control={form.control} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="default"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Default enum value</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <MdTextarea height={130} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </FieldVStack>
        </FormContent>
        <BtnGroup className="justify-end pt-2">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </FormComp>
    </Form>
  );
}

const intLimitSchema = z
  .string()
  .optional()
  .transform((val) => {
    if (val === "") return undefined;
    const num = Number(val);
    return isNaN(num) ? undefined : num;
  })
  .refine((val) => Number.isInteger(val) || val === undefined, {
    message: "Must be an integer",
  });

const integerFormSchema = z
  .object({
    description: z
      .string()
      .optional()
      .transform((e) => (e ? e : undefined)),
    minimum: intLimitSchema,
    maximum: intLimitSchema,
    format: z
      .enum(["int32", "int64", ""])
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
  })
  .refine(
    (data) => {
      if (data.minimum !== undefined && data.maximum !== undefined) {
        return data.minimum <= data.maximum;
      }
      return true;
    },
    {
      message: "Minimum must be less than or equal to maximum",
      path: ["minimum"],
    }
  );
export type IntegerFormOutputValues = z.output<typeof integerFormSchema>;
export type IntegerFormInputValues = z.input<typeof integerFormSchema>;

export function EditIntegerForm({
  onSubmit,
  defaultValues,
  isDisabled,
  btnTitle,
  title,
  toolbar,
}: StandardFormProps<IntegerFormOutputValues, IntegerFormInputValues> & {
  toolbar?: ReactNode;
}) {
  const form = useForm<IntegerFormInputValues, any, IntegerFormOutputValues>({
    resolver: zodResolver(integerFormSchema),
    defaultValues,
    disabled: isDisabled,
  });

  return (
    <Form {...form}>
      <FormComp
        onSubmit={async (evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          await form.handleSubmit(onSubmit)(evt);
        }}
      >
        <FormHeader>
          <FormTitle>{title}</FormTitle>
        </FormHeader>
        {toolbar}
        <FormContent>
          <FieldHStack>
            <FormField
              control={form.control}
              name="minimum"
              render={({ field }) => (
                <FormItem className="grow">
                  <FormLabel>Minimum</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="maximum"
              render={({ field }) => (
                <FormItem className="grow">
                  <FormLabel>Maximum</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </FieldHStack>
          <FieldVStack>
            <FormField
              control={form.control}
              name="format"
              render={({ field }) => (
                <FormItem className="">
                  <FormLabel>Format</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="int32">Int32</SelectItem>
                      <SelectItem value="int64">Int64</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <MdTextarea height={130} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </FieldVStack>
        </FormContent>
        <FormFooter>
          <BtnGroup className="justify-end pt-2">
            <SubmitButton>{btnTitle}</SubmitButton>
          </BtnGroup>
        </FormFooter>
      </FormComp>
    </Form>
  );
}

const limitSchema = z
  .string()
  .optional()
  .transform((val) => {
    if (val === "") return undefined;
    const num = Number(val);
    return isNaN(num) ? undefined : num;
  });

const numberFormSchema = z
  .object({
    description: z
      .string()
      .optional()
      .transform((e) => (e ? e : undefined)),
    minimum: limitSchema,
    maximum: limitSchema,
    format: z
      .enum(["float", "double", ""])
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
  })
  .refine(
    (data) => {
      if (data.minimum !== undefined && data.maximum !== undefined) {
        return data.minimum <= data.maximum;
      }
      return true;
    },
    {
      message: "Minimum must be less than or equal to maximum",
      path: ["minimum"],
    }
  );
export type NumberFormInputValues = z.input<typeof numberFormSchema>;
export type NumberFormOutputValues = z.output<typeof numberFormSchema>;

export function EditNumberForm({
  onSubmit,
  defaultValues,
  isDisabled,
  btnTitle,
  title,
  toolbar,
}: StandardFormProps<NumberFormOutputValues, NumberFormInputValues> & {
  toolbar?: ReactNode;
}) {
  const form = useForm<NumberFormInputValues, any, NumberFormOutputValues>({
    resolver: zodResolver(numberFormSchema),
    defaultValues,
    disabled: isDisabled,
  });

  return (
    <Form {...form}>
      <FormComp
        onSubmit={async (evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          await form.handleSubmit(onSubmit)(evt);
        }}
      >
        <FormHeader>
          <FormTitle>{title}</FormTitle>
        </FormHeader>
        {toolbar}
        <FormContent>
          <FieldHStack>
            <FormField
              control={form.control}
              name="minimum"
              render={({ field }) => (
                <FormItem className="grow">
                  <FormLabel>Minimum</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="maximum"
              render={({ field }) => (
                <FormItem className="grow">
                  <FormLabel>Maximum</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </FieldHStack>
          <FieldVStack>
            <FormField
              control={form.control}
              name="format"
              render={({ field }) => (
                <FormItem className="i">
                  <FormLabel>Format</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl className="">
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="float">Float</SelectItem>
                      <SelectItem value="double">Double</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <MdTextarea height={130} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </FieldVStack>
        </FormContent>
        <FormFooter>
          <BtnGroup className="justify-end pt-2">
            <SubmitButton>{btnTitle}</SubmitButton>
          </BtnGroup>
        </FormFooter>
      </FormComp>
    </Form>
  );
}
