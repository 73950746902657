import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { H5 } from "@/components/headings";
import { ScrollableLayoutContainer } from "@/components/layout-right-drawer";
import {
  EditorItemDescription,
  PreviewHeading,
  PreviewToolbarContainer,
} from "@/components/module-api-editor";
import {
  ActiveElement,
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaQuickEditor } from "@/components/schema-quick-editor";
import {
  SecondaryCard,
  SecondaryCardContent,
  SecondaryCardHeader,
} from "@/components/secondary-card";
import {
  ParameterPositioinBadge,
  TypeBadge,
} from "@/components/special-badges";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { useComponentParameters } from "@/hooks/use-component-parameters";
import { NotFoundError } from "@/lib/errors";
import { canEdit } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { ReactNode, useMemo } from "react";

export function EditorPreviewComponentParameter({
  value,
  onChange,
  activeElement,
  workspaceSlug,
  organizationSlug,
  isAuthed,
  workspaceRole,
  extraToolbarItems,
}: {
  isAuthed: boolean;
  workspaceSlug: string | undefined;
  organizationSlug: string | undefined;
  activeElement: Extract<ActiveElement, { type: "component-parameter" }>;
  workspaceRole: WorkspaceRole;
  extraToolbarItems: ReactNode;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();

  const { getComponentParameterByLabel, removeComponentParameter } =
    useComponentParameters({
      value,
      onChange,
    });

  const componentParameter = useMemo(() => {
    const found = getComponentParameterByLabel(activeElement.label);
    if (!found) throw new NotFoundError("parameter");
    return found;
  }, [activeElement.label, getComponentParameterByLabel]);

  const handleEditClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-parameter",
        context: {
          label: activeElement.label,
          parameter: componentParameter,
        },
      },
    });
  };

  const handleRemoveClick = async () => {
    await removeComponentParameter(activeElement.label);
  };

  const isEditor = canEdit(workspaceRole);

  return (
    <div>
      <PreviewToolbarContainer
        value={value}
        onChange={onChange}
        workspaceRole={workspaceRole}
        isAuthed={isAuthed}
        workspaceSlug={workspaceSlug}
        organizationSlug={organizationSlug}
        extraToolbarItems={extraToolbarItems}
      >
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem className="hidden md:block">
              <Link search={(prev) => ({ ...prev, activeElement: undefined })}>
                <BreadcrumbLink asChild>
                  <span>Workspace</span>
                </BreadcrumbLink>
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="hidden md:block" />
            <BreadcrumbItem>
              <BreadcrumbPage>Parameter</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </PreviewToolbarContainer>
      <ScrollableLayoutContainer>
        <div className="flex flex-col items-stretch gap-4 pb-10 pt-4">
          <div className="flex justify-between px-4">
            <PreviewHeading preHeading="Parameter">
              {activeElement.label}
            </PreviewHeading>
            {isEditor ? (
              <BtnGroup>
                <EditButton onClick={handleEditClick} />
                <DeleteButton onClick={handleRemoveClick} />
              </BtnGroup>
            ) : (
              <span />
            )}
          </div>
          <div className="grid grid-cols-[min-content,min-content,1fr] gap-2 px-4">
            <ParameterPositioinBadge
              className="px-5 py-5"
              parameterPosition={componentParameter.in}
            />
            <TypeBadge
              schema={componentParameter.schema}
              showIcon
              className="px-5"
            />
            <SecondaryCard className="col-span-3">
              <SecondaryCardHeader>
                <H5 className="text-muted-foreground">Description</H5>
                {isEditor && <EditButton onClick={handleEditClick} />}
              </SecondaryCardHeader>
              <SecondaryCardContent>
                <EditorItemDescription
                  item={componentParameter}
                  onAddDescriptionClick={handleEditClick}
                  workspaceRole={workspaceRole}
                />
              </SecondaryCardContent>
            </SecondaryCard>
            <SecondaryCard className="col-span-3">
              <SecondaryCardHeader>
                <H5 className="text-muted-foreground">Schema</H5>
              </SecondaryCardHeader>
              <SecondaryCardContent>
                <SchemaQuickEditor
                  onChange={onChange}
                  workspaceRole={workspaceRole}
                  value={value}
                  title={`${activeElement.label} schema`}
                  className="min-h-[100px]"
                  path={`components.parameters.${activeElement.label}.schema`}
                  allowTopLevelReferences={false}
                />
              </SecondaryCardContent>
            </SecondaryCard>
          </div>
        </div>
      </ScrollableLayoutContainer>
    </div>
  );
}
