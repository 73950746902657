import { simplePropertyTypes } from "@/lib/oas-tools/oas-schema-utils";
import { OpenAPIV3_1 } from "@/lib/oas-tools/openapi-types";
import {
  OASArraySchemaObject,
  OASMixedSchemaObject,
  OASNonArraySchemaObject,
  OASReferenceObject,
  OASSchema,
} from "@/lib/types";
import { typedIncludes } from "@/lib/utils";

export function getMixedTagStringValue(schema: OASMixedSchemaObject) {
  if (!Array.isArray(schema.type))
    throw new Error("Schema is not of type mixed");
  if (!schema.type) throw new Error("Mixed schema has not type");

  return "( " + schema.type.join(" | ") + " )";
}

export function findComposedKey(schema: OASNonArraySchemaObject) {
  if (schema.oneOf) return "oneOf";
  if (schema.anyOf) return "anyOf";
  return "allOf";
}

export function deref(path: string, prefix: string = "") {
  return (
    prefix +
    path.replace(
      /^#\/components\/(schemas|responses|parameters|requestBodies|headers|examples|links|callbacks|securitySchemes)\//,
      ""
    )
  );
}

export type RefComponents =
  | "schemas"
  | "responses"
  | "parameters"
  | "requestBodies"
  | "headers"
  | "examples"
  | "links"
  | "callbacks"
  | "securitySchemes";

export function getRefPath(componentKey: RefComponents, componentName: string) {
  return `#/components/${componentKey}/${componentName}`;
}

export function isReferenceSchema(
  schema: OASSchema | OASReferenceObject
): schema is OASReferenceObject {
  return "$ref" in schema;
}

// Generic type guard for objects that might have a $ref property
export function isReference<T extends object>(
  obj: T
): obj is T & { $ref: string } {
  // eslint-disable-next-line
  return typeof (obj as any).$ref === "string";
}

export function isArraySchema(
  schema: OASSchema
): schema is OASArraySchemaObject {
  return schema.type === "array";
}

export function isMixedSchema(
  schema: OASSchema
): schema is OASMixedSchemaObject {
  return typeof schema.type === "object" && Array.isArray(schema.type);
}

export function isSimpleSchema(
  schema: OASSchema
): schema is OASNonArraySchemaObject {
  return typedIncludes(simplePropertyTypes, schema.type);
}

export function isAnySchema(schema: OASSchema) {
  return Object.keys(schema).length === 0;
}

export function isObjectSchema(
  schema: OASSchema
): schema is OpenAPIV3_1.NonArraySchemaObject {
  if (Array.isArray(schema.type)) return false;
  if (schema.type && schema.type === "object") return true;
  return !!schema.properties;
}

export function isComposedSchema(schema: OASSchema) {
  return Boolean(schema.allOf || schema.oneOf || schema.anyOf);
}
