import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { FormLabelRequired } from "@/components/form-label-required";
import { MdTextarea } from "@/components/md-textarea";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { FieldVStack, FormTitle } from "@/forms";
import { MutationCallbacks } from "@/hooks";
import { useSecuritySchemes } from "@/hooks/use-security-schemes";
import {
  OASSecuritySchemeObject,
  SecuritySchemeEasierType,
  securitySchemeEasierTypes,
  supportedSecuritySchemeTypes,
} from "@/lib/types";
import { typedIncludes } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import capitalize from "lodash/capitalize";
import { useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

const apiKeyIn = ["query", "header", "cookie"] as const;
type ApiKeyIn = (typeof apiKeyIn)[number];

const supportedHttpSchemes = ["Basic", "Bearer"] as const;
type SupportedHttpSchemes = (typeof supportedHttpSchemes)[number];

const easierTypesDescriptionMap: Record<
  (typeof securitySchemeEasierTypes)[number],
  string
> = {
  ApiKey: "Authenticate using an API key",
  BearerBasic: "Use 'Authorization: Basic <credentials>' header.",
  BearerCustom: "Use 'Authorization: Bearer <credentials>' header.",
  BearerJwt: "Use 'Authorization: Bearer <jwtToken>' header.",
};

const formValuesHttp = z.object({
  type: z.literal("http"),
  easierType: z.enum(securitySchemeEasierTypes),
  scheme: z.enum(supportedHttpSchemes),
  bearerFormat: z.string().min(1, "Required"),
  description: z.string().optional(),
});

const formValuesApiKey = z.object({
  easierType: z.enum(securitySchemeEasierTypes),
  type: z.literal("apiKey"),
  name: z.string().min(1, "Required"), // name of the header, query parameter or cookie
  in: z.enum(apiKeyIn), // position
  description: z.string().optional(),
});

const formValues = z.union([formValuesApiKey, formValuesHttp]);

type FormValues = z.infer<typeof formValues>;

function RawForm({
  onSubmit,
  defaultValues,
  btnTitle,
  title,
}: {
  onSubmit: SubmitHandler<FormValues>;
  defaultValues: Partial<FormValues>;
  btnTitle: string;
  title: string;
}) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formValues),
    defaultValues: { ...defaultValues },
  });

  const easierType = form.watch("easierType");
  const type = form.watch("type");

  form.watch("scheme");

  return (
    <Form {...form}>
      <FormTitle>{title}</FormTitle>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FieldVStack>
          <FormField
            control={form.control}
            name="easierType"
            render={({ field }) => (
              <FormItem className="grow">
                <FormLabel>
                  <FormLabelRequired />
                  Authorization method
                </FormLabel>
                <Select
                  onValueChange={(v) => {
                    if (v === "BearerJwt") {
                      form.setValue("type", "http");
                      form.setValue("bearerFormat", "Json Web Token (JWT)");
                      form.setValue("scheme", "Bearer");
                    }
                    if (v === "BearerBasic") {
                      form.setValue("type", "http");
                      form.setValue("scheme", "Basic");
                      form.setValue(
                        "bearerFormat",
                        "Basic Access Authentication"
                      );
                    }
                    if (v === "BearerCustom") {
                      form.setValue("type", "http");
                      form.setValue("bearerFormat", "Custom");
                      form.setValue("scheme", "Bearer");
                    }
                    if (v === "ApiKey") {
                      form.setValue("type", "apiKey");
                    }
                    field.onChange(v);
                  }}
                  defaultValue={field.value}
                  disabled={field.disabled}
                >
                  <SelectTrigger className="">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="BearerJwt">
                      Json Web Token (Bearer token)
                    </SelectItem>
                    <SelectItem value="BearerBasic">
                      Basic Auth (Bearer token)
                    </SelectItem>
                    <SelectItem value="BearerCustom">
                      Custom (Bearer token)
                    </SelectItem>
                    <SelectItem value="ApiKey">API Key</SelectItem>
                  </SelectContent>
                </Select>
                <FormDescription>
                  {easierType
                    ? easierTypesDescriptionMap[easierType]
                    : "Select an authorization type"}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          {type === "apiKey" && (
            <>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="grow">
                    <FormLabel>
                      <FormLabelRequired />
                      Name
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormDescription>
                      The name of the header, query or cookie parameter to be
                      used.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="in"
                render={({ field }) => (
                  <FormItem className="grow">
                    <FormLabel>
                      <FormLabelRequired />
                      Location
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      disabled={field.disabled}
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {apiKeyIn.map((e) => (
                          <SelectItem key={e} value={e}>
                            {e}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormDescription>
                      The location of the API key.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
          {type === "http" && (
            <>
              <FormField
                control={form.control}
                name="scheme"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormLabelRequired />
                      Token prefix
                    </FormLabel>
                    <Select
                      onValueChange={(v) => {
                        field.onChange(v);
                      }}
                      value={field.value}
                      defaultValue={field.value}
                      disabled={field.disabled}
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {supportedHttpSchemes.map((e) => (
                          <SelectItem key={e} value={e}>
                            {capitalize(e)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormDescription>
                      e.g. Authorization: <b>Bearer</b> &lt;credentials&gt; (s.{" "}
                      <a
                        href="https://tools.ietf.org/html/rfc7235#section-5.1"
                        target="_blank"
                      >
                        RFC7235
                      </a>
                      )
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="bearerFormat"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormLabelRequired />
                      Token format
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormDescription>
                      A hint to the client to identify how the bearer token is
                      formatted.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <MdTextarea height={130} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </FieldVStack>
        <BtnGroup className="justify-end pt-2">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </form>
    </Form>
  );
}

export function AddSecurityScheme({
  value,
  onChange,
  onSuccess,
  onError,
  onSettled,
}: EditorInputProps & MutationCallbacks) {
  const { addSecurityScheme } = useSecuritySchemes({ value, onChange });

  const handleSubmit: SubmitHandler<FormValues> = (v) => {
    if (v.type === "apiKey") {
      addSecurityScheme({
        securityScheme: { type: v.type, name: v.name, in: v.in },
        schemeName: v.name,
        onSuccess,
        onError,
        onSettled,
      });
    }
    if (v.type === "http") {
      addSecurityScheme({
        securityScheme: {
          type: v.type,
          bearerFormat: v.bearerFormat,
          scheme: v.scheme,
          description: v.description,
        },
        schemeName: v.easierType,
        onSuccess,
        onError,
        onSettled,
      });
    }
  };

  return (
    <RawForm
      onSubmit={handleSubmit}
      btnTitle="Add scheme"
      title="Add authorization scheme"
      defaultValues={{}}
    />
  );
}

export function EditSecurityScheme({
  value,
  onChange,
  schemeName,
  securityScheme,
  onSuccess,
  onError,
  onSettled,
}: {
  schemeName: string;
  securityScheme: OASSecuritySchemeObject;
} & EditorInputProps &
  MutationCallbacks) {
  const { editSecuritySchema } = useSecuritySchemes({ value, onChange });

  const isCompatible = useMemo(() => {
    return typedIncludes(supportedSecuritySchemeTypes, securityScheme.type);
  }, [securityScheme]);

  const easierType = useMemo((): SecuritySchemeEasierType => {
    if (
      securityScheme.type === "http" &&
      securityScheme.scheme.toLowerCase() === "bearer"
    ) {
      if (securityScheme.bearerFormat?.toLowerCase().includes("Json")) {
        return "BearerJwt";
      }
      return "BearerCustom";
    }
    if (
      securityScheme.type === "http" &&
      securityScheme.scheme.toLowerCase() === "basic"
    ) {
      return "BearerBasic";
    }
    if (securityScheme.type === "apiKey") {
      return "ApiKey";
    }

    return "BearerJwt";
  }, [securityScheme]);

  const handleSubmit: SubmitHandler<FormValues> = (v) => {
    if (v.type === "apiKey") {
      editSecuritySchema({
        newScheme: {
          type: v.type,
          name: v.name,
          in: v.in,
          description: v.description,
        },
        oldSchemeName: schemeName,
        newSchemeName: v.name,
        onSuccess,
        onError,
        onSettled,
      });
    }
    if (v.type === "http") {
      editSecuritySchema({
        newScheme: {
          type: v.type,
          bearerFormat: schemeName,
          scheme: v.scheme,
          description: v.description,
        },
        newSchemeName: v.easierType,
        oldSchemeName: schemeName,
        onSuccess,
        onError,
        onSettled,
      });
    }
  };

  if (!isCompatible)
    return (
      <Alert>
        <AlertTitle>Not compatible</AlertTitle>
        <AlertDescription>
          This security scheme cannot be edited with here.
        </AlertDescription>
      </Alert>
    );

  return (
    <RawForm
      onSubmit={handleSubmit}
      btnTitle="Edit scheme"
      title="Edit authorization scheme"
      defaultValues={
        securityScheme.type === "apiKey"
          ? {
              easierType: easierType,
              type: securityScheme.type,
              name: securityScheme.name,
              in: (securityScheme.in as ApiKeyIn | undefined) || "header",
              description: securityScheme.description || "",
            }
          : securityScheme.type === "http"
            ? {
                easierType: easierType,
                type: securityScheme.type,
                bearerFormat: securityScheme.bearerFormat,
                scheme: securityScheme.scheme as SupportedHttpSchemes,
                description: securityScheme.description || "",
              }
            : {}
      }
    />
  );
}
