import { TemplateCRUDOperation } from "@/lib/types";
import { modelfyStr } from "@/lib/utils";
import { z } from "zod";

export const dtoTemplateNames = [
  "create",
  "update",
  "partial update",
  "summary",
] as const;
export const dtoTemplateNamesSchema = z.enum(dtoTemplateNames);
export type DtoTemplateName = z.infer<typeof dtoTemplateNamesSchema>;

// export function getTemplateDtoName(
//   operation: TemplateCRUDOperation,
//   baseSchema: string
// ) {
//   if (operation.level === "detail") {
//     switch (operation.method) {
//       case "patch":
//         return `${baseSchema}PartialUpdate`;
//       case "put":
//         return `${baseSchema}Update`;
//       default:
//         const _operation: never = operation;
//         throw new Error("Unknown operation: " + JSON.stringify(_operation));
//     }
//   } else {
//     switch (operation.method) {
//       case "get":
//         return `${baseSchema}Summary`;
//       case "post":
//         return `${baseSchema}Create`;
//       default:
//         const _operation: never = operation;
//         throw new Error("Unknown operation: " + JSON.stringify(_operation));
//     }
//   }
// }

export function getDtoTemplateValues(
  crudOperation: TemplateCRUDOperation,
  baseSchemaName: string
): { dtoName: string; dtoDescription: string } {
  const sanitizedBaseName = modelfyStr(baseSchemaName);
  if (crudOperation.level === "detail") {
    switch (crudOperation.method) {
      case "patch":
        return {
          dtoName: `${sanitizedBaseName}PartialUpdate`,
          dtoDescription: `Data transfer object for partially updating an existing ${baseSchemaName} (PATCH operation).`,
        };
      case "put":
        return {
          dtoName: `${sanitizedBaseName}Update`,
          dtoDescription: `Data transfer object for updating all fields of an existing ${baseSchemaName} (PUT operation).`,
        };
      default:
        const _operation: never = crudOperation;
        throw new Error("Unknown operation: " + JSON.stringify(_operation));
    }
  } else {
    switch (crudOperation.method) {
      case "get":
        return {
          dtoName: `${sanitizedBaseName}Summary`,
          dtoDescription: `A simplified representation of a ${baseSchemaName}, typically used in list views.`,
        };
      case "post":
        return {
          dtoName: `${sanitizedBaseName}Create`,
          dtoDescription: `Data transfer object for creating a new ${baseSchemaName}.`,
        };
      default:
        const _operation: never = crudOperation;
        throw new Error("Unknown operation: " + JSON.stringify(_operation));
    }
  }
}
