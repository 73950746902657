import {
  LayoutSidebar,
  LayoutSidebarHeader,
} from "@/components/layout-right-drawer";
import {
  DescriptionCard,
  EditorCard,
  EditorItemDescription,
} from "@/components/module-api-editor";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { SchemaContentQuickEditor } from "@/components/schema-content-quick-editor";
import { SchemaContentQuickViewer } from "@/components/schema-content-quick-viewer";
import { SchemaEditor } from "@/components/schema-editor";
import { SchemaContentEditorQuickContainer } from "@/components/schema-editor-containers";
import { SchemaQuickEditor } from "@/components/schema-quick-editor";
import { SchemaQuickViewer } from "@/components/schema-quick-viewer";
import {
  ParameterPositioinBadge,
  ResponseCodeBadge,
  TypeBadge,
} from "@/components/special-badges";
import { ColorBadge } from "@/components/ui/color-badge";
import { useOperation } from "@/hooks/use-operation";
import { useParameters } from "@/hooks/use-parameters";
import { useRequestBody } from "@/hooks/use-request-body";
import { useResponses } from "@/hooks/use-responses";
import {
  HttpStatus,
  httpStatusMap,
  isSupportedHttpStatus,
} from "@/lib/helpers";
import { getFirstSchemaFromContentObject } from "@/lib/oas-tools/oas-schema-utils";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { OASParameterObject } from "@/lib/types";
import { useMemo } from "react";

export type ActiveOperationItem =
  | {
      type: "response";
      responseCode: string;
    }
  | {
      type: "parameter";
      idx: number;
    }
  | {
      type: "requestBody";
    }
  | { type: "auth-parameter"; authParameter: OASParameterObject };

export type SetActiveItem = React.Dispatch<
  React.SetStateAction<ActiveOperationItem | undefined>
>;

export function EditorPreviewOperationActiveItem({
  value,
  onChange,
  activeItem,
  onClose,
  operationId,
  workspaceRole,
}: {
  activeItem: ActiveOperationItem;
  operationId: string;
  workspaceRole: WorkspaceRole;
  onClose: () => unknown;
} & EditorInputProps) {
  const { findOperationWithInfo: getOperationWithInfo } = useOperation({
    value,
    onChange,
  });
  const { getResponseOrError } = useResponses({ value, onChange });
  const { getRequestBodyOrError, resolveRequestBody } = useRequestBody({
    value,
    onChange,
  });
  const { getParameterByIndex, resolveParameterObject } = useParameters({
    value,
    onChange,
  });

  const operationWithInfo = useMemo(
    () => getOperationWithInfo(operationId),
    [operationId, getOperationWithInfo]
  );

  if (activeItem.type === "parameter") {
    const parameter = getParameterByIndex(operationId, activeItem.idx);
    if (!parameter) return null;
    const resolved = resolveParameterObject(parameter);

    const content = (() => {
      if (isReference(parameter)) {
        const refName = deref(parameter.$ref);
        return (
          <SchemaQuickViewer
            path={`components.parameters.${refName}.schema`}
            value={value}
            onChange={onChange}
            title={`${refName}`}
          />
        );
      }
      return (
        <SchemaQuickEditor
          title={`${parameter.name}`}
          path={`paths.${operationWithInfo.urlPath}.${operationWithInfo.method}.parameters[${activeItem.idx}].schema`}
          workspaceRole={workspaceRole}
          value={value}
          onChange={onChange}
          allowTopLevelReferences={true}
        />
      );
    })();
    return (
      <LayoutSidebar>
        <LayoutSidebarHeader showControls onClose={onClose}>
          <ParameterPositioinBadge
            className="px-5 py-5 col-span-2"
            parameterPosition={resolved.in}
          />
        </LayoutSidebarHeader>
        <DescriptionCard title={resolved.name}>
          <EditorItemDescription
            item={resolved}
            onAddDescriptionClick={undefined}
            workspaceRole={workspaceRole}
          />
        </DescriptionCard>
        <EditorCard>{content}</EditorCard>
      </LayoutSidebar>
    );
  }

  if (activeItem.type === "requestBody") {
    const requestBody = getRequestBodyOrError({ operationId });
    const resolved = resolveRequestBody(requestBody);

    const schema = getFirstSchemaFromContentObject(resolved.content);

    const content = (() => {
      if (isReference(requestBody)) {
        const refName = deref(requestBody.$ref);
        return (
          <SchemaContentQuickViewer
            title={`Request body schema`}
            value={value}
            onChange={onChange}
            path={`components.requestBodies.${refName}`}
          />
        );
      }
      return (
        <SchemaContentQuickEditor
          title={`Request body schema`}
          path={`paths.${operationWithInfo.urlPath}.${operationWithInfo.method}.requestBody.content`}
          workspaceRole={workspaceRole}
          value={value}
          onChange={onChange}
          allowTopLevelReferences={true}
        />
      );
    })();
    return (
      <LayoutSidebar>
        <LayoutSidebarHeader showControls onClose={onClose}>
          {schema ? (
            <TypeBadge
              className="col-span-2 px-5 py-5"
              showIcon
              schema={schema}
            />
          ) : (
            <ColorBadge className="col-span-2">"Not defined"</ColorBadge>
          )}
        </LayoutSidebarHeader>

        <DescriptionCard title="Request body">
          <EditorItemDescription
            item={requestBody}
            onAddDescriptionClick={undefined}
            workspaceRole={workspaceRole}
          />
        </DescriptionCard>
        <EditorCard>{content}</EditorCard>
      </LayoutSidebar>
    );
  }

  if (activeItem.type === "response") {
    const response = getResponseOrError({
      operationId,
      responseCode: activeItem.responseCode,
    });

    const content = (() => {
      if (isReference(response.response)) {
        const refName = deref(response.response.$ref);
        return (
          <SchemaContentQuickViewer
            path={`components.responses.${refName}.content`}
            value={value}
            onChange={onChange}
            title={`(Response) ${refName}`}
          />
        );
      } else {
        return (
          <SchemaContentQuickEditor
            title={`${activeItem.responseCode} ${isSupportedHttpStatus(activeItem.responseCode) ? httpStatusMap[activeItem.responseCode].message : ""} - Schema`}
            path={`paths.${operationWithInfo.urlPath}.${operationWithInfo.method}.responses[${activeItem.responseCode}].content`}
            workspaceRole={workspaceRole}
            value={value}
            onChange={onChange}
            allowTopLevelReferences={true}
          />
        );
      }
    })();

    return (
      <LayoutSidebar>
        <LayoutSidebarHeader showControls onClose={onClose}>
          <ResponseCodeBadge
            className="px-5 py-5 col-span-2"
            code={response.responseCode as HttpStatus}
          />
        </LayoutSidebarHeader>
        <DescriptionCard
          title={httpStatusMap[response.responseCode as HttpStatus].message}
        >
          <EditorItemDescription
            item={response.response}
            onAddDescriptionClick={undefined}
            workspaceRole={workspaceRole}
          />
        </DescriptionCard>

        <EditorCard>{content}</EditorCard>
      </LayoutSidebar>
    );
  }

  if (activeItem.type === "auth-parameter") {
    const parameter = activeItem.authParameter;
    if (!parameter.schema || isReference(parameter.schema)) return null;
    return (
      <LayoutSidebar>
        <LayoutSidebarHeader showControls onClose={onClose}>
          <ParameterPositioinBadge
            className="px-5 py-5 col-span-2"
            parameterPosition={parameter.in}
          />
        </LayoutSidebarHeader>
        <DescriptionCard title={parameter.name}>
          <EditorItemDescription
            item={parameter}
            onAddDescriptionClick={undefined}
            workspaceRole={workspaceRole}
          />
        </DescriptionCard>
        <EditorCard>
          <SchemaContentEditorQuickContainer className="pt-2">
            <SchemaEditor
              value={parameter.schema}
              onChange={() => undefined}
              onRemoveRootSchema={() => undefined}
              componentsObject={{}}
              allowTopLevelReferences={false}
              isReadOnly={true}
            />
          </SchemaContentEditorQuickContainer>
        </EditorCard>
      </LayoutSidebar>
    );
  }

  const _type: never = activeItem;
  throw new Error(`Unknown active item type: ${_type}`);
}
