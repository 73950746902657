import { ApiError, SmallText } from "@/components";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ColorBadge } from "@/components/ui/color-badge";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import List from "@/components/list";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { appLimits } from "@/lib/const";
import { toReadableDate } from "@/lib/helpers";
import { apiClient } from "@/lib/http-utils";
import {
  WorkspaceDocumentDraftStatus,
  WorkspaceDocumentDto,
} from "@/lib/main-rest-client/definitions";
import { cn, generateWorkspaceName } from "@/lib/utils";
import {
  useCreateWorkspaceDocumentDraft,
  workspaceDocumentDraftKeys,
} from "@/queries/workspaces";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

type ListItem = {
  id: string;
  name: string;
  createdAt: string;
  status: WorkspaceDocumentDraftStatus;
};

export function SelectDraftModal({
  workspaceDocument,
  isOpen,
  onOpenChange,
  organizationSlug,
  workspaceSlug,
  isOrgMember,
}: {
  workspaceDocument: WorkspaceDocumentDto;
  isOpen: boolean;
  onOpenChange: (v: boolean) => unknown;
  organizationSlug: string;
  workspaceSlug: string;
  isOrgMember: boolean;
}) {
  const navigate = useNavigate();

  const draftQuery = useQuery({
    queryKey: [
      workspaceDocumentDraftKeys.list({
        workspaceDocumentId: workspaceDocument.id,
        status: "open+locked",
      }),
    ],
    queryFn: async () => {
      const open = apiClient.findWorkspaceDocumentDraftsByStatus({
        workspaceDocumentId: workspaceDocument.id,
        status: "open",
      });

      const locked = apiClient.findWorkspaceDocumentDraftsByStatus({
        workspaceDocumentId: workspaceDocument.id,
        status: "locked",
      });

      const all = await Promise.all([open, locked]);
      return all.flatMap((e) => e.data);
    },
  });

  const handleCreate = () => {
    createDraftQuery.mutate(
      {
        data: workspaceDocument.data,
        workspace_document_id: workspaceDocument.id,
        name: generateWorkspaceName(),
        organization_id: workspaceDocument.organization_id,
        status: "open",
        workspace_id: workspaceDocument.workspace_id,
      },
      {
        onSuccess: (data) => {
          onOpenChange(false);
          return void navigate({
            to: "/editor/$organizationSlug/$workspaceSlug/drafts/$draftId",
            params: { draftId: data.id, organizationSlug, workspaceSlug },
            search: {
              set_name: true,
            },
          });
        },
      }
    );
  };

  const createDraftQuery = useCreateWorkspaceDocumentDraft();

  const renderCard = (item: ListItem, isSelected: boolean) => (
    <Card
      aria-selected={isSelected}
      className={cn(
        "cursor-pointer hover:bg-accent aria-selected:bg-accent border-2 border-border hover:border-brand transition-all h-full"
      )}
    >
      <CardHeader>
        <small className="text-muted-foreground">Draft name</small>
        <CardTitle>{item.name}</CardTitle>
      </CardHeader>
      <CardContent className="">
        <div className="pb-2">
          <small className="text-muted-foreground">Status</small>
          <div>
            <ColorBadge color={item.status === "locked" ? "yellow" : "green"}>
              {item.status}
            </ColorBadge>
          </div>
        </div>
        <small className="text-muted-foreground">Created</small>
        <p>{toReadableDate(new Date(item.createdAt))}</p>
      </CardContent>
    </Card>
  );

  const hasReachedLimit =
    appLimits.maxNumberOfDrafts <= (draftQuery.data?.length || 0);

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="pt-10">
        {draftQuery.status === "error" ? (
          <ApiError error={draftQuery.error} />
        ) : draftQuery.status === "pending" ? (
          <div>
            <Skeleton />
          </div>
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>Document drafts</DialogTitle>
            </DialogHeader>
            {draftQuery.data.length > 0 ? (
              <List
                displayStyle="card"
                items={draftQuery.data.map((el) => ({
                  id: el.id,
                  name: el.name,
                  createdAt: el.created_at,
                  status: el.status,
                }))}
                renderItem={renderCard}
                onItemSelect={async (item) => {
                  onOpenChange(false);
                  await navigate({
                    to: "/editor/$organizationSlug/$workspaceSlug/drafts/$draftId",
                    params: {
                      draftId: item.id,
                      organizationSlug,
                      workspaceSlug,
                    },
                  });
                }}
              />
            ) : (
              <small className="text-muted-foreground">
                No open drafts yet
              </small>
            )}
            {isOrgMember ? (
              <div className="pt-3">
                <BtnGroup className="justify-end">
                  <SubmitButton
                    className="align-self-end"
                    isLoading={createDraftQuery.isPending}
                    disabled={createDraftQuery.isPending || hasReachedLimit}
                    onClick={handleCreate}
                  >
                    Create new draft
                  </SubmitButton>
                </BtnGroup>
                {hasReachedLimit && (
                  <SmallText>
                    Upgrade to have more than {appLimits.maxNumberOfDrafts} open
                    drafts at a time
                  </SmallText>
                )}
              </div>
            ) : (
              <small className="text-muted-foreground">
                Only members or the workspace organization can create drafts
              </small>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
