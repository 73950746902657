import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ColorBadge } from "@/components/ui/color-badge";
import { cn } from "@/lib/utils";
import { useState } from "react";

export function SuggestCard({
  onAdd,
  title,
  description,
}: {
  onAdd: ({ onSuccess }: { onSuccess: () => unknown }) => unknown;
  title: string;
  description: string;
}) {
  const [added, setAdded] = useState(false);
  const handleAdd = () => {
    onAdd({
      onSuccess: () => {
        setAdded(true);
      },
    });
  };
  return (
    <Card
      role="checkbox"
      aria-checked={added}
      className="cursor-pointer hover:bg-accent border ease-in-out aria-checked:bg-transparent aria-checked:cursor-default aria-checked:border-brand border-border"
      onClick={handleAdd}
    >
      <CardHeader className="py-0 pt-4 relative">
        <div className="flex justify-between items-center gap-2">
          <CardTitle className="text-sm">{title}</CardTitle>
          {true && (
            <ColorBadge
              color="green"
              className={cn("invisible", { visible: added })}
            >
              Added
            </ColorBadge>
          )}
        </div>
      </CardHeader>
      <CardContent className="py-2 pb-4">
        <CardDescription>{description}</CardDescription>
      </CardContent>
      <CardFooter className="justify-end pb-0"></CardFooter>
    </Card>
  );
}
