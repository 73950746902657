// import { addResponseToOperationInDefinition } from "@/lib/editor-mutations/oas-operations";
import {
  removeSchemaRefsFromDefinition,
  updateSchemaRefsInDefinition,
} from "@/lib/editor-mutations/oas-references";
import { NoRefsHereError, NotFoundError } from "@/lib/errors";
import { xFields } from "@/lib/oas-tools/x-fields";
import { OASDefinition, OASReferenceObject, OASSchema } from "@/lib/types";
import { safeGet } from "@/lib/utils";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import unset from "lodash/unset";

export function addComponentSchemaToDefinition({
  definition,
  name,
  schema,
}: {
  definition: OASDefinition;
  name: string;
  schema: OASSchema;
}) {
  const cp = cloneDeep(definition);
  set(cp, `components.schemas.${name}`, schema);
  return cp;
}

export function findComponentSchemasInDefinition({
  definition,
}: {
  definition: OASDefinition;
}): Record<string, OASSchema> {
  return Object.fromEntries(
    Object.entries(definition.components?.schemas || {}).filter(
      ([, schema]) => {
        return !(xFields.dtoInfo in schema);
      }
    )
  );
}

export function findComponentSchemaInDefinition({
  definition,
  schemaName,
}: {
  definition: OASDefinition;
  schemaName: string;
}): OASSchema | undefined {
  const componentSchemas = findComponentSchemasInDefinition({ definition });

  const found = safeGet(componentSchemas, schemaName);
  if (found && "$ref" in found) throw new NoRefsHereError();
  return found;
}

export function findComponentSchemaInDefinitionOrError({
  definition,
  schemaName,
}: {
  definition: OASDefinition;
  schemaName: string;
}): OASSchema {
  const found = findComponentSchemaInDefinition({ definition, schemaName });
  if (!found) throw new NotFoundError("model");
  return found;
}

export function editComponentSchemaInDefinition({
  oldName,
  newName,
  definition,
  newComponentSchema,
}: {
  definition: OASDefinition;
  oldName: string;
  newName: string;
  newComponentSchema: OASSchema | OASReferenceObject;
}) {
  if (
    oldName !== newName &&
    findComponentSchemaInDefinition({
      definition,
      schemaName: newName,
    })
  ) {
    throw new Error("A response with this name already exists");
  }

  let newDefinition = definition;
  // IMPORTANT: only if names differ we update the refs and remove
  // the response
  if (oldName !== newName) {
    newDefinition = removeComponentSchemaFromDefinition({
      definition: updateSchemaRefsInDefinition({
        definition,
        oldRefName: oldName,
        newRefName: newName,
        type: "schemas",
      }),
      schemaName: oldName,
    });
  }
  return addComponentSchemaToDefinition({
    definition: newDefinition,
    name: newName,
    schema: newComponentSchema,
  });
}

export function removeSchemaDtosFromDefinition(
  definition: OASDefinition,
  schemaName: string
): OASDefinition {
  const shallowCp = { ...definition };
  const schemas = shallowCp.components?.schemas;
  if (!schemas) return shallowCp;
  const deletedDtoNames = [];
  for (const [key, value] of Object.entries(schemas)) {
    if (
      value["x-fiddle-dto-info"] &&
      value["x-fiddle-dto-info"]?.baseSchemaName === schemaName
    ) {
      deletedDtoNames.push(key);
      delete schemas[key];
    }
  }
  // Delete all references to dtos that exist in the definition
  let result = shallowCp;
  for (const dtoName of deletedDtoNames) {
    result = removeSchemaRefsFromDefinition(result, dtoName, "schemas");
  }
  return result;
}

export function removeComponentSchemaFromDefinition({
  definition,
  schemaName,
}: {
  definition: OASDefinition;
  schemaName: string;
}) {
  const cp = cloneDeep(definition);
  const schema: OASSchema | undefined = get(
    cp,
    `components.schemas.${schemaName}`
  );
  if (!schema) throw new Error("Old component schema not found");
  unset(cp, `components.schemas.${schemaName}`);
  return removeSchemaDtosFromDefinition(
    removeSchemaRefsFromDefinition(cp, schemaName, "schemas"),
    schemaName
  );
}
