import { cn } from "@/lib/utils";
import { ComponentProps, forwardRef } from "react";

export const SecondaryCard = forwardRef<
  HTMLDivElement,
  ComponentProps<"div"> & { selected?: boolean; disabled?: boolean }
>(({ children, className, selected, disabled, ...rest }, ref) => {
  const clickable = rest.onClick;

  return (
    <div
      className={cn(
        "py-2.5 px-4 shadow-lg bg-secondary/90 rounded-sm gap-2 grid-rows-[max-content] grid grid-cols-1 auto-rows-fr items-center",
        "aria-disabled:opacity-70 aria-disabled:pointer-events-none",
        clickable &&
          "hover:bg-secondary/60 border-2 border-transparent aria-selected:border-input transition-all",
        className
      )}
      role={clickable ? "button" : ""}
      aria-selected={selected}
      aria-disabled={disabled}
      {...rest}
      ref={ref}
    >
      {children}
    </div>
  );
});

export const SecondaryCardHeader = forwardRef<
  HTMLDivElement,
  ComponentProps<"div">
>(({ children, className, ...rest }, ref) => {
  return (
    <div
      className={cn(
        "grid grid-cols-[1fr,max-content] content-between justify-items-stretch items-center",
        className
      )}
      {...rest}
      ref={ref}
    >
      {children}
    </div>
  );
});

export const SecondaryCardContent = forwardRef<
  HTMLDivElement,
  ComponentProps<"div">
>(({ children, className, ...rest }, ref) => {
  return (
    <div className={cn(className)} {...rest} ref={ref}>
      {children}
    </div>
  );
});
