import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { useActionBarContext } from "../contexts/action-bar-context";
import { ActionBarContent } from "./action-bar-content";
import { Command, CommandItem, CommandList } from "@/components/ui/command";

export function SelectExample(props: EditorInputProps) {
  const { resetToExample } = useAPIEditorTools(props);
  const [, actionBarDispatch] = useActionBarContext();

  const handleResetToExample = async (exampleName: "bookStore") => {
    await resetToExample(exampleName);
  };

  return (
    <ActionBarContent>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink
              className="cursor-pointer"
              onClick={() => {
                actionBarDispatch({
                  type: "SET_PAGE",
                  payload: {
                    name: "workspace-actions",
                  },
                });
              }}
            >
              Workspace actions
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink>Select example</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Command>
        <CommandList>
          <CommandItem onSelect={() => handleResetToExample("bookStore")}>
            Book store
          </CommandItem>
        </CommandList>
      </Command>
    </ActionBarContent>
  );
}
