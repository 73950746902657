import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  addComponentParameterToDefinition,
  addOrAttatchExistingParameterAsComponentToDefinition,
  editComponentParameterInDefinition,
  getComponentParameterByLabelFromDefinition,
  getComponentParametersFromDefinition,
  removeComponentParameterFromDefinition,
} from "@/lib/editor-mutations/oas-component-parameters";
import { resolveParameterFromDefinition } from "@/lib/editor-mutations/oas-parameters";
import { OASParameter, OASParameterObject } from "@/lib/types";
import { toastError } from "@/lib/utils";
import { useCallback } from "react";
import { toast } from "sonner";

export type ComponentParameterWithInfo = {
  parameter: OASParameterObject;
  label: string;
};

export function useComponentParameters({ value, onChange }: EditorInputProps) {
  const { setNewEditorState, setActiveElement } = useAPIEditorTools({
    value,
    onChange,
  });

  const getComponentParametersWithInfo =
    useCallback((): ComponentParameterWithInfo[] => {
      const parameters = getComponentParametersFromDefinition({
        definition: value.data,
      });
      return Object.entries(parameters).map(([label, parameterObject]) => ({
        label,
        parameter: parameterObject as OASParameterObject,
      }));
    }, [value.data]);

  const getComponentParameterByLabel = useCallback(
    (label: string) => {
      return getComponentParameterByLabelFromDefinition({
        label,
        definition: value.data,
      });
    },
    [value.data]
  );

  const getComponentParameterByLabelOrError = useCallback(
    (label: string) => {
      const found = getComponentParameterByLabel(label);
      if (!found) throw new Error("Component parameter not found");
      return found;
    },
    [getComponentParameterByLabel]
  );

  const addComponentParameter = useCallback(
    ({ name, parameter }: { name: string; parameter: OASParameterObject }) => {
      try {
        const newDefinition = addComponentParameterToDefinition({
          definition: value.data,
          label: name,
          parameter,
        });
        setNewEditorState({
          data: newDefinition,
          document_type: value.document_type,
        });
      } catch (err) {
        toastError(err);
      }
    },
    [value.data, value.document_type, setNewEditorState]
  );

  const resolveParameter = useCallback(
    (parameter: OASParameter) => {
      return resolveParameterFromDefinition({
        definition: value.data,
        parameter,
      });
    },
    [value.data]
  );

  const addOrAttatchExistingParameterAsComponent = useCallback(
    ({
      operationId,
      parameterIdx,
    }: {
      operationId: string;
      parameterIdx: number;
    }) => {
      try {
        const newDefinition =
          addOrAttatchExistingParameterAsComponentToDefinition({
            definition: value.data,
            operationId,
            parameterIdx,
          });
        setNewEditorState({
          data: newDefinition,
          document_type: value.document_type,
        });
        toast.success("Added as component");
      } catch (err) {
        toastError(err);
      }
    },
    [value.document_type, value.data, setNewEditorState]
  );

  const removeComponentParameter = useCallback(
    async (label: string) => {
      try {
        const newDefinition = removeComponentParameterFromDefinition({
          definition: value.data,
          label,
        });
        await setActiveElement(undefined);
        setNewEditorState({
          data: newDefinition,
          document_type: value.document_type,
        });
      } catch (err) {
        toastError(err);
      }
    },
    [value.data, setActiveElement, value.document_type, setNewEditorState]
  );

  const editComponentParameter = useCallback(
    async ({
      oldName,
      newName,
      parameterObject,
    }: {
      oldName: string;
      newName: string;
      parameterObject: OASParameterObject;
    }) => {
      try {
        const newDefinition = editComponentParameterInDefinition({
          oldName,
          newName,
          parameterObject,
          definition: value.data,
        });
        setNewEditorState({
          data: newDefinition,
          document_type: value.document_type,
        });
        await setActiveElement({ type: "component-parameter", label: newName });
      } catch (err) {
        toastError(err);
      }
    },
    [value.data, setActiveElement, setNewEditorState, value.document_type]
  );

  return {
    getComponentParametersWithInfo,
    getComponentParameterByLabelOrError,
    getComponentParameterByLabel,
    addComponentParameter,
    removeComponentParameter,
    editComponentParameter,
    resolveParameter,
    addOrAttatchExistingParameterAsComponent,
  };
}
