// import { addResponseToOperationInDefinition } from "@/lib/editor-mutations/oas-operations";
import { findOperationsWithInfoInDefinition } from "@/lib/editor-mutations/oas-operations";
import { updateSchemaRefsInDefinition } from "@/lib/editor-mutations/oas-references";
import { addResponseToOperationInDefinition } from "@/lib/editor-mutations/oas-responses";
import { NoRefsHereError } from "@/lib/errors";
import { HttpStatus } from "@/lib/helpers";
import { httpStatusDescriptions } from "@/lib/oas-tools/http-status-default-description";
import { getDefaultSchemaForResponseCode } from "@/lib/oas-tools/https-status-default-schemas";
import { TemplateResponseCodes } from "@/lib/oas-tools/https-verb-status-codes";
import { isReference } from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASComponentsResponses,
  OASDefinition,
  OASResponseObject,
  SupportedContentFormats,
} from "@/lib/types";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import unset from "lodash/unset";

export function getComponentResponsesFromDefinition({
  definition,
}: {
  definition: OASDefinition;
}): OASComponentsResponses {
  return (definition.components?.responses || {}) as Record<
    string,
    OASResponseObject
  >;
}

export function getComponentResponseFromDefinition({
  definition,
  responseName,
}: {
  definition: OASDefinition;
  responseName: string;
}): OASResponseObject | undefined {
  const componentRespones = getComponentResponsesFromDefinition({ definition });
  const found = componentRespones[responseName];
  if (found && "$ref" in found) throw new NoRefsHereError();
  return found;
}

export function addComponentResponseToDefinition({
  definition,
  name,
  responseObject,
  skipCheckForExisting = false,
  skipCloning = false,
}: {
  definition: OASDefinition;
  name: string;
  responseObject: OASResponseObject;
  skipCheckForExisting?: boolean;
  skipCloning?: boolean;
}) {
  if (!skipCheckForExisting) {
    const found = getComponentResponseFromDefinition({
      definition,
      responseName: name,
    });
    if (found) throw new Error("A response with this name already exists");
  }

  const cp = skipCloning ? definition : structuredClone(definition);
  set(cp, `components.responses.${name}`, responseObject);
  return cp;
}

export function removeComponentResponseFromDefinition({
  definition,
  responseName,
}: {
  definition: OASDefinition;
  responseName: string;
}) {
  const cp = cloneDeep(definition);

  const found = getComponentResponseFromDefinition({
    definition: cp,
    responseName,
  });
  if (!found) throw new Error("No response found");

  unset(cp, `components.responses.${responseName}`);

  const operations = findOperationsWithInfoInDefinition(cp);

  // dereference all responses
  operations.forEach(({ operation }) => {
    Object.entries(operation.responses || {}).forEach(
      ([responseCode, response]) => {
        if (!isReference(response)) return;
        if (response.$ref !== `#/components/responses/${responseName}`) return;
        operation.responses![responseCode] = found;
      }
    );
  });

  // clean up empty object
  if (isEmpty(cp.components?.responses)) {
    delete cp.components?.responses;
  }

  return cp;
}

export function editComponentResponseInDefinition({
  oldName,
  newName,
  definition,
  newResponseObject,
}: {
  definition: OASDefinition;
  oldName: string;
  newName: string;
  newResponseObject: OASResponseObject;
}) {
  if (
    oldName !== newName &&
    getComponentResponseFromDefinition({
      definition,
      responseName: newName,
    })
  ) {
    throw new Error("A response with this name already exists.");
  }

  let newDefinition = definition;

  // IMPORTANT: only if names differ we update the refs and remove
  // the response
  if (oldName !== newName) {
    newDefinition = removeComponentResponseFromDefinition({
      definition: updateSchemaRefsInDefinition({
        definition,
        oldRefName: oldName,
        newRefName: newName,
        type: "responses",
      }),
      responseName: oldName,
    });
  }

  return addComponentResponseToDefinition({
    definition: newDefinition,
    name: newName,
    responseObject: newResponseObject,
    skipCheckForExisting: true,
  });
}

export function addComponentResponseToOperationByResponseName({
  operationId,
  componentResponseName,
  definition,
  skipCheckForExisting,
  newResponse,
  responseCode,
  skipCloning,
}: {
  operationId: string;
  componentResponseName: string;
  definition: OASDefinition;
  newResponse: OASResponseObject;
  skipCheckForExisting?: boolean;
  responseCode: HttpStatus;
  skipCloning?: boolean;
}) {
  let newDefinition = definition;

  const existingResponse = getComponentResponseFromDefinition({
    definition,
    responseName: componentResponseName,
  });
  // If the component response does not exist we create it
  if (!existingResponse) {
    newDefinition = addComponentResponseToDefinition({
      definition,
      name: componentResponseName,
      responseObject: newResponse,
      skipCloning,
    });
  }

  // Add the reference to the response to the operation
  newDefinition = addResponseToOperationInDefinition({
    responseCode,
    operationId,
    definition: newDefinition,
    skipCheckForExisting,
    skipCloning,
    response: {
      $ref: `#/components/responses/${componentResponseName}`,
    },
  });

  return newDefinition;
}

const componentResponseMap: Record<TemplateResponseCodes, string> = {
  204: "NoContent",
  202: "Accepted",
  400: "BadRequest",
  401: "Unauthorized",
  403: "Forbidden",
  404: "NotFound",
  409: "Conflict",
  422: "UnprocessableEntity",
  500: "InternalServerErrror",
};

export function generateTemplateResponse(
  templateResponseCode: TemplateResponseCodes,
  format: SupportedContentFormats
): OASResponseObject {
  const description = httpStatusDescriptions[templateResponseCode].description;
  const schema = getDefaultSchemaForResponseCode(templateResponseCode);

  return {
    description,
    content: {
      [format]: {
        schema,
      },
    },
  };
}

export function addComponentResponseByCodeToOperationInDefinition({
  operationId,
  responseCode,
  definition,
  skipCheckForExisting = false,
  skipCloning = false,
  format,
}: {
  operationId: string;
  responseCode: TemplateResponseCodes;
  definition: OASDefinition;
  skipCheckForExisting?: boolean;
  skipCloning?: boolean;
  format: SupportedContentFormats;
}) {
  const responseName = componentResponseMap[responseCode];

  return addComponentResponseToOperationByResponseName({
    definition,
    responseCode,
    componentResponseName: responseName,
    operationId,
    newResponse: generateTemplateResponse(responseCode, format),
    skipCheckForExisting,
    skipCloning,
  });
}
