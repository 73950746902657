import { ReactNode } from "react";

import {
  BtnGroup,
  MinimizeButton,
} from "@/components/module-visual-editor/shared-components";
import { Button } from "@/components/ui/button";
import { cn, DEFAULT_ICON_SIZE_SM, NormIcons } from "@/lib/utils";
import { ComponentProps, PropsWithChildren } from "react";

export function SchemaEditorPreviewContainer({
  title,
  children,
  className,
  isLocked,
  // If no `onLockChange` is passed the editor is
  // always read-only
  onLockChange,
  onClose,
  isDisabled = false,
  ...rest
}: PropsWithChildren<
  Omit<ComponentProps<"div">, "title"> & {
    title: ReactNode;
    isLocked?: boolean;
    onLockChange?: (value: boolean) => unknown;
    onClose?: () => unknown;
    isDisabled?: boolean;
  }
>) {
  return (
    <div
      className={cn(
        "bg-primary-foreground border border-input h-full overflow-auto rounded-md",
        { "opacity-50": isDisabled },
        className
      )}
      {...rest}
    >
      <div
        className={cn(
          "flex items-center justify-between border-input border-b px-2 py-1 gap-3"
        )}
      >
        <p className="text-sm block">{title}</p>
        <BtnGroup>
          {onLockChange && (
            <Button size="icon-sm" onClick={() => onLockChange(!isLocked)}>
              {isLocked ? (
                <NormIcons.Lock size={DEFAULT_ICON_SIZE_SM} />
              ) : (
                <NormIcons.LockOpen size={DEFAULT_ICON_SIZE_SM} />
              )}
            </Button>
          )}
          {onClose && <MinimizeButton onClick={onClose} />}
        </BtnGroup>
      </div>
      <div className="py-2 px-4">{children}</div>
    </div>
  );
}

export function QuickToolbar({
  isLocked,
  onLockChange,
}: {
  isLocked?: boolean;
  onLockChange?: (value: boolean) => unknown;
}) {
  return (
    <BtnGroup className="w-min mt-1 pl-0">
      {onLockChange && (
        <Button
          size="xs"
          variant="ghost"
          onClick={() => onLockChange(!isLocked)}
        >
          {isLocked ? (
            <>
              <NormIcons.Lock className="mr-2" size={DEFAULT_ICON_SIZE_SM} />
              <span>Unlock editor</span>
            </>
          ) : (
            <>
              <NormIcons.LockOpen
                className="mr-2"
                size={DEFAULT_ICON_SIZE_SM}
              />
              <span>Lock editor</span>
            </>
          )}
        </Button>
      )}
    </BtnGroup>
  );
}

export function QuickEditorContainer({
  children,
  className,
  toolbar,
  isDisabled = false,
  ...rest
}: PropsWithChildren<
  Omit<ComponentProps<"div">, "title"> & {
    toolbar: ReactNode;
    isDisabled?: boolean;
  }
>) {
  return (
    <div
      className={cn(
        "bg-background rounded-md border h-full flex flex-col items-stretch border-input",
        { "opacity-50": isDisabled },
        className
      )}
      {...rest}
    >
      {toolbar}
      <div className="grow overflow-auto py-2 px-4 min-h-[280px]">
        <div className="w-max">{children}</div>
      </div>
    </div>
  );
}

export function SchemaContentEditorQuickContainer({
  children,
  className,
  ...rest
}: PropsWithChildren<Omit<ComponentProps<"div">, "title">>) {
  return (
    <div
      className={cn(
        "overflow-auto bg-background rounded-md h-full min-h-[300px] border border-input pb-2",
        className
      )}
      {...rest}
    >
      <div className="w-max px-4 h-full">{children}</div>
    </div>
  );
}

export function SchemaEditorQuickContainer({
  children,
  className,
  isLocked,
  onLockChange,
  isDisabled = false,
  ...rest
}: PropsWithChildren<
  Omit<ComponentProps<"div">, "title"> & {
    isLocked?: boolean;
    onLockChange?: (value: boolean) => unknown;
    isDisabled?: boolean;
  }
>) {
  return (
    <div
      className={cn(
        "bg-background rounded-md border h-full flex flex-col items-stretch border-input",
        { "opacity-50": isDisabled }
      )}
      {...rest}
    >
      <QuickToolbar onLockChange={onLockChange} isLocked={isLocked} />
      <div
        className={cn("grow overflow-auto py-2 px-4 min-h-[180px]", className)}
      >
        <div className="w-max">{children}</div>
      </div>
    </div>
  );
}
