import { useRightDrawerLayout } from "@/components/contexts/api-editor-context-hooks";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn, DEFAULT_ICON_SIZE_LG } from "@/lib/utils";
import defaults from "lodash/defaults";
import { Maximize2, PanelLeft } from "lucide-react";
import * as React from "react";

export const LayoutSidebarHeader = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div"> & {
    showControls: boolean;
    onClose?: () => unknown;
  }
>(({ className, showControls, onClose, children, ...rest }, ref) => {
  const [state, setState] = useRightDrawerLayout();
  return (
    <div
      className={cn(
        "grid grid-cols-[max-content,1fr,max-content] gap-2",
        className
      )}
      ref={ref}
      {...rest}
    >
      {children}
      {showControls && (
        <BtnGroup className="items-center justify-end gap-0 px-4 bg-secondary rounded-sm">
          {!state.expanded && (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setState({ ...state, expanded: true })}
            >
              <Maximize2 size={DEFAULT_ICON_SIZE_LG} />
            </Button>
          )}

          <Button
            variant="ghost"
            size="icon"
            onClick={() => {
              onClose?.();
              setState({ ...state, expanded: false });
            }}
          >
            <PanelLeft size={DEFAULT_ICON_SIZE_LG} />
          </Button>
        </BtnGroup>
      )}
    </div>
  );
});

export const LayoutSidebarContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ className, children, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      className={cn("flex flex-col items-stretch gap-2", className)}
      {...rest}
    >
      {children}
    </div>
  );
});

export function LayoutSidebar({
  children,
  className,
  style: _style,
  ...rest
}: React.ComponentProps<"div">) {
  const [state] = useRightDrawerLayout();
  return (
    <div
      // 350 - (2 * 8px) = 234px: reason: px-2
      className={cn(
        "layout-sidebar flex flex-col items-stretch gap-2 w-[334px]",
        {
          "w-[534px]": state.expanded,
        },
        className
      )}
      style={{ height: "calc(100vh - 100px)" }}
      {...rest}
    >
      {children}
    </div>
  );
}

type State = { expanded: boolean };

type ContextState =
  | readonly [State, React.Dispatch<React.SetStateAction<State>>]
  | undefined;
export const LayoutRightDrawerContext =
  React.createContext<ContextState>(undefined);

function LayoutRightDrawerProvider({
  children,
  initialState,
}: React.PropsWithChildren<{ initialState: Partial<State> }>) {
  const [state, setState] = React.useState<State>(
    defaults(initialState, { expanded: false })
  );
  const contextValue = React.useMemo(() => [state, setState] as const, [state]);

  return (
    <LayoutRightDrawerContext.Provider value={contextValue}>
      {children}
    </LayoutRightDrawerContext.Provider>
  );
}
interface SlidingDrawerLayoutProps {
  children: React.ReactNode;
  sidebar: React.ReactNode;
  placeholder?: React.ReactNode;
  defaultExpaned?: boolean;
}

export function ScrollableLayoutContainer({
  children,
}: React.PropsWithChildren) {
  return (
    <ScrollArea className="h-[calc(100vh-theme(spacing.16))] overflow-hidden overflow-y-auto">
      {children}
    </ScrollArea>
  );
}

export function ContentHeightContainer({
  className,
  children,
  ...rest
}: React.ComponentProps<"div">) {
  return (
    <div
      className={cn(
        "h-1 min-h-[calc(100vh-theme(spacing.16))] w-full",
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
}

function LayoutRightDrawerContent({
  sidebar,
  placeholder,
  children,
}: SlidingDrawerLayoutProps) {
  const [layoutState] = useRightDrawerLayout();

  const sidebarWidth = layoutState.expanded ? "550px" : "350px";

  return (
    <ScrollableLayoutContainer>
      <div
        className={cn(
          "grid transition-[grid-template-columns] duration-300 ease-in-out h-full",
          {
            "grid-cols-[1fr,350px]":
              (sidebar || placeholder) && !layoutState.expanded,
            "grid-cols-[1fr,550px]": sidebar && layoutState.expanded,
            "grid-cols-[1fr,0px]": !(sidebar || placeholder),
          }
        )}
      >
        <ScrollArea className="overflow-y-auto overflow-x-hidden p-0">
          {children}
        </ScrollArea>
        <aside
          className={cn(
            "relative transition-[width] duration-300 ease-in-out",
            sidebar ? `w-[${sidebarWidth}]` : "w-0"
          )}
        >
          {placeholder && (
            <div
              className={cn(
                "absolute inset-0 transition-opacity duration-300 ease-in-out",
                sidebar ? "opacity-0 pointer-events-none" : "opacity-100"
              )}
            >
              <div className="sticky top-2 mt-2">{placeholder}</div>
            </div>
          )}
          {sidebar && (
            <div
              className={cn(
                "absolute inset-0 transition-opacity duration-300 ease-in-out",
                sidebar ? "opacity-100" : "opacity-0 pointer-events-none"
              )}
            >
              <div className="sticky top-2 mt-2">{sidebar}</div>
            </div>
          )}
        </aside>
      </div>
    </ScrollableLayoutContainer>
  );
}

export default function LayoutRightDrawer(props: SlidingDrawerLayoutProps) {
  return (
    <LayoutRightDrawerProvider
      initialState={{ expanded: props.defaultExpaned }}
    >
      <LayoutRightDrawerContent {...props} />
    </LayoutRightDrawerProvider>
  );
}
