import { H3 } from "@/components/headings";
import { cn } from "@/lib/utils";
import { ComponentProps, PropsWithChildren } from "react";

export function ActionBarForm({ className, ...rest }: ComponentProps<"form">) {
  return (
    <form
      className={cn("px-4 flex flex-col items-stretch gap-3", className)}
      {...rest}
    />
  );
}

export function ActionBarFormTitle({ children }: PropsWithChildren) {
  return (
    <H3 className="text-muted-foreground">
      <span className="inline-block pb-0.5 pr-1 mb-2">{children}</span>
    </H3>
  );
}

export function ActionBarFieldGroup({
  className,
  ...rest
}: ComponentProps<"div">) {
  return (
    <div
      className={cn("flex flex-col items-stretch gap-2", className)}
      {...rest}
    />
  );
}
