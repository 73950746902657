import { addComponentResponseByCodeToOperationInDefinition } from "@/lib/editor-mutations/oas-component-responses";
import {
  findComponentSchemaInDefinition,
  findComponentSchemaInDefinitionOrError,
} from "@/lib/editor-mutations/oas-components";
import {
  createDtoInDefinition,
  findDtoInDefinition,
} from "@/lib/editor-mutations/oas-dto";
import {
  createOperationAndParamsToDefinition,
  findOperationByIdFromDefinition,
  findOperationByUrlAndMethodFromDefinition,
} from "@/lib/editor-mutations/oas-operations";
import { addResponseToOperationInDefinition } from "@/lib/editor-mutations/oas-responses";
import { findSecuritySchemeInDefinition } from "@/lib/editor-mutations/oas-security-schemes";
import { findTagInDefinition } from "@/lib/editor-mutations/oas-tags";
import { HttpStatus } from "@/lib/helpers";
import {
  createDtoFromOASSchema,
  isTemplateCrudOperation,
} from "@/lib/oas-tools/create-dto-from-schema";
import { getDtoTemplateValues } from "@/lib/oas-tools/dto-templates";
import { httpStatusDescriptions } from "@/lib/oas-tools/http-status-default-description";
import { getDefaultSchemaForResponseCode } from "@/lib/oas-tools/https-status-default-schemas";
import { httpMethodResponseCodes } from "@/lib/oas-tools/https-verb-status-codes";
import { getRefPath } from "@/lib/oas-tools/oas-tag-helpers";
import {
  CRUDOperation,
  OASDefinition,
  OASOperation,
  OASReferenceObject,
  OASSchema,
  SupportedContentFormats,
  TemplateCRUDOperation,
} from "@/lib/types";
import { slugify } from "@/lib/utils";
import { isEmpty } from "lodash";

function getWizardPath({
  baseUrl,
  crudOperation,
  parameterName,
}: {
  baseUrl: string;
  crudOperation: CRUDOperation;
  parameterName: string;
}) {
  if (crudOperation.level === "detail") {
    return `${baseUrl}/{${parameterName}}`;
  }
  return baseUrl;
}

function addDtoToDefinitionIfNotExists({
  dtoName,
  definition,
  baseSchemaName,
  baseSchema,
  crudOperation,
}: {
  dtoName: string;
  definition: OASDefinition;
  baseSchemaName: string;
  crudOperation: TemplateCRUDOperation | undefined;
  baseSchema: OASSchema;
}): OASDefinition {
  if (findComponentSchemaInDefinition({ definition, schemaName: dtoName })) {
    throw new Error(
      `A component schema exists with the requested DTO name: ${dtoName}. DTOs cannot overwrite component schemas.`
    );
  }
  if (
    findDtoInDefinition({
      definition,
      baseSchemaName,
      dtoName,
    })
  ) {
    return definition;
  }

  if (!crudOperation)
    throw new Error(
      "Internal: Must provide template CRUDOperation when trying to create DTO from template"
    );

  const templateValues = getDtoTemplateValues(crudOperation, baseSchemaName);

  if (dtoName !== templateValues.dtoName)
    throw new Error(
      "Internal: (DTONameError) Wizard DTO names can either point to an existing DTO or use a valid template name. Valid tamplate names for a schema of type Book would be e.g. BookUpdate, BookSummary."
    );

  const dtoSchema = createDtoFromOASSchema({
    schema: baseSchema,
    componentsObject: definition.components || {},
    crudOperation,
  });

  return createDtoInDefinition({
    definition,
    dtoName: templateValues.dtoName,
    dtoSchema,
    baseSchemaName: baseSchemaName,
  });
}

export function getOpenAPIOperationDetails(
  crudOperation: CRUDOperation,
  schemaName: string
): { description: string; summary: string; operationId: string } {
  type Desc = {
    description: string;
    summary: string;
  };
  const dislaySchemaName = slugify(schemaName);
  const descriptions: Record<
    CRUDOperation["method"],
    { detail?: Desc; list?: Desc }
  > = {
    get: {
      detail: {
        description: `Returns a record of type ${schemaName}.`,
        summary: `Get ${dislaySchemaName}`,
      },
      list: {
        description: `Returns a list of ${schemaName} records.`,
        summary: `List ${dislaySchemaName}`,
      },
    },
    post: {
      list: {
        description: `Creates a new record of type ${schemaName}.`,
        summary: `Create ${dislaySchemaName}`,
      },
    },
    put: {
      detail: {
        description: `Updates a record of type ${schemaName}.`,
        summary: `Update ${dislaySchemaName}`,
      },
    },
    patch: {
      detail: {
        description: `Partially updates a record of type ${schemaName}.`,
        summary: `Patch ${dislaySchemaName}`,
      },
    },
    delete: {
      detail: {
        description: `Deletes a record of type ${schemaName}.`,
        summary: `Delete ${dislaySchemaName}`,
      },
    },
  };

  const { method, level } = crudOperation;
  const options = descriptions[method][level];
  const description = options?.description || "";
  const summary = options?.summary || "";

  let operationId: string;
  switch (method) {
    case "get":
      operationId =
        level === "detail" ? `get${schemaName}` : `list${schemaName}s`;
      break;
    case "post":
      operationId = `create${schemaName}`;
      break;
    case "put":
      operationId = `update${schemaName}`;
      break;
    case "patch":
      operationId = `partialUpdate${schemaName}`;
      break;
    case "delete":
      operationId = `remove${schemaName}`;
      break;
  }

  return { description, summary, operationId };
}

function generateCustomResponseSchema({
  crudOperation,
  dtoName,
  responsePropertyName,
  statusCode,
  baseSchemaName,
}: {
  crudOperation: CRUDOperation;
  dtoName: string | undefined;
  responsePropertyName: string;
  statusCode: HttpStatus;
  baseSchemaName: string;
}): OASSchema | OASReferenceObject {
  const level = crudOperation.level;
  let schema: OASSchema | OASReferenceObject;
  if (dtoName) {
    const refPath = getRefPath("schemas", dtoName);
    schema = responsePropertyName
      ? {
          type: "object",
          required: [responsePropertyName],
          properties: {
            [responsePropertyName]: {
              $ref: refPath,
            },
          },
        }
      : {
          $ref: refPath,
        };
  } else if (crudOperation.method !== "delete") {
    const refPath = getRefPath("schemas", baseSchemaName);
    // Let's assume the user wants to return the schema and not the dto
    schema = responsePropertyName
      ? {
          type: "object",
          properties: {
            [responsePropertyName]: {
              $ref: refPath,
            },
          },
        }
      : {
          $ref: refPath,
        };
  } else {
    schema = getDefaultSchemaForResponseCode(statusCode);
  }

  return level === "list" ? { type: "array", items: schema } : schema;
}

function createOperationResponsesToDefinition({
  crudOperation,
  securitySchemes,
  definition,
  operationId,
  format,
  dtoName,
  responsePropertyName,
  baseSchemaName,
}: {
  definition: OASDefinition;
  crudOperation: CRUDOperation;
  dtoName: string | undefined;
  responsePropertyName: string;
  operationId: string;
  securitySchemes: OASOperation["security"];
  format: SupportedContentFormats;
  baseSchemaName: string;
}): OASDefinition {
  const responseCodesAsTemplates =
    httpMethodResponseCodes[crudOperation.method];

  let cp = { ...definition };

  // add custom responses for success codes
  responseCodesAsTemplates.custom.forEach((statusCode) => {
    const description = httpStatusDescriptions[statusCode].description;

    const responseObject = {
      description,
      content: {
        [format]: {
          schema: generateCustomResponseSchema({
            crudOperation,
            dtoName,
            responsePropertyName,
            statusCode,
            baseSchemaName,
          }),
        },
      },
    };

    addResponseToOperationInDefinition({
      operationId,
      definition: cp,
      response: responseObject,
      responseCode: statusCode,
      skipCloning: true,
    });
  });

  // add component responses for error codes
  responseCodesAsTemplates.components.forEach((statusCode) => {
    cp = addComponentResponseByCodeToOperationInDefinition({
      definition: cp,
      operationId,
      responseCode: statusCode,
      skipCheckForExisting: true,
      skipCloning: true,
      format,
    });
  });

  // add component responses if there are any auth requirements
  if ((securitySchemes || []).length) {
    responseCodesAsTemplates.authComponents.forEach((statusCode) => {
      cp = addComponentResponseByCodeToOperationInDefinition({
        definition: cp,
        operationId,
        responseCode: statusCode,
        skipCheckForExisting: true,
        skipCloning: true,
        format,
      });
    });
  }

  return cp;
}

/**
 * Adds a CRUD operation for a given schema definition to the OpenAPI specification.
 *
 * If `dtoName` is provided dto are find-or-create. If no dtoName is provided the default
 * response schema is used.
 *
 * @param definition - The OAS (OpenAPI Specification) definition object.
 * @param baseSchemaName - The base schema name for the operation.
 * @param crudOperation - The CRUD operation to be added (create, read, update, delete).
 * @param dtoName - dtoName can be an existing DTO or a valid template name s. `getDtoTemplateValues`. If `undefined` return baseSchema unless method is `delete`.
 * @param baseUrl - The base URL path for the operation (e.g., `/books`).
 * @param parameterName - The name of the parameter in the URL path (e.g., `book_id`).
 * @param responsePropertyName - The name the response schema should be wrapped in.
 * @param tags - The tags to be associated with the operation.
 * @param securitySchemes - The security schemes to be applied to the operation.
 * @param format - The content format for the operation (e.g., 'json', 'xml').
 * @returns - The updated OAS definition object.
 */
export function createWizardOperationInDefinition({
  definition,
  crudOperation,
  baseUrl,
  responsePropertyName,
  dtoName,
  baseSchemaName,
  parameterName,
  tags,
  securitySchemes,
  format,
}: {
  definition: OASDefinition;
  baseSchemaName: string;
  crudOperation: CRUDOperation;
  dtoName: string | undefined;
  baseUrl: string;
  parameterName: string;
  responsePropertyName: string;
  tags: string[];
  securitySchemes: OASOperation["security"];
  format: SupportedContentFormats;
}) {
  const urlPath = getWizardPath({ baseUrl, parameterName, crudOperation });

  if (parameterName && crudOperation.level === "list")
    throw new Error(
      "Internal: (Misconfiguration) the CRUD operation cannot have the level `list` and provide a parameterName at the same time. Providing a parameterName implies that you intent to perform an operation on a single record."
    );

  if (
    findOperationByUrlAndMethodFromDefinition({
      definition,
      urlPath,
      method: crudOperation.method,
    })
  ) {
    throw new Error(
      "Conflict: An operation with this path & method already exists."
    );
  }

  tags.forEach((t) => {
    const found = findTagInDefinition({ definition, tagName: t });
    if (!found) throw new Error("NotFound: Unable to find tag");
  });

  (securitySchemes || [])?.forEach((s) => {
    if (isEmpty(s)) return; // schema marking operation as public
    const key = Object.keys(s)[0];
    const found = findSecuritySchemeInDefinition({
      definition,
      schemeName: key,
    });
    if (!found) throw new Error("NotFound: SecurityScheme not found in spec.");
  });

  const operationDetails = getOpenAPIOperationDetails(
    crudOperation,
    baseSchemaName
  );

  if (
    findOperationByIdFromDefinition({
      definition: definition,
      operationId: operationDetails.operationId,
    })
  )
    throw new Error(
      "Conflict: An operation with this operationId already exists."
    );

  const componentSchema = findComponentSchemaInDefinitionOrError({
    definition,
    schemaName: baseSchemaName,
  });

  let cp = definition;

  if (dtoName)
    cp = addDtoToDefinitionIfNotExists({
      dtoName,
      definition: cp,
      baseSchemaName,
      baseSchema: componentSchema,
      crudOperation: isTemplateCrudOperation(crudOperation)
        ? crudOperation
        : undefined,
    });

  // Create the operation in the definition
  cp = createOperationAndParamsToDefinition({
    definition: cp,
    urlPath,
    method: crudOperation.method,
    newOperation: {
      summary: operationDetails.summary,
      operationId: operationDetails.operationId,
      description: operationDetails.description,
      tags: tags,
      security: securitySchemes || [],
    },
  });

  // Add the operation responses to the definition
  cp = createOperationResponsesToDefinition({
    crudOperation,
    definition: cp,
    dtoName,
    responsePropertyName,
    securitySchemes,
    operationId: operationDetails.operationId,
    format,
    baseSchemaName,
  });

  return cp;
}
