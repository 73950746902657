import { sleep } from "@/lib/helpers";
import { apiClient } from "@/lib/http-utils";
import {
  CreateWorkspaceDocumentDto,
  CreateWorkspaceDto,
  CreateWorkspaceItemDto,
  PatchWorkspaceDto,
  PatchWorkspaceMemberDto,
  UpdateWorkspaceDocumentDto,
  UserWorkspaceDto,
  WorksapceItemDto,
  WorkspaceDocumentDraftCreateDto,
  WorkspaceDocumentDraftDto,
  WorkspaceDocumentDraftPatchDto,
  WorkspaceDocumentDraftStatus,
  WorkspaceDocumentDto,
  WorkspaceDto,
} from "@/lib/main-rest-client/definitions";
import { emptyWorkspaceExample } from "@/lib/oas-examples/empty-workspace";
import { OASDefinition } from "@/lib/types";
import { slugify } from "@/lib/utils";
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { toast } from "sonner";
import { parse } from "yaml";

export const workspaceKeys = {
  all: ["workspaces"] as const,
  lists: () => [...workspaceKeys.all, "list"] as const,
  list: (filters: { organizationSlug: string }) =>
    [...workspaceKeys.lists(), filters] as const,
  details: () => [...workspaceKeys.all, "detail"] as const,
  detail: (id: string) => [...workspaceKeys.details(), id] as const,
};

export const userWorkspaceKeys = {
  all: ["user-workspaces"] as const,
  list: () => [...userWorkspaceKeys.all, "list"] as const,
  details: () => [...userWorkspaceKeys.all, "detail"] as const,
  detail: (id: string) => [...userWorkspaceKeys.details(), id] as const,
};

type OrganizationWorkspacesParams = {
  organizationSlug: string;
};

export const organisationWorkspacesQuery = ({
  organizationSlug,
}: OrganizationWorkspacesParams) =>
  queryOptions<WorkspaceDto[], AxiosError>({
    queryKey: workspaceKeys.list({ organizationSlug }),
    queryFn: async () => {
      return (
        await apiClient.findWorkspacesByOrganizationSlug({ organizationSlug })
      ).data;
    },
  });

export function useOrganizationWorkspaces({
  organizationSlug,
}: OrganizationWorkspacesParams) {
  return useQuery(organisationWorkspacesQuery({ organizationSlug }));
}

const workspaceMembersKeys = {
  all: ["workspace-members"] as const,
  lists: () => [...workspaceMembersKeys.all, "list"] as const,
  list: (filters: { workspaceId: string }) =>
    [...workspaceMembersKeys.lists(), filters] as const,
};

export function useWorkspaceMembers({ workspaceId }: { workspaceId: string }) {
  return useQuery({
    queryKey: workspaceMembersKeys.list({ workspaceId }),
    queryFn: async () => {
      return (await apiClient.findWorkspaceMembers({ workspaceId })).data;
    },
  });
}

export function usePatchWorkspaceMember() {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    AxiosError,
    PatchWorkspaceMemberDto & { workspaceId: string; userId: string }
  >({
    mutationFn: async ({ workspaceId, userId, ...values }) => {
      return (
        await apiClient.patchWorkspaceMember({ workspaceId, userId }, values)
      ).data;
    },
    onSuccess: async (_, { workspaceId }) => {
      await queryClient.invalidateQueries({
        queryKey: workspaceMembersKeys.list({ workspaceId }),
      });
      toast.success("Workspace member updated");
    },
  });
}

export function useRemoveWorkspaceMember() {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    AxiosError,
    { workspaceId: string; userId: string }
  >({
    mutationFn: async ({ workspaceId, userId }) => {
      return (await apiClient.deleteWorkspaceMember({ workspaceId, userId }))
        .data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: workspaceMembersKeys.all,
      });
    },
  });
}

export const userWorkspacesQueryOptions = () =>
  queryOptions({
    queryKey: userWorkspaceKeys.list(),
    queryFn: async () => {
      return (await apiClient.findUserWorkspaces()).data;
    },
  });

export function useUserWorkspaces() {
  return useQuery(userWorkspacesQueryOptions());
}

export function useUserWorkspace(
  {
    workspaceSlugOrId,
  }: {
    workspaceSlugOrId: string;
  },
  options?: { enabled?: boolean }
) {
  return useQuery<UserWorkspaceDto, AxiosError>({
    queryKey: workspaceKeys.detail(workspaceSlugOrId),
    queryFn: async () => {
      return (
        await apiClient.findWorkspaceByIdOrSlug({
          workspaceSlugOrId,
        })
      ).data;
    },
    enabled: options?.enabled,
  });
}

export function useCreateWorkspace() {
  const queryClient = useQueryClient();
  return useMutation<WorkspaceDto, AxiosError, CreateWorkspaceDto>({
    mutationFn: async (values) => {
      return (
        await apiClient.createWorkspace({
          name: values.name,
          slug: slugify(values.slug),
          access_level: values.access_level,
        })
      ).data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: userWorkspaceKeys.all });
      await queryClient.invalidateQueries({ queryKey: workspaceKeys.lists() });
    },
  });
}

export function usePatchWorkspace() {
  const queryClient = useQueryClient();
  return useMutation<
    WorkspaceDto,
    AxiosError,
    PatchWorkspaceDto & { workspaceId: string }
  >({
    mutationFn: async ({ workspaceId, ...values }) => {
      return (await apiClient.patchWorkspace({ workspaceId }, values)).data;
    },
    onSuccess: async (_, values) => {
      await queryClient.invalidateQueries({ queryKey: workspaceKeys.lists() });
      await queryClient.invalidateQueries({
        queryKey: workspaceKeys.detail(values.workspaceId),
      });
      await queryClient.invalidateQueries({
        queryKey: userWorkspaceKeys.list(),
      });
      await queryClient.invalidateQueries({
        queryKey: userWorkspaceKeys.detail(values.workspaceId),
      });
    },
  });
}

export function useDeleteWorkspace() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ workspaceId }: { workspaceId: string }) => {
      return (await apiClient.deleteWorkspace({ workspaceId })).data;
    },
    onSuccess: async (_, data) => {
      await queryClient.invalidateQueries({
        queryKey: workspaceItemKeys.lists(),
      });
      await queryClient.invalidateQueries({
        queryKey: workspaceKeys.detail(data.workspaceId),
      });
      await queryClient.invalidateQueries({
        queryKey: userWorkspaceKeys.list(),
      });
      await queryClient.invalidateQueries({
        queryKey: userWorkspaceKeys.detail(data.workspaceId),
      });
    },
  });
}

const workspaceItemKeys = {
  all: ["workspace_items"] as const,
  lists: () => [...workspaceItemKeys.all, "list"] as const,
  list: (filters: { organizationId: string; workspaceId: string }) =>
    [...workspaceItemKeys.lists(), filters] as const,
  details: () => [...workspaceItemKeys.all, "detail"] as const,
  detail: (id: string) => [...workspaceItemKeys.details(), id] as const,
};

export function useCreateWorkspaceItem() {
  const queryClient = useQueryClient();
  return useMutation<WorksapceItemDto, AxiosError, CreateWorkspaceItemDto>({
    mutationFn: async (values) => {
      return (await apiClient.createWorkspaceItem(values)).data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: workspaceItemKeys.lists(),
      });
    },
  });
}

const workspaceDocumentKeys = {
  all: ["workspace_documents"] as const,
  lists: () => [...workspaceDocumentKeys.all, "list"] as const,
  list: (filters: { workspaceItemId: string }) =>
    [...workspaceDocumentKeys.lists(), filters] as const,
  details: () => [...workspaceDocumentKeys.all, "detail"] as const,
  detail: (id: string) => [...workspaceDocumentKeys.details(), id] as const,
};

export function useCreateWorkspaceDocument() {
  const queryClient = useQueryClient();
  return useMutation<
    WorkspaceDocumentDto,
    AxiosError,
    CreateWorkspaceDocumentDto
  >({
    mutationFn: async (values) => {
      return (await apiClient.createWorkspaceDocument(values)).data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: workspaceDocumentKeys.lists(),
      });
    },
  });
}

export function useWorkspaceDocuments({
  workspaceItemId,
}: {
  workspaceItemId: string;
}) {
  return useQuery({
    queryKey: workspaceDocumentKeys.list({ workspaceItemId }),
    queryFn: async () => {
      return (await apiClient.findWorkspaceDocuments({ workspaceItemId })).data;
    },
  });
}

export function useUpdateWorkspaceDocument() {
  const queryClient = useQueryClient();
  return useMutation<
    WorkspaceDocumentDto,
    AxiosError,
    UpdateWorkspaceDocumentDto & { workspaceDocumentId: string }
  >({
    mutationFn: async ({ workspaceDocumentId, ...data }) => {
      return (
        await apiClient.patchWorkspaceDocument({ workspaceDocumentId }, data)
      ).data;
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: workspaceDocumentKeys.detail(data.id),
      });
    },
  });
}

export function useRemoteResetWrorkspaceDocument() {
  const queryClient = useQueryClient();
  return useMutation<
    WorkspaceDocumentDto,
    AxiosError,
    { workspaceDocumentId: string }
  >({
    mutationFn: async ({ workspaceDocumentId }) => {
      return (
        await apiClient.resetWorkspaceDocumentToRemoteState({
          workspaceDocumentId,
        })
      ).data;
    },
    onSuccess: async (_, { workspaceDocumentId }) => {
      await queryClient.invalidateQueries({
        queryKey: workspaceDocumentKeys.detail(workspaceDocumentId),
      });
    },
  });
}

export const workspaceDocumentDraftKeys = {
  all: ["workspace_document-drafts"] as const,
  lists: () => [...workspaceDocumentDraftKeys.all, "list"] as const,
  list: (filters: {
    workspaceDocumentId: string;
    status: WorkspaceDocumentDraftStatus | "open+locked";
  }) => [...workspaceDocumentDraftKeys.lists(), filters] as const,
  details: () => [...workspaceDocumentDraftKeys.all, "detail"] as const,
  detail: (id: string) =>
    [...workspaceDocumentDraftKeys.details(), id] as const,
};

export function useUpdateWorkspaceDocumentDraft() {
  const queryClient = useQueryClient();
  return useMutation<
    WorkspaceDocumentDraftDto,
    AxiosError,
    WorkspaceDocumentDraftPatchDto & { workspaceDocumentDraftId: string }
  >({
    mutationFn: async ({ workspaceDocumentDraftId, ...data }) => {
      return (
        await apiClient.patchWorkspaceDocumentDraft(
          { workspaceDocumentDraftId },
          data
        )
      ).data;
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: workspaceDocumentDraftKeys.detail(data.id),
      });
    },
  });
}

export function useCheckWorkspaceDocumentDraftStatusManually() {
  const queryClient = useQueryClient();
  return useMutation<
    WorkspaceDocumentDraftDto,
    AxiosError,
    { workspaceDocumentDraftId: string }
  >({
    mutationFn: async ({ workspaceDocumentDraftId }) => {
      const res = (
        await apiClient.checkWorkspaceDocumentDraftStatus({
          workspaceDocumentDraftId,
        })
      ).data;
      await sleep(4000);
      return res;
    },
    onSuccess: async (_, { workspaceDocumentDraftId }) => {
      await queryClient.invalidateQueries({
        queryKey: workspaceDocumentDraftKeys.detail(workspaceDocumentDraftId),
      });
      await queryClient.invalidateQueries({
        queryKey: documentDraftPullRequestsKeys.list({
          workspaceDocumentDraftId,
        }),
      });
    },
  });
}

export function useWorkspaceDocumentDrafts({
  workspaceDocumentId,
  status,
}: {
  workspaceDocumentId: string;
  status: WorkspaceDocumentDraftStatus;
}) {
  return useQuery({
    queryKey: [
      workspaceDocumentDraftKeys.list({ workspaceDocumentId, status }),
    ],
    queryFn: async () => {
      return (
        await apiClient.findWorkspaceDocumentDraftsByStatus({
          workspaceDocumentId,
          status,
        })
      ).data;
    },
  });
}

export const documentDraftQueryOptions = ({
  workspaceDocumentDraftId,
  refetchOnWindowFocus,
}: {
  workspaceDocumentDraftId: string;
  refetchOnWindowFocus?: boolean;
}) =>
  queryOptions({
    queryKey: workspaceDocumentDraftKeys.detail(workspaceDocumentDraftId),
    queryFn: async () => {
      return (
        await apiClient.findWorkspaceDocumentDraft({ workspaceDocumentDraftId })
      ).data;
    },
    refetchOnWindowFocus: refetchOnWindowFocus,
  });

export function useWorkspaceDocumentDraft({
  workspaceDocumentDraftId,
  refetchOnWindowFocus,
}: {
  workspaceDocumentDraftId: string;
  refetchOnWindowFocus?: boolean;
}) {
  return useQuery(
    documentDraftQueryOptions({
      workspaceDocumentDraftId,
      refetchOnWindowFocus,
    })
  );
}

export function useSubmitWorkspaceDocumentDraft() {
  const queryClient = useQueryClient();
  return useMutation<
    WorkspaceDocumentDraftDto,
    AxiosError,
    {
      workspaceDocumentDraftId: string;
    }
  >({
    mutationFn: async ({ workspaceDocumentDraftId }) => {
      return (
        await apiClient.submitWorkspaceDocumentDraft({
          workspaceDocumentDraftId,
        })
      ).data;
    },
    onSuccess: async (_, { workspaceDocumentDraftId }) => {
      await queryClient.invalidateQueries({
        queryKey: workspaceDocumentDraftKeys.detail(workspaceDocumentDraftId),
      });
      await queryClient.invalidateQueries({
        queryKey: workspaceDocumentDraftKeys.lists(),
      });
      await queryClient.invalidateQueries({
        queryKey: documentDraftPullRequestsKeys.list({
          workspaceDocumentDraftId,
        }),
      });
    },
  });
}

export function useCreateWorkspaceDocumentDraft() {
  const queryClient = useQueryClient();
  return useMutation<
    WorkspaceDocumentDraftDto,
    AxiosError,
    WorkspaceDocumentDraftCreateDto
  >({
    mutationFn: async (workspaceDocumentDraftCreateDto) => {
      return (
        await apiClient.createWorkspaceDocumentDraft(
          workspaceDocumentDraftCreateDto
        )
      ).data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: workspaceDocumentDraftKeys.lists(),
      });
    },
  });
}

export function useDeleteWorkspaceDocumentDraft() {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, { workspaceDocumentDraftId: string }>({
    mutationFn: async ({ workspaceDocumentDraftId }) => {
      return (
        await apiClient.deleteWorkspaceDocumentDraft({
          workspaceDocumentDraftId,
        })
      ).data;
    },
    onSuccess: async (_, { workspaceDocumentDraftId }) => {
      await queryClient.invalidateQueries({
        queryKey: workspaceDocumentDraftKeys.lists(),
      });
      await queryClient.invalidateQueries({
        queryKey: workspaceDocumentDraftKeys.detail(workspaceDocumentDraftId),
      });
    },
  });
}

export function useUnlockWorkspaceDocumentDraft() {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, { workspaceDocumentDraftId: string }>({
    mutationFn: async ({ workspaceDocumentDraftId }) => {
      return (
        await apiClient.unlockWorkspaceDocumentDraft({
          workspaceDocumentDraftId,
        })
      ).data;
    },
    onSuccess: async (_, { workspaceDocumentDraftId }) => {
      await queryClient.invalidateQueries({
        queryKey: workspaceDocumentDraftKeys.detail(workspaceDocumentDraftId),
      });
      await queryClient.invalidateQueries({
        queryKey: workspaceDocumentDraftKeys.lists(),
      });
      await queryClient.invalidateQueries({
        queryKey: documentDraftPullRequestsKeys.list({
          workspaceDocumentDraftId,
        }),
      });
    },
  });
}

const documentDraftPullRequestsKeys = {
  all: ["workspace_document-drafts"] as const,
  lists: () => [...documentDraftPullRequestsKeys.all, "list"] as const,
  list: (filters: { workspaceDocumentDraftId: string }) =>
    [...documentDraftPullRequestsKeys.lists(), filters] as const,
  details: () => [...documentDraftPullRequestsKeys.all, "detail"] as const,
  detail: (pullRequestId: string) =>
    [...documentDraftPullRequestsKeys.details(), pullRequestId] as const,
};

export const documentDraftPullRequestOptions = ({
  workspaceDocumentDraftId,
}: {
  workspaceDocumentDraftId: string;
}) =>
  queryOptions({
    queryKey: documentDraftPullRequestsKeys.list({ workspaceDocumentDraftId }),
    queryFn: async () => {
      return (
        await apiClient.findWorkspaceDocumentDraftPullRequests({
          workspaceDocumentDraftId,
        })
      ).data;
    },
  });

export function useDocumentDraftPullRequests({
  workspaceDocumentDraftId,
}: {
  workspaceDocumentDraftId: string;
}) {
  return useQuery(
    documentDraftPullRequestOptions({ workspaceDocumentDraftId })
  );
}

export function useCloneWorkspace() {
  const queryClient = useQueryClient();
  return useMutation<WorkspaceDto, AxiosError, { workspaceId: string }>({
    mutationFn: async ({ workspaceId }) => {
      return (await apiClient.cloneWorkspace({ workspaceId })).data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: workspaceKeys.lists() });
      await queryClient.invalidateQueries({
        queryKey: userWorkspaceKeys.list(),
      });
    },
  });
}

// Utility that procues a workspace with an item and a document
export function useCreateFullWorkspace() {
  const workspaceCreateMutation = useCreateWorkspace();
  const workspaceItemCreateMutation = useCreateWorkspaceItem();
  const workspaceDocumentCreateMutation = useCreateWorkspaceDocument();

  const mutate = useCallback(
    ({
      createWorkspaceDto,
      workspaceItemDto,
      workspaceDocumentDto,
      onSuccess,
    }: {
      createWorkspaceDto: CreateWorkspaceDto;
      workspaceItemDto?: Partial<CreateWorkspaceItemDto>;
      workspaceDocumentDto?: Partial<CreateWorkspaceDocumentDto>;
      onSuccess?: () => void;
    }) => {
      workspaceCreateMutation.mutate(createWorkspaceDto, {
        onSuccess: (workspaceData) => {
          workspaceItemCreateMutation.mutate(
            {
              name: createWorkspaceDto.name,
              organization_id: workspaceData.organization_id,
              workspace_id: workspaceData.id,
              ...workspaceItemDto,
            },
            {
              onSuccess: (workspaceItemData) => {
                workspaceDocumentCreateMutation.mutate(
                  {
                    workspace_id: workspaceItemData.workspace_id,
                    organization_id: workspaceItemData.organization_id,
                    data: parse(emptyWorkspaceExample) as OASDefinition,
                    document_type: "oas_api_3_1",
                    workspace_item_id: workspaceItemData.id,
                    ...workspaceDocumentDto,
                  },
                  {
                    onSuccess: () => {
                      onSuccess?.();
                    },
                  }
                );
              },
            }
          );
        },
      });
    },
    [
      workspaceCreateMutation,
      workspaceItemCreateMutation,
      workspaceDocumentCreateMutation,
    ]
  );

  return {
    mutate,
    isPending:
      workspaceCreateMutation.isPending ||
      workspaceItemCreateMutation.isPending ||
      workspaceDocumentCreateMutation.isPending,
    error:
      workspaceCreateMutation.error ||
      workspaceItemCreateMutation.error ||
      workspaceDocumentCreateMutation.error,
    isSucces:
      workspaceCreateMutation.isSuccess &&
      workspaceItemCreateMutation.isSuccess &&
      workspaceDocumentCreateMutation.isSuccess,
    data: {
      workspace: workspaceCreateMutation.data,
      workspaceItem: workspaceItemCreateMutation.data,
      workspaceDocument: workspaceDocumentCreateMutation.data,
    },
  };
}
