import { Button } from "@/components/ui/button";
import { MoveLeft } from "lucide-react";
import { ComponentProps, PropsWithChildren } from "react";

interface ContentHeaderProps {}

export function ActionBarContentHeader({
  children,
}: PropsWithChildren<ContentHeaderProps>) {
  return <div className="p-2 text-muted-foreground">{children}</div>;
}

interface ContentHeaderBackButtonProps extends ComponentProps<"button"> {}

export function ActionBarHeaderBackButton({
  ...rest
}: ContentHeaderBackButtonProps) {
  return (
    <Button
      size="icon"
      variant="ghost"
      className="text-gray-300 focus:ring-0 mr-1"
      {...rest}
    >
      <MoveLeft size={12} />
    </Button>
  );
}
