import {
  findOperationByIdOrErrorFromDefinition,
  findOperationsWithInfoInDefinition,
} from "@/lib/editor-mutations/oas-operations";
import { NoRefsHereError } from "@/lib/errors";
import {
  getSecurityRequirementObjectValues,
  securitySchemeTemplateMap,
} from "@/lib/oas-tools/oas-schema-utils";
import { isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { OpenAPIV3_1 } from "@/lib/oas-tools/openapi-types";
import {
  OASDefinition,
  OASSecurityRequirementDocument,
  OASSecuritySchemeObject,
  securitySchemeEasierTypes,
} from "@/lib/types";
import { typedIncludes } from "@/lib/utils";
import isEmpty from "lodash/isEmpty";
import setWith from "lodash/setWith";

export function findSecuritySchmesInDefinition({
  definition,
}: {
  definition: OASDefinition;
}): Record<string, OASSecuritySchemeObject> {
  const componentSecuritySchemes = definition.components?.securitySchemes || {};
  if (Object.values(componentSecuritySchemes).some(isReference))
    throw new NoRefsHereError();
  return componentSecuritySchemes as Record<string, OASSecuritySchemeObject>;
}

export function findSecuritySchemeInDefinition({
  definition,
  schemeName,
}: {
  definition: OASDefinition;
  schemeName: string;
}) {
  const componentSchmes = findSecuritySchmesInDefinition({ definition });
  return componentSchmes[schemeName];
}

export function createSecuritySchemeComponentInDefinition({
  definition,
  securityScheme,
  schemeName,
}: {
  definition: OASDefinition;
  securityScheme: OASSecuritySchemeObject;
  schemeName: string;
}) {
  if (definition.components?.securitySchemes?.[schemeName]) {
    throw new Error(
      "Conflict: You already have a security method of this kind."
    );
  }
  const cp = structuredClone(definition);
  setWith(
    cp,
    `components.securitySchemes.${schemeName}`,
    securityScheme,
    Object
  );

  return cp;
}

export function findOperationSecuritySchemeNamesInDefinition({
  operationId,
  definition,
}: {
  definition: OASDefinition;
  operationId: string;
}) {
  const operation = findOperationByIdOrErrorFromDefinition(
    definition,
    operationId
  );

  return (
    (operation.security || [])
      // TODO: empty security schemas are allowed [{}]
      // They simply declare the endpoint as public
      // by filtering we treat empty objects like they don't exist at all
      // but the UI should probably point out that these endpoints are public
      .filter((e) => !isEmpty(e))
      .map((reqObj) => {
        const { schemeName } = getSecurityRequirementObjectValues(reqObj);
        return schemeName;
      })
  );
}

export function addSecuritySchemesOrTemplatesToOperationInDefinition({
  definition,
  operationId,
  requirementsObjectArr,
}: {
  definition: OASDefinition;
  requirementsObjectArr: OASSecurityRequirementDocument[];
  operationId: string;
}) {
  const cp = structuredClone(definition);

  const foundOperation = findOperationByIdOrErrorFromDefinition(
    cp,
    operationId
  );

  foundOperation.security = [];

  requirementsObjectArr.forEach((reqObj) => {
    // special case for when the object is empty and it need to be set public
    if (isEmpty(reqObj)) {
      return foundOperation.security?.push({});
    }

    const { schemeName } = getSecurityRequirementObjectValues(reqObj);
    const found = findSecuritySchemeInDefinition({
      // TODO: This can contain multiple security values to combine multiple auth schemes
      definition,
      schemeName: schemeName,
    });

    if (!found) {
      if (typedIncludes(securitySchemeEasierTypes, schemeName)) {
        const scheme = securitySchemeTemplateMap[schemeName];
        setWith(cp, `components.securitySchemes.${schemeName}`, scheme, Object);
      } else {
        throw new Error(
          "Not Allowed: Cannot add a SecurityScheme that does not exist."
        );
      }
    }

    return foundOperation.security?.push(reqObj);
  });

  return cp;
}

export function removeSecuritySchemeComponentFromDefinition({
  definition,
  schemeName,
}: {
  definition: OASDefinition;
  schemeName: string;
}) {
  const found = definition.components?.securitySchemes?.[schemeName];

  if (!found) throw new Error("Not found: Security scheme with this name");

  const cp = structuredClone(definition);

  delete cp.components?.securitySchemes?.[schemeName];

  const operations = findOperationsWithInfoInDefinition(cp);

  operations.forEach((operation) => {
    if (!operation.operation.security) return;
    const newSecurityArr: OpenAPIV3_1.SecurityRequirementObject[] = [];
    operation.operation.security.forEach((e) => {
      if (!e[schemeName]) newSecurityArr.push(e);
    });

    // clean up empty array
    if (newSecurityArr.length) {
      operation.operation.security = newSecurityArr;
    } else {
      delete operation.operation.security;
    }
  });

  return cp;
}

export function updateSecuritySchemeInDefinition({
  definition,
  oldSchemeName,
  newSchemeName,
  newScheme,
}: {
  definition: OASDefinition;
  oldSchemeName: string;
  newSchemeName: string;
  newScheme: OASSecuritySchemeObject;
}) {
  const oldFound = definition.components?.securitySchemes?.[oldSchemeName];

  if (!oldFound) throw new Error("Not found: Security scheme with this name");

  const cp = structuredClone(definition);

  if (oldSchemeName !== newSchemeName) {
    const newFound = definition.components?.securitySchemes?.[newSchemeName];
    if (newFound) {
      throw new Error(
        "Conflict: You already have an authentication method of this kind"
      );
    }

    // Update references in operations
    const operations = findOperationsWithInfoInDefinition(cp);

    operations.forEach((operation) => {
      if (!operation.operation.security) return;
      const newSecurityArr: OpenAPIV3_1.SecurityRequirementObject[] = [];
      operation.operation.security.forEach((e) => {
        if (!e[oldSchemeName]) {
          newSecurityArr.push(e);
        } else {
          newSecurityArr.push({ [newSchemeName]: e[oldSchemeName] });
        }
      });
      operation.operation.security = newSecurityArr;
    });
  }

  delete cp.components?.securitySchemes?.[oldSchemeName];

  setWith(cp, `components.securitySchemes.${newSchemeName}`, newScheme, Object);

  return cp;
}
