import React, { forwardRef, useState } from "react";
import MDEditor, { ICommand, commands } from "@uiw/react-md-editor";

const toolbarCommands: ICommand[] = [
  commands.bold,
  commands.italic,
  commands.strikethrough,
  commands.link,
  commands.quote,
  commands.code,
  commands.unorderedListCommand,
  commands.orderedListCommand,
  commands.image,
];

interface LazyMdEditorProps {
  value?: string;
  onChange?: (value?: string) => void;
  height: number;
}
// This editor was opted-out of lazy loading since chunks are invalidated when they are deployed.
// This has caused errors which some users. We can still lazy load and try to eagerly (useEffect((...)))
// preload the chunk here but we're not doing this for now
const LazyMdEditor: React.FC<LazyMdEditorProps> = forwardRef<
  HTMLDivElement,
  LazyMdEditorProps
>(({ value, onChange, height }, ref) => {
  const [v, setValue] = useState(value);

  const handleChange = (newValue?: string) => {
    setValue(newValue || "");
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <MDEditor
      ref={ref}
      value={v}
      onChange={handleChange}
      preview="edit"
      commands={toolbarCommands}
      extraCommands={[]}
      className="text-primary border-input border-1"
      style={{
        backgroundColor: "transparent",
      }}
      height={height}
      visibleDragbar={false}
      textareaProps={{
        placeholder: "Enter your markdown here...",
      }}
    />
  );
});

export default LazyMdEditor;
