import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { ButtonLoading } from "@/components/button-loading";
import { FormLabelRequired } from "@/components/form-label-required";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { FieldVStack } from "@/forms";
import { usePatchOrganization } from "@/queries/organizations";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import { useForm, type SubmitHandler } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  name: z.string(),
  slug: z.string(),
});

type FormValues = z.infer<typeof formSchema>;

function BaseForm({
  form,
  onSubmit,
  isLoading,
}: {
  form: ReturnType<typeof useForm<FormValues>>;
  onSubmit: SubmitHandler<FormValues>;
  isLoading?: boolean;
}) {
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FieldVStack>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Name
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="slug"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Slug
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </FieldVStack>
        <BtnGroup className="pt-4 justify-end">
          <ButtonLoading type="submit" isLoading={isLoading}>
            Save
          </ButtonLoading>
        </BtnGroup>
      </form>
    </Form>
  );
}

export function PatchOrganizationForm({
  defaultValues,
  organizationId,
}: {
  defaultValues: Partial<FormValues>;
  organizationId: string;
}) {
  const navigate = useNavigate();
  const patchOrganizationMutation = usePatchOrganization();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    try {
      const response = await patchOrganizationMutation.mutateAsync({
        ...values,
        organizationId,
      });
      toast.success("Saved successfully");
      await navigate({
        from: "/dash/$organizationSlug/home",
        to: "/dash/$organizationSlug/home",
        params: { organizationSlug: response.slug },
      });
    } catch {
      form.setError("slug", {
        message:
          "Slugs must be globally unique, lowercase, and can only include '-' as a special character. Please be creative!",
      });
    }
  };

  return (
    <BaseForm
      form={form}
      onSubmit={onSubmit}
      isLoading={patchOrganizationMutation.isPending}
    />
  );
}
