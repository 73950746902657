import { OpenAPIV3_1 } from "@/lib/oas-tools/openapi-types";
import { SubmitHandler } from "react-hook-form";
import { supportedHttpVerbs } from "./helpers";

export interface KeyKombo {
  keys: string[];
  ctrlKey?: boolean;
}
export type ParameterPosition = "cookie" | "header" | "query" | "path";
export type SupportedHTTPVerbs = (typeof supportedHttpVerbs)[number];
export const supportedContentFormats = [
  "application/json",
  "text/html",
  "text/plain",
  "application/x-www-form-urlencoded",
  "multipart/form-data",
  "text/yaml",
  "text/xml",
  "image/png",
  "image/jpeg",
  "image/svg+xml",
  "image/webp",
  "image/avif",
  "audio/mpeg", // Common for MP3
  "audio/wav", // WAV format
  "video/mp4", // Widely supported video format
  "video/webm", // Common for modern browsers
  "application/pdf",
  "application/octet-stream",
] as const;
export type SupportedContentFormats = (typeof supportedContentFormats)[number];
export type SupportedSerializationStyles =
  | "matrix"
  | "label"
  | "form"
  | "simple"
  | "spaceDelimited"
  | "pipeDelimited"
  | "deepObject";

export const supportedSecuritySchemeTypes = ["apiKey", "http"] as const;
export type SupportedSecuritySchemeTypes = typeof supportedSecuritySchemeTypes;

export const securitySchemeEasierTypes = [
  "BearerJwt",
  "BearerBasic",
  "BearerCustom",
  "ApiKey",
] as const;

export type SecuritySchemeEasierType =
  (typeof securitySchemeEasierTypes)[number];

export type Prettyfy<T> = { [K in keyof T]: T[K] } & {};

export type OASDefinition = OpenAPIV3_1.Document;
export type OASPath = OpenAPIV3_1.PathItemObject;
export type OASSecurityRequirementDocument =
  OpenAPIV3_1.SecurityRequirementObject;
export type OASOperation = OpenAPIV3_1.OperationObject;
export type OASResponseObject = OpenAPIV3_1.ResponseObject;
export type OASContentObject = { [media: string]: OpenAPIV3_1.MediaTypeObject };
export type OASResponsesObject = OpenAPIV3_1.ResponsesObject;
export type OASRequestBodyObject = OpenAPIV3_1.RequestBodyObject;
export type OASResponse = OpenAPIV3_1.ResponseObject | OASReferenceObject;
export type OASSchema = OpenAPIV3_1.SchemaObject;
export type OASSecuritySchemeObject = OpenAPIV3_1.SecuritySchemeObject;
export type OASNonArraySchemaObject = OpenAPIV3_1.NonArraySchemaObject;
export type OASParameterObject = OpenAPIV3_1.ParameterObject;
export type OASParameter = OpenAPIV3_1.ParameterObject | OASReferenceObject;
export type OASTag = OpenAPIV3_1.TagObject;
export type OASReferenceObject = OpenAPIV3_1.ReferenceObject;
export type OASArraySchemaObject = OpenAPIV3_1.ArraySchemaObject;
export type OASComponentsObject = OpenAPIV3_1.ComponentsObject;
export type OASComponentsResponses = NonNullable<
  OASComponentsObject["responses"]
>;
export type OASMixedSchemaObject = OpenAPIV3_1.MixedSchemaObject;
export type OASServer = OpenAPIV3_1.ServerObject;

export type StandardFormProps<
  TOutput extends object,
  TInput extends object = TOutput,
> = {
  onSubmit: SubmitHandler<TOutput>;
  isDisabled?: boolean;
  defaultValues: Partial<TInput>;
  btnTitle: string;
  title: string;
};

export type WizardFormProps<
  TOutput extends object,
  TInput extends object = TOutput,
> = StandardFormProps<TOutput, TInput> & {
  onPreviousPage?: () => unknown;
};

export type StandardDialogProps = {
  isOpen: boolean;
  onClose: () => unknown;
};

export type CRUDOperation =
  | {
      method: "get";
      level: "detail";
    }
  | {
      method: "get";
      level: "list";
    }
  | {
      method: "delete";
      level: "detail";
    }
  | {
      method: "put";
      level: "detail";
    }
  | {
      method: "patch";
      level: "detail";
    }
  | {
      method: "post";
      level: "list";
    };

export type CRUDOperationMeta = {
  name: string;
  description: string;
};

export type TemplateCRUDOperation = Exclude<
  CRUDOperation,
  { method: "delete" } | { level: "detail"; method: "get" }
>;
