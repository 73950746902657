import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { FormLabelRequired } from "@/components/form-label-required";
import { MdTextarea } from "@/components/md-textarea";
import { ActionBarReferenceMessage } from "@/components/module-action-bar";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaContentEditor } from "@/components/schema-content-editor";
import { SchemaEditorPreviewContainer } from "@/components/schema-editor-containers";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FieldVStack,
  FormComp,
  FormContent,
  FormHeader,
  FormTitle,
} from "@/forms";
import { useResponses } from "@/hooks/use-responses";
import {
  defaultResponseCodeMap,
  HttpStatus,
  httpStatusMap,
  isSupportedHttpStatus,
  supportedHttpStatusCodes,
} from "@/lib/helpers";
import { httpStatusDescriptions } from "@/lib/oas-tools/http-status-default-description";
import { getDefaultSchemaForResponseCode } from "@/lib/oas-tools/https-status-default-schemas";
import { httpMethodResponseCodes } from "@/lib/oas-tools/https-verb-status-codes";
import {
  getPristineType,
  zodContentObject,
} from "@/lib/oas-tools/oas-schema-utils";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASComponentsObject,
  OASResponse,
  SupportedHTTPVerbs,
} from "@/lib/types";
import { NormIcons, safeGet } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

type Row = { code: string; message: string; isRecommended: boolean };

const formSchema = z.object({
  responseCode: z.string(),
  contentObject: zodContentObject,
  description: z.string().trim().min(1, "Required"),
});

type FormValues = z.infer<typeof formSchema>;

export function FormResponse({
  onSubmit,
  method,
  defaultValues,
  componentsObject,
  isEditing,
  title,
  btnTitle,
  isDisabled = false,
}: {
  onSubmit: (v: FormValues) => unknown;
  method: SupportedHTTPVerbs;
  defaultValues: Partial<FormValues>;
  componentsObject: OASComponentsObject;
  isEditing: boolean;
  isDisabled?: boolean;
  responseName?: string;
  title: string;
  btnTitle: string;
}) {
  const [schemaDirty, setSchemaDirty] = useState(isEditing);
  const defaultResponseCode = defaultResponseCodeMap[method];
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...{
        responseCode: defaultResponseCode,
        schema: getDefaultSchemaForResponseCode(defaultResponseCode),
      },
      ...defaultValues,
    },
  });

  const dirty = form.formState.dirtyFields;

  const schema = form.watch("contentObject");
  const responseCode = form.watch("responseCode");

  const canPaginate =
    method === "get" && +responseCode >= 200 && +responseCode <= 299;

  const rows: Row[] = useMemo(() => {
    const res: Row[] = [];
    const suggestionObject = httpMethodResponseCodes[method];
    const suggestedStatusCodes: HttpStatus[] = [
      ...suggestionObject.custom,
      ...suggestionObject.components,
      ...suggestionObject.authComponents,
    ];

    suggestedStatusCodes.forEach((responseCode) => {
      const value = safeGet(httpStatusMap, responseCode);
      // const description = safeGet(httpStatusDescriptions, responseCode);
      res.push({
        code: responseCode,
        message: value?.message || "",
        isRecommended: true,
      });
    });
    supportedHttpStatusCodes
      .filter((e) => !suggestedStatusCodes.includes(e))
      .forEach((code) => {
        const value = httpStatusMap[code];
        res.push({
          code,
          message: value.message,
          isRecommended: false,
        });
      });
    return res;
  }, [method]);

  return (
    <Form {...form}>
      <FormComp onSubmit={form.handleSubmit(onSubmit)}>
        <FormHeader>
          <FormTitle>{title}</FormTitle>
        </FormHeader>
        <FormContent>
          <FieldVStack>
            <FormField
              control={form.control}
              name="responseCode"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>
                      <FormLabelRequired />
                      Response code
                    </FormLabel>
                    <Select
                      disabled={isEditing || isDisabled}
                      onValueChange={(v) => {
                        field.onChange(v);
                        if (!schemaDirty && isSupportedHttpStatus(v)) {
                          form.setValue("contentObject", {
                            "application/json": {
                              schema: getDefaultSchemaForResponseCode(v),
                            },
                          });
                        }
                        if (!dirty["description"]) {
                          form.setValue(
                            "description",
                            safeGet(httpStatusDescriptions, v)?.description ||
                              ""
                          );
                        }
                      }}
                      defaultValue={String(field.value)}
                    >
                      <FormControl>
                        <SelectTrigger className="w-[180px]">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectGroup>
                          {rows.map((row) => {
                            return (
                              <SelectItem
                                key={`row-${row.code}-${row.isRecommended ? "recommended" : "all-codes"}`}
                                value={`${row.code}`}
                              >
                                <div className="flex items-center">
                                  {row.isRecommended && (
                                    <NormIcons.Recommended
                                      size={16}
                                      className="mr-2 text-brand"
                                    />
                                  )}
                                  {row.code} {row.message}
                                </div>
                              </SelectItem>
                            );
                          })}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Description
                  </FormLabel>
                  <FormControl>
                    <MdTextarea height={130} disabled={isDisabled} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {canPaginate && !isDisabled ? (
              <SchemaEditorPreviewContainer
                title="Schema"
                isDisabled={isDisabled}
                className="h-[230px]"
              >
                <Controller
                  name="contentObject"
                  control={form.control}
                  render={({ field: { onChange, value } }) => (
                    <SchemaContentEditor
                      allowTopLevelReferences={true}
                      componentsObject={componentsObject}
                      isDisabled={isDisabled}
                      value={value}
                      onChange={(schema) => {
                        if (!schemaDirty) setSchemaDirty(true);
                        onChange(schema);
                      }}
                    />
                  )}
                />
              </SchemaEditorPreviewContainer>
            ) : (
              <SchemaEditorPreviewContainer
                title="Schema"
                className="h-[260px]"
                isDisabled={isDisabled}
              >
                <SchemaContentEditor
                  componentsObject={componentsObject}
                  allowTopLevelReferences={true}
                  isDisabled={isDisabled}
                  value={schema}
                  onChange={(contentObject) => {
                    if (!schemaDirty) setSchemaDirty(true);
                    form.setValue("contentObject", contentObject);
                  }}
                />
              </SchemaEditorPreviewContainer>
            )}
          </FieldVStack>
        </FormContent>
        {!isDisabled && (
          <BtnGroup className="justify-end">
            <SubmitButton disabled={isDisabled} aria-disabled={isDisabled}>
              {btnTitle}
            </SubmitButton>
          </BtnGroup>
        )}
      </FormComp>
    </Form>
  );
}

export function FormResponseEdit({
  operationId,
  method,
  response,
  responseCode,
  value,
  onChange,
}: {
  operationId: string;
  method: SupportedHTTPVerbs;
  response: OASResponse;
  responseCode: string;
} & EditorInputProps) {
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { update, dereferenceResponse } = useResponses({ value, onChange });
  const { setActiveElement } = useAPIEditorTools({ value, onChange });

  const [, actionbarDispatch] = useActionBarContext();

  const onSubmit = (values: FormValues, response: OASResponse) => {
    if (isReference(response)) return;
    update({
      newResponseCode: responseCode,
      oldResponseCode: responseCode,
      operationId,
      response: {
        ...response,
        description: values.description || "",
        content: values.contentObject,
      },
    });
    actionbarDispatch({
      type: "CLOSE",
    });
  };

  const isRef = isReference(response);

  const handleVisitResponse = async (responseName: string) => {
    actionbarDispatch({
      type: "CLOSE",
    });
    await setActiveElement({ type: "response", responseName });
  };

  const handleDereference = () => {
    actionbarDispatch({
      type: "CLOSE",
    });
    dereferenceResponse({ operationId, responseCode });
  };

  if (isRef) {
    const responseName = deref(response.$ref);
    return (
      <ActionBarReferenceMessage
        title="Reference"
        description={
          <p>
            This response points to{" "}
            <span
              role="button"
              onClick={() => handleVisitResponse(responseName)}
              className="text-brand underline cursor-pointer"
            >
              {responseName}
            </span>
            . Edit the component or dereference this response.
          </p>
        }
        onVisitReference={() => handleVisitResponse(responseName)}
        onDereference={handleDereference}
      />
    );
  }

  return (
    <FormResponse
      title="Edit response"
      btnTitle="Edit"
      isDisabled={isRef}
      isEditing={true}
      onSubmit={(values) => onSubmit(values, response)}
      method={method}
      componentsObject={componentsObject}
      defaultValues={{
        contentObject: response?.content || {
          "application/json": { schema: getPristineType("object") },
        },
        responseCode,
        description: response.description || `Response ${responseCode}`,
      }}
    />
  );
}

export function FormResponseAdd({
  operationId,
  method,
  value,
  onChange,
}: {
  operationId: string;
  method: SupportedHTTPVerbs;
} & EditorInputProps) {
  const defaultResponseCode = defaultResponseCodeMap[method];
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { addResponse } = useResponses({ value, onChange });
  const [, actionbarDispatch] = useActionBarContext();

  const onSubmit = (values: FormValues) => {
    addResponse({
      operationId,
      responseCode: values.responseCode as HttpStatus,
      response: {
        description: values.description,
        content: values.contentObject,
      },
    });
    actionbarDispatch({
      type: "CLOSE",
    });
  };

  return (
    <FormResponse
      title="Add response"
      btnTitle="Create"
      isEditing={false}
      onSubmit={onSubmit}
      method={method}
      componentsObject={componentsObject}
      defaultValues={{
        contentObject: {
          "application/json": {
            schema: getDefaultSchemaForResponseCode(defaultResponseCode),
          },
        },
        responseCode: defaultResponseCode,
        description:
          httpStatusDescriptions[defaultResponseCode]?.description || "",
      }}
    />
  );
}
