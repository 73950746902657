import { State } from "@/components/contexts/schema-editor-context";
import { SchemaEditor } from "@/components/schema-editor";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useDisclosure } from "@/hooks/use-disclosure";
import { changeFormatInContentObject } from "@/lib/editor-mutations/oas-content-formats";
import {
  convertToReadableContentFormat,
  getPristineType,
} from "@/lib/oas-tools/oas-schema-utils";
import {
  OASComponentsObject,
  OASContentObject,
  OASSchema,
  supportedContentFormats,
  SupportedContentFormats,
} from "@/lib/types";
import { cn, DEFAULT_ICON_SIZE_SM, NormIcons } from "@/lib/utils";
import { Check, Layers } from "lucide-react";
import { ReactNode, useMemo } from "react";

function FormatButton({
  activeFormat,
  onChange,
  disabled,
}: {
  onChange: (format: SupportedContentFormats) => unknown;
  activeFormat: SupportedContentFormats;
  disabled?: boolean;
}) {
  const popoverDisclosure = useDisclosure();
  return (
    <Popover
      open={popoverDisclosure.isOpen}
      onOpenChange={popoverDisclosure.onOpenChange}
      modal={true}
    >
      <PopoverTrigger asChild>
        <Button
          onClick={popoverDisclosure.onToggle}
          key={activeFormat}
          variant="link"
          size="link"
          className={cn(
            "flex pt-0 pb-0 text-xs cursor-pointer font-semibold h-[1.5rem]",
            {
              underline: !disabled,
            }
          )}
          disabled={disabled}
        >
          {convertToReadableContentFormat(activeFormat)}

          {!disabled && (
            <NormIcons.Expand
              size={DEFAULT_ICON_SIZE_SM}
              className="shrink-0 grow-0 ml-1"
            />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search formats..." />
          <CommandList>
            <CommandEmpty>No framework found.</CommandEmpty>
            <CommandGroup>
              {supportedContentFormats.map((format) => (
                <CommandItem
                  key={format}
                  value={format}
                  onSelect={(currentValue) => {
                    onChange(currentValue as SupportedContentFormats);
                    popoverDisclosure.onClose();
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      activeFormat === format ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {convertToReadableContentFormat(format)}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

type OnChange = (s: OASContentObject) => unknown;

interface ContentObjectEditorProps {
  value: OASContentObject;
  onChange: OnChange;
  isDisabled?: boolean; // editor should be grayed out and signal that this shema cannot be edited
  isReadOnly?: boolean; // editor should be locked but can be unlocked
  isModelsDisabled?: boolean;
  title?: string;
  description?: string;
  initialState?: Partial<State>;
  componentsObject: OASComponentsObject;
  disableRootSchemaRemove?: boolean;
  allowTopLevelReferences: boolean;
  toolbar?: ReactNode;
}

export const SchemaContentEditor = ({
  title,
  description,
  allowTopLevelReferences,
  value,
  onChange,
  disableRootSchemaRemove,
  isModelsDisabled,
  isDisabled,
  isReadOnly,
  initialState,
  componentsObject,
  toolbar,
}: ContentObjectEditorProps) => {
  const existingFormats = useMemo(() => {
    const formats = Object.keys(value);
    return (
      formats.length ? formats : ["application/json"]
    ) as SupportedContentFormats[];
  }, [value]);

  const format = useMemo(
    () => (existingFormats.length ? existingFormats[0] : "application/json"),
    [existingFormats]
  );

  const handleChange = (schema: OASSchema) => {
    onChange({
      ...value,
      [format]: {
        ...value[format],
        schema,
      },
    });
  };

  const handleChangeExistingFormat = ({
    oldFormat,
    newFormat,
  }: {
    oldFormat: SupportedContentFormats;
    newFormat: SupportedContentFormats;
  }) => {
    const newContentObject = changeFormatInContentObject({
      contentObject: value,
      oldFormat,
      newFormat,
    });
    onChange(newContentObject);
  };

  return (
    <div>
      <div className="flex justify-start items-center gap-0 pb-2 -translate-x-2">
        {toolbar ? toolbar : <span />}

        <div
          className={cn(
            "flex gap-1 items-center pl-1 border-input translate-y-[2px]"
          )}
        >
          <Layers size={10} />
          <span className="text-muted-foreground text-xs">Format:</span>
          {existingFormats.map((e) => (
            <FormatButton
              key={e}
              activeFormat={e}
              disabled={isReadOnly || isDisabled}
              onChange={(newFormat: SupportedContentFormats) =>
                handleChangeExistingFormat({ newFormat, oldFormat: e })
              }
            />
          ))}
        </div>
      </div>
      <SchemaEditor
        value={value?.[format].schema || getPristineType("object")}
        onChange={handleChange}
        onRemoveRootSchema={() => handleChange(getPristineType("object"))}
        componentsObject={componentsObject}
        allowTopLevelReferences={allowTopLevelReferences}
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
        initialState={initialState}
        title={title}
        description={description}
        isModelsDisabled={isModelsDisabled}
        disableRootSchemaRemove={disableRootSchemaRemove}
      />
    </div>
  );
};
