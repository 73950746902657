import {
  getTypeOptions,
  simplePropertyTypes,
  TypeOptions,
} from "@/lib/oas-tools/oas-schema-utils";
import {
  findComposedKey,
  isComposedSchema,
  isReferenceSchema,
} from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASArraySchemaObject,
  OASComponentsObject,
  OASMixedSchemaObject,
  OASNonArraySchemaObject,
  OASReferenceObject,
  OASSchema,
} from "@/lib/types";
import { globalVariables } from "../const";
import * as Sentry from "@sentry/react";
import isEmpty from "lodash/isEmpty";

export const propertyTypes = [
  ...simplePropertyTypes,
  "array",
  "object",
] as const;
export type PropertyTypes = (typeof propertyTypes)[number];

export const composedPropertyTypes = ["allOf", "anyOf", "oneOf"] as const;
export type ComposedPropertyTypes = (typeof composedPropertyTypes)[number];

export const allPropertyTypes = [
  ...propertyTypes,
  ...composedPropertyTypes,
] as const;
export type AllPropertyTypes = (typeof allPropertyTypes)[number];

export type OASSchemaRowType =
  | "reference-row"
  | "mixed-row"
  | "composed-row"
  | "simple-row"
  | "array-row"
  | "object-row"
  | "any-row"
  | "unsupported-row";

export type OASSchemaRow = {
  kind: "oas-schema-row";
  typeOptions: TypeOptions; // What types can this row be changed to
  onDelete?: () => unknown;
  rowType: OASSchemaRowType;
  schema: OASSchema | OASReferenceObject;
  level: number;
  propertyName?: string;
  path: string;
  isRequired: boolean;
  /**
   * In some cases we hide the required toggle. We also disallow users from using the
   * required toggle.
   *
   * Example: jobs = allOf<object<Value1> OR array<Value1>>. Jobs is required + inherits
   * required tag to its subrows. However, showing the required badge on subrows is confusing.
   */
  hideRequiredToggle: boolean;
  isRemovable: boolean;
  rootSchema: OASSchema;
  isPlaceholder: boolean;
  isDisabled: boolean;
  isReadOnly: boolean;
  extraInsetLevel: number;
  prefix: string;
};

type OASSchemaRowConfig<T extends OASSchema = OASSchema> = {
  kind: "oas-schema-row";
  schema: T;
  level: number;
  propertyName?: string;
  path: string;
  isRequired: boolean;
  /**
   * In some cases we hide the required toggle. We also disallow users from using the
   * required toggle.
   *
   * Example: jobs = allOf<object<Value1> OR array<Value1>>. Jobs is required + inherits
   * required tag to its subrows. However, showing the required badge on subrows is confusing.
   */
  hideRequiredToggle: boolean;
  isRemovable: boolean;
  rootSchema: OASSchema;
  isPlaceholder: boolean;
  isDisabled: boolean;
  extraInsetLevel: number;
  parentPrefix?: string; // New field to track parent's prefix
  isLastChild: boolean; // New field to track if this is the last child
};

// Constants for tree visualization
const TREE_VERTICAL = "│  ";
const TREE_CORNER = "└──";
const TREE_TEE = "├──";
const TREE_SPACE = "   ";

function calculatePrefix(config: {
  level: number;
  parentPrefix?: string;
  isLastChild: boolean;
}): string {
  const { level, parentPrefix = "", isLastChild } = config;

  if (level === 0) return "";

  // For first level items
  if (level === 1) {
    return isLastChild ? TREE_CORNER : TREE_TEE;
  }

  // Split parent prefix into segments
  const parentSegments = parentPrefix.match(/.{1,3}/g) || [];

  // Calculate the new segments needed for this level
  const newPrefix = parentSegments
    .map((segment) =>
      segment === TREE_CORNER || segment === TREE_SPACE
        ? TREE_SPACE
        : TREE_VERTICAL
    )
    .join("");

  return newPrefix + (isLastChild ? TREE_CORNER : TREE_TEE);
}

function addPropertyNameToPath(path: string, propertyName?: string) {
  if (path.startsWith(".")) path = path.slice(1);
  if (propertyName && path === "") return `properties.${propertyName}`;
  return propertyName != null ? `${path}.properties.${propertyName}` : path;
}

export function generateOASSchemaRows({
  schema,
  isDisabled,
  initialLevel = 0,
  componentsObject = {},
  options: { allowTopLevelReferences },
  extraInsetLevel = 0,
  isReadOnly,
}: {
  schema: OASSchema;
  isDisabled: boolean;
  isReadOnly: boolean;
  initialLevel: number;
  componentsObject?: OASComponentsObject;
  options: {
    // in most places the editor accepts OASchema & OASReferenceObject/
    // this is not true for all places. e.g. 'components.schemas' only
    // accepts OASSchema. Since the editor does not now what OAS property
    // is edited, we manually have to configure if the top-most object can
    // point to a reference.
    allowTopLevelReferences: boolean;
  };
  extraInsetLevel?: number;
}) {
  const rows: OASSchemaRow[] = [];

  function addReferenceRow({
    level,
    propertyName,
    path,
    schema,
    isDisabled,
    rootSchema,
    parentPrefix,
    isLastChild,
    ...rest
  }: OASSchemaRowConfig<OASReferenceObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const prefix = calculatePrefix({ level, parentPrefix, isLastChild });
    const typeOptions = getTypeOptions({
      rootSchema: rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName: propertyName,
      path: newPath,
      rowType: "reference-row",
      schema: schema,
      typeOptions,
      isDisabled,
      isReadOnly,
      rootSchema,
      prefix,
      ...rest,
    });
  }

  function addComposedRow({
    level,
    propertyName,
    path,
    schema,
    isDisabled,
    rootSchema,
    parentPrefix,
    isLastChild,
    ...rest
  }: OASSchemaRowConfig<OASNonArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const prefix = calculatePrefix({ level, parentPrefix, isLastChild });
    const typeOptions = getTypeOptions({
      rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName,
      path: newPath,
      rowType: "composed-row",
      schema,
      typeOptions,
      rootSchema,
      isDisabled,
      isReadOnly,
      prefix,
      ...rest,
    });
  }

  function addMixedTypeRow({
    level,
    propertyName,
    path,
    schema,
    isDisabled,
    rootSchema,
    parentPrefix,
    isLastChild,
    ...rest
  }: OASSchemaRowConfig<OASMixedSchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const prefix = calculatePrefix({ level, parentPrefix, isLastChild });
    const typeOptions = getTypeOptions({
      rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName,
      path: newPath,
      schema,
      rowType: "mixed-row",
      typeOptions,
      isDisabled,
      isReadOnly,
      rootSchema,
      prefix,
      ...rest,
    });
  }

  function addArrayRow({
    level,
    propertyName,
    path,
    schema,
    isDisabled,
    rootSchema,
    isLastChild,
    parentPrefix,
    ...rest
  }: OASSchemaRowConfig<OASArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const prefix = calculatePrefix({ level, parentPrefix, isLastChild });
    const typeOptions = getTypeOptions({
      rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName,
      path: newPath,
      schema,
      rowType: "array-row",
      typeOptions,
      isDisabled,
      isReadOnly,
      rootSchema,
      prefix,
      ...rest,
    });
  }

  function addObjectRow({
    level,
    propertyName,
    path,
    schema,
    isDisabled,
    rootSchema,
    parentPrefix,
    isLastChild,
    ...rest
  }: OASSchemaRowConfig<OASNonArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const prefix = calculatePrefix({ level, parentPrefix, isLastChild });
    const typeOptions = getTypeOptions({
      rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName,
      path: newPath,
      schema,
      rowType: "object-row",
      typeOptions,
      isDisabled,
      isReadOnly,
      rootSchema,
      prefix,
      ...rest,
    });
  }

  function addSimpleRow({
    level,
    propertyName,
    path,
    schema,
    rootSchema,
    isDisabled,
    isLastChild,
    parentPrefix,
    ...rest
  }: OASSchemaRowConfig<OASNonArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const prefix = calculatePrefix({ level, parentPrefix, isLastChild });
    const typeOptions = getTypeOptions({
      rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName,
      path: newPath,
      schema,
      rowType: "simple-row",
      typeOptions,
      rootSchema,
      isDisabled,
      isReadOnly,
      prefix,
      ...rest,
    });
  }

  function addAnyRow({
    level,
    propertyName,
    path,
    schema,
    rootSchema,
    isDisabled,
    parentPrefix,
    isLastChild,
    ...rest
  }: OASSchemaRowConfig<OASNonArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const prefix = calculatePrefix({ level, parentPrefix, isLastChild });
    const typeOptions = getTypeOptions({
      rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName,
      path: newPath,
      schema,
      rowType: "any-row",
      typeOptions,
      rootSchema,
      isDisabled,
      isReadOnly,
      prefix,
      ...rest,
    });
  }

  function addUnsupportedRow({
    level,
    propertyName,
    path,
    schema,
    rootSchema,
    isDisabled,
    isLastChild,
    parentPrefix,
    ...rest
  }: OASSchemaRowConfig<OASNonArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const prefix = calculatePrefix({ level, parentPrefix, isLastChild });
    const typeOptions = getTypeOptions({
      rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName,
      path: newPath,
      schema,
      rowType: "unsupported-row",
      typeOptions,
      rootSchema,
      isDisabled,
      isReadOnly,
      prefix,
      ...rest,
    });
  }

  function getRequiredFields(schema: OASSchema): string[] {
    return schema.required || [];
  }

  function resolveObjectRows({
    schema,
    level,
    path,
    propertyName,
    isRequired: _isRequired,
    parentPrefix,
    isLastChild,
    ...rest
  }: OASSchemaRowConfig<OASNonArraySchemaObject>) {
    if (!schema.properties) return;
    const requiredFields = getRequiredFields(schema);
    const newPath = addPropertyNameToPath(path, propertyName);
    const entries = Object.entries(schema.properties).sort(([keyA], [keyB]) => {
      if (keyA.startsWith(globalVariables.editorPlaceholderToken)) return 1;
      if (keyA.toLowerCase() === "id" || keyA.toLowerCase() === "uuid")
        return -1;
      return keyA > keyB ? 1 : -1;
    });

    const prefix = calculatePrefix({
      level,
      parentPrefix,
      isLastChild: isLastChild,
    });

    entries.forEach((entry, index) => {
      const [key, value] = entry;
      rootRow({
        propertyName: key,
        level: level + 1,
        schema: value,
        path: newPath,
        isRequired: requiredFields.includes(key),
        parentPrefix: prefix,
        isLastChild: index === entries.length - 1,
        ...rest,
      });
    });
  }

  function resolveArrayRows({
    schema,
    level,
    path,
    propertyName,
    parentPrefix,
    isLastChild,
    ...rest
  }: OASSchemaRowConfig<OASArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const prefix = calculatePrefix({ level, parentPrefix, isLastChild });

    const options = {
      propertyName: undefined, // Array rows cannot have property type
      level: level + 1,
      path: newPath + ".items",
      parentPrefix: prefix,
      isLastChild: true, // Array items are always "last" since there's only one items field
      ...rest,
    };

    if (!schema.items || "$ref" in schema.items) return;

    if (schema.items.oneOf || schema.items.allOf || schema.items.anyOf) {
      resolveComposedRows({
        schema: schema.items as OASNonArraySchemaObject,
        ...options,
      });
      return;
    }

    if (!schema.items.type) {
      throw new Error("Array items have no type");
    }

    if (typeof schema.items.type === "string") {
      if (schema.items.type === "object") {
        resolveObjectRows({
          schema: schema.items,
          ...options,
        });
        return;
      } else if (schema.items.type === "array") {
        resolveArrayRows({
          schema: schema.items,
          ...options,
        });
        return;
      }
    }
  }

  function resolveComposedRows({
    schema,
    level,
    path,
    propertyName,
    hideRequiredToggle: _,
    parentPrefix,
    isLastChild,
    ...rest
  }: OASSchemaRowConfig<OASNonArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const prefix = calculatePrefix({ level, parentPrefix, isLastChild });

    const composedKey = findComposedKey(schema);
    if (!composedKey) throw new Error("Schema is not composed");

    // Get the array of schemas from the composed key (oneOf, allOf, anyOf)
    const schemas = schema[composedKey]!;

    // Process each schema in the composition
    schemas.forEach((nestedSchema, index) => {
      rootRow({
        schema: nestedSchema,
        path: `${newPath}.${composedKey}[${index}]`,
        level: level + 1,
        hideRequiredToggle: true,
        parentPrefix: prefix,
        isLastChild: index === schemas.length - 1, // Last item in the composition
        ...rest,
      });
    });
  }

  function rootRow({
    schema,
    hideRequiredToggle: _hideRequiredToggle,
    ...rest
  }: OASSchemaRowConfig<OASSchema | OASReferenceObject>) {
    // the root object is not removable
    // instead there's a separate action (onRemoveRootRow) that fires
    // when we try to delete the root row
    rest.isRemovable = !rest.path && !rest.propertyName ? false : true;

    // Handle reference schemas
    if (isReferenceSchema(schema)) {
      addReferenceRow({
        schema: schema,
        hideRequiredToggle: rest.propertyName ? false : true,
        ...rest,
      });
      return;
    }

    // Handle composed schemas (allOf, anyOf, oneOf)
    if (isComposedSchema(schema)) {
      addComposedRow({
        schema: schema as OASNonArraySchemaObject,
        hideRequiredToggle: rest.propertyName ? false : true,
        ...rest,
      });
      return resolveComposedRows({
        schema: schema as OASNonArraySchemaObject,
        hideRequiredToggle: true,
        ...rest,
      });
    }

    // Handle schemas without a `type` property by inferring the type
    if (!("type" in schema)) {
      if (schema.properties) {
        schema.type = "object"; // Assume object if properties are present
      } else if ("items" in schema) {
        // this is invalid due to OAS but we can still help people out how forgot to put the type
        // eslint-disable-next-line
        (schema as any).type = "array";
      } else if ("enum" in schema || isEmpty(schema)) {
        addAnyRow({
          schema: schema as OASNonArraySchemaObject,
          ...rest,
          hideRequiredToggle: rest.propertyName ? false : true,
        });
        return;
      }
    }

    // Handle mixed types (array of types)
    if (Array.isArray(schema.type)) {
      addMixedTypeRow({
        schema: schema as OASMixedSchemaObject,
        hideRequiredToggle: rest.propertyName ? false : true,
        ...rest,
      });
      return;
    }

    // Handle different schema types
    switch (schema.type) {
      case "array":
        addArrayRow({
          schema,
          ...rest,
          hideRequiredToggle: rest.propertyName ? false : true,
        });
        resolveArrayRows({
          schema,
          hideRequiredToggle: true,
          ...rest,
        });
        break;
      case "object":
        addObjectRow({
          schema,
          hideRequiredToggle: rest.propertyName ? false : true,
          ...rest,
        });
        resolveObjectRows({
          schema,
          hideRequiredToggle: false,
          ...rest,
        });
        break;
      case "boolean":
      case "integer":
      case "null":
      case "number":
      case "string":
        addSimpleRow({
          schema,
          hideRequiredToggle: rest.propertyName ? false : true,
          ...rest,
        });
        break;
      default:
        addUnsupportedRow({
          schema: schema as OASNonArraySchemaObject,
          hideRequiredToggle: rest.propertyName ? false : true,
          ...rest,
        });
        // Handle unexpected or unhandled schema types
        Sentry.captureException({
          name: "Unsupported schema type",
          schema: JSON.stringify(schema),
        });
        console.error(`Unsupported schema type: ${schema.type}`);
        break;
    }
  }

  rootRow({
    kind: "oas-schema-row",
    schema,
    level: initialLevel,
    path: "",
    isRequired: false,
    hideRequiredToggle: true,
    isRemovable: true,
    rootSchema: schema,
    isPlaceholder: false,
    isDisabled: !!isDisabled,
    extraInsetLevel,
    parentPrefix: "",
    isLastChild: true,
  });

  return rows;
}
