import { H2 } from "@/components/headings";
import {
  ContentHeightContainer,
  ScrollableLayoutContainer,
} from "@/components/layout-right-drawer";
import { PreviewToolbarContainer } from "@/components/module-api-editor";
import {
  ActiveElement,
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import NotFoundAnimation from "@/components/svg-animations/not-found";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { capitalize } from "@/lib/helpers";
import { Link } from "@tanstack/react-router";
import { ReactNode } from "react";

export function EditorPreviewMeta({
  value,
  onChange,
  organizationSlug,
  workspaceSlug,
  isAuthed,
  workspaceRole,
  extraToolbarItems,
  activeElement,
}: {
  organizationSlug?: string;
  workspaceSlug?: string;
  isAuthed: boolean;
  workspaceRole: WorkspaceRole;
  extraToolbarItems: ReactNode;
  activeElement: Extract<ActiveElement, { type: "meta" }>;
} & EditorInputProps) {
  return (
    <div>
      <PreviewToolbarContainer
        workspaceRole={workspaceRole}
        value={value}
        onChange={onChange}
        isAuthed={isAuthed}
        organizationSlug={organizationSlug}
        workspaceSlug={workspaceSlug}
        extraToolbarItems={extraToolbarItems}
      >
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem className="hidden md:block">
              <Link search={(prev) => ({ ...prev, activeElement: undefined })}>
                <BreadcrumbLink asChild>
                  <span>Workspace</span>
                </BreadcrumbLink>
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="hidden md:block" />
            <BreadcrumbItem>
              <BreadcrumbPage>Meta</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </PreviewToolbarContainer>
      <ScrollableLayoutContainer>
        <ContentHeightContainer className="px-4 py-4">
          <div className="h-full w-full bg-secondary rounded-md grid place-items-center">
            <div className="text-center">
              <span className=" inline-block w-[350px] text-brandSoft">
                <NotFoundAnimation />
              </span>
              <div className="-translate-y-12">
                <H2 className="font-bold text-md text-brandSoft">
                  {capitalize(activeElement.info.component)}
                </H2>
                <p className="text-sm text-muted-foreground">
                  Not found in document
                </p>
              </div>
            </div>
          </div>
        </ContentHeightContainer>
      </ScrollableLayoutContainer>
    </div>
  );
}
