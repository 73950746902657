// File: MdTextarea.tsx
import LazyMdEditor from "@/components/md-textarea-lazy-editor";
import React, { forwardRef } from "react";

interface CustomMDEditorProps {
  value?: string;
  onChange?: (value?: string) => void;
  height: number;
}

export const MdTextarea: React.FC<CustomMDEditorProps> = forwardRef<
  HTMLDivElement,
  CustomMDEditorProps
>(({ value, onChange, height = 150 }, ref) => {
  return (
    <div tabIndex={0} ref={ref} className="text-primary">
      <LazyMdEditor value={value} onChange={onChange} height={height} />
    </div>
  );
});
