import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { FormLabelRequired } from "@/components/form-label-required";
import { H4 } from "@/components/headings";
import { ActionBarContent } from "@/components/module-action-bar/action-bar-content";
import {
  ActiveElement,
  EditorInputProps,
} from "@/components/module-api-editor/types";
import {
  BackButton,
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { NormIcon } from "@/components/norm-icon";
import {
  SecondaryCard,
  SecondaryCardContent,
  SecondaryCardHeader,
} from "@/components/secondary-card";
import { Button } from "@/components/ui/button";
import { ColorBadge } from "@/components/ui/color-badge";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { MultiSelect } from "@/components/ui/multi-select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  FieldVStack,
  FormComp,
  FormContent,
  FormFooter,
  FormHeader,
  FormTitle,
} from "@/forms";
import { TagFormValues, TagInlineForm } from "@/forms/form-tag";
import { ComponentSchemaWithInfo, useComponents } from "@/hooks/use-components";
import { useDisclosure } from "@/hooks/use-disclosure";
import { useOperation } from "@/hooks/use-operation";
import { useSecuritySchemes } from "@/hooks/use-security-schemes";
import { useTags } from "@/hooks/use-tags";
import { getOpenAPIOperationDetails } from "@/lib/editor-mutations/oas-misc";
import {
  crudOperations,
  getCrudOperationMeta,
  templateCrudOperations,
} from "@/lib/helpers";
import { getDtoTemplateValues } from "@/lib/oas-tools/dto-templates";
import { appRegex } from "@/lib/regex";
import {
  CRUDOperation,
  OASTag,
  StandardFormProps,
  supportedContentFormats,
  WizardFormProps,
} from "@/lib/types";
import { toastError, toastSuccess } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "@tanstack/react-router";
import { defaults } from "lodash";
import { ChevronDown } from "lucide-react";
import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

// Values for operation
const step1Schema = z.object({
  baseSchemaName: z.string().min(1, "Required"),
  baseUrl: z
    .string()
    .min(1, "Required")
    .regex(appRegex.urlPath.expression, appRegex.urlPath.message),
  parameterName: z
    .string()
    .min(1, "Required")
    .regex(appRegex.parameterName.expression, appRegex.parameterName.message),
  securitySchemes: z.array(z.string()),
  tags: z.array(z.string()),
});
type Step1Values = z.infer<typeof step1Schema>;

const step2Schema = z.object({
  format: z.enum(supportedContentFormats),
  responsePropertyName: z.string(),
});
type Step2Values = z.infer<typeof step2Schema>;

function Step1Form({
  onSubmit,
  btnTitle,
  title,
  isDisabled,
  defaultValues,
  tags,
  securitySchems,
  componentSchemas,
  value,
  onChange,
}: StandardFormProps<Step1Values> & {
  tags: OASTag[];
  securitySchems: string[];
  componentSchemas: ComponentSchemaWithInfo[];
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { create } = useTags({ value, onChange });

  const form = useForm<Step1Values>({
    resolver: zodResolver(step1Schema),
    defaultValues: defaults(defaultValues, {
      tags: [],
      securitySchems: [],
      parameterName: "",
      baseUrl: "",
      baseSchemaName: "",
    }),
  });

  const popoverDisclosure = useDisclosure();

  const handleInlineTagSubmit: SubmitHandler<TagFormValues> = (values) => {
    try {
      create({ name: values.name, description: values.description });
      toastSuccess("Tag created.");
      popoverDisclosure.onClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <Form {...form}>
      <FormComp onSubmit={form.handleSubmit(onSubmit)}>
        <FormHeader>
          <FormTitle>{title}</FormTitle>
          <p className="text-muted-foreground text-sm p2-4">
            Quickly create operations that get, list, create, delete, and update
            records.
          </p>
        </FormHeader>
        <FormContent>
          <FieldVStack>
            <FormField
              control={form.control}
              name="baseSchemaName"
              render={({ field }) => (
                <FormItem className="grow basis-10">
                  <FormLabel>
                    <FormLabelRequired />
                    Base schema
                  </FormLabel>
                  <FormDescription>
                    Operations target this schema.
                  </FormDescription>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {componentSchemas.length === 0 && <p>No schemas found</p>}
                      {componentSchemas.map((schemaWithInfo) => (
                        <SelectItem
                          value={schemaWithInfo.name}
                          key={schemaWithInfo.name}
                        >
                          {schemaWithInfo.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="baseUrl"
              render={({ field }) => (
                <FormItem className="grow basis-10">
                  <FormLabel>
                    <FormLabelRequired />
                    Base URL
                  </FormLabel>
                  <FormDescription>
                    Pase path without parameters.
                  </FormDescription>
                  <FormControl>
                    <Input placeholder="/books" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="parameterName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Parameter name
                  </FormLabel>
                  <FormDescription>
                    Operations targeting one record will use this URL parameter.
                    Ex. GET /book/{"{"}bookId{"}"}.
                  </FormDescription>
                  <FormControl>
                    <Input placeholder="book_id" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="tags"
              render={({ field }) => (
                <FormItem className="grow basis-10">
                  <div className="grid grid-cols-[1fr,max-content]">
                    <FormLabel>Tags</FormLabel>
                    <Popover open={popoverDisclosure.isOpen}>
                      <PopoverTrigger asChild>
                        <Button
                          size="icon-sm"
                          variant="ghost"
                          type="button"
                          onClick={popoverDisclosure.onOpen}
                        >
                          <NormIcon name="Add" size="sm" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent
                        onInteractOutside={popoverDisclosure.onClose}
                      >
                        <TagInlineForm
                          btnTitle="Create"
                          defaultValues={{ description: "", name: "" }}
                          onSubmit={handleInlineTagSubmit}
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                  <MultiSelect
                    value={field.value}
                    badgeColor="muted"
                    onValueChange={field.onChange}
                    options={tags.map((e) => ({
                      label: e.name,
                      value: e.name,
                    }))}
                  />

                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="securitySchemes"
              render={({ field }) => (
                <FormItem className="grow basis-10">
                  <div className="grid grid-cols-[1fr,max-content]">
                    <FormLabel>Security schemes</FormLabel>
                    <Button size="icon-sm" variant="ghost">
                      <Link
                        onClick={() => actionBarDispatch({ type: "CLOSE" })}
                        search={(prev) => ({
                          ...prev,
                          activeElement: {
                            type: "general_information",
                          } satisfies ActiveElement,
                        })}
                      >
                        <NormIcon name="Add" size="sm" />
                      </Link>
                    </Button>
                  </div>
                  <MultiSelect
                    value={field.value}
                    onValueChange={field.onChange}
                    badgeColor="muted"
                    options={securitySchems.map((e) => ({
                      label: e,
                      value: e,
                    }))}
                  />

                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </FieldVStack>
        </FormContent>
        <FormFooter>
          <BtnGroup className="justify-end">
            <SubmitButton disabled={isDisabled}>{btnTitle}</SubmitButton>
          </BtnGroup>
        </FormFooter>
      </FormComp>
    </Form>
  );
}
function Step2Form({
  btnTitle,
  defaultValues,
  isDisabled,
  title,
  onSubmit,
  onPreviousPage,
}: WizardFormProps<Step2Values> & {}) {
  const form = useForm({
    resolver: zodResolver(step2Schema),
    defaultValues: defaults(defaultValues, {
      format: "application/json",
      responsePropertyName: "",
    }),
  });
  return (
    <Form {...form}>
      <FormComp onSubmit={form.handleSubmit(onSubmit)}>
        <FormHeader>
          <FormTitle>{title}</FormTitle>
          <p className="text-muted-foreground text-sm pb-4">
            Information on formatting responses.
          </p>
        </FormHeader>
        <FormContent>
          <FieldVStack>
            <FormField
              control={form.control}
              name="format"
              render={({ field }) => (
                <FormItem className="grow basis-10">
                  <FormLabel>
                    <FormLabelRequired />
                    Format
                  </FormLabel>
                  <FormDescription>
                    Responses are returned with this content-type.
                  </FormDescription>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {supportedContentFormats.map((e) => (
                        <SelectItem key={e} value={e}>
                          {e}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="responsePropertyName"
              render={({ field }) => (
                <FormItem className="grow basis-10">
                  <FormLabel>Response property name</FormLabel>
                  <FormDescription>
                    Wrap responses in an object with the following property
                    name. Ex. {"{"} data: &lt;Record&gt; {"}"}.
                  </FormDescription>
                  <FormControl>
                    <Input placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </FieldVStack>
        </FormContent>
        <FormFooter>
          <BtnGroup className="justify-end pt-2">
            {onPreviousPage ? (
              <BackButton onClick={onPreviousPage}>Back</BackButton>
            ) : (
              <span />
            )}
            <SubmitButton disabled={isDisabled}>{btnTitle}</SubmitButton>
          </BtnGroup>
        </FormFooter>
      </FormComp>
    </Form>
  );
}

function CrudOperationCard({
  crudOperation,
  step1Values,
  step2Values,
  value,
  onChange,
  activeElement,
}: {
  crudOperation: CRUDOperation;
  step1Values: Step1Values;
  step2Values: Step2Values;
  activeElement: ActiveElement;
} & EditorInputProps) {
  const [created, setCreated] = useState(false);
  const {
    findOperation,
    createWizardOperation,
    findOperationById,
    removeOperation,
  } = useOperation({
    value,
    onChange,
  });

  const isSelectDisabled =
    crudOperation.level === "detail" && crudOperation.method === "delete";

  const [selectValue, setSelectValue] = useState(() => {
    // default value based on crudOperation
    if (crudOperation.level === "detail" && crudOperation.method === "get")
      return step1Values.baseSchemaName;
    if (isSelectDisabled) return "";

    return getDtoTemplateValues(crudOperation, step1Values.baseSchemaName)
      .dtoName;
  });

  const cleanedParameterName =
    crudOperation.level === "detail" ? step1Values.parameterName : "";

  const onCreate = () => {
    try {
      createWizardOperation({
        baseSchemaName: step1Values.baseSchemaName,
        baseUrl: step1Values.baseUrl,
        crudOperation,
        dtoName: selectValue !== step1Values.baseSchemaName ? selectValue : "",
        format: step2Values.format,
        parameterName: cleanedParameterName,
        responsePropertyName: step2Values.responsePropertyName,
        securitySchemes: step1Values.securitySchemes.map((key) => ({
          [key]: [],
        })),
        tags: step1Values.tags,
      });
      setCreated(true);
      toastSuccess("Operation created");
    } catch (err) {
      toastError(err);
    }
  };

  const method = crudOperation.method;
  const path = cleanedParameterName
    ? `${step1Values.baseUrl}/{${step1Values.parameterName}}`
    : step1Values.baseUrl;

  const doesExist = useMemo(() => {
    const operation = findOperation(path, method);
    return !!operation;
  }, [findOperation, path, method]);

  const operationDetails = getOpenAPIOperationDetails(
    crudOperation,
    step1Values.baseSchemaName
  );

  const operationIdConflict = useMemo(() => {
    const found = findOperationById(operationDetails.operationId);
    return !!found;
  }, [findOperationById, operationDetails.operationId]);

  const crudOperationMeta = useMemo(
    () => getCrudOperationMeta(crudOperation),
    [crudOperation]
  );

  const templateDtoValues = useMemo(() => {
    return templateCrudOperations.map((o) =>
      getDtoTemplateValues(o, step1Values.baseSchemaName)
    );
  }, [step1Values.baseSchemaName]);

  const handleRemoveClick = async () => {
    try {
      await removeOperation(
        path,
        method,
        activeElement,
        operationDetails.operationId
      );
      setCreated(false);
      toastSuccess("Operation removed.");
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <SecondaryCard
      className="pb-3 pt-3 px-5 relative bg-transparent border border-input"
      selected={created || doesExist}
    >
      {created && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="icon-sm"
              className="absolute right-3 top-3"
              onClick={handleRemoveClick}
            >
              <NormIcon name="Remove" size="sm" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Remove this operation</TooltipContent>
        </Tooltip>
      )}
      <SecondaryCardHeader className="grid-cols-1">
        <H4 className="pb-2">{crudOperationMeta.name}</H4>
        <p className="text-sm text-muted-foreground">
          {crudOperationMeta.description}
        </p>
      </SecondaryCardHeader>
      <SecondaryCardContent>
        <form>
          <Select
            value={selectValue}
            onValueChange={setSelectValue}
            disabled={isSelectDisabled || created}
          >
            <SelectTrigger className="py-0 h-8">
              <SelectValue
                className="border-0 py-0"
                placeholder={isSelectDisabled ? "No return value" : ""}
              />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel className="text-muted-foreground font-normal">
                  Base schema
                </SelectLabel>
                <SelectItem value={step1Values.baseSchemaName}>
                  {step1Values.baseSchemaName}
                </SelectItem>
              </SelectGroup>
              <SelectGroup>
                <SelectLabel className="text-muted-foreground font-normal">
                  Templates
                </SelectLabel>
              </SelectGroup>
              {templateDtoValues.map((templateDtoValue) => (
                <SelectItem
                  value={templateDtoValue.dtoName}
                  key={templateDtoValue.dtoName}
                >
                  {templateDtoValue.dtoName}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </form>
      </SecondaryCardContent>
      <BtnGroup className="w-full">
        {created ? (
          <ColorBadge
            color="muted"
            className="w-full h-8 justify-center border-brandSoft border opacity-50"
          >
            Created
          </ColorBadge>
        ) : doesExist ? (
          <ColorBadge
            className="w-full h-8 justify-center border-input border opacity-50"
            color="muted"
          >
            Route exists in file
          </ColorBadge>
        ) : operationIdConflict ? (
          <ColorBadge
            className="w-full h-8 justify-center border-input border opacity-50"
            color="muted"
          >
            ID conflict{" "}
            <Tooltip>
              <TooltipTrigger asChild>
                <Button size="icon-sm" variant="ghost">
                  <NormIcon name="Info" className="ml-2" />
                </Button>
              </TooltipTrigger>
              <TooltipContent className="max-w-screen-sm">
                CRUD Wizard generates operationIds like getBook, listBooks,
                deleteBook, etc. If these Ids already exist in your file, they
                can not be overwritten. Please Rename operations with
                conflicting Ids.
              </TooltipContent>
            </Tooltip>
          </ColorBadge>
        ) : (
          <Button
            onClick={onCreate}
            variant="outline"
            className="py-3 h-8 grow"
            type="button"
            size="xs"
          >
            <NormIcon name="Add" size="sm" className="mr-2" />
            Create
          </Button>
        )}
      </BtnGroup>
    </SecondaryCard>
  );
}

function Step3({
  step1Values,
  step2Values,
  value,
  onChange,
  onPreviousPage,
  onDone,
  activeElement,
}: {
  step1Values: Step1Values;
  step2Values: Step2Values;
  onPreviousPage: () => unknown;
  onDone: () => unknown;
  activeElement: ActiveElement;
} & EditorInputProps) {
  return (
    <div className="py-3">
      <FormHeader>
        <FormTitle>CRUD Wizard 🧙🏻 (3/3)</FormTitle>
        <p className="text-muted-foreground text-sm pb-4">
          Select the return type of each operation by using the select input{" "}
          <ColorBadge color="muted" className="border border-input">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <ChevronDown size={"8"} />
          </ColorBadge>
          . Create operations by pressing the create button on each card.
        </p>
      </FormHeader>
      <div className="grid grid-cols-2 gap-2 pb-4">
        {crudOperations.map((operation) => {
          return (
            <CrudOperationCard
              key={JSON.stringify(operation)}
              step1Values={step1Values}
              step2Values={step2Values}
              crudOperation={operation}
              onChange={onChange}
              value={value}
              activeElement={activeElement}
            />
          );
        })}
      </div>
      <FormFooter>
        <BtnGroup className="justify-end">
          <BackButton onClick={onPreviousPage}>Back</BackButton>
          <SubmitButton onClick={onDone}>Done</SubmitButton>
        </BtnGroup>
      </FormFooter>
    </div>
  );
}

export function CRUDWizard({
  schemaName,
  value,
  onChange,
  activeElement,
}: {
  schemaName: string | undefined;
  activeElement: ActiveElement;
} & EditorInputProps) {
  const [step, setStep] = useState(0);
  const [, actionBarDispatch] = useActionBarContext();

  const [step1Values, setStep1Values] = useState<Step1Values>({
    baseSchemaName: schemaName || "",
    baseUrl: "",
    parameterName: "",
    securitySchemes: [],
    tags: [],
  });
  const [step2Values, setStep2Values] = useState<Step2Values>({
    format: "application/json",
    responsePropertyName: "",
  });

  const { findAllTags } = useTags({ value, onChange });
  const { getComponentSchemas } = useComponents({ value, onChange });

  const { findAllSecuritySchemes } = useSecuritySchemes({
    value,
    onChange,
  });

  const tags = useMemo(findAllTags, [findAllTags]);
  const componentSchemas = useMemo(
    () => getComponentSchemas(),
    [getComponentSchemas]
  );
  const securitySchemes = useMemo(
    () => findAllSecuritySchemes(),
    [findAllSecuritySchemes]
  );

  return (
    <ActionBarContent>
      {step == 0 && (
        <Step1Form
          value={value}
          onChange={onChange}
          onSubmit={(v) => {
            setStep(1);
            setStep1Values(v);
          }}
          btnTitle="Next"
          isDisabled={false}
          securitySchems={Object.keys(securitySchemes)}
          componentSchemas={componentSchemas}
          tags={tags}
          title="CRUD Wizard 🧙🏻 (1/3)"
          defaultValues={step1Values}
        />
      )}
      {step === 1 && (
        <Step2Form
          onSubmit={(v) => {
            setStep2Values(v);
            setStep(2);
          }}
          defaultValues={step2Values}
          title="CRUD Wizard 🧙🏻 (2/3)"
          btnTitle="Next"
          isDisabled={false}
          onPreviousPage={() => setStep(0)}
        />
      )}
      {step === 2 && step1Values && step2Values && (
        <Step3
          step1Values={step1Values}
          step2Values={step2Values}
          value={value}
          onChange={onChange}
          onPreviousPage={() => setStep(1)}
          onDone={() => actionBarDispatch({ type: "CLOSE" })}
          activeElement={activeElement}
        />
      )}
    </ActionBarContent>
  );
}
