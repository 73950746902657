import { apiClient } from "@/lib/http-utils";
import {
  CreateOrganizationDto,
  OrganizationDto,
  PatchOrganizationDto,
} from "@/lib/main-rest-client/definitions";
import { slugify } from "@/lib/utils";
import {
  queryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

const organizationKeys = {
  all: ["organization"] as const,
  lists: () => [...organizationKeys.all, "list"] as const,
  list: (filters: { organizationId: string }) =>
    [...organizationKeys.lists(), filters] as const,
  details: () => [...organizationKeys.all, "detail"] as const,
  detail: (id: string) => [...organizationKeys.details(), id] as const,
};

type OrganizationByIdParams = {
  organizationSlug: string;
};

export const organizationBySlugQuery = ({
  organizationSlug,
}: OrganizationByIdParams) =>
  queryOptions<OrganizationDto, AxiosError>({
    queryKey: organizationKeys.detail(organizationSlug),
    queryFn: async () => {
      return (
        await apiClient.findOrganizationByIdOrSlug({
          organizationIdOrSlug: organizationSlug,
        })
      ).data;
    },
  });

export function useOrganizationByIdOrSlug({
  organizationSlug,
}: {
  organizationSlug: string;
}) {
  return useQuery(organizationBySlugQuery({ organizationSlug }));
}

export function useCreateOrganization(
  options?: Omit<
    UseMutationOptions<OrganizationDto, AxiosError, CreateOrganizationDto>,
    "mutationFn"
  >
) {
  const queryClient = useQueryClient();
  return useMutation<OrganizationDto, AxiosError, CreateOrganizationDto>({
    mutationFn: async ({ slug, ...rest }) => {
      return (
        await apiClient.createOrganization({ slug: slugify(slug), ...rest })
      ).data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: organizationKeys.lists() }),
    ...options,
  });
}

export function usePatchOrganization() {
  const queryClient = useQueryClient();
  return useMutation<
    OrganizationDto,
    AxiosError,
    PatchOrganizationDto & { organizationId: string }
  >({
    mutationFn: async ({ organizationId, ...values }) => {
      return (await apiClient.patchOrganization({ organizationId }, values))
        .data;
    },
    onSuccess: async (_, values) => {
      await queryClient.invalidateQueries({
        queryKey: organizationKeys.lists(),
      });
      await queryClient.invalidateQueries({
        queryKey: organizationKeys.detail(values.organizationId),
      });
    },
  });
}
