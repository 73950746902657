import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { FormLabelRequired } from "@/components/form-label-required";
import { MdTextarea } from "@/components/md-textarea";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaEditor } from "@/components/schema-editor";
import { SchemaEditorPreviewContainer } from "@/components/schema-editor-containers";
import {
  FieldVStack,
  FormComp,
  FormContent,
  FormHeader,
  FormTitle,
} from "@/forms";
import { useComponents } from "@/hooks/use-components";
import {
  getPristineType,
  zodSchemaSchema,
} from "@/lib/oas-tools/oas-schema-utils";
import { OASComponentsObject, OASSchema, StandardFormProps } from "@/lib/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { appRegex } from "@/lib/regex";
import { toastError, toastSuccess } from "@/lib/utils";

const formSchema = z.object({
  name: z
    .string()
    .min(1, "Required")
    .regex(appRegex.modelName.expression, appRegex.modelName.message),
  description: z.string().optional(),
  schema: zodSchemaSchema,
});
type FormValues = z.infer<typeof formSchema>;

function RawForm({
  title,
  btnTitle,
  defaultValues,
  onSubmit,
  componentsObject,
}: StandardFormProps<FormValues> & {
  componentsObject: OASComponentsObject;
}) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: defaultValues.name,
      description: defaultValues.description || "",
      schema: defaultValues.schema,
    },
  });

  return (
    <Form {...form}>
      <FormComp onSubmit={form.handleSubmit(onSubmit)}>
        <FormHeader>
          <FormTitle>{title}</FormTitle>
        </FormHeader>
        <FormContent>
          <FieldVStack>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Schema name
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="ex. User, Book, Account" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Description
                  </FormLabel>
                  <FormControl>
                    <MdTextarea height={130} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <SchemaEditorPreviewContainer
              title="Schema"
              className="min-h-[220px]"
            >
              <Controller
                name="schema"
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <SchemaEditor
                    allowTopLevelReferences={false}
                    componentsObject={componentsObject}
                    value={value}
                    onChange={(schema) => {
                      onChange(schema);
                    }}
                    onRemoveRootSchema={() => {
                      form.setValue("schema", getPristineType("object"));
                    }}
                  />
                )}
              />
            </SchemaEditorPreviewContainer>
          </FieldVStack>
        </FormContent>

        <BtnGroup className="justify-end">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </FormComp>
    </Form>
  );
}

export function FormComponentSchemaEdit({
  schemaName,
  componentSchemaObject,
  value,
  onChange,
}: {
  schemaName: string;
  componentSchemaObject: OASSchema;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { editComponentSchema } = useComponents({ value, onChange });
  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    values.schema.description = values.description || "";
    try {
      await editComponentSchema({
        oldName: schemaName,
        newName: values.name,
        schema: values.schema,
      });
      toastSuccess("Schema updated.");
      actionBarDispatch({ type: "CLOSE" });
    } catch (err) {
      toastError(err);
    }
  };
  return (
    <RawForm
      title="Edit reusable schema"
      btnTitle="Edit"
      onSubmit={onSubmit}
      isDisabled={false}
      defaultValues={{
        name: schemaName,
        schema: componentSchemaObject,
        description: componentSchemaObject.description || "",
      }}
      componentsObject={componentsObject}
    />
  );
}

export function FormComponentSchemaAdd({ value, onChange }: EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { componentsObject, setActiveElement } = useAPIEditorTools({
    value,
    onChange,
  });
  const { addComponentSchema } = useComponents({ value, onChange });
  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    values.schema.description = values.description;
    addComponentSchema({ schema: values.schema, name: values.name });
    await setActiveElement({ type: "model", modelName: values.name });
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "add-component-suggest-dtos",
        context: {
          schemaName: values.name,
        },
      },
    });
  };
  return (
    <RawForm
      title="Create reusable schema"
      btnTitle="Create"
      onSubmit={onSubmit}
      componentsObject={componentsObject}
      isDisabled={false}
      defaultValues={{
        name: "",
        description: "",
        schema: getPristineType("object"),
      }}
    />
  );
}
