import { PreviewToolbarContainer } from "@/components/module-api-editor";
import { EditorPreviewComponentParameter } from "@/components/module-api-editor/editor-preview-component-parameter";
import { EditorPreviewCreateClient } from "@/components/module-api-editor/editor-preview-create-client";
import { EditorPreviewGeneralInformation } from "@/components/module-api-editor/editor-preview-general-information";
import { EditorPreviewMeta } from "@/components/module-api-editor/editor-preview-meta";
import { EditorPreviewModel } from "@/components/module-api-editor/editor-preview-model";
import { EditorPreviewOperation } from "@/components/module-api-editor/editor-preview-operation";
import { EditorPreviewResponse } from "@/components/module-api-editor/editor-preview-response";
import {
  ActiveElement,
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { NothingSelected } from "@/components/module-preview-pane/operation-preview";
import { useComponents } from "@/hooks/use-components";
import { WorkspaceDocumentDraftDto } from "@/lib/main-rest-client/definitions";
import { ReactNode } from "react";

export function PreviewPanel({
  value,
  onChange,
  isAuthed,
  organizationSlug,
  workspaceSlug,
  workspaceRole,
  draft,
  activeElement,
  extraToolbarItems,
}: {
  isAuthed: boolean;
  hasOperations: boolean;
  activeElement: ActiveElement;
  organizationSlug?: string;
  workspaceSlug?: string;
  workspaceRole: WorkspaceRole;
  extraToolbarItems: ReactNode;
  draft?: WorkspaceDocumentDraftDto;
} & EditorInputProps) {
  const { componentsObject } = useComponents({ value, onChange });

  if (!activeElement)
    return (
      <div className="grow flex flex-col items-stretch">
        <PreviewToolbarContainer
          value={value}
          onChange={onChange}
          organizationSlug={organizationSlug}
          workspaceRole={workspaceRole}
          isAuthed={isAuthed}
          workspaceSlug={workspaceSlug}
          extraToolbarItems={extraToolbarItems}
          hideSidebarToggle
        />
        <NothingSelected />
      </div>
    );
  return (
    <>
      {activeElement.type === "general_information" ? (
        <EditorPreviewGeneralInformation
          value={value}
          onChange={onChange}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          isAuthed={isAuthed}
          workspaceRole={workspaceRole}
          extraToolbarItems={extraToolbarItems}
        />
      ) : activeElement.type === "meta" ? (
        <EditorPreviewMeta
          value={value}
          onChange={onChange}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          isAuthed={isAuthed}
          workspaceRole={workspaceRole}
          extraToolbarItems={extraToolbarItems}
          activeElement={activeElement}
        />
      ) : activeElement.type === "create_client" ? (
        <EditorPreviewCreateClient
          value={value}
          onChange={onChange}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          isAuthed={isAuthed}
          workspaceRole={workspaceRole}
          draftId={draft?.id}
          extraToolbarItems={extraToolbarItems}
        />
      ) : activeElement.type === "response" ? (
        <EditorPreviewResponse
          key={JSON.stringify(activeElement)}
          value={value}
          onChange={onChange}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          isAuthed={isAuthed}
          activeElement={activeElement}
          workspaceRole={workspaceRole}
          extraToolbarItems={extraToolbarItems}
        />
      ) : activeElement.type === "component-parameter" ? (
        <EditorPreviewComponentParameter
          key={JSON.stringify(activeElement)}
          value={value}
          onChange={onChange}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          isAuthed={isAuthed}
          activeElement={activeElement}
          workspaceRole={workspaceRole}
          extraToolbarItems={extraToolbarItems}
        />
      ) : activeElement?.type === "model" ? (
        <EditorPreviewModel
          key={JSON.stringify(activeElement)}
          value={value}
          onChange={onChange}
          activeElement={activeElement}
          isAuthed={isAuthed}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          workspaceRole={workspaceRole}
          componentsObject={componentsObject}
          extraToolbarItems={extraToolbarItems}
        />
      ) : (
        <EditorPreviewOperation
          key={JSON.stringify(activeElement)}
          activeElement={activeElement}
          componentsObject={componentsObject}
          isAuthed={isAuthed}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          value={value}
          onChange={onChange}
          workspaceRole={workspaceRole}
          extraToolbarItems={extraToolbarItems}
        />
      )}
    </>
  );
}
