import {
  removeSchemaRefsFromDefinition,
  updateSchemaRefsInDefinition,
} from "@/lib/editor-mutations/oas-references";
import { getDtoXFields, xFields } from "@/lib/oas-tools/x-fields";
import { OASDefinition, OASSchema } from "@/lib/types";
import { safeGet } from "@/lib/utils";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import unset from "lodash/unset";

export function findAllDtosInDefinition({
  definition,
}: {
  definition: OASDefinition;
}): Record<string, OASSchema> {
  const schemas = definition.components?.schemas || {};

  return Object.fromEntries(
    Object.entries(schemas).filter(([, schema]) => {
      const dtoInfoObject = schema[xFields.dtoInfo];
      return dtoInfoObject && typeof dtoInfoObject === "object";
    })
  );
}

export function findDtoInDefinition({
  definition,
  baseSchemaName,
  dtoName,
}: {
  definition: OASDefinition;
  baseSchemaName: string;
  dtoName: string;
}) {
  const found = safeGet(findAllDtosInDefinition({ definition }), dtoName);

  if (!found || found[xFields.dtoInfo]?.baseSchemaName !== baseSchemaName)
    return undefined;

  return found;
}

export function findDtoInDefinitionOrError({
  definition,
  baseSchemaName,
  dtoName,
}: {
  definition: OASDefinition;
  baseSchemaName: string;
  dtoName: string;
}) {
  const found = findDtoInDefinition({ definition, baseSchemaName, dtoName });

  if (!found) throw new Error(`Not found: dto with name: ${dtoName}`);

  return found;
}

function createDtoPath(dtoName: string) {
  return `components.schemas.${dtoName}`;
}

function findBaseSchemaOrError(
  definition: OASDefinition,
  baseSchemaName: string
) {
  const schema = definition.components?.schemas?.[baseSchemaName];
  if (!schema) throw new Error("Base schema not found");
  if (schema[xFields.dtoInfo])
    throw new Error("Base schema is also a DTO. This is forbidden");
  return schema;
}

export function createDtoInDefinition({
  definition,
  dtoName,
  dtoSchema,
  baseSchemaName,
}: {
  definition: OASDefinition;
  dtoName: string;
  dtoSchema: OASSchema;
  baseSchemaName: string;
}): OASDefinition {
  findBaseSchemaOrError(definition, baseSchemaName);
  const cp = cloneDeep(definition);
  const path = createDtoPath(dtoName);
  return set(cp, path, { ...dtoSchema, ...getDtoXFields(baseSchemaName) });
}

export function patchDtoInDefinition({
  oldDtoName,
  newDtoName,
  definition,
  dtoSchema,
  baseSchemaName,
}: {
  oldDtoName: string;
  newDtoName: string;
  definition: OASDefinition;
  dtoSchema: OASSchema;
  baseSchemaName: string;
}) {
  findBaseSchemaOrError(definition, baseSchemaName);

  let newDefinition = definition;

  if (oldDtoName !== newDtoName) {
    newDefinition = removeDtoFromDefinition(
      updateSchemaRefsInDefinition({
        definition: newDefinition,
        oldRefName: oldDtoName,
        newRefName: newDtoName,
        type: "schemas",
      }),
      oldDtoName
    );
  }
  return createDtoInDefinition({
    definition: newDefinition,
    dtoName: newDtoName,
    dtoSchema: dtoSchema,
    baseSchemaName,
  });
}

export function removeDtoFromDefinition(
  definition: OASDefinition,
  dtoName: string
) {
  const schema = definition.components?.schemas?.[dtoName];
  if (!schema || !schema["x-fiddle-dto-info"])
    throw new Error("Trying to remove invalid DTO");
  const cp = cloneDeep(definition);
  const path = createDtoPath(dtoName);
  unset(cp, path);
  return removeSchemaRefsFromDefinition(cp, dtoName, "schemas");
}
