import { getIsAuthed } from "@/lib/utils";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { z } from "zod";
import { zodSearchValidator } from "@tanstack/router-zod-adapter";
import { useEffect } from "react";
import { localStorageKeys } from "@/lib/const";

const acceptSearchSchema = z.object({
  token: z.string(),
});

export const Route = createFileRoute("/accept-workspace-invitation")({
  component: AcceptWorkspaceInvite,
  validateSearch: zodSearchValidator(acceptSearchSchema),
});

export function AcceptWorkspaceInvite() {
  const { token } = Route.useSearch();

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem(localStorageKeys.workspaceInvitationToken, token);
    const isAuthed = getIsAuthed();
    if (isAuthed) {
      void navigate({ to: "/dash/onboarding" });
    } else {
      void navigate({ to: "/login" });
    }
  });

  return null;
}
