import { OASDefinition, OASServer } from "@/lib/types";

export type OASServerWithInfo = {
  idx: number;
  server: OASServer;
};

export function findServersInDefinition({
  definition,
}: {
  definition: OASDefinition;
}): Array<OASServerWithInfo> {
  return (definition.servers || []).map((e, i) => ({ server: e, idx: i }));
}

export function findServerInDefinitionOrError({
  definition,
  idx,
}: {
  definition: OASDefinition;
  idx: number;
}): OASServerWithInfo {
  const servers = findServersInDefinition({ definition });
  const found = servers[idx];
  if (!found) throw new Error("Server does not exist");
  return found;
}

export function addServerToDefinition({
  definition,
  serverObject,
}: {
  definition: OASDefinition;
  serverObject: OASServer;
}) {
  const cp = structuredClone(definition);
  const servers = findServersInDefinition({ definition });

  if (
    servers.some(
      (s) => s.server.url.toLowerCase() === serverObject.url.toLowerCase()
    )
  ) {
    throw new Error("Conflict: A server with this url already exists.");
  }

  cp.servers ||= [];
  cp.servers.push(serverObject);

  return cp;
}

export function removeServerFromDefinition({
  definition,
  idx,
}: {
  definition: OASDefinition;
  idx: number;
}) {
  const cp = structuredClone(definition);

  // throw error if idx does not exist
  findServerInDefinitionOrError({ definition: cp, idx });

  cp.servers!.splice(idx, 1);

  return cp;
}

export function updateServerInDefinition({
  definition,
  idx,
  newServerObject,
}: {
  definition: OASDefinition;
  idx: number;
  newServerObject: OASServer;
}) {
  const cp = structuredClone(definition);

  // throw error if no element at given index
  findServerInDefinitionOrError({ definition: cp, idx });

  cp.servers!.splice(idx, 1, newServerObject);

  return cp;
}
