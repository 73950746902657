import { ColorBadeColors } from "@/components/ui/color-badge";
import { ReadableTypeNames } from "@/lib/oas-tools/oas-schema-utils";
import { ParameterPosition, SupportedHTTPVerbs } from "@/lib/types";
import {
  Asterisk,
  Ban,
  Box,
  Combine,
  GitBranch,
  Hash,
  List,
  LucideIcon,
  Shuffle,
  Split,
  Text,
  ToggleLeft,
  XCircle,
} from "lucide-react";

export const typeIconMap: Record<ReadableTypeNames, LucideIcon> = {
  string: Text, // Text representation
  number: Hash, // Numeric symbol
  boolean: ToggleLeft, // Toggle switch for true/false
  integer: Hash, // Similar to number but whole
  object: Box, // Container for properties
  array: List, // Ordered collection
  allOf: Combine, // Combining elements
  oneOf: Split, // Choosing one path
  anyOf: GitBranch, // Multiple possible paths
  mixed: Shuffle, // Mixed/combined types
  null: Ban, // Absence of value
  not: XCircle, // Negation
  any: Asterisk, // Wildcard/anything goes
};

export const typeColorMap: Record<ReadableTypeNames, ColorBadeColors> = {
  string: "blue", // Blue is often associated with text/communication
  number: "cyan", // Cyan feels technical and precise, good for numbers
  boolean: "amber", // Amber for true/false, like a switch or signal
  integer: "green", // Green is stable/whole, fitting for integers
  object: "purple", // Purple for complex/composite types
  array: "indigo", // Indigo for collections, slightly different from objects
  allOf: "red", // Red for strict combination requirements
  oneOf: "orange", // Orange for alternatives/choices
  anyOf: "yellow", // Yellow for flexible combinations
  mixed: "lime", // Lime for hybrid/mixed types
  null: "gray", // Gray for absence of value
  not: "rose", // Rose/pink for negation/exclusion
  any: "gray", // Neutral slate for unrestricted type
};

export const httpColorMap: Record<SupportedHTTPVerbs, ColorBadeColors> = {
  get: "green", // Green for safe retrieval operations
  post: "blue", // Blue for creation operations
  put: "orange", // Orange for full updates/replacements
  delete: "red", // Red for destructive operations
  patch: "amber", // Amber for partial modifications
  head: "purple", // Purple for metadata requests
  options: "gray", // Gray for information about capabilities
  trace: "gray", // Slate for diagnostic operations
};

export const parameterPositionColorMap: Record<
  ParameterPosition,
  ColorBadeColors
> = {
  path: "blue", // Blue for URL path parameters - most visible part of route
  query: "green", // Green for query parameters - common/safe additions
  header: "purple", // Purple for header params - metadata/control information
  cookie: "amber", // Amber for cookies - persistent/stateful information
};
