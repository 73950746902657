import * as React from "react";

import { Button } from "@/components/ui/button";
import { ColorBadeColors, ColorBadge } from "@/components/ui/color-badge";
import { HttpStatus } from "@/lib/helpers";
import { getSchemaTypeName } from "@/lib/oas-tools/oas-schema-utils";
import { deref, isReferenceSchema } from "@/lib/oas-tools/oas-tag-helpers";
import {
  parameterPositionColorMap,
  typeColorMap,
  typeIconMap,
} from "@/lib/oas-tools/style-helpers";
import {
  OASReferenceObject,
  OASSchema,
  ParameterPosition,
  SupportedHTTPVerbs,
} from "@/lib/types";
import { cn, DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";
import inRange from "lodash/inRange";
import {
  AlertCircle,
  ArrowRightCircle,
  CheckCircle,
  FileQuestion,
  LucideIcon,
  XCircle,
} from "lucide-react";

export function TagBadge({
  children,
  className,
  ...rest
}: React.ComponentProps<typeof ColorBadge>) {
  return (
    <ColorBadge color="muted" className={className} {...rest}>
      <NormIcons.Tag size={DEFAULT_ICON_SIZE} className="mr-2" />
      <span>{children}</span>
    </ColorBadge>
  );
}

export const TypeBadge = ({
  className,
  schema,
  variant,
  forceColor,
  isRequired = true,
  showIcon = false,
  size = "default",
  ...rest
}: React.ComponentProps<typeof ColorBadge> & {
  schema: OASSchema | OASReferenceObject | undefined;
  isRequired?: boolean;
  forceColor?: ColorBadeColors;
  showIcon?: boolean;
  size?: "default" | "icon";
}) => {
  const badge = React.useMemo(() => {
    if (!schema)
      return (
        <ColorBadge
          variant={variant}
          className={cn(className)}
          color={forceColor || "muted"}
          {...rest}
        >
          null
        </ColorBadge>
      );
    if (isReferenceSchema(schema))
      return (
        <ColorBadge
          {...rest}
          variant={variant}
          className={className}
          color={forceColor || "muted"}
        >
          {deref(schema.$ref)}
        </ColorBadge>
      );

    const schemaName = getSchemaTypeName(schema);
    const Icon = typeIconMap[schemaName];
    return (
      <ColorBadge
        className={className}
        variant={variant}
        size="icon"
        color={forceColor || typeColorMap[getSchemaTypeName(schema)] || "muted"}
        {...rest}
      >
        {showIcon && (
          <Icon
            size={DEFAULT_ICON_SIZE}
            className={cn(size !== "icon" && "mr-2")}
          />
        )}
        {size !== "icon" && getSchemaTypeName(schema)}
      </ColorBadge>
    );
  }, [className, forceColor, schema, variant, rest, showIcon, size]);

  if (isRequired) return badge;

  return (
    <span className="inline-flex gap-1">
      {badge}
      {!isRequired && <ColorBadge color="muted">or null</ColorBadge>}
    </span>
  );
};

const colorMap: Record<SupportedHTTPVerbs, ColorBadeColors> = {
  delete: "red",
  get: "green",
  head: "yellow",
  options: "muted",
  patch: "indigo",
  post: "blue",
  put: "teal",
  trace: "emerald",
};

export function HttpMethodBadge({
  method,
  className,
  variant,
  ...rest
}: { method: SupportedHTTPVerbs } & React.ComponentProps<typeof ColorBadge>) {
  const methodStr = method.toUpperCase();
  return (
    <ColorBadge
      variant={variant}
      color={colorMap[method] || "blue"}
      className={cn("text-center justify-center", className)}
      {...rest}
    >
      {methodStr.toLowerCase()}
    </ColorBadge>
  );
}

export function HttpLinkBadge({
  method,
  className,
  ...rest
}: { method: SupportedHTTPVerbs } & React.ComponentProps<typeof Button>) {
  const methodStr = method.toLowerCase();
  const color = colorMap[methodStr as SupportedHTTPVerbs] || "blue";
  return (
    <span
      className={cn(
        `text-${color}-400 inline-block font-semibold pr-2 text-left justify-start`,
        className
      )}
      {...rest}
    >
      {methodStr}
    </span>
  );
}

const iconMap: Record<ParameterPosition, LucideIcon> = {
  cookie: NormIcons.Cookie,
  header: NormIcons.Header,
  path: NormIcons.Path,
  query: NormIcons.Query,
};

export const ParameterPositioinBadge = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof ColorBadge> & {
    parameterPosition: ParameterPosition;
  }
>(({ parameterPosition, className, children: _children, ...rest }, ref) => {
  const Icon = iconMap[parameterPosition];
  return (
    <ColorBadge
      ref={ref}
      color={parameterPositionColorMap[parameterPosition] || "blue"}
      className={cn("", className)}
      {...rest}
    >
      <Icon size={DEFAULT_ICON_SIZE} className="mr-2" />
      {parameterPosition.toLowerCase()}
    </ColorBadge>
  );
});

export function ResponseInfoBadge({ status }: { status: HttpStatus }) {
  const parsed = Number(status);

  if (parsed >= 500)
    return <ColorBadge color="red">Operation failed</ColorBadge>;
  if (parsed >= 400) return <ColorBadge color="red">Client error</ColorBadge>;
  if (parsed >= 300)
    return <ColorBadge color="orange">Redirection required</ColorBadge>;
  if (parsed >= 200)
    return <ColorBadge color="green">Operation successful</ColorBadge>;
  if (parsed >= 100)
    return <ColorBadge color="muted">Informational response</ColorBadge>;

  return <ColorBadge color="muted">Meta status</ColorBadge>;
}

export const ResponseCodeBadge = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof ColorBadge> & {
    code: HttpStatus;
    forceColor?: ColorBadeColors;
  }
>(({ code, className, forceColor, ...rest }, ref) => {
  const color = (() => {
    if (inRange(+code, 200, 299)) return "green";
    if (inRange(+code, 300, 399)) return "muted";
    if (inRange(+code, 400, 599)) return "muted";
    return "blue";
  })();
  const Icon = (() => {
    if (inRange(+code, 200, 299)) return CheckCircle;
    if (inRange(+code, 300, 399)) return ArrowRightCircle;
    if (inRange(+code, 400, 499)) return AlertCircle;
    if (inRange(+code, 500, 599)) return XCircle;
    return FileQuestion;
  })();
  return (
    <ColorBadge
      color={forceColor || color}
      className={className}
      ref={ref}
      {...rest}
    >
      <Icon size={DEFAULT_ICON_SIZE} className="mr-2" />
      {code}
    </ColorBadge>
  );
});
