import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { MdTextarea } from "@/components/md-textarea";
import {
  ActionBarFieldGroup,
  ActionBarForm,
  ActionBarFormTitle,
} from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaContentEditor } from "@/components/schema-content-editor";
import { SchemaEditorPreviewContainer } from "@/components/schema-editor-containers";
import { useRequestBody } from "@/hooks/use-request-body";
import {
  getPristineContentObject,
  getPristineType,
  zodContentObject,
} from "@/lib/oas-tools/oas-schema-utils";
import { OASComponentsObject, OASRequestBodyObject } from "@/lib/types";
import { zodResolver } from "@hookform/resolvers/zod";
import merge from "lodash/merge";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  contentObject: zodContentObject,
  description: z.string().optional(),
  required: z.boolean(),
});
type FormValues = z.infer<typeof formSchema>;

export function RawForm({
  defaultValues,
  onSubmit,
  title,
  componentsObject,
  btnTitle,
}: {
  defaultValues: Partial<FormValues>;
  onSubmit: (values: FormValues) => unknown;
  title: string;
  componentsObject: OASComponentsObject;
  btnTitle: "Create" | "Edit";
}) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: merge(
      {
        schema: getPristineType("string"),
      },
      defaultValues
    ),
  });

  return (
    <Form {...form}>
      <ActionBarForm onSubmit={(e) => form.handleSubmit(onSubmit)(e)}>
        <ActionBarFormTitle>{title}</ActionBarFormTitle>
        <ActionBarFieldGroup>
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <MdTextarea height={130} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="required"
            render={({ field }) => {
              return (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Required</FormLabel>
                    <FormDescription>
                      Do consumers have to provide a request body?
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              );
            }}
          />
          <Controller
            name="contentObject"
            control={form.control}
            render={({ field: { onChange, value } }) => {
              return (
                <SchemaEditorPreviewContainer title="Schema">
                  <SchemaContentEditor
                    value={value}
                    onChange={onChange}
                    allowTopLevelReferences={true}
                    componentsObject={componentsObject}
                  />
                </SchemaEditorPreviewContainer>
              );
            }}
          />
        </ActionBarFieldGroup>
        <BtnGroup className="justify-end">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </ActionBarForm>
    </Form>
  );
}

export function RequestBodyEdit({
  operationId,
  requestBody,
  value,
  onChange,
}: {
  operationId: string;
  requestBody: OASRequestBodyObject;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { update } = useRequestBody({ value, onChange });

  if ("$ref" in requestBody)
    throw new Error("Requestbodies cannot be reference objects");

  const onSubmit = (values: FormValues) => {
    update({
      operationId,
      requestBody: {
        ...requestBody,
        required: values.required,
        description: values.description,
        content: values.contentObject,
      },
    });
    actionBarDispatch({
      type: "CLOSE",
    });
  };

  return (
    <RawForm
      btnTitle="Edit"
      title="Edit request body"
      defaultValues={{
        description: requestBody.description,
        required: requestBody.required,
        contentObject: requestBody.content || getPristineContentObject(),
      }}
      onSubmit={onSubmit}
      componentsObject={componentsObject}
    />
  );
}

export function RequestBodyAdd({
  operationId,
  value,
  onChange,
}: {
  operationId: string;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { add } = useRequestBody({ value, onChange });

  const onSubmit = (values: FormValues) => {
    add({
      operationId,
      requestBody: {
        required: values.required,
        description: values.description,
        content: values.contentObject,
      },
    });
    actionBarDispatch({
      type: "CLOSE",
    });
  };

  return (
    <RawForm
      btnTitle="Create"
      title="Create request body"
      defaultValues={{
        description: "",
        required: true,
        contentObject: getPristineContentObject(),
      }}
      onSubmit={onSubmit}
      componentsObject={componentsObject}
    />
  );
}
