import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { DashboardHeader } from "@/components/dashboard/dashboard-header";
import { RepositoryAdapterCreate } from "@/forms/form-repository-adapter";
import { companyInfo } from "@/lib/const";
import { apiClient } from "@/lib/http-utils";
import { GithubAppConnectDto } from "@/lib/main-rest-client/definitions";
import { toastError } from "@/lib/utils";
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";

import { z } from "zod";

const ghInstallationSearchSchema = z.object({
  code: z.string(),
  state: z.string(),
  installation_id: z.number(),
});

export const Route = createFileRoute("/gh-installation/redirect")({
  component: GhInstallationRedirect,
  validateSearch: (search) => ghInstallationSearchSchema.parse(search),
});

function GhInstallationRedirect() {
  const navigate = useNavigate();
  const { code, state, installation_id } = Route.useSearch();

  const connectQuery = useQuery({
    queryKey: [],
    queryFn: async () => {
      const res = (
        await apiClient.githubAppAuthConnect({ code, state, installation_id })
      ).data;
      return res;
    },
  });

  const handleSuccess = async (
    data: GithubAppConnectDto,
    repositoryAdapterId: string
  ) => {
    try {
      const drafts = (
        await apiClient.findRespositoryAdapterWorkspaceDocumentDrafts({
          repositoryAdapterId,
        })
      ).data;
      // the last draft will be the newest
      const found = drafts[drafts.length - 1];
      if (!found) throw new Error("Draft was not created");
      await navigate({
        to: "/editor/$organizationSlug/$workspaceSlug/drafts/$draftId",
        params: {
          organizationSlug: data.organization_slug,
          workspaceSlug: data.workspace_slug,
          draftId: found.id,
        },
      });
    } catch (err) {
      Sentry.captureException(err);
      toastError(err);
      await navigate({
        to: "/editor/$organizationSlug/$workspaceSlug",
        params: {
          organizationSlug: data.organization_slug,
          workspaceSlug: data.workspace_slug,
        },
      });
    }
  };

  return (
    <div className="max-w-screen-xl mx-auto px-norm">
      {connectQuery.status === "error" ? (
        <div className="max-w-screen-sm flex flex-col items-stretch gap-norm pt-4 mx-auto">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <Link to="/dash/onboarding">
                  <BreadcrumbLink href="/">Dashboard</BreadcrumbLink>
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Connect to repository</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <Alert>
            <AlertTitle>Unable to connect to Github</AlertTitle>
            <AlertDescription>
              We saw an error when connecting to Github. To try again, return to
              your workspace and repeat the connection process. If this error
              persists,{" "}
              <a href={`mailto:${companyInfo.supportEmail}`}>contact us</a>.
            </AlertDescription>
          </Alert>
        </div>
      ) : connectQuery.status === "pending" ? (
        <div className="mx-auto max-w-screen-sm mt-12 pt-12">
          <Card>
            <CardHeader>
              <CardTitle>Connect workspace to Github Repository</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-2 gap-x-4 gap-y-8">
                <Skeleton className="h-4 col-span-2" />
                <Skeleton className="h-12" />
                <Skeleton className="h-12" />
                <Skeleton className="h-12 col-span-2" />
                <Skeleton className="h-12" />
                <Skeleton className="h-12" />
                <Skeleton className="h-12" />
                <Skeleton className="h-12" />
              </div>
            </CardContent>
          </Card>
        </div>
      ) : (
        <>
          <DashboardHeader
            organizationSlug={connectQuery.data.organization_slug}
            showUpgradeButton={false}
          />
          <div className="mx-auto max-w-screen-sm mt-12">
            <Card>
              <CardHeader>
                <CardTitle>Connect workspace to Github Repository</CardTitle>
              </CardHeader>
              <CardContent>
                <RepositoryAdapterCreate
                  organizationId={connectQuery.data.organization_id}
                  githubInstallationId={
                    connectQuery.data.github_installation_id
                  }
                  onSuccess={(data) =>
                    handleSuccess(
                      connectQuery.data,
                      data?.repositoryAdapterId || ""
                    )
                  }
                  repositoryList={connectQuery.data.accessible_repositories}
                  workspaceItemId={connectQuery.data.workspace_item_id}
                  defaultBranch={
                    connectQuery.data.accessible_repositories[0]
                      .default_branch || ""
                  }
                  defaultRepositoryId={String(
                    connectQuery.data.accessible_repositories[0].id
                  )}
                />
              </CardContent>
            </Card>
          </div>
        </>
      )}
    </div>
  );
}
