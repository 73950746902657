import { resolveParameterFromDefinition } from "@/lib/editor-mutations/oas-parameters";
import { appRegex } from "./regex";
import { OASDefinition, OASOperation, OASParameterObject } from "./types";

export function getParametersByType(
  operation: OASOperation,
  where: "path" | "query",
  definition: OASDefinition
) {
  return [
    ...(operation.parameters || []).filter((p) => {
      const resolvedParameter = resolveParameterFromDefinition({
        definition,
        parameter: p,
      });
      return resolvedParameter.in === where;
    }),
  ];
}

export function getParametersFromUrlPath(
  urlPath: string
): OASParameterObject[] {
  const found = urlPath.match(/{([\w_.-]+)}/g);
  if (!found?.length) return [];
  return found.map((r) => ({
    name: r.replace(/[{}]/g, ""),
    in: "path",
    required: true,
    schema: {
      type: "string",
    },
  }));
}

export function validateUrlPath(urlPath: string) {
  if (!appRegex.urlPath.expression.test(urlPath))
    throw new Error(appRegex.urlPath.message);
  return urlPath;
}
