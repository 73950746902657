import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ReactNode } from "@tanstack/react-router";
import { PropsWithChildren } from "react";

export interface Item {
  id: string;
  label: string;
}

interface MultiSelectPopoverProps {
  items: Item[];
  value: string[];
  onChange: (value: string[]) => void;
  height?: number;
  label?: ReactNode;
}

export function MultiSelectPopover({
  children,
  items,
  value,
  onChange,
  height = 150,
  label,
}: PropsWithChildren<MultiSelectPopoverProps>) {
  const handleItemToggle = (itemId: string) => {
    const newValue = value.includes(itemId)
      ? value.filter((id) => id !== itemId)
      : [...value, itemId];
    onChange(newValue);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>{label || "Select Items"}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <ScrollArea style={{ maxHeight: height }}>
          {items.map((item) => (
            <DropdownMenuCheckboxItem
              key={item.id}
              checked={value.includes(item.id)}
              onCheckedChange={() => handleItemToggle(item.id)}
            >
              {item.label}
            </DropdownMenuCheckboxItem>
          ))}
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
