"use client";

import * as React from "react";

import { TextLink, UserAvatar } from "@/components";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { DropdownOperationMore } from "@/components/dropdown-operation-more";
import { HideEmptyListWhenNonEditor } from "@/components/module-api-editor";
import {
  ActiveElement,
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  AddButton,
  CollapseButton,
  DeleteButton,
  EditButton,
  MoreButton,
} from "@/components/module-visual-editor/shared-components";
import {
  HttpMethodBadge,
  ParameterPositioinBadge,
  TypeBadge,
} from "@/components/special-badges";
import { Collapsible, CollapsibleContent } from "@/components/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuActionGroup,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarRail,
} from "@/components/ui/sidebar";
import {
  ComponentParameterWithInfo,
  useComponentParameters,
} from "@/hooks/use-component-parameters";
import { useComponentResponses } from "@/hooks/use-component-responses";
import {
  ComponentResponseWithInfo,
  ComponentSchemaWithInfo,
  useComponents,
} from "@/hooks/use-components";
import { useEditorNavigation } from "@/hooks/use-editor-navigation";
import { useOperation } from "@/hooks/use-operation";
import { useTags } from "@/hooks/use-tags";
import { companyInfo } from "@/lib/const";
import { OperationWithInfo } from "@/lib/editor-mutations/oas-operations";
import {
  getFirstSchemaFromContentObject,
  getPristineType,
} from "@/lib/oas-tools/oas-schema-utils";
import {
  canEdit,
  DEFAULT_ICON_SIZE,
  NormIcons,
  toastError,
  toastSuccess,
} from "@/lib/utils";
import { CollapsibleTrigger } from "@radix-ui/react-collapsible";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { Link } from "@tanstack/react-router";

function SchemaItem({
  componentSchemaWithInfo,
  value,
  onChange,
  workspaceRole,
  activeElement,
}: {
  componentSchemaWithInfo: ComponentSchemaWithInfo;
  workspaceRole: WorkspaceRole;
  activeElement: ActiveElement;
} & EditorInputProps) {
  const { removeComponentSchema } = useComponents({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();
  const { setActiveElement } = useAPIEditorTools({
    value,
    onChange,
  });

  const isActive =
    activeElement?.type !== "model"
      ? false
      : componentSchemaWithInfo.name === activeElement.modelName;

  const handleClick = () =>
    setActiveElement({
      type: "model",
      modelName: componentSchemaWithInfo.name,
    });
  const showEditButtons = canEdit(workspaceRole);

  const handleEditClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-schema",
        context: {
          schemaName: componentSchemaWithInfo.name,
          componentSchemaObject: componentSchemaWithInfo.schema,
        },
      },
    });
  };

  const handleRemove = async () => {
    await removeComponentSchema(componentSchemaWithInfo.name);
  };

  return (
    <SidebarMenuItem className="relative group/sub-item cursor-default">
      <SidebarMenuButton asChild isActive={isActive} onClick={handleClick}>
        <a>
          <TypeBadge
            showIcon
            forceColor="ghost"
            size="icon"
            schema={componentSchemaWithInfo.schema}
          />
          {componentSchemaWithInfo.name}
        </a>
      </SidebarMenuButton>
      {showEditButtons && (
        <SidebarMenuActionGroup showOnHover className="">
          <EditButton onClick={handleEditClick} />
          <DeleteButton onClick={handleRemove} />
        </SidebarMenuActionGroup>
      )}
    </SidebarMenuItem>
  );
}

function ResponseItem({
  value,
  onChange,
  workspaceRole,
  componentResponseWithInfo: { responseObject, responseName },
  activeElement,
}: {
  componentResponseWithInfo: ComponentResponseWithInfo;
  workspaceRole: WorkspaceRole;
  activeElement: ActiveElement;
} & EditorInputProps) {
  const { removeComponentResponse } = useComponentResponses({
    value,
    onChange,
  });
  const [, actionBarDispatch] = useActionBarContext();

  const { setActiveElement } = useAPIEditorTools({
    value,
    onChange,
  });

  const isActive =
    activeElement?.type === "response" &&
    activeElement.responseName === responseName;

  const schema = responseObject.content
    ? getFirstSchemaFromContentObject(responseObject.content)
    : getPristineType("null");

  const handleClick = () =>
    setActiveElement({
      type: "response",
      responseName,
    });

  const handleEditClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-response",
        context: {
          responseName,
          responseObject,
        },
      },
    });
  };

  const handleRemove = async () => {
    try {
      await removeComponentResponse(responseName);
      toastSuccess("Response removed");
    } catch (err) {
      toastError(err);
    }
  };

  const showEditButtons = canEdit(workspaceRole);

  return (
    <SidebarMenuItem
      key={responseName}
      className="relative group/sub-item cursor-default"
    >
      <SidebarMenuButton asChild isActive={isActive} onClick={handleClick}>
        <a>
          <TypeBadge showIcon size="icon" forceColor="ghost" schema={schema} />
          {responseName}
        </a>
      </SidebarMenuButton>
      {showEditButtons && (
        <SidebarMenuActionGroup showOnHover>
          <EditButton onClick={handleEditClick} />
          <DeleteButton onClick={handleRemove} />
        </SidebarMenuActionGroup>
      )}
    </SidebarMenuItem>
  );
}

function OperationItem({
  activeElement,
  operationWithInfo,
  workspaceRole,
  value,
  onChange,
}: {
  activeElement: ActiveElement;
  operationWithInfo: OperationWithInfo;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const { urlPath, method, operation } = operationWithInfo;
  const { setActiveElement } = useAPIEditorTools({
    value,
    onChange,
  });
  const { removeOperation } = useOperation({ value, onChange });

  const handleRemove = async () => {
    try {
      await removeOperation(
        urlPath,
        method,
        activeElement,
        operationWithInfo.operation.operationId
      );
      toastSuccess("Operation deleted");
    } catch (err) {
      toastError(err);
    }
  };

  const handleClick = async () => {
    if (!operation.operationId) throw new Error("Operation has no id");
    await setActiveElement({
      type: "operation",
      operationId: operation.operationId,
    });
  };

  const isActive =
    activeElement?.type === "operation" &&
    activeElement.operationId === operation.operationId;

  const showEditButtons = canEdit(workspaceRole);
  return (
    <SidebarMenuItem className="relative group/sub-item cursor-default">
      <SidebarMenuButton asChild isActive={isActive} onClick={handleClick}>
        <a>
          <HttpMethodBadge
            variant="default"
            className="w-[50px] py-1"
            method={method}
          />
          {operation.summary}
        </a>
      </SidebarMenuButton>
      {showEditButtons && (
        <SidebarMenuActionGroup showOnHover>
          <DropdownOperationMore operationWithInfo={operationWithInfo}>
            <MoreButton />
          </DropdownOperationMore>
          <DeleteButton onClick={handleRemove} />
        </SidebarMenuActionGroup>
      )}
    </SidebarMenuItem>
  );
}

function ComponentParameterItem({
  value,
  onChange,
  workspaceRole,
  componentParameterWithInfo,
  activeElement,
}: {
  workspaceRole: WorkspaceRole;
  componentParameterWithInfo: ComponentParameterWithInfo;
  activeElement: ActiveElement;
} & EditorInputProps) {
  const { label, parameter } = componentParameterWithInfo;

  const { removeComponentParameter } = useComponentParameters({
    value,
    onChange,
  });

  const [, actionBarDispatch] = useActionBarContext();

  const { setActiveElement } = useAPIEditorTools({
    value,
    onChange,
  });

  const isActive =
    activeElement?.type === "component-parameter" &&
    activeElement.label === label;

  const handleClick = () =>
    setActiveElement({
      type: "component-parameter",
      label,
    });

  const handleEditClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-parameter",
        context: {
          label,
          parameter,
        },
      },
    });
  };

  const handleRemove = async () => {
    try {
      await removeComponentParameter(label);
      toastSuccess("Parameter removed");
    } catch (err) {
      toastError(err);
    }
  };

  const showEditButtons = canEdit(workspaceRole);

  return (
    <SidebarMenuItem className="relative group/sub-item cursor-default">
      <SidebarMenuButton asChild isActive={isActive} onClick={handleClick}>
        <a>
          <ParameterPositioinBadge
            className="w-auto"
            variant="outline"
            parameterPosition={parameter.in}
          />
          {parameter.name}
        </a>
      </SidebarMenuButton>
      {showEditButtons && (
        <SidebarMenuActionGroup showOnHover>
          <EditButton onClick={handleEditClick} />
          <DeleteButton onClick={handleRemove} />
        </SidebarMenuActionGroup>
      )}
    </SidebarMenuItem>
  );
}

export default function EditorSidebarLayout({
  value,
  onChange,
  children,
  workspaceSlug,
  workspaceRole,
  activeElement,
  isAuthed,
}: React.PropsWithChildren<
  EditorInputProps & {
    workspaceRole: WorkspaceRole;
    activeElement: ActiveElement;
    workspaceSlug?: string;
    isAuthed: boolean;
  }
>) {
  const [, actionBarDispatch] = useActionBarContext();
  const {
    navigationTree,
    componentResponsesWithInfo,
    componentParametersWithInfo,
    componentSchemasWithInfo,
    unusedTags,
  } = useEditorNavigation({ value, onChange, activeElement });

  const { findTag: get, remove } = useTags({ value, onChange });

  const handleEditTagClick = (tagName: string) => {
    const tagObject = get(tagName);
    if (!tagObject) throw new Error("Cannot find tag object");
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-tag",
        context: {
          tagObject,
        },
      },
    });
  };

  const handleRemoveTagClick = (tagName: string) => {
    const tagObject = get(tagName);
    if (!tagObject) throw new Error("Cannot find tag object");

    try {
      remove(tagName);
      toastSuccess("Tag removed");
    } catch (err) {
      toastError(err);
    }
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <SidebarProvider
      style={
        {
          "--sidebar-width": "24rem",
        } as React.CSSProperties
      }
    >
      <Sidebar variant="sidebar" className="">
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <Link to={isAuthed ? "/dash/onboarding" : "/login"}>
                <SidebarMenuButton size="lg" asChild>
                  <div>
                    <div className="flex aspect-square size-8 items-center justify-center rounded-lg text-sidebar-primary-foreground">
                      <UserAvatar />
                    </div>
                    <div className="flex flex-col gap-0.5 leading-none">
                      <p>
                        {workspaceSlug && (
                          <span className="text-muted-foreground">Space: </span>
                        )}
                        <span className="font-semibold">
                          {workspaceSlug || "Local workspace"}
                        </span>
                      </p>
                      {isAuthed ? (
                        <span className="text-muted-foreground">
                          Go to dashboard
                        </span>
                      ) : (
                        <span className="text-muted-foreground">
                          <TextLink>Sign up</TextLink> to save workspace
                        </span>
                      )}
                    </div>
                  </div>
                </SidebarMenuButton>
              </Link>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>
        {/* <div className="flex justify-end sticky top-3 h-0.5 overflow-visible z-10">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                size="icon-sm"
                variant="brand"
                className="mr-3 size-5 rounded-full"
              >
                <Plus strokeWidth={2} />
              </Button>
            </DropdownMenuTrigger>
          </DropdownMenu>
        </div> */}
        <SidebarContent>
          <SidebarGroup>
            <SidebarGroupLabel>General</SidebarGroupLabel>

            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={activeElement?.type === "general_information"}
                >
                  <Link
                    search={(prev) => ({
                      ...prev,
                      activeElement: { type: "general_information" },
                    })}
                  >
                    <NormIcons.Globals size={DEFAULT_ICON_SIZE} />
                    Globals
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={activeElement?.type === "create_client"}
                >
                  <Link
                    search={(prev) => ({
                      ...prev,
                      activeElement: { type: "create_client" },
                    })}
                  >
                    <NormIcons.OpenApi size={DEFAULT_ICON_SIZE} />
                    OpenAPI
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroup>
          <Collapsible defaultOpen className="group/collapsible">
            <SidebarGroup className="pb-0">
              <SidebarGroupLabel asChild>
                <SidebarMenuItem>
                  <span className="grow">Operations</span>
                  {isEditor && (
                    <SidebarMenuActionGroup showOnHover>
                      <CollapsibleTrigger asChild>
                        <CollapseButton />
                      </CollapsibleTrigger>
                      <AddButton
                        onClick={() => {
                          actionBarDispatch({
                            type: "SET_PAGE",
                            payload: {
                              name: "add-operation",
                            },
                          });
                        }}
                      />
                    </SidebarMenuActionGroup>
                  )}
                </SidebarMenuItem>
              </SidebarGroupLabel>
            </SidebarGroup>
            <CollapsibleContent className="">
              {navigationTree.sortedList.map(([tag, list]) => {
                return (
                  <Collapsible
                    defaultOpen
                    className="group/collapsible py-0"
                    asChild
                    key={tag}
                  >
                    <SidebarGroup>
                      <SidebarGroupLabel asChild>
                        <SidebarMenuItem className="cursor-default select-none">
                          <span>
                            <NormIcons.Tag
                              size={DEFAULT_ICON_SIZE}
                              className="mr-2"
                            />
                          </span>
                          <span className="grow text-left">{tag}</span>
                          <SidebarMenuActionGroup showOnHover>
                            <CollapsibleTrigger asChild>
                              <CollapseButton className="group-data-[state=open]/collapsible:rotate-90" />
                            </CollapsibleTrigger>
                            {tag !== "default" && isEditor && (
                              <>
                                <EditButton
                                  onClick={() => handleEditTagClick(tag)}
                                />
                                <DeleteButton
                                  onClick={() => handleRemoveTagClick(tag)}
                                />
                              </>
                            )}
                          </SidebarMenuActionGroup>
                        </SidebarMenuItem>
                      </SidebarGroupLabel>
                      <CollapsibleContent>
                        <SidebarGroupContent>
                          <SidebarMenu>
                            {list.map((item) => (
                              <OperationItem
                                key={item.operation.operationId}
                                workspaceRole={workspaceRole}
                                activeElement={activeElement}
                                onChange={onChange}
                                value={value}
                                operationWithInfo={item}
                              />
                            ))}
                          </SidebarMenu>
                        </SidebarGroupContent>
                      </CollapsibleContent>
                    </SidebarGroup>
                  </Collapsible>
                );
              })}
              {unusedTags.map((tag) => {
                return (
                  <SidebarGroup>
                    <SidebarGroupLabel asChild>
                      <SidebarMenuItem className="cursor-default select-none">
                        <span>
                          <NormIcons.Tag
                            size={DEFAULT_ICON_SIZE}
                            className="mr-2"
                          />
                        </span>
                        <span className="grow text-left">{tag.name}</span>
                        {isEditor && (
                          <SidebarMenuActionGroup showOnHover>
                            <>
                              <EditButton
                                onClick={() => handleEditTagClick(tag.name)}
                              />
                              <DeleteButton
                                onClick={() => handleRemoveTagClick(tag.name)}
                              />
                            </>
                          </SidebarMenuActionGroup>
                        )}
                      </SidebarMenuItem>
                    </SidebarGroupLabel>
                  </SidebarGroup>
                );
              })}
            </CollapsibleContent>
          </Collapsible>
          <HideEmptyListWhenNonEditor
            list={componentParametersWithInfo}
            workspaceRole={workspaceRole}
          >
            <Collapsible defaultOpen className="group/collapsible" asChild>
              <SidebarGroup>
                <SidebarGroupLabel asChild>
                  <SidebarMenuItem className="cursor-default select-none">
                    <span className="grow text-left">Parameters</span>
                    <SidebarMenuActionGroup showOnHover>
                      <CollapsibleTrigger asChild>
                        <CollapseButton />
                      </CollapsibleTrigger>
                      {isEditor && (
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <AddButton />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            <DropdownMenuGroup>
                              <DropdownMenuItem
                                onClick={() =>
                                  actionBarDispatch({
                                    type: "SET_PAGE",
                                    payload: {
                                      name: "add-component-parameter",
                                      context: { parameterPosition: "query" },
                                    },
                                  })
                                }
                              >
                                <NormIcons.Query
                                  size={DEFAULT_ICON_SIZE}
                                  className="mr-2"
                                />
                                <span>Query</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() =>
                                  actionBarDispatch({
                                    type: "SET_PAGE",
                                    payload: {
                                      name: "add-component-parameter",
                                      context: { parameterPosition: "header" },
                                    },
                                  })
                                }
                              >
                                <NormIcons.Header
                                  size={DEFAULT_ICON_SIZE}
                                  className="mr-2"
                                />
                                <span>Header</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() =>
                                  actionBarDispatch({
                                    type: "SET_PAGE",
                                    payload: {
                                      name: "add-component-parameter",
                                      context: { parameterPosition: "cookie" },
                                    },
                                  })
                                }
                              >
                                <NormIcons.Cookie
                                  size={DEFAULT_ICON_SIZE}
                                  className="mr-2"
                                />
                                <span>Cookie</span>
                              </DropdownMenuItem>
                            </DropdownMenuGroup>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      )}
                    </SidebarMenuActionGroup>
                  </SidebarMenuItem>
                </SidebarGroupLabel>
                <CollapsibleContent>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      {componentParametersWithInfo.map((parameterWithInfo) => {
                        return (
                          <ComponentParameterItem
                            key={parameterWithInfo.label}
                            value={value}
                            onChange={onChange}
                            workspaceRole={workspaceRole}
                            activeElement={activeElement}
                            componentParameterWithInfo={parameterWithInfo}
                          />
                        );
                      })}
                    </SidebarMenu>
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>
          </HideEmptyListWhenNonEditor>
          <HideEmptyListWhenNonEditor
            list={componentSchemasWithInfo}
            workspaceRole={workspaceRole}
          >
            <Collapsible defaultOpen className="group/collapsible" asChild>
              <SidebarGroup>
                <SidebarGroupLabel asChild>
                  <SidebarMenuItem className="cursor-default select-none">
                    <span className="grow text-left">Schemas</span>
                    <SidebarMenuActionGroup showOnHover>
                      <CollapsibleTrigger asChild>
                        <CollapseButton />
                      </CollapsibleTrigger>
                      {isEditor && (
                        <AddButton
                          onClick={() => {
                            actionBarDispatch({
                              type: "SET_PAGE",
                              payload: {
                                name: "add-component-schema",
                              },
                            });
                          }}
                        />
                      )}
                    </SidebarMenuActionGroup>
                  </SidebarMenuItem>
                </SidebarGroupLabel>
                <CollapsibleContent>
                  <SidebarGroupContent className="">
                    <SidebarMenu>
                      {componentSchemasWithInfo.map((schemaWithInfo) => {
                        return (
                          <SchemaItem
                            activeElement={activeElement}
                            value={value}
                            onChange={onChange}
                            componentSchemaWithInfo={schemaWithInfo}
                            key={schemaWithInfo.name}
                            workspaceRole={workspaceRole}
                          />
                        );
                      })}
                    </SidebarMenu>
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>
          </HideEmptyListWhenNonEditor>
          <HideEmptyListWhenNonEditor
            list={componentParametersWithInfo}
            workspaceRole={workspaceRole}
          >
            <Collapsible defaultOpen className="group/collapsible" asChild>
              <SidebarGroup>
                <SidebarGroupLabel asChild>
                  <SidebarMenuItem className="cursor-default select-none">
                    <span className="grow text-left">Responses</span>
                    <SidebarMenuActionGroup showOnHover>
                      <CollapsibleTrigger asChild>
                        <CollapseButton />
                      </CollapsibleTrigger>
                    </SidebarMenuActionGroup>
                  </SidebarMenuItem>
                </SidebarGroupLabel>
                <CollapsibleContent>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      {componentResponsesWithInfo.map((responseWithInfo) => {
                        return (
                          <ResponseItem
                            key={responseWithInfo.responseName}
                            workspaceRole={workspaceRole}
                            value={value}
                            onChange={onChange}
                            activeElement={activeElement}
                            componentResponseWithInfo={responseWithInfo}
                          />
                        );
                      })}
                    </SidebarMenu>
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>
          </HideEmptyListWhenNonEditor>
        </SidebarContent>
        <SidebarFooter>
          <SidebarMenu>
            <SidebarMenuItem>
              <a href={companyInfo.surveys.generalFeedback} target="_blank">
                <SidebarMenuButton
                  size="lg"
                  className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                >
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span className="truncate font-semibold">
                      We need your help!
                    </span>
                    <span className="truncate text-xs">
                      Tell us what to build by{" "}
                      <span className="hover:underline text-brand">
                        answering three questions
                      </span>
                      .
                    </span>
                  </div>
                  <NormIcons.ExternalLink className="ml-auto size-4" />
                </SidebarMenuButton>
              </a>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>
        <SidebarRail />
      </Sidebar>
      <SidebarInset>{children}</SidebarInset>
    </SidebarProvider>
  );
}
