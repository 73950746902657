import { Card, CardHeader } from "@/components/ui/card";
import {
  DashboardHeader,
  HeaderBackButton,
} from "@/components/dashboard/dashboard-header";
import { PreviewHeading } from "@/components/module-api-editor";
import { WorkspaceSettingsCloneCard } from "@/components/workspace-settings-clone-card";
import { WorkspaceSettingsLeaveCard } from "@/components/workspace-settings-leave-card";
import { WorkspaceSettingsRepositoryAdapterCard } from "@/components/workspace-settings-repository-adapter-card";
import { WorkspaceSettingsMemberList } from "@/components/workspace_settings_member_list";
import { FormPatchWorkspace } from "@/forms/form-workspace";
import { apiClient } from "@/lib/http-utils";
import { canAdmin } from "@/lib/utils";
import {
  organizationRepositoryAdaptersQueryOptions,
  useOrganizationRepositoryAdapters,
} from "@/queries/repository-adapters";
import { useMe, userMeQuery } from "@/queries/users";
import { createFileRoute, Link } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/dash/$organizationSlug/$workspaceSlug/edit"
)({
  component: WorkspaceSettings,
  loader: async ({ params: { workspaceSlug }, context: { queryClient } }) => {
    const workspaceDto = (
      await apiClient.findWorkspaceByIdOrSlug({
        workspaceSlugOrId: workspaceSlug,
      })
    ).data;
    await queryClient.ensureQueryData(userMeQuery);
    await queryClient.ensureQueryData(
      organizationRepositoryAdaptersQueryOptions()
    );
    return { workspaceDto };
  },
});

function WorkspaceSettings() {
  const { organizationSlug } = Route.useParams();
  const workspaceDto = Route.useLoaderData().workspaceDto;
  const repositoryAdaptersQuery = useOrganizationRepositoryAdapters();

  const meQuery = useMe();

  if (!workspaceDto)
    return <span>There was an error when trying to get your workspace</span>;

  if (!meQuery.data) return null;
  if (!repositoryAdaptersQuery.data) return null;

  const isOrgMember =
    meQuery.data?.organization_id === workspaceDto.organization_id;

  const isAdmin = canAdmin(workspaceDto.role);

  return (
    <div>
      <div className="max-w-screen-xl mx-auto px-5">
        <DashboardHeader
          organizationSlug={organizationSlug}
          showUpgradeButton={false}
          leftItems={
            <Link
              to="/dash/$organizationSlug/home"
              params={{ organizationSlug }}
            >
              <HeaderBackButton>Dashboard</HeaderBackButton>
            </Link>
          }
        />
        <div className="pt-8">
          <PreviewHeading className="pb-4 px-2" preHeading="Settings">
            Workspace
          </PreviewHeading>
          <div className="grid gap-8 grid-cols-1">
            <Card>
              <CardHeader>
                <FormPatchWorkspace
                  isDisabled={!canAdmin(workspaceDto.role)}
                  organizationSlug={organizationSlug}
                  formValues={{
                    name: workspaceDto.name,
                    slug: workspaceDto.slug,
                    accessLevel: workspaceDto.access_level,
                  }}
                  workspaceId={workspaceDto.id}
                />
              </CardHeader>
            </Card>
            <WorkspaceSettingsMemberList
              userWorkspaceDto={workspaceDto}
              meDto={meQuery.data}
            />
            <WorkspaceSettingsCloneCard userWorkspace={workspaceDto} />
            {isOrgMember && (
              <WorkspaceSettingsRepositoryAdapterCard
                workspace={workspaceDto}
                organizationRepositoryAdapters={repositoryAdaptersQuery.data}
              />
            )}
            {(!isAdmin || !isOrgMember) && (
              <WorkspaceSettingsLeaveCard
                userWorkspace={workspaceDto}
                me={meQuery.data}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
