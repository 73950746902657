import { usePreviewContext } from "@/components/contexts/operation-preview-context";
import {
  ActiveElement,
  EditorInputProps,
} from "@/components/module-api-editor/types";
import { PreviewContainer as PreviewContainerWrapper } from "@/components/module-preview-pane/model-preview";
import { useOperation } from "@/hooks/use-operation";
import { OperationWithInfo } from "@/lib/editor-mutations/oas-operations";
import { generateOASOperationRows } from "@/lib/oas-tools/generate-oas-operation-rows";
import { OASComponentsObject } from "@/lib/types";
import { cn, toastError } from "@/lib/utils";
import { ComponentProps, forwardRef, useMemo } from "react";
import { PreviewRow } from "./preview-schema-row";
import WaveLoading from "@/components/svg-animations/wave-loading";

export function OperationRender({
  operationWithInfo,
  components,
}: {
  operationWithInfo: OperationWithInfo;
  components: OASComponentsObject;
}) {
  const [state] = usePreviewContext();
  const rows = useMemo(() => {
    const { method, urlPath, operation } = operationWithInfo;
    try {
      return generateOASOperationRows({
        initialLevel: 0,
        operation,
        method,
        urlPath,
        components,
        showNestedComponents: state.showNestedComponents,
      });
    } catch (err) {
      toastError(err);
      return [];
    }
  }, [operationWithInfo, components, state.showNestedComponents]);

  return (
    <PreviewContainerWrapper>
      <pre>
        {rows.map((r, i) => {
          return (
            <PreviewRow
              row={r}
              key={`preview-row-${i}`}
              componentsObject={components}
            />
          );
        })}
      </pre>
    </PreviewContainerWrapper>
  );
}

export function OperationPreviewContainer({
  children,
  className,
  ...rest
}: ComponentProps<"div">) {
  return (
    <div
      className={cn(
        "mx-4 mb-4 mt-2 grow overflow-auto bg-preview-background rounded-md shadow-lg flex justify-center items-center",
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
}

export function NothingSelected() {
  return (
    <OperationPreviewContainer>
      <div className="text-center">
        <span className="text-brandSoft w-20 inline-block">
          <WaveLoading />
        </span>
        <p className="text-muted-foreground text-sm pb-norm">
          <span className="hidden md:inline">
            Select a model or operation
            <br /> to display a preview.
          </span>
          <span className="md:hidden">No support for mobile yet.</span>
        </p>
      </div>
    </OperationPreviewContainer>
  );
}

export const OperationPreview = forwardRef<
  HTMLDivElement,
  {
    activeElement: NonNullable<ActiveElement>;
    componentsObject: OASComponentsObject;
  } & EditorInputProps
>(({ activeElement, componentsObject, value, onChange }, _ref) => {
  const { findOperationWithInfo: getOperationWithInfo } = useOperation({
    value,
    onChange,
  });
  if (activeElement.type !== "operation")
    throw new Error("Active element needs to be operation");

  const operationWithInfo = useMemo(
    () => getOperationWithInfo(activeElement.operationId),
    [activeElement.operationId, getOperationWithInfo]
  );

  return (
    <OperationRender
      operationWithInfo={operationWithInfo}
      components={componentsObject}
    />
  );
});
