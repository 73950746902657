import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import {
  ActionBarFieldGroup,
  ActionBarForm,
} from "../module-action-bar/elements";
import { DialogTitle } from "@radix-ui/react-dialog";
import { companyInfo } from "@/lib/const";
import { getWorkspaceUrl, NormIcons, setClipboard } from "@/lib/utils";
import { Label } from "@/components/ui/label";
import { AlertDialogFooter } from "@/components/ui/alert-dialog";

const formSchema = z.object({
  email: z.string().email(),
});
type FormValues = z.infer<typeof formSchema>;

export function NewsletterDialog({ ...props }: ComponentProps<typeof Dialog>) {
  const form = useForm<FormValues>({
    defaultValues: { email: "" },
    resolver: zodResolver(formSchema),
  });
  const onSubmit = async (values: FormValues) => {
    const formData = new URLSearchParams();
    formData.append("email", values.email);
    formData.append("userGroup", "");
    formData.append("mailingLists", "");
    try {
      await fetch(
        "https://app.loops.so/api/newsletter-form/clv0z3lr40015fy8dno9pf0tg",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: formData.toString(),
        }
      );
      toast.success("Successfully subscribed");
      form.reset();
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };
  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Join our growing API community</DialogTitle>
        </DialogHeader>
        <DialogDescription className="font-mono">
          <p className="pb-2">What we do:</p>
          <ul className="pb-4">
            <li>💻:: Discover & discuss API design</li>
            <li>📚:: Uncover best practices with us</li>
            <li>🥰:: Help us build a product that you will love</li>
          </ul>
          <p className="pb-2">Our promise:</p>
          <ul>
            <li>❌:: No spam</li>
            <li>🌿:: Unsubscribe anytime</li>
            <li></li>
          </ul>
          <Form {...form}>
            <ActionBarForm onSubmit={form.handleSubmit(onSubmit)}>
              <ActionBarFieldGroup>
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel />
                      <FormControl>
                        <Input placeholder="name@api-fiddle.com" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </ActionBarFieldGroup>
              <Button type="submit">Submit</Button>
            </ActionBarForm>
          </Form>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}

export function FeedbackDialog(props: ComponentProps<typeof Dialog>) {
  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>We love feedback</DialogHeader>
        <DialogDescription>
          <p>Please send your feedback to:</p>
          <ul>
            <li>{companyInfo.feedbackEmail}</li>
          </ul>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}

export function AboutDialog(props: ComponentProps<typeof Dialog>) {
  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>About Api-Fiddle</DialogHeader>
        <DialogDescription>
          <p className="pb-2">
            Api-Fiddle is built by{" "}
            <a
              target="_blank"
              href="https://twitter.com/florianmartens9"
              className="underline"
            >
              Florian Martens
            </a>
            .
          </p>
          <p className="pb-4">
            PWLL UG (haftungsbeschränkt) <br />
            Quitzowstrasse 118 <br />
            10559 Berlin (Germany) <br />
          </p>
          <ul>
            <a href={`mailto:${companyInfo.feedbackEmail}`} target="_blank">
              {companyInfo.feedbackEmail}
            </a>
          </ul>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}

export function ShareDialog({
  organizationSlug,
  workspaceSlug,
  children: _children,
  ...rest
}: ComponentProps<typeof Dialog> & {
  organizationSlug: string;
  workspaceSlug: string;
}) {
  const copyUrl = useMemo(
    () => getWorkspaceUrl({ workspaceSlug, organizationSlug }),
    [organizationSlug, workspaceSlug]
  );

  const handleCopy = async () => {
    if (!copyUrl) return;
    await setClipboard(copyUrl);
    toast.success("Copied url to clipboard");
  };

  return (
    <Dialog {...rest}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Share link</DialogTitle>
          <DialogDescription>
            Share this link so other members can see this workspace.
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-2">
            <Label htmlFor="link" className="sr-only">
              Link
            </Label>
            <Input id="link" defaultValue={copyUrl || ""} readOnly />
          </div>
          <Button type="submit" size="sm" className="px-3" onClick={handleCopy}>
            <span className="sr-only">Copy</span>
            <NormIcons.Clipboard className="h-4 w-4" />
          </Button>
        </div>
        <AlertDialogFooter className="sm:justify-start">
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              Close
            </Button>
          </DialogClose>
        </AlertDialogFooter>
      </DialogContent>
    </Dialog>
  );
}
