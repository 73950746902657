import { OASDefinition } from "@/lib/types";
import { z } from "zod";

export type EditorState = {
  data: OASDefinition;
  document_type: "oas_api_3_1";
};

export type EditorInputProps = {
  value: EditorState;
  onChange: (value: EditorState) => unknown;
};

export type WorkspaceRole = "reader" | "editor" | "admin";

export const editorSearchSchema = z.object({
  activeElement: z
    .union([
      z.object({ type: z.literal("operation"), operationId: z.string() }),
      z.object({ type: z.literal("model"), modelName: z.string() }),
      z.object({ type: z.literal("response"), responseName: z.string() }),
      z.object({ type: z.literal("component-parameter"), label: z.string() }),
      z.object({ type: z.literal("general_information") }),
      z.object({ type: z.literal("create_client") }),
      z.object({
        type: z.literal("meta"),
        info: z.object({
          reason: z.enum(["not_found"]),
          component: z.enum(["response", "parameter", "model", "operation"]),
        }),
      }),
    ])
    .optional(),
});

export type EditorSearchSchema = z.infer<typeof editorSearchSchema>;

export type ActiveElement = EditorSearchSchema["activeElement"];

export type CasingPreference = "snake_case" | "camelCase";
