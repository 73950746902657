import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { WorkspaceRole } from "@/components/module-api-editor/types";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { localStorageKeys } from "@/lib/const";
import { NormIcons } from "@/lib/utils";
import { useAcceptWorkspaceInvitaiton } from "@/queries/workspace-invitation";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { toast } from "sonner";

type WorkspaceInvitationTokenClaims = {
  workspace_id: string;
  token_type: "workspace_invitation";
  email: string;
  role: WorkspaceRole;
  sender_username: string;
  workspace_name: string;
  exp: number;
};

export function WorkspaceInvitationPrompt() {
  const [tokenClaims, setTokenClaims] = useState<
    undefined | WorkspaceInvitationTokenClaims
  >(() => {
    const token = localStorage.getItem(
      localStorageKeys.workspaceInvitationToken
    );
    if (!token) return undefined;

    try {
      return jwtDecode(token);
    } catch (err) {
      localStorage.removeItem(localStorageKeys.workspaceInvitationToken);
      toast.error("Unable to decode invitation token");
      return undefined;
    }
  });

  const acceptInvitationMutation = useAcceptWorkspaceInvitaiton();

  const handleAccept = () => {
    const token = localStorage.getItem(
      localStorageKeys.workspaceInvitationToken
    );
    if (!token) return;
    acceptInvitationMutation.mutate(
      { token },
      {
        onSuccess: () => {
          localStorage.removeItem(localStorageKeys.workspaceInvitationToken);
          setTokenClaims(undefined);
        },
      }
    );
  };

  const handleDeleteInvitation = () => {
    localStorage.removeItem(localStorageKeys.workspaceInvitationToken);
    setTokenClaims(undefined);
  };

  if (!tokenClaims) return null;

  return (
    <Alert className="bg-preview-background pt-4 border-0 mb-4">
      <NormIcons.Invite className="h-4 w-4" />
      <div className="flex justify-between items-center">
        <div>
          <AlertTitle>You have a pending invitation!</AlertTitle>
          <AlertDescription>
            The user <b>{tokenClaims.sender_username}</b> has invited you to the
            workspace <b>{tokenClaims.workspace_name}</b>.
          </AlertDescription>
        </div>
        <BtnGroup>
          <Button size="sm" onClick={handleAccept}>
            Accept
          </Button>
          <Button
            size="sm"
            variant="destructive"
            onClick={handleDeleteInvitation}
          >
            Delete
          </Button>
        </BtnGroup>
      </div>
    </Alert>
  );
}
