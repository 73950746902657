import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { SchemaEditor } from "@/components/schema-editor";
import { SchemaEditorQuickContainer } from "@/components/schema-editor-containers";
import { getPristineType } from "@/lib/oas-tools/oas-schema-utils";
import { OASSchema } from "@/lib/types";
import { canEdit } from "@/lib/utils";
import get from "lodash/get";
import set from "lodash/set";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from "react";

export const SchemaQuickEditor = forwardRef<
  HTMLDivElement,
  Omit<ComponentPropsWithoutRef<"div">, "onChange"> &
    EditorInputProps & {
      workspaceRole: WorkspaceRole;
      path: string;
      allowTopLevelReferences: boolean;
    }
>(
  (
    {
      path,
      className,
      allowTopLevelReferences,
      workspaceRole,
      children: _children,
      value: editorValue,
      onChange: editorOnChange,
      ...rest
    },
    ref
  ) => {
    const { setNewEditorState, componentsObject } = useAPIEditorTools({
      value: editorValue,
      onChange: editorOnChange,
    });

    const value = useMemo(() => {
      return (
        (get(editorValue.data, path) as OASSchema | undefined) ||
        getPristineType("object")
      );
    }, [editorValue.data, path]);

    const handleDefinitionUpdate = useCallback(
      (schema: OASSchema) => {
        const cp = structuredClone(editorValue.data);
        set(cp, path, schema);
        setNewEditorState({
          data: cp,
          document_type: editorValue.document_type,
        });
      },
      [path, editorValue.data, setNewEditorState, editorValue.document_type]
    );

    const handleChange = (schema: OASSchema) => {
      handleDefinitionUpdate(schema);
    };

    const [isLocked, setIsLocked] = useState(true);

    const isEditor = canEdit(workspaceRole);
    return (
      <SchemaEditorQuickContainer
        isLocked={isLocked}
        onLockChange={isEditor ? () => setIsLocked(!isLocked) : undefined}
        className={className}
        {...rest}
        ref={ref}
      >
        <SchemaEditor
          value={value}
          onChange={handleChange}
          onRemoveRootSchema={() => handleChange(getPristineType("object"))}
          componentsObject={componentsObject}
          allowTopLevelReferences={allowTopLevelReferences}
          isReadOnly={isLocked}
        />
      </SchemaEditorQuickContainer>
    );
  }
);
