import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
} from "@/components/ui/breadcrumb";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { useActionBarContext } from "../contexts/action-bar-context";
import { ActionBarContent } from "./action-bar-content";
import { ActionBarContentHeader } from "./action-bar-content-header";
import { Command, CommandItem, CommandList } from "@/components/ui/command";

export function WorkspaceActions(props: EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { resetToEmptyWorkspace } = useAPIEditorTools(props);

  const handleResetToExample = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "select-example",
      },
    });
  };

  return (
    <ActionBarContent>
      <ActionBarContentHeader>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>Reset mode</BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </ActionBarContentHeader>
      <Command>
        <CommandList>
          <CommandItem onSelect={resetToEmptyWorkspace}>
            Reset to empty workspace
          </CommandItem>
          <CommandItem onSelect={handleResetToExample}>
            Reset to example workspace
          </CommandItem>
        </CommandList>
      </Command>
    </ActionBarContent>
  );
}
