import { ActiveElement } from "@/components/module-api-editor/types";

export class OperationIdUndefinedError extends Error {
  constructor(msg?: string) {
    super(msg || "Operation id undefined");
  }
}

export class DefinitionNotDefinedError extends Error {
  constructor() {
    super("Definition not defined");
  }
}

export class NoRefsHereError extends Error {
  constructor() {
    super("Refs are not yet supported for this entity");
  }
}

export class NotFoundError extends Error {
  constructor(
    readonly component: Extract<
      ActiveElement,
      { type: "meta" }
    >["info"]["component"]
  ) {
    super(`NotFound: Unable to find ${component} in spec.`);
    this.component = component;
  }
}
