import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  DocumentationCard,
  DocumentationCardButtonGroup,
  DocumentationCardContent,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemButtonGroup,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import { HideEmptyListWhenNonEditor } from "@/components/module-api-editor";
import {
  type ActiveOperationItem,
  type SetActiveItem,
} from "@/components/module-api-editor/editor-preview-operation-active-item";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  AddButton,
  AddLabelButton,
  BtnGroup,
  ComponentButton,
  DeleteButton,
  EditButton,
  LinkedComponentButton,
} from "@/components/module-visual-editor/shared-components";
import { ParameterPositioinBadge } from "@/components/special-badges";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useComponentParameters } from "@/hooks/use-component-parameters";
import { ParameterWithInfo, useParameters } from "@/hooks/use-parameters";
import { OperationWithInfo } from "@/lib/editor-mutations/oas-operations";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { ParameterPosition } from "@/lib/types";
import { canEdit, DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";
import { useMemo } from "react";

function ParameterItem({
  parameter,
  activeItem,
  setActiveItem,
  operationWithInfo,
  value,
  onChange,
  workspaceRole,
  isLast,
}: {
  parameter: ParameterWithInfo;
  setActiveItem: SetActiveItem;
  activeItem: ActiveOperationItem | undefined;
  operationWithInfo: OperationWithInfo;
  workspaceRole: WorkspaceRole;
  isLast: boolean;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { setActiveElement } = useAPIEditorTools({ value, onChange });
  const { addOrAttatchExistingParameterAsComponent } = useComponentParameters({
    value,
    onChange,
  });
  const { resolveParameterObject, remove } = useParameters({
    value,
    onChange,
  });
  const refName = isReference(parameter.parameter)
    ? deref(parameter.parameter.$ref)
    : undefined;
  const resolved = resolveParameterObject(parameter.parameter);

  const operationId = operationWithInfo.operation.operationId || "";

  const handleRemoveParameter = (parameter: ParameterWithInfo) => {
    // IMPORTANT: Reset active item so path in `QuickEditor`
    // does not become invalid
    setActiveItem(undefined);
    remove({
      parameterIdx: parameter.idx,
      operationId,
    });
  };

  const handleEditClick = (parameterWithInfo: ParameterWithInfo) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-request-parameter",
        onInteractOutside: (e) => {
          e.preventDefault();
        },
        context: {
          operationId,
          parameterWithInfo,
        },
      },
    });
  };

  const handleReferenceClick = (refName: string) =>
    setActiveElement({ type: "component-parameter", label: refName });

  const handleAddOrAttachToComponentClick = (parameterIdx: number) => {
    addOrAttatchExistingParameterAsComponent({ operationId, parameterIdx });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <DocumentationCardItem
      key={resolved.name + "-" + resolved.in}
      isSelected={
        activeItem?.type === "parameter" && activeItem.idx === parameter.idx
      }
      onClick={() => setActiveItem({ type: "parameter", idx: parameter.idx })}
      isLast={isLast}
    >
      <DocumentationCardItemHeader>
        <div className="flex gap-2 items-center">
          <ParameterPositioinBadge
            parameterPosition={resolved.in as ParameterPosition}
          />
          {/* <TypeBadge schema={resolved.schema} isRequired={resolved.required} /> */}
          {refName && (
            <Tooltip>
              <TooltipTrigger asChild>
                <LinkedComponentButton
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await handleReferenceClick(refName);
                  }}
                />
              </TooltipTrigger>
              <TooltipContent>
                This links to a component: <b>{refName}</b>
              </TooltipContent>
            </Tooltip>
          )}
          <DocumentationCardItemTitle>
            {resolved.name}
          </DocumentationCardItemTitle>
        </div>
        {isEditor && (
          <DocumentationCardItemButtonGroup>
            {!refName && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <ComponentButton
                    onClick={(evt) => {
                      evt.stopPropagation();
                      evt.preventDefault();
                      handleAddOrAttachToComponentClick(parameter.idx);
                    }}
                  >
                    <span className="sr-only">Turn into component</span>
                  </ComponentButton>
                </TooltipTrigger>
                <TooltipContent>Merge into component</TooltipContent>
              </Tooltip>
            )}
            <EditButton
              onClick={(evt) => {
                evt.stopPropagation();
                evt.preventDefault();
                handleEditClick(parameter);
              }}
            >
              Edit {resolved.in} parameter
            </EditButton>
            <DeleteButton
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                handleRemoveParameter(parameter);
              }}
            >
              Delte parameter
            </DeleteButton>
          </DocumentationCardItemButtonGroup>
        )}
      </DocumentationCardItemHeader>
    </DocumentationCardItem>
  );
}

export function RequestParameterCard({
  value,
  onChange,
  operationWithInfo,
  workspaceRole,
  setActiveItem,
  activeItem,
}: {
  operationWithInfo: OperationWithInfo;
  workspaceRole: WorkspaceRole;
  setActiveItem: SetActiveItem;
  activeItem: ActiveOperationItem | undefined;
} & EditorInputProps) {
  const operation = operationWithInfo.operation;
  const operationId = operation.operationId || "";

  const { getParametersByPosition: getParameters, resolveParameterObject } =
    useParameters({
      value,
      onChange,
    });
  const [, actionBarDispatch] = useActionBarContext();

  const handleAdd = (parameterPosition: ParameterPosition) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "add-request-parameter",
        onInteractOutside: (e) => {
          e.preventDefault();
        },
        context: {
          operationId,
          urlPath: operationWithInfo.urlPath,
          parameterPosition,
        },
      },
    });
  };

  const queryParameters = useMemo(
    () => getParameters(operationId, "query"),
    [getParameters, operationId]
  );

  const cookieParameters = useMemo(
    () => getParameters(operationId, "cookie"),
    [getParameters, operationId]
  );

  const headersParameters = useMemo(
    () => getParameters(operationId, "header"),
    [getParameters, operationId]
  );

  const handleComponentAddClick = (parameterPosition: ParameterPosition) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "add-component-parameter-to-operation",
        context: {
          urlPath: operationWithInfo.urlPath,
          operationId,
          parameterPosition,
        },
      },
    });
  };

  const parameterList = [
    ...queryParameters,
    ...cookieParameters,
    ...headersParameters,
  ];

  const isEditor = canEdit(workspaceRole);
  return (
    <HideEmptyListWhenNonEditor
      list={parameterList}
      workspaceRole={workspaceRole}
    >
      <DocumentationCard>
        <DocumentationCardHeader
          btnGroup={
            isEditor ? (
              <BtnGroup>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <AddButton role="button" variant="ghost" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuGroup>
                      <DropdownMenuSub>
                        <DropdownMenuSubTrigger>Add</DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                          <DropdownMenuSubContent>
                            <DropdownMenuItem
                              onClick={() => handleAdd("query")}
                            >
                              <NormIcons.Query
                                size={DEFAULT_ICON_SIZE}
                                className="mr-2 text-muted-foreground"
                              />
                              Query parameter
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => handleAdd("header")}
                            >
                              <NormIcons.Header
                                size={DEFAULT_ICON_SIZE}
                                className="mr-2 text-muted-foreground"
                              />
                              Request header
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => handleAdd("cookie")}
                            >
                              <NormIcons.Cookie
                                size={DEFAULT_ICON_SIZE}
                                className="mr-2 text-muted-foreground"
                              />
                              Cookie
                            </DropdownMenuItem>
                          </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                      </DropdownMenuSub>
                      <DropdownMenuSub>
                        <DropdownMenuSubTrigger>
                          Add component
                        </DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                          <DropdownMenuSubContent>
                            <DropdownMenuItem
                              onClick={() => handleComponentAddClick("query")}
                            >
                              <NormIcons.Query
                                size={DEFAULT_ICON_SIZE}
                                className="mr-2 text-muted-foreground"
                              />
                              Query parameter
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => handleComponentAddClick("header")}
                            >
                              <NormIcons.Header
                                size={DEFAULT_ICON_SIZE}
                                className="mr-2 text-muted-foreground"
                              />
                              Request header
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => handleComponentAddClick("cookie")}
                            >
                              <NormIcons.Cookie
                                size={DEFAULT_ICON_SIZE}
                                className="mr-2 text-muted-foreground"
                              />
                              Cookie
                            </DropdownMenuItem>
                          </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                      </DropdownMenuSub>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </BtnGroup>
            ) : null
          }
        >
          <DocumentationCardTitle>Request parameters</DocumentationCardTitle>
        </DocumentationCardHeader>
        <DocumentationCardContent>
          {parameterList.map((parameter, i) => {
            const resolved = resolveParameterObject(parameter.parameter);
            return (
              <ParameterItem
                key={resolved.name}
                parameter={parameter}
                isLast={i === parameterList.length - 1}
                workspaceRole={workspaceRole}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                value={value}
                onChange={onChange}
                operationWithInfo={operationWithInfo}
              />
            );
          })}
          {isEditor && parameterList.length === 0 && (
            <DocumentationCardButtonGroup className="mt-2">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <AddLabelButton />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuGroup>
                    <DropdownMenuItem onClick={() => handleAdd("query")}>
                      <NormIcons.Query
                        size={DEFAULT_ICON_SIZE}
                        className="mr-2 text-muted-foreground"
                      />
                      Query parameter
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleAdd("header")}>
                      <NormIcons.Header
                        size={DEFAULT_ICON_SIZE}
                        className="mr-2 text-muted-foreground"
                      />
                      Request header
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleAdd("cookie")}>
                      <NormIcons.Cookie
                        size={DEFAULT_ICON_SIZE}
                        className="mr-2 text-muted-foreground"
                      />
                      Cookie
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </DocumentationCardButtonGroup>
          )}
        </DocumentationCardContent>
      </DocumentationCard>
    </HideEmptyListWhenNonEditor>
  );
}
