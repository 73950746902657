import { CasingPreference } from "@/components/module-api-editor/types";
import { deref } from "@/lib/oas-tools/oas-tag-helpers";
import {
  CRUDOperation,
  OASComponentsObject,
  OASReferenceObject,
  OASSchema,
  TemplateCRUDOperation,
} from "@/lib/types";
import { safeGet } from "@/lib/utils";

/**
 * Determine the type of the id. Ids can be e.g. intergers or strings
 */
function getIdSchema(
  ref: string,
  componentsObject: OASComponentsObject
): OASSchema {
  const refName = deref(ref);

  const schema = componentsObject.schemas?.[refName];
  if (!schema || !schema.properties) return { type: "string" };

  // try to get `id` property
  const idSchema = safeGet(schema.properties, "id");
  if (idSchema) return idSchema;

  // other id formats (uuid, cuid) are of type script
  return { type: "string" };
}

/**
 * constructive DTOs (put, patch, post) dont user nested models ( $ref: "#components/schemas/Book" )
 * is converted to {bookId: string}
 */
function convertOpenAPIToConstructiveDtochema(
  schema: OASSchema | OASReferenceObject,
  componentsObject: OASComponentsObject,
  casingPreference: CasingPreference
): OASSchema {
  if ("$ref" in schema) {
    return getIdSchema(schema.$ref, componentsObject);
  }

  if (schema.type === "object" && schema.properties) {
    const requiredArr: string[] = [];
    const dtoProperties: { [key: string]: OASSchema } = {};
    for (const [key, value] of Object.entries(schema.properties)) {
      const isRequired = (schema.required || []).indexOf(key) !== -1;
      if ("$ref" in value) {
        const idFieldName = `${key.charAt(0).toLowerCase() + key.slice(1)}${casingPreference === "snake_case" ? "_id" : ""}${casingPreference === "camelCase" ? "Id" : ""}`;
        dtoProperties[idFieldName] = getIdSchema(value.$ref, componentsObject);
        if (isRequired) requiredArr.push(idFieldName);
      } else {
        dtoProperties[key] = convertOpenAPIToConstructiveDtochema(
          value,
          componentsObject,
          casingPreference
        );
        if (isRequired) requiredArr.push(key);
      }
    }
    return { type: "object", properties: dtoProperties, required: requiredArr };
  }

  if (schema.type === "array") {
    return {
      type: "array",
      items: convertOpenAPIToConstructiveDtochema(
        schema.items,
        componentsObject,
        casingPreference
      ),
    };
  }
  // For primitive types or unknown types, return as is
  return { type: schema.type };
}

export function isTemplateCrudOperation(
  crudOperation: CRUDOperation
): crudOperation is TemplateCRUDOperation {
  if (
    crudOperation.method == "delete" ||
    (crudOperation.level === "detail" && crudOperation.method === "get")
  ) {
    return false;
  }
  return true;
}

export function createDtoFromOASSchema({
  schema,
  componentsObject,
  crudOperation,
  casingPreference = "snake_case",
}: {
  schema: OASSchema;
  componentsObject: OASComponentsObject;
  crudOperation: TemplateCRUDOperation;
  casingPreference?: CasingPreference;
}): OASSchema {
  switch (crudOperation.method) {
    case "patch":
    case "post":
    case "put":
      return convertOpenAPIToConstructiveDtochema(
        schema,
        componentsObject,
        casingPreference
      );
  }
  return schema;
}
