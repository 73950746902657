import { HttpStatus } from "@/lib/helpers";
import { getPristineType } from "@/lib/oas-tools/oas-schema-utils";
import { OASSchema } from "@/lib/types";
import { safeGet } from "@/lib/utils";

const httpStatusDefaultSchemas: Record<HttpStatus, OASSchema> = {
  "100": {
    type: "object",
    properties: {
      status: {
        type: "string",
        enum: ["informational"],
        default: "informational",
      },
      message: { type: "string" },
    },
    required: ["status", "message"],
  },
  "200": {
    type: "object",
    properties: {
      data: { type: "object" },
    },
    required: ["data"],
  },
  "201": {
    type: "object",
    properties: {
      data: { type: "object" },
    },
    required: ["data"],
  },
  "202": {
    type: "object",
    properties: {
      message: { type: "string" },
    },
    required: ["message"],
  },
  "203": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "204": {
    type: "null",
  }, // No content, empty response body
  "206": {
    type: "object",
    properties: {
      data: { type: "object", properties: {} },
      contentRange: { type: "string" },
    },
    required: ["data", "contentRange"],
  },
  "207": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "208": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "226": {
    type: "object",
    properties: {
      message: { type: "string" },
    },
    required: ["message"],
  },
  "300": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "301": {
    type: "object",
    properties: {
      status: { type: "string" },
      message: {
        type: "string",
      },
      location: { type: "string" },
    },
    required: ["status", "message", "location"],
  },
  "302": {
    type: "object",
    properties: {
      status: { type: "string" },
      message: { type: "string" },
      location: { type: "string" },
    },
    required: ["status", "message", "location"],
  },
  "303": {
    type: "object",
    properties: {
      status: { type: "string" },
      message: { type: "string" },
      location: { type: "string" },
    },
    required: ["status", "message", "location"],
  },
  "304": {
    type: "null",
  }, // Not Modified, empty response body
  "305": {
    type: "object",
    properties: {
      status: { type: "string" },
      message: { type: "string" },
    },
    required: ["status", "message"],
  },
  "306": {
    type: "object",
    properties: {
      status: { type: "string" },
      message: { type: "string" },
    },
    required: ["status", "message"],
  },
  "307": {
    type: "object",
    properties: {
      status: { type: "string" },
      message: {
        type: "string",
      },
      location: { type: "string" },
    },
    required: ["status", "message", "location"],
  },
  "308": {
    type: "object",
    properties: {
      status: { type: "string" },
      message: {
        type: "string",
      },
      location: { type: "string" },
    },
    required: ["status", "message", "location"],
  },
  "400": {
    type: "object",
    properties: {
      message: { type: "string" },
      errors: {
        type: "array",
        items: {
          type: "object",
          properties: {
            message: { type: "string" },
          },
          required: ["message"],
        },
      },
    },
    required: ["message"],
  },
  "401": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "402": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "403": {
    type: "object",
    properties: {
      message: { type: "string" },
    },
    required: ["message"],
  },
  "404": {
    type: "object",
    properties: {
      message: { type: "string" },
    },
    required: ["message"],
  },
  "405": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
      allowedMethods: { type: "array", items: { type: "string" } },
    },
    required: ["message", "allowedMethods"],
  },
  "406": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "407": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "408": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "409": {
    type: "object",
    properties: {
      message: { type: "string" },
    },
    required: ["message"],
  },
  "410": {
    type: "object",
    properties: {
      message: { type: "string" },
    },
    required: ["message"],
  },
  "411": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "412": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "413": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "414": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "416": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "417": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "418": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "420": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "422": {
    type: "object",
    properties: {
      message: { type: "string" },
      errors: {
        type: "array",
        items: {
          type: "object",
          properties: {
            field: { type: "string" },
            message: { type: "string" },
          },
          required: ["field", "message"],
        },
      },
    },
    required: ["message", "errors"],
  },
  "423": {
    type: "object",
    properties: {
      message: { type: "string" },
    },
    required: ["message"],
  },
  "424": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "425": {
    type: "object",
    properties: {
      message: { type: "string" },
    },
    required: ["message"],
  },
  "426": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "429": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
      retryAfter: { type: "number" },
    },
    required: ["message"],
  },
  "431": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "444": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "450": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "451": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "494": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "500": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "501": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "502": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "503": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
      retryAfter: { type: "number" },
    },
    required: ["message"],
  },
  "504": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "506": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "507": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "508": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "509": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  "510": {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
  default: {
    type: "object",
    properties: {
      message: {
        type: "string",
      },
    },
    required: ["message"],
  },
};

export function getDefaultSchemaForResponseCode(code: string) {
  const statusResponse = safeGet(httpStatusDefaultSchemas, code);
  return statusResponse
    ? structuredClone(statusResponse)
    : getPristineType("object");
}
