import { findOperationByIdOrErrorFromDefinition } from "@/lib/editor-mutations/oas-operations";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { OASDefinition, OASResponse, OASResponseObject } from "@/lib/types";
import setWith from "lodash/setWith";
import unset from "lodash/unset";
import cloneDeep from "lodash/cloneDeep";
import { getComponentResponseFromDefinition } from "@/lib/editor-mutations/oas-component-responses";

export function setResponse(
  path: string,
  definition: OASDefinition,
  response: OASResponseObject
) {
  const cp = cloneDeep(definition);
  setWith(cp, path, response, Object);
  return cp;
}

export type ResponseWithInfo = {
  responseCode: string;
  response: OASResponse;
};

export function getResponseFromDefinition({
  operationId,
  responseCode,
  definition,
}: {
  operationId: string;
  responseCode: string;
  definition: OASDefinition;
}): ResponseWithInfo | undefined {
  const operation = findOperationByIdOrErrorFromDefinition(
    definition,
    operationId
  );

  const found = operation.responses?.[responseCode];
  if (!found) return undefined;

  return {
    responseCode,
    response: found,
  };
}
export function getResponseOrErrorFromDefinition({
  operationId,
  responseCode,
  definition,
}: {
  operationId: string;
  responseCode: string;
  definition: OASDefinition;
}): ResponseWithInfo {
  const found = getResponseFromDefinition({
    operationId,
    responseCode,
    definition,
  });
  if (!found) throw new Error("Response not found");

  return found;
}

export function addResponseToOperationInDefinition({
  operationId,
  responseCode,
  definition,
  response,
  skipCheckForExisting = false,
  skipCloning = false,
}: {
  operationId: string;
  responseCode: string;
  definition: OASDefinition;
  response: OASResponse;
  skipCheckForExisting?: boolean;
  skipCloning?: boolean;
}) {
  if (!skipCheckForExisting) {
    const found = getResponseFromDefinition({
      operationId,
      responseCode,
      definition,
    });
    if (found) throw new Error("Response for given code already exists.");
  }

  const cp = skipCloning ? definition : cloneDeep(definition);
  const operation = findOperationByIdOrErrorFromDefinition(cp, operationId);

  setWith(operation, `responses.${responseCode}`, response, Object);

  return cp;
}

export function removeResponseFromDefinition({
  operationId,
  responseCode,
  definition,
}: {
  operationId: string;
  responseCode: string;
  definition: OASDefinition;
}) {
  const cp = cloneDeep(definition);
  const operation = findOperationByIdOrErrorFromDefinition(cp, operationId);
  unset(operation, `responses.${responseCode}`);
  return cp;
}

export function editResponseInDefinition({
  operationId,
  oldResponseCode,
  newResponseCode,
  definition,
  response,
}: {
  operationId: string;
  oldResponseCode: string;
  newResponseCode: string;
  definition: OASDefinition;
  response: OASResponse;
}) {
  return addResponseToOperationInDefinition({
    operationId,
    responseCode: newResponseCode,
    response,
    definition: removeResponseFromDefinition({
      operationId,
      responseCode: oldResponseCode,
      definition,
    }),
  });
}

export function dereferenceResponseInDefinition({
  operationId,
  responseCode,
  definition,
}: {
  operationId: string;
  definition: OASDefinition;
  responseCode: string;
}) {
  // get response
  const { response } = getResponseOrErrorFromDefinition({
    operationId,
    responseCode,
    definition,
  });
  if (!isReference(response)) throw new Error("Not a reference");
  // dereference top level reference
  const referencedResponseName = deref(response.$ref);
  // get component response from reference
  const componentResponse = getComponentResponseFromDefinition({
    definition,
    responseName: referencedResponseName,
  });

  if (!componentResponse) throw new Error("Component response not found");
  // copy schema into new response object

  return editResponseInDefinition({
    operationId,
    oldResponseCode: responseCode,
    newResponseCode: responseCode,
    definition,
    response: {
      description: componentResponse.description,
      content: componentResponse.content,
    },
  });
}
