export const companyInfo = {
  feedbackEmail: "support@api-fiddle.com",
  supportEmail: "support@api-fiddle.com",
  blogUrl: "https://blog.api-fiddle.com",
  scheduleCall: "https://cal.com/florian-martens/15min",
  surveys: {
    generalFeedback: "https://forms.gle/ch4KijbE2TbZaYZX8",
  },
  videos: {
    intro: "https://vimeo.com/1021674113",
  },
};

export const appLimits = {
  maxNumberOfDrafts: 2,
  maxNumberOfRepositoryAdapters: 1,
};

export const localStorageKeys = {
  legacyPreservedEditorState: "preservedEditorState",
  editorStateV1: "editorStateV1",
  editorStateV1Backup: "editorStateV1Backup",
  authTokens: "authTokens",
  workspaceInvitationToken: "workspaceInvitationToken",
};

export const queryParams = {
  editorState: "editorState",
};

export const hardCodedLimits = {
  maxEditorHistoryLength: 30,
};

export const globalVariables = {
  editorPlaceholderToken: "%%%",
};

export const testIds = {
  emptyLoadExample: "empty-load-example",
  visualRouteList: "visual-route-list",
};
