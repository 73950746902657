import { FormLabelRequired } from "@/components/form-label-required";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FieldVStack,
  FormComp,
  FormContent,
  FormHeader,
  FormTitle,
} from "@/forms";
import { WorkspaceAccessLevel } from "@/lib/main-rest-client/definitions";
import { StandardFormProps } from "@/lib/types";
import { usePatchWorkspace } from "@/queries/workspaces";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  name: z.string(),
  slug: z.string(),
  accessLevel: z.enum(["public_write", "public_read", "private_read"] as const),
});

const descriptionMap: Record<WorkspaceAccessLevel, string> = {
  private_read: "Only invited members can see or edit the workspace.",
  public_read:
    "Everyone with a link can see the workspace. Only invited users can edit the workspace.",
  public_write: "Everyone can read and edit the workspace.",
};

type FormValues = z.infer<typeof formSchema>;

export function BaseForm({
  defaultValues,
  onSubmit,
  isDisabled,
  btnTitle,
  title,
}: StandardFormProps<FormValues>) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
    disabled: isDisabled,
  });
  const access_level = form.watch("accessLevel");

  return (
    <Form {...form}>
      <FormComp onSubmit={form.handleSubmit(onSubmit)}>
        <FormHeader>
          <FormTitle>{title}</FormTitle>
        </FormHeader>
        <FormContent>
          <FieldVStack>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Name
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="slug"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Slug
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="accessLevel"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Access level
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    disabled={field.disabled}
                  >
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Access level" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="public_write">Public write</SelectItem>
                      <SelectItem value="public_read">Public read</SelectItem>
                      <SelectItem value="private_read">Private read</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    {descriptionMap[access_level]}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </FieldVStack>
          <BtnGroup className="justify-end">
            <SubmitButton disabled={form.formState.disabled}>
              {btnTitle}
            </SubmitButton>
          </BtnGroup>
        </FormContent>
      </FormComp>
    </Form>
  );
}

export function FormPatchWorkspace({
  workspaceId,
  formValues,
  organizationSlug,
  isDisabled = false,
}: {
  workspaceId: string;
  formValues: FormValues;
  organizationSlug: string;
  isDisabled?: boolean;
}) {
  const patchWorkspaceMutation = usePatchWorkspace();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = async ({
    accessLevel,
    ...values
  }) => {
    try {
      await patchWorkspaceMutation.mutateAsync({
        ...values,
        workspaceId,
        access_level: accessLevel,
      });
      await navigate({
        to: "/dash/$organizationSlug/$workspaceSlug/edit",
        params: { organizationSlug, workspaceSlug: values.slug },
      });
      toast.success("Changes saves successfully");
    } catch (error) {
      if (error instanceof AxiosError) {
        // eslint-disable-next-line
        toast.error(error.response?.data?.message || "");
      }
      toast.error(
        "Fail: Slugs must be globally unique, lowercase, and can only include '-' as a special character. Please be creative!"
      );
    }
  };

  return (
    <BaseForm
      title="Workspace properties"
      btnTitle="Save"
      onSubmit={onSubmit}
      isDisabled={patchWorkspaceMutation.isPending || isDisabled}
      defaultValues={{
        accessLevel: formValues.accessLevel,
        name: formValues.name || "",
        slug: formValues.slug || "",
      }}
    />
  );
}
