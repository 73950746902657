import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  DocumentationCard,
  DocumentationCardContent,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemButtonGroup,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import { HideEmptyListWhenNonEditor } from "@/components/module-api-editor";
import {
  ActiveOperationItem,
  SetActiveItem,
} from "@/components/module-api-editor/editor-preview-operation-active-item";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { MoreButton } from "@/components/module-visual-editor/shared-components";
import { ParameterPositioinBadge } from "@/components/special-badges";
import { ColorBadge } from "@/components/ui/color-badge";
import { useParameters } from "@/hooks/use-parameters";
import { OperationWithInfo } from "@/lib/editor-mutations/oas-operations";
import { OASParameterObject, ParameterPosition } from "@/lib/types";
import { canEdit } from "@/lib/utils";
import { useMemo } from "react";

function AuthParameterItem({
  parameter,
  parameterList,
  currentIndex,
  workspaceRole,
  value,
  onChange,
  setActiveItem,
  numberOfAuthMethods,
  activeItem,
}: {
  parameter: OASParameterObject;
  currentIndex: number;
  parameterList: OASParameterObject[];
  workspaceRole: WorkspaceRole;
  setActiveItem: SetActiveItem;
  numberOfAuthMethods: number;
  activeItem: ActiveOperationItem | undefined;
} & EditorInputProps) {
  const { setActiveElement } = useAPIEditorTools({ value, onChange });

  const handleEditClick = async () =>
    setActiveElement({ type: "general_information" });

  const isEditor = canEdit(workspaceRole);
  return (
    <DocumentationCardItem
      key={parameter.name}
      isLast={currentIndex === parameterList.length - 1}
      isSelected={
        activeItem?.type === "auth-parameter" &&
        activeItem.authParameter.name === parameter.name &&
        activeItem.authParameter.description === parameter.description
      }
      onClick={() =>
        setActiveItem({ type: "auth-parameter", authParameter: parameter })
      }
    >
      <DocumentationCardItemHeader>
        <div className="flex gap-2 items-center">
          {numberOfAuthMethods > 1 && (
            <ColorBadge color="ghost">option {currentIndex + 1}</ColorBadge>
          )}
          <ParameterPositioinBadge
            parameterPosition={parameter.in as ParameterPosition}
          />
          <DocumentationCardItemTitle>
            {parameter.name}
          </DocumentationCardItemTitle>
        </div>
        {isEditor && (
          <DocumentationCardItemButtonGroup>
            <MoreButton
              onClick={async (evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                await handleEditClick();
              }}
            >
              Edit response
            </MoreButton>
          </DocumentationCardItemButtonGroup>
        )}
      </DocumentationCardItemHeader>
    </DocumentationCardItem>
  );
}

export function AuthParameterCard({
  value,
  onChange,
  operationWithInfo,
  workspaceRole,
  setActiveItem,
  activeItem,
}: {
  operationWithInfo: OperationWithInfo;
  workspaceRole: WorkspaceRole;
  setActiveItem: SetActiveItem;
  activeItem: ActiveOperationItem | undefined;
} & EditorInputProps) {
  const operation = operationWithInfo.operation;
  const operationId = operation.operationId || "";

  const { findAuthenticationRequestParametersForOperation } = useParameters({
    value,
    onChange,
  });

  const authParameters = useMemo(() => {
    return findAuthenticationRequestParametersForOperation({ operationId });
  }, [findAuthenticationRequestParametersForOperation, operationId]);

  if (authParameters.length === 0) return null;

  return (
    <HideEmptyListWhenNonEditor
      list={authParameters}
      workspaceRole={workspaceRole}
    >
      <DocumentationCard>
        <DocumentationCardHeader>
          <DocumentationCardTitle>
            Authentication parameters
          </DocumentationCardTitle>
        </DocumentationCardHeader>
        <DocumentationCardContent>
          {authParameters.map((authParameter, i) => {
            return (
              <AuthParameterItem
                key={JSON.stringify(authParameter)}
                parameter={authParameter}
                currentIndex={i}
                parameterList={authParameters}
                value={value}
                onChange={onChange}
                workspaceRole={workspaceRole}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                numberOfAuthMethods={authParameters.length}
              />
            );
          })}
        </DocumentationCardContent>
      </DocumentationCard>
    </HideEmptyListWhenNonEditor>
  );
}
