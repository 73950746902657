import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  ActiveElement,
  EditorInputProps,
} from "@/components/module-api-editor/types";
import { createWizardOperationInDefinition } from "@/lib/editor-mutations/oas-misc";
import {
  createOperationAndParamsToDefinition,
  editOperationAndParamsInDefinition,
  findOperationByIdFromDefinition,
  findOperationByIdOrErrorFromDefinition,
  findOperationsWithInfoInDefinition,
  findOperationWithInfoInDefinition,
  removeOperationFromDefinition,
} from "@/lib/editor-mutations/oas-operations";
import {
  CRUDOperation,
  OASOperation,
  SupportedContentFormats,
  SupportedHTTPVerbs,
} from "@/lib/types";
import get from "lodash/get";
import { useCallback } from "react";

export function useOperation(props: EditorInputProps) {
  const { oas, setNewEditorState, setActiveElement } = useAPIEditorTools(props);
  const definition = oas;
  if (!definition) throw new Error("Definition not defined");

  const findOperation = useCallback(
    (urlPath: string, method: SupportedHTTPVerbs) => {
      const op = get(definition, `paths.${urlPath}.${method}`) as
        | OASOperation
        | undefined;
      return op;
    },
    [definition]
  );

  const findOperationByIdOrError = useCallback(
    (operationId: string) => {
      return findOperationByIdOrErrorFromDefinition(definition, operationId);
    },
    [definition]
  );

  const findOperationById = useCallback(
    (operationId: string) => {
      return findOperationByIdFromDefinition({ definition, operationId });
    },
    [definition]
  );

  const findOperationsWithInfo = useCallback(() => {
    return findOperationsWithInfoInDefinition(definition);
  }, [definition]);

  const createOperationAndParameters = useCallback(
    ({
      urlPath,
      method,
      operation,
    }: {
      urlPath: string;
      method: SupportedHTTPVerbs;
      operation: OASOperation;
    }) => {
      if (urlPath.endsWith("/")) urlPath = urlPath.slice(0, -1);

      const newDefinition = createOperationAndParamsToDefinition({
        definition,
        urlPath,
        method,
        newOperation: operation,
      });

      setNewEditorState({
        document_type: props.value.document_type,
        data: newDefinition,
      });
    },
    [definition, setNewEditorState, props.value.document_type]
  );

  const editOperationAndParams = useCallback(
    async ({
      oldMethod,
      oldUrlPath,
      newMethod,
      newUrlPath,
      newOperation,
    }: {
      oldUrlPath: string;
      newUrlPath: string;
      oldMethod: SupportedHTTPVerbs;
      newMethod: SupportedHTTPVerbs;
      newOperation: OASOperation;
    }) => {
      if (newUrlPath.endsWith("/")) newUrlPath = newUrlPath.slice(0, -1);

      const newDefinition = editOperationAndParamsInDefinition({
        oldMethod,
        oldUrlPath,
        newMethod,
        newOperation,
        newUrlPath,
        definition,
      });
      setNewEditorState({
        document_type: props.value.document_type,
        data: newDefinition,
      });
      await setActiveElement(
        newOperation.operationId
          ? {
              operationId: newOperation.operationId,
              type: "operation",
            }
          : undefined
      );
    },
    [definition, setActiveElement, setNewEditorState, props.value.document_type]
  );

  const findOperationWithInfo = useCallback(
    (operationId: string) => {
      return findOperationWithInfoInDefinition({ definition, operationId });
    },
    [definition]
  );

  const removeOperation = useCallback(
    async (
      urlPath: string,
      method: SupportedHTTPVerbs,
      activeElement: ActiveElement,
      operationId: string | undefined
    ) => {
      if (
        activeElement?.type === "operation" &&
        activeElement.operationId === operationId
      ) {
        await setActiveElement(undefined);
      }
      const newDefinition = removeOperationFromDefinition({
        definition,
        urlPath,
        method,
      });
      setNewEditorState({
        document_type: props.value.document_type,
        data: newDefinition,
      });
    },
    [definition, setNewEditorState, setActiveElement, props.value.document_type]
  );

  const createWizardOperation = useCallback(
    ({
      baseSchemaName,
      crudOperation,
      dtoName,
      baseUrl,
      parameterName,
      responsePropertyName,
      securitySchemes,
      tags,
      format,
    }: {
      baseSchemaName: string;
      crudOperation: CRUDOperation;
      dtoName: string | undefined;
      baseUrl: string;
      parameterName: string;
      responsePropertyName: string;
      tags: string[];
      securitySchemes: OASOperation["security"];
      format: SupportedContentFormats;
    }) => {
      const newDefinition = createWizardOperationInDefinition({
        definition: props.value.data,
        baseSchemaName,
        baseUrl,
        crudOperation,
        dtoName,
        format,
        parameterName,
        responsePropertyName,
        securitySchemes,
        tags,
      });

      setNewEditorState({
        document_type: props.value.document_type,
        data: newDefinition,
      });
    },
    [props.value.document_type, props.value.data, setNewEditorState]
  );

  return {
    findOperation,
    addOperationAndParameters: createOperationAndParameters,
    removeOperation,
    editOperationAndParams,
    findOperationByIdOrError,
    findOperationById,
    findOperationWithInfo,
    findOperationsWithInfo,
    createWizardOperation,
  };
}
