export const PROPERTY_NAME_REGEX = /^[a-zA-Z0-9_-]+$/;

export const appRegex = {
  parameterName: {
    expression: /^[a-zA-Z0-9_\-$()]+$/,
    message: "Parameter names cannot contain special characters or spaces",
  },
  propertyName: {
    expression: /^[a-zA-Z0-9_-]+$/,
    message: "Property names cannot contain special characters or spaces",
  },
  genericUrl: {
    expression:
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
    message: "Enter a valid url like: https://api.example.com/v1.",
  },
  urlPath: {
    expression: /^(?=.+)(\/([a-z0-9_.-]+|(\{[a-zA-Z0-9_.-]+\})))*\/?$/,
    message:
      "Invalid url path. Example: /{variable}/foo. Special characters are not allowed. URL path must be lower case.",
  },
  modelName: {
    expression: /^[a-zA-Z0-9]+$/,
    message: "Schema names cannot contain spaces or special characters.",
  },
};
