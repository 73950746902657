import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  DocumentationCard,
  DocumentationCardButtonGroup,
  DocumentationCardContent,
  DocumentationCardContentBadge,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemButtonGroup,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import { HideEmptyListWhenNonEditor } from "@/components/module-api-editor";
import {
  type ActiveOperationItem,
  type SetActiveItem,
} from "@/components/module-api-editor/editor-preview-operation-active-item";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  AddButton,
  AddLabelButton,
  ComponentButton,
  DeleteButton,
  EditButton,
  LinkedComponentButton,
  MoreButton,
} from "@/components/module-visual-editor/shared-components";
import { NormIcon } from "@/components/norm-icon";
import { ResponseCodeBadge, TypeBadge } from "@/components/special-badges";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useComponentResponses } from "@/hooks/use-component-responses";
import { useResponses } from "@/hooks/use-responses";
import { HttpStatus, httpStatusMap } from "@/lib/helpers";
import {
  templateResponseCodes,
  TemplateResponseCodes,
} from "@/lib/oas-tools/https-verb-status-codes";
import { getDeepSchemaOrError } from "@/lib/oas-tools/oas-schema-utils";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASContentObject,
  OASResponse,
  OASSchema,
  SupportedHTTPVerbs,
} from "@/lib/types";
import { canEdit, safeGet, typedIncludes } from "@/lib/utils";
import inRange from "lodash/inRange";
import { useMemo } from "react";

export function ResponsesCard({
  operationId,
  path,
  method,
  urlPath,
  onChange,
  value,
  setActiveItem,
  workspaceRole,
  activeItem,
}: {
  operationId: string;
  path: string;
  method: SupportedHTTPVerbs;
  urlPath: string;
  setActiveItem: SetActiveItem;
  workspaceRole: WorkspaceRole;
  activeItem: ActiveOperationItem | undefined;
} & EditorInputProps) {
  const { getResponses, removeResponse } = useResponses({ value, onChange });
  const {
    getComponentResponseOrError,
    addTemplateComponentResponseToOperation: addComponentResponseToOperation,
  } = useComponentResponses({ value, onChange });
  const { setActiveElement } = useAPIEditorTools({ value, onChange });

  const [, actionBarDispatch] = useActionBarContext();

  const responseList = useMemo(
    () => Object.entries(getResponses(path) || {}),
    [getResponses, path]
  );

  const onRemove = (responseCode: string) => {
    // IMPORTANT: Reset active item so path in `QuickEditor`
    // does not become invalid
    setActiveItem(undefined);
    removeResponse({ operationId, responseCode });
  };

  const handleEditClick = ({
    response,
    responseCode,
  }: {
    response: OASResponse;
    responseCode: string;
    responsePath: string;
  }) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "response-edit",
        onInteractOutside: (e) => {
          e.preventDefault();
        },
        context: {
          operationId,
          response,
          method,
          responseCode,
        },
      },
    });
  };

  const onAdd = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "response-add",
        context: { operationId, method },
      },
    });
  };

  const onSuggestionsClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "suggested-responses",
        context: {
          method,
          urlPath,
          operationId,
        },
      },
    });
  };

  const handleReferenceClick = async (responseName: string) =>
    setActiveElement({ type: "response", responseName });

  const handleMakeReference = (responseCode: HttpStatus) => {
    addComponentResponseToOperation({
      operationId,
      responseCode: responseCode as TemplateResponseCodes,
      skipCheckForExisting: true,
      format: "application/json",
    });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <HideEmptyListWhenNonEditor
      workspaceRole={workspaceRole}
      list={responseList}
    >
      <DocumentationCard>
        <DocumentationCardHeader
          btnGroup={
            isEditor ? (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <AddButton />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem onClick={onAdd}>
                    <NormIcon name="Add" size="sm" className="mr-2" />
                    Add
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={onSuggestionsClick}>
                    <NormIcon name="Component" size="sm" className="mr-2" />
                    Add component
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : null
          }
        >
          <DocumentationCardTitle>Responses</DocumentationCardTitle>
        </DocumentationCardHeader>
        <DocumentationCardContent>
          {responseList.map((r, i) => {
            const [code, response] = r;

            const responseCode = code;
            const responsePath = `${path}.${code}`;

            let schema: OASSchema;
            let contentObject: OASContentObject | undefined;
            const isRef = isReference(response);
            if (isRef) {
              const responseName = deref(response.$ref);
              const res = getComponentResponseOrError(responseName);

              schema = getDeepSchemaOrError(res.responseObject.content);
              contentObject = res.responseObject.content;
            } else {
              schema = getDeepSchemaOrError(response.content);
              contentObject = response.content;
            }

            const onEdit = () =>
              handleEditClick({
                response,
                responsePath,
                responseCode,
              });

            const canReference = typedIncludes(
              templateResponseCodes,
              responseCode
            );

            const refName = isReference(response)
              ? deref(response.$ref)
              : undefined;

            const showType = inRange(+code, 200, 299) || code === "default";

            return (
              <DocumentationCardItem
                key={`response-${operationId}-${code}`}
                isSelected={
                  activeItem?.type === "response" &&
                  activeItem.responseCode === responseCode
                }
                onClick={() =>
                  setActiveItem({ type: "response", responseCode })
                }
                isLast={i === responseList.length - 1}
              >
                <DocumentationCardItemHeader>
                  <div className="flex gap-2 items-center">
                    <ResponseCodeBadge code={code as HttpStatus}>
                      {code}
                    </ResponseCodeBadge>

                    <DocumentationCardContentBadge
                      contentObject={contentObject}
                    />

                    {showType && (
                      <TypeBadge forceColor="ghost" schema={schema} />
                    )}

                    {refName && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <LinkedComponentButton
                            onClick={async (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              await handleReferenceClick(refName);
                            }}
                          />
                        </TooltipTrigger>
                        <TooltipContent>Go to component</TooltipContent>
                      </Tooltip>
                    )}
                    <DocumentationCardItemTitle>
                      {safeGet(httpStatusMap, responseCode)?.message || ""}
                    </DocumentationCardItemTitle>
                  </div>
                  {isEditor && (
                    <DocumentationCardItemButtonGroup>
                      {!refName && canReference && (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <ComponentButton
                              onClick={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                                handleMakeReference(responseCode);
                              }}
                            >
                              <span className="sr-only">
                                Turn into component
                              </span>
                            </ComponentButton>
                          </TooltipTrigger>
                          <TooltipContent>Merge into component</TooltipContent>
                        </Tooltip>
                      )}
                      {isRef ? (
                        <MoreButton onClick={onEdit}></MoreButton>
                      ) : (
                        <EditButton onClick={onEdit}>Edit response</EditButton>
                      )}
                      <DeleteButton
                        onClick={(evt) => {
                          evt.stopPropagation();
                          onRemove(responseCode);
                        }}
                      >
                        Delete response
                      </DeleteButton>
                    </DocumentationCardItemButtonGroup>
                  )}
                </DocumentationCardItemHeader>
              </DocumentationCardItem>
            );
          })}
          {isEditor && responseList.length === 0 && (
            <DocumentationCardButtonGroup className="mt-2">
              <AddLabelButton className="mr-0" onClick={onAdd} />
            </DocumentationCardButtonGroup>
          )}
        </DocumentationCardContent>
      </DocumentationCard>
    </HideEmptyListWhenNonEditor>
  );
}
