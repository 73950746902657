import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { H3 } from "@/components/headings";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { WorkspaceInvitationResultDto } from "@/lib/main-rest-client/definitions";
import { useCreateWorkspaceInvitation } from "@/queries/workspace-invitation";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  email: z.string().email(),
  role: z.enum(["admin", "editor", "reader"]),
});

type FormValues = z.infer<typeof formSchema>;

function RawForm({
  defaultValues,
  onSubmit,
  btnTitle,
  title,
  isLoading,
}: {
  defaultValues: Partial<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
  title: string;
  btnTitle: string;
  isLoading: boolean;
}) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      role: "reader",
      email: "",
      ...defaultValues,
    },
  });

  return (
    <div className="">
      <div className="space-y-2 pb-4">
        <H3 className="text-muted-foreground">{title}</H3>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid gap-2">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="grid grid-cols-3 items-center gap-4">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      className="col-span-2 h-8"
                      placeholder="member@api-fiddle.com"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem className="grid grid-cols-3 items-center gap-4">
                  <FormLabel>Role</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl className="col-span-2 h-8">
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="reader">Reader</SelectItem>
                      <SelectItem value="editor">Editor</SelectItem>
                      <SelectItem value="admin">Admin</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
          </div>
          <BtnGroup className="pt-4 justify-end">
            <SubmitButton disabled={isLoading}>{btnTitle}</SubmitButton>
          </BtnGroup>
        </form>
      </Form>
    </div>
  );
}

export function CreateInvitationForm({
  workspaceId,
  onSuccess,
  onError,
  onSettled,
}: {
  workspaceId: string;
  onSuccess?: (data: WorkspaceInvitationResultDto) => unknown;
  onError?: () => unknown;
  onSettled?: () => unknown;
}) {
  const createInvitationMutation = useCreateWorkspaceInvitation();

  const handleSubmit: SubmitHandler<FormValues> = (values) => {
    createInvitationMutation.mutate(
      {
        email: values.email,
        role: values.role,
        workspace_id: workspaceId,
      },
      {
        onSuccess: (data) => {
          onSuccess?.(data);
        },
        onError: () => {
          onError?.();
        },
        onSettled: () => {
          onSettled?.();
        },
      }
    );
  };
  return (
    <RawForm
      defaultValues={{ role: "reader" }}
      onSubmit={handleSubmit}
      title="Create invitation"
      btnTitle="Send"
      isLoading={createInvitationMutation.isPending}
    />
  );
}
