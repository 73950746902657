import { AlertDescription, AlertTitle } from "@/components/ui/alert";
import { companyInfo } from "@/lib/const";
import { OASDefinition } from "@/lib/types";
import { cn, toastError } from "@/lib/utils";
import React, { useCallback, useState } from "react";
import { toast } from "sonner";
import yaml from "yaml";

const MAX_FILE_SIZE = 1024 * 1024; // 1MB

interface OpenAPIDocument {
  openapi?: string;
  swagger?: string;
  info?: {
    [key: string]: any;
  };
  [key: string]: any;
}

interface ImportOpenAPIFileProps {
  onSuccessfulUpload: (openApiObject: OpenAPIDocument) => void;
}

const isOpenAPIFile = (content: unknown): content is OASDefinition => {
  if (
    typeof content !== "object" ||
    content == null ||
    !("openapi" in content)
  ) {
    throw new Error(
      "Invalid OpenAPI File: Missing top-level key or not an object"
    );
  }

  if (content.openapi !== "3.1.0") {
    throw new Error("Unsupported version: Only 3.1.0 is supported");
  }

  return true;
};

const addOpenAPIExtension = (content: OASDefinition): OASDefinition => {
  if (!content.info) {
    throw new Error("content.info is not defined");
  }
  // eslint-disable-next-line
  (content.info as any)["x-fiddle-import-file"] = true;
  return content;
};

const Dropzone: React.FC<{
  onFileDrop: (file: File) => void;
}> = ({ onFileDrop }) => {
  const [isOver, setIsOver] = useState(false);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsOver(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      onFileDrop(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    if (!isOver) setIsOver(true);
    e.preventDefault();
  };

  return (
    <div className="flex flex-col items-stretch gap-4">
      <input
        type="file"
        id="file-input"
        accept=".json,.yaml,.yml"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) onFileDrop(file);
        }}
        style={{ display: "none" }}
      />
      <label htmlFor="file-input" className="my-4">
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={(evt) => {
            evt.preventDefault();
            setIsOver(false);
          }}
          className={cn(
            `px-6 py-20  bg-accent/80 hover:bg-accent cursor-pointer rounded-lg text-center border border-transparent border-dashed`,
            { "bg-accent border-border": isOver }
          )}
        >
          <p>Drop your OpenAPI file here, or click</p>
          <p className="text-sm text-gray-500 mt-2">
            Supported formats: .json, .yaml, .yml (max 1MB)
          </p>
        </div>
      </label>
      <div className="flex items-center gap-2 text-red-400 p-2 rounded-md bg-red-900/20 px-4">
        <div>
          <AlertTitle className="font-bold">
            This can break if your file was NOT created with API-Fiddle
          </AlertTitle>
          <AlertDescription>
            API contracts can get complicated. If you're using a third-party API
            contract, the editor may crash immediately or at a later point in
            time. You can try to import and{" "}
            <a
              href={`mailto:${companyInfo.supportEmail}`}
              className="underline"
            >
              let us know
            </a>{" "}
            if you get stuck.
          </AlertDescription>
        </div>
      </div>
    </div>
  );
};

const ImportOpenAPIFile: React.FC<ImportOpenAPIFileProps> = ({
  onSuccessfulUpload,
}) => {
  const handleFileDrop = useCallback(
    (file: File) => {
      if (file.size > MAX_FILE_SIZE) {
        toast.error("File size exceeds 1MB limit");
        return;
      }

      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        try {
          const fileContent = event.target?.result as string;
          const fileExtension = file.name.split(".").pop()?.toLowerCase();
          let parsedContent: any;

          if (fileExtension === "json") {
            parsedContent = JSON.parse(fileContent) as OASDefinition;
          } else if (fileExtension === "yaml" || fileExtension === "yml") {
            parsedContent = yaml.parse(fileContent) as OASDefinition;
          } else {
            throw new Error("Unsupported file format");
          }

          if (!isOpenAPIFile(parsedContent)) {
            throw new Error("Not a valid OpenAPI file");
          }

          const modifiedContent = addOpenAPIExtension(parsedContent);
          onSuccessfulUpload(modifiedContent);
        } catch (err) {
          toastError(err);
        }
      };

      reader.readAsText(file);
    },
    [onSuccessfulUpload]
  );

  return (
    <div>
      <Dropzone onFileDrop={handleFileDrop} />
    </div>
  );
};

export default ImportOpenAPIFile;
