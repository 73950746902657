import { ErrorFieldValue } from "@/components/error-field-value";
import { PreviewReferenceTag } from "@/components/module-preview-pane/preview-reference-tag";
import {
  type OASRow,
  type OASRowFormat,
  type PreviewRow,
} from "@/lib/oas-tools/generate-oas-operation-rows";
import { type OASSchemaRow } from "@/lib/oas-tools/generate-schema-rows";
import { getComponentSchemaFromComponentsObject } from "@/lib/oas-tools/oas-schema-utils";
import {
  deref,
  findComposedKey,
  getMixedTagStringValue,
  isAnySchema,
  isArraySchema,
  isComposedSchema,
  isMixedSchema,
  isObjectSchema,
  isReferenceSchema,
  isSimpleSchema,
} from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASArraySchemaObject,
  OASComponentsObject,
  OASMixedSchemaObject,
  OASNonArraySchemaObject,
  OASReferenceObject,
  OASSchema,
} from "@/lib/types";
import { cn } from "@/lib/utils";
import { ComponentProps, PropsWithChildren, useMemo } from "react";

function Tag({
  children,
  className,
  ...rest
}: PropsWithChildren<ComponentProps<"span">>) {
  return (
    <span className={cn("", className)} {...rest}>
      {children}
    </span>
  );
}

function MixedSchemaTag({ schema }: { schema: OASMixedSchemaObject }) {
  const value = useMemo(() => getMixedTagStringValue(schema), [schema]);
  return <Tag>{value}</Tag>;
}

function ComposedSchemaTag({ schema }: { schema: OASNonArraySchemaObject }) {
  const value = useMemo(() => {
    return findComposedKey(schema);
  }, [schema]);
  return <Tag>{value}</Tag>;
}

function SimpleSchemaTag({ schema }: { schema: OASNonArraySchemaObject }) {
  return <Tag>{schema.type}</Tag>;
}

function AnySchemaTag({
  schema: _schema,
}: {
  schema: OASNonArraySchemaObject;
}) {
  return <Tag>any</Tag>;
}

function UnsupportedSchemaTag({
  schema: _schema,
}: {
  schema: OASNonArraySchemaObject;
}) {
  return <Tag>Unsupported</Tag>;
}

function ObjectSchemaTag({
  schema: _schema,
}: {
  schema: OASNonArraySchemaObject;
}) {
  return <Tag>object</Tag>;
}

function ArraySchemaTag({
  schema,
  componentsObject,
}: {
  schema: OASArraySchemaObject;
  componentsObject: OASComponentsObject;
}) {
  return (
    <Tag>
      array[
      <RowChild schema={schema.items} componentsObject={componentsObject} />]
    </Tag>
  );
}

function ReferenceSchemaTag({
  schema,
  componentsObject,
}: {
  schema: OASReferenceObject;
  componentsObject: OASComponentsObject;
}) {
  const componentId = {
    id: deref(schema.$ref, ""),
    type: "schema",
  } as const;

  const componentSchema = getComponentSchemaFromComponentsObject(
    componentsObject,
    componentId
  );
  if (!componentSchema) {
    return (
      <ErrorFieldValue tooltipMessage="Unable to find this schema. Remove references to deleted schemas." />
    );
  }

  return (
    <Tag>
      <PreviewReferenceTag schema={componentSchema} />
      <span className={cn("py-0 h-auto")}>
        &lt;{deref(schema.$ref, "")}&gt;{" "}
      </span>
    </Tag>
  );
}

function RowChild({
  schema,
  componentsObject,
}: {
  schema: OASSchema | OASReferenceObject;
  componentsObject: OASComponentsObject;
}) {
  if (isReferenceSchema(schema))
    return (
      <ReferenceSchemaTag schema={schema} componentsObject={componentsObject} />
    );
  if (isArraySchema(schema))
    return (
      <ArraySchemaTag schema={schema} componentsObject={componentsObject} />
    );
  if (isMixedSchema(schema)) return <MixedSchemaTag schema={schema} />;
  if (isComposedSchema(schema)) return <ComposedSchemaTag schema={schema} />;
  if (isObjectSchema(schema)) return <ObjectSchemaTag schema={schema} />;
  if (isSimpleSchema(schema)) return <SimpleSchemaTag schema={schema} />;
  if (isAnySchema(schema)) return <AnySchemaTag schema={schema} />;

  console.error(`Unsupported schema: ${schema}`);
  return <UnsupportedSchemaTag schema={schema} />;

  throw new Error("Unknown row child");
}

export function RowBase({
  children,
  level,
  propertyName,
  format,
  className,
  isRequired,
  hideRequiredToggle,
  kind,
  ...rest
}: ComponentProps<"div"> & {
  level: number;
  propertyName?: string;
  format: OASRowFormat;
  isRequired: boolean;
  hideRequiredToggle: boolean;
  kind: PreviewRow["kind"];
}) {
  const displayPropertyName = (propertyName: string) => {
    if (propertyName.startsWith("%%%")) {
      return "[...]";
    }
    return `${propertyName}`;
  };
  return (
    <div
      className={cn("", { "font-bold": format === "bold" }, className)}
      style={{}}
      {...rest}
    >
      {" ".repeat(level * 2)}
      {propertyName && (
        <span>{displayPropertyName(propertyName)}:&nbsp;&nbsp;</span>
      )}
      {children}
      {!hideRequiredToggle && !isRequired && kind === "oas-schema-row" && (
        <span className=""> | null</span>
      )}
    </div>
  );
}

export function OASSchemaRow({
  row,
  componentsObject,
  className,
}: {
  row: OASSchemaRow;
  componentsObject: OASComponentsObject;
  className?: string;
}) {
  return (
    <RowBase
      level={row.level}
      propertyName={row.propertyName}
      format={undefined}
      isRequired={row.isRequired}
      hideRequiredToggle={row.hideRequiredToggle}
      kind={row.kind}
      className={className}
    >
      <RowChild schema={row.schema} componentsObject={componentsObject} />
    </RowBase>
  );
}

function isOASRow(row: PreviewRow): row is OASRow {
  return row.kind === "oas-row";
}

function isOASSchemaRow(row: PreviewRow): row is OASSchemaRow {
  return row.kind === "oas-schema-row";
}

function OperationHeaderRow({ row }: { row: OASRow }) {
  if (row.rowType !== "header")
    throw new Error("Row is not of type 'operation-header'");
  const headerSplit = useMemo(() => {
    if (row.rowType !== "header") return [];
    return row.text.split(" ");
  }, [row.text, row.rowType]);
  return (
    <div>
      <span
        className={cn("font-bold", {
          // !!! DONT CHANGE WITHOUT CHANGING INLINE CSS FOR PREVIEW
          "text-red-400": headerSplit[0].toLowerCase() === "delete",
          "text-green-400": headerSplit[0].toLowerCase() === "get",
          "text-yellow-400": headerSplit[0].toLowerCase() === "head",
          "text-gray-400": headerSplit[0].toLowerCase() === "options",
          "text-indigo-400": headerSplit[0].toLowerCase() === "patch",
          "text-blue-400": headerSplit[0].toLowerCase() === "post",
          "text-teal-400": headerSplit[0].toLowerCase() === "put",
          "text-emerald-400": headerSplit[0].toLowerCase() === "trace",
        })}
      >
        {headerSplit[0].toUpperCase()}&nbsp;&nbsp;
      </span>
      <span>{headerSplit[1]}</span>
    </div>
  );
}

function ModelHeaderRow({ row }: { row: OASRow }) {
  if (row.rowType !== "model-header")
    throw new Error("Row is not of type 'operation-header'");
  return (
    <div className={cn("font-bold text-blue-400")}>
      <span>{row.text}</span>
    </div>
  );
}

function TextRow({ row, className }: { row: OASRow } & ComponentProps<"div">) {
  return (
    <p
      className={cn(className, {
        "text-muted-foreground": row.format === "muted",
      })}
    >
      {row.text === " " ? <>&nbsp;</> : row.text}
    </p>
  );
}

export const rowSeparator = "----------------------------------------";

function SeparatorRow({ className }: { className?: string }) {
  return (
    <p className={cn(className, "text-muted-foreground")}>{rowSeparator}</p>
  );
}

function OASRow({ row, className }: { row: OASRow; className?: string }) {
  const Child = () => {
    switch (row.rowType) {
      case "header":
        return <OperationHeaderRow row={row} />;
      case "description":
        return <TextRow className="whitespace-pre-wrap" row={row} />;
      case "heading":
        return <TextRow className="text-md" row={row} />;
      case "response-code":
      case "text":
        return <TextRow className="whitespace-pre-wrap" row={row} />;
      case "model-header":
        return <ModelHeaderRow row={row} />;
      case "separator":
        return <SeparatorRow />;
      default:
        const exhaustiveCheck: never = row.rowType;
        throw new Error(`Unexpected row type: ${exhaustiveCheck}`);
    }
  };

  return (
    <RowBase
      level={row.level}
      propertyName={row.propertyName}
      format={row.format}
      className={className}
      hideRequiredToggle={true} // hardcoded placeholder
      isRequired={false} // hardcoded placeholder
      kind="oas-row"
    >
      <Child />
    </RowBase>
  );
}

export function PreviewRow({
  row,
  componentsObject,
}: {
  row: PreviewRow;
  componentsObject: OASComponentsObject;
}) {
  const cls = { "pl-5": row.extraInsetLevel === 1 };
  if (isOASRow(row)) return <OASRow className={cn(cls)} row={row} />;
  if (isOASSchemaRow(row))
    return (
      <OASSchemaRow
        className={cn(cls, "text-muted-foreground")}
        row={row}
        componentsObject={componentsObject}
      />
    );
  throw new Error("Unknown row type");
}
